import form from "./form"

const initialState = {
    cost_code: null,
}

export default function addCostCode(state = initialState, action) {
    switch (action.type) {
        default:
            // Use default form reducer if none of these cases match
            if (action.form == "addCostCode") {
                return form(state, action, initialState)
            } else {
                return state
            }
    }
}
