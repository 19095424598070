import { ReportsPageContainer, CustomDashboard } from "./lazy-components"
import { navCategories } from "../common/constants"

import fieldFormsIconSrc from "../../images/sidebar_icons/field-forms.svg"
import { slugify } from "../common/ts-utils"
import structuredClone from "@ungap/structured-clone"
import { getFlagEnabled } from "../getFlagValue"

/**
 * Collect all the routes for the provided list of listViews. nestedFolderPaths should only be included for the
 * <RouteList /> component, and an empty list should be used for the sidebar wrapper since we don't want it
 * to create menu items for nested folder list views.
 * @param {Record<string, any>[]} listViews all list views available to the current user
 * @param {string[]} nestedFolderPaths list of path strings for all schema nav_folder_path values
 * @returns {Record<string, any>[]} list of Nav Routes
 */
export const getListViewRoutes = (listViews, nestedFolderPaths) => {
    const listRoutes = []
    // this will consist of bundle list views and nested folder list views - they won't have show_nav_link checked
    const relatedListViews = {}
    listViews?.forEach(listView => {
        if (!listView?.show_nav_link && listView.title) {
            relatedListViews[listView.title] = listView
        }
    })
    for (const listView of listViews) {
        /**
         * List views have settings for `show_nav_link` and `use_nav_folder_paths`
         * If a list view has both settings checked, we will build out dynamic nested list views based on
         * a list of nestedFolderPaths, which come from the currentUser along with listViews.
         * If the list view has only `show_nav_link` checked it is a flat list view with a single route.
         * If the list view does not have `show_nav_link` checked, it will be ignored unless it matches
         * a nested list view path.
         */
        const matchingPaths = nestedFolderPaths?.filter(path => path.startsWith(listView.title))
        if (
            getFlagEnabled("WA-7882-nested-left-nav") &&
            listView.show_nav_link &&
            listView.use_nav_folder_paths &&
            matchingPaths?.length
        ) {
            // go through all the matching paths and create routes for them
            matchingPaths.forEach(path => {
                const folders = path.split("/")
                let nestedListView = structuredClone(listView)
                folders.forEach((path, index) => {
                    const leafTitle = folders[index]
                    const partialPath = folders.slice(0, index + 1).join("/")
                    // we allow ProServe to designate nested list views, so if a parent folder has a list view
                    // apply that list view to all the child items as well.
                    // If a child has its own list view that will win out over the parent list view.
                    if (relatedListViews?.[partialPath]) {
                        nestedListView = relatedListViews[partialPath]
                    }
                    if (listView.related_list_view && !nestedListView.related_list_view) {
                        nestedListView.related_list_view = listView.related_list_view
                    }
                    // we create routes for every level of the folder path
                    const route = {
                        path: `/rhumbix/field-forms/list-view/${slugify(partialPath)}`,
                        authRequired: true,
                        wrapper: ReportsPageContainer,
                        component: CustomDashboard,
                        listView: {
                            ...nestedListView,
                            title: partialPath,
                            show_nav_link: false,
                            slug: slugify(partialPath),
                        },
                        navTitle: leafTitle,
                        navIconSrc: fieldFormsIconSrc,
                        navCategory: navCategories.fieldForms,
                        exact: true,
                    }
                    listRoutes.push(route)
                })
            })
        } else if (listView.show_nav_link) {
            const route = {
                path: `/rhumbix/field-forms/list-view/${listView.slug}`,
                authRequired: true,
                wrapper: ReportsPageContainer,
                component: CustomDashboard,
                listView: listView,
                navTitle: listView.title,
                navIconSrc: fieldFormsIconSrc,
                navCategory: navCategories.fieldForms,
                exact: true,
            }
            listRoutes.push(route)
        }
    }
    return listRoutes
}
