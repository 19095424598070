import _uniqWith from "lodash/uniqWith"
import _isEqual from "lodash/isEqual"
import _mapKeys from "lodash/mapKeys"
import { error as Errors } from "../../strings"
import { statusOptionsMap } from "../../common/constants"

/**
 * Verify all the required headers and data are included in the uploaded csv file.
 * Values might not get inserted properly if the non-required csv headers are wrong,
 * since they cannot be mapped.
 * @param {Object[]} data Uploaded data.
 * @param {string[]} csvHeaders Headers in the uploaded csv file.
 * @param {string[]} requiredHeaders Required headers in the csv file.
 * @param {Object[]} errors Errors.
 * @param {string} errorType Type of error.
 * @returns {true|null} Returns true if there is no error.
 */
export const validateDataLengthAndHeaders = (
    data: Array<Record<string, any>>,
    csvHeaders: Array<string>,
    requiredHeaders: Array<string>,
    errors: Array<Record<string, any>>,
    errorType: string
): boolean => {
    if (!data.length) {
        errors.push({ "*": [Errors[errorType]] })
        return false
    }
    // Check if all the required headers are included in the uploaded csv file.
    const headersSet: Set<string> = new Set()
    for (const header of csvHeaders) {
        // On a number of pages, the column header may have an asterisk to mark it
        // required. You may need to export from a page without the asterisk and
        // import to one that has it, so ignore that in the comparison
        headersSet.add(cleanHeader(header))
    }
    const missingHeaders = requiredHeaders.filter(header => {
        const coreHeader = cleanHeader(header)
        if (!headersSet.has(coreHeader)) return coreHeader
    })
    if (!missingHeaders.length) return true
    errors.push({
        Header: [[`Missing required header(s): ${missingHeaders.join(", ")}. Please use the example csv file.`]],
    })
    return false

    // TODO: Per the typescript warning, this is a weird function that can return nothing, or a boolean ...
    // might be worth making a new version
}

/**
 * Validate the status field for a row of the CSV file. Most of the files have a status field,
 * and they follow the same rules: the field must be populated and use one of the recognized strings
 *
 * @param {string} status The value provided in the file. May be an empty string (which will trigger an error)
 * @param {Object} rowErrors  Error messages are added to this collection
 */
export const validateStatus = (status: string, rowErrors: Record<string, any>): void => {
    const statusOptions = Object.values(statusOptionsMap)

    if (status) {
        // Make sure a valid string was entered. (The user may also have plugged in a
        // boolean, which will work)
        if (typeof status === "string") {
            if (!statusOptions.map(v => v.toUpperCase()).includes(status.trim().toUpperCase())) {
                rowErrors["Status"] = [
                    `${status} is an invalid option. Valid options are: ` +
                        `${statusOptions.map(v => `"${v}"`).join(", ")}`,
                ]
            }
        }
    }

    // If no status was entered, the endpoint will default to "Active"
}

/**
 * Convert the csv headers to the corresponding db fields.
 * @param {Object[]} csvData Uploaded csv data.
 * @param {Object} headersMap Map from csv headers to field names in db.
 * @returns {Object[]} Returns processed csv data.
 */
export const parseCsv = (
    csvData: Array<Record<string, any>>,
    headersMap: Record<string, any>
): Array<Record<string, any>> => {
    const data = _uniqWith(csvData, _isEqual) // Remove duplicates in the csv file.

    const cleanedHeadersMap: Record<string, any> = {}

    // Clean the keys in the headers map to remove extraneous characters
    Object.keys(headersMap).forEach(key => (cleanedHeadersMap[cleanHeader(key)] = headersMap[key]))

    // Compare the clean headers in the map to the cleaned headers in the incoming data
    return data.map(item => {
        item = _mapKeys(item, (v_, key) => cleanedHeadersMap[cleanHeader(key)])

        // If unexpected values are found in the item, they will be converted to a
        // property named Undefined, which we don't need - clean that up
        if (item["undefined"]) delete item["undefined"]
        return item
    })
}

/**
 * Compare the incoming csvData to the headers map and identify any columns that
 * don't match the map
 * @param csvData       The incoming CSV file data
 * @param headersMap    The expected headers
 * @returns An array of headers for the unexpected columns, or an empty array if none was found
 */
export const findUnexpectedColumns = (
    csvData: Array<Record<string, any>>,
    headersMap: Record<string, any>
): string[] => {
    if (csvData?.length > 0) {
        const expectedHeaders = Object.keys(headersMap).map(key => cleanHeader(key))
        const incomingHeaders = Object.keys(csvData[0]).map(key => cleanHeader(key))

        return incomingHeaders.filter(key => !expectedHeaders.find(k => k === key))
    }

    return []
}

// The headers across different screens and upload templates may have different characters around the
// core text. For example, they may add an asterisk (or two) on one page and have none on another.
// This helper function strips out those nonessential characters to ensure that the core headers are
// comparable
const cleanHeader = (header: string): string => header?.replace(/\*/g, "")
