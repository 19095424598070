const initialState = {
    submitting: false,
    fetchingFormats: false,
    formats: [],
    selectedFormat: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "EXPORT_ACTION_STARTED":
            return Object.assign({}, state, { submitting: true })
        case "EXPORT_ACTION_COMPLETED":
            return Object.assign({}, state, { submitting: false })
        case "FETCHING_EXPORT_FORMATS":
            return Object.assign({}, state, { fetchingFormats: true })
        case "UPDATE_EXPORT_FORMATS":
            return Object.assign({}, state, {
                formats: action.data,
                fetchingFormats: false,
                selectedFormat: action.data.length > 0 ? action.data[0] : null,
            })
        case "UPDATE_EXPORT_FORMAT_SELECTED":
            return Object.assign({}, state, { selectedFormat: action.data })
        default:
            return state
    }
}
