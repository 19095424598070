import { AnalyticsDashboard } from "./lazy-components"
import { navCategories } from "../common/constants"

import { tRoute, tDashboard } from "./types"
import analyticsIconSrc from "../../images/sidebar_icons/analytics.svg"

export const getAnalyticsRoutes = (dashboards: Array<tDashboard>): Array<tRoute> => {
    const analyticsRoutes: Array<tRoute> = []
    for (const dashboard of dashboards) {
        if (dashboard.category === "ANALYTICS" && dashboard.url) {
            const route: tRoute = {
                path: `/rhumbix/analytics/${dashboard.url_slug}/`,
                authRequired: true,
                component: AnalyticsDashboard,
                navTitle: dashboard.name,
                navIconSrc: analyticsIconSrc,
                navCategory: navCategories.analytics,
            }
            analyticsRoutes.push(route)
        }
    }
    return analyticsRoutes
}
