const initialState = {
    features: {},
}

export default function featureFlags(state = initialState, action) {
    switch (action.type) {
        case "FEATURES_UPDATE":
            return Object.assign({}, state, { features: action.data })
        default:
            return state
    }
}
