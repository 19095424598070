import React from "react"

import "sidebar.less"

type tProps = {
    isSidebarCollapsed: boolean
}

const Status = (props: tProps): JSX.Element | null => {
    return !props.isSidebarCollapsed ? (
        <div className="collapsible-menu-group sidebar-left-padding sidebar-menu-item-container">
            <a
                className="sidebar-status-link no-hover-menu-item"
                href="http://status.rhumbix.com/"
                target="_blank"
                rel="noopener noreferrer"
            >
                RHUMBIX STATUS
            </a>
        </div>
    ) : null
}

export default Status
