import cloneDeep from "clone-deep"
import { AnyAction as iAnyAction } from "redux"

import { actionTypesForResource } from "./constants"
import {
    iKeyedResourcePaginationState,
    iMutableEntityState,
    iMutablePaginationState,
    iMutableLoadingState,
    iResourcePaginationState,
    tCachedResourceName,
    eEntityActionType,
    tEntityState,
    tPaginateArgs,
    tPaginationState,
    tLoadingState,
} from "./types"

// Constants ------------------------------------------------------------------

// Actions that indicate cache invalidation should occur
export const CACHE_INVALIDATING_ACTIONS = [
    "INVALIDATE_CACHE",
    "SOURCE_DATA_ADDED",
    "SOURCE_DATA_UPDATED",
    "SOURCE_DATA_DELETED",
]

// Cached resource names
export const CACHED_RESOURCE_NAMES: tCachedResourceName[] = [
    "cohorts",
    "companyAbsenceTypes",
    "companyFormSchemas",
    "companyGroups",
    "companyStartStopTypes",
    "costCodes",
    "costCodeControls",
    "companyCrewTypes",
    "employees",
    "employeeTrades",
    "employeeClassifications",
    "guestFormShares",
    "projectEmployees",
    "employeeSchemas",
    "equipment",
    "projectEquipment",
    "projects",
    "materials",
    "companies",
    "schemaStatusNames",
    "analyticsDashboards",
    "companyTrades",
    "companyClassifications",
    "picklistItems",
    "timekeepingStatuses",
    "workShifts",
]

// A listing of entity types (and their related entities, if any) for cache
// invalidation purposes.
//
// When an entity's cache is invalidated, we also need to invalidate the cache
// for any related entities. E.g. if we add a cost code, the project it's
// associated with in the cache will be stale because it won't reflect the
// relationship between the new cost code and itself.
//
// EVERY CACHED ENTITY MUST HAVE AN ENTRY HERE. If it has no relationships
// to other entities, assign it an empty array.
const entityRelationships: { [key in tCachedResourceName]: tCachedResourceName[] } = {
    cohorts: ["employees"],
    cohortEmployees: ["cohorts"],
    companyAbsenceTypes: [],
    companyFormSchemas: [],
    companyFormStores: [],
    companyGroups: ["projects"],
    companyStartStopTypes: [],
    costCodes: ["projects"],
    costItems: ["projects"],
    changeOrders: ["projects"],
    companyCrewTypes: ["costCodeControls"],
    costCodeControls: ["companyCrewTypes"],
    employees: [],
    employeeTrades: [],
    employeeClassifications: [],
    projectEmployees: ["projects"],
    guestFormShares: ["companyFormStores"],
    employeeSchemas: [],
    equipment: [],
    projectEquipment: ["projects"],
    projects: [
        "companyGroups",
        "costCodes",
        "projectMaterials",
        "projectEquipment",
        "projectEmployees",
        "costItems",
        "changeOrders",
    ],
    companies: [],
    materials: [],
    projectMaterials: ["projects"],
    schemaStatusNames: [],
    analyticsDashboards: [],
    companyTrades: [],
    companyClassifications: ["companyTrades"],
    picklistItems: [],
    timekeepingStatuses: [],
    timelineEntryVersions: [],
    workShifts: [],
}

// Utility functions ----------------------------------------------------------

/**
 * Invalidate a resource in entities, and any resources that are related to it
 */
export const _invalidateEntityCache = (state: tEntityState, resourceName: tCachedResourceName): tEntityState => {
    const newState = cloneDeep(state) as iMutableEntityState
    newState[resourceName].invalidate = true

    const relatedEntities = entityRelationships[resourceName] as Array<tCachedResourceName>
    relatedEntities.map(relatedEntity => {
        newState[relatedEntity].invalidate = true
    })

    return newState
}

/**
 * Invalidate a resource in pagination, and any resources that are related to it
 */
export const _invalidatePaginationCache = (
    state: tPaginationState,
    resourceName: tCachedResourceName
): tPaginationState => {
    const newState = cloneDeep(state) as iMutablePaginationState
    for (const key in newState[resourceName]) {
        newState[resourceName][key].invalidate = true
    }

    const relatedEntities = entityRelationships[resourceName] as Array<tCachedResourceName>
    relatedEntities.map(relatedEntity => {
        for (const relatedKey in newState[relatedEntity]) {
            newState[relatedEntity][relatedKey].invalidate = true
        }
    })

    return newState
}

/**
 * Update the entities state in response to an action with entities in its
 * payload
 */
export const _updateEntityState = (action: iAnyAction, state: tEntityState): tEntityState => {
    const newState = cloneDeep(state) as iMutableEntityState

    // Add new entities to the cache and reset the invalidation states for the
    // relevant resource types
    const newEntities =
        action.payload && action.payload && action.payload.response ? action.payload.response.entities || {} : {}

    const resourceTypes = Object.keys(state) as Array<tCachedResourceName>

    for (const resourceType of resourceTypes) {
        if (newEntities[resourceType]) {
            if (state[resourceType].invalidate) {
                newState[resourceType] = {
                    objects: newEntities[resourceType],
                    invalidate: false,
                }
            } else {
                newState[resourceType] = {
                    objects: {
                        ...state[resourceType].objects,
                        ...newEntities[resourceType],
                    },
                    invalidate: false,
                }
            }
        } else {
            newState[resourceType].invalidate = false
        }
    }

    return newState as tEntityState
}

// Cached Entities ------------------------------------------------------------

const initialEntityState = {
    cohorts: { invalidate: false, objects: {} },
    cohortEmployees: { invalidate: false, objects: {} },
    companyAbsenceTypes: { invalidate: false, objects: {} },
    companyFormSchemas: { invalidate: false, objects: {} },
    companyFormStores: { invalidate: false, objects: {} },
    companyStartStopTypes: { invalidate: false, objects: {} },
    costCodes: { invalidate: false, objects: {} },
    costItems: { invalidate: false, objects: {} },
    changeOrders: { invalidate: false, objects: {} },
    costCodeControls: { invalidate: false, objects: {} },
    companyCrewTypes: { invalidate: false, objects: {} },
    employees: { invalidate: false, objects: {} },
    employeeTrades: { invalidate: false, objects: {} },
    employeeClassifications: { invalidate: false, objects: {} },
    projectEmployees: { invalidate: false, objects: {} },
    guestFormShares: { invalidate: false, objects: {} },
    employeeSchemas: { invalidate: false, objects: {} },
    equipment: { invalidate: false, objects: {} },
    projectEquipment: { invalidate: false, objects: {} },
    companyGroups: { invalidate: false, objects: {} },
    materials: { invalidate: false, objects: {} },
    projectMaterials: { invalidate: false, objects: {} },
    projects: { invalidate: false, objects: {} },
    companies: { invalidate: false, objects: {} },
    schemaStatusNames: { invalidate: false, objects: {} },
    analyticsDashboards: { invalidate: false, objects: {} },
    companyTrades: { invalidate: false, objects: {} },
    companyClassifications: { invalidate: false, objects: {} },
    picklistItems: { invalidate: false, objects: {} },
    timekeepingStatuses: { invalidate: false, objects: {} },
    timelineEntryVersions: { invalidate: false, objects: {} },
    workShifts: { invalidate: false, objects: {} },
}

/**
 * Reducer that updates the entity cache in response to ANY action with
 * response.entities.
 */
export const entities = (state: tEntityState = initialEntityState, action: iAnyAction) => {
    // Perform any necessary cache invalidation
    if (CACHE_INVALIDATING_ACTIONS.includes(action.type) && action.payload) {
        const resourceName = action.payload.entityType
            ? action.payload.entityType
            : (Object.keys(action.payload)[0] as tCachedResourceName)

        if (CACHED_RESOURCE_NAMES.includes(resourceName)) {
            return _invalidateEntityCache(state, resourceName)
        }
    }

    if (action.payload && action.payload.response && action.payload.response.entities) {
        return _updateEntityState(action, state)
    }

    return state
}

// Error Messages -------------------------------------------------------------

/**
 * Reducer that manages error messages related to cached data
 */
export const cacheError = (state: null | string = null, action: iAnyAction) => {
    const { type } = action

    if (type === "RESET_ERROR_MESSAGE") {
        return null
    } else if (Object.values(eEntityActionType).includes(type) && action.error) {
        const payload = action.payload || {}
        return payload.error ? `${payload.error}` : "Unknown cache error"
    }

    return state
}

// Pagination -----------------------------------------------------------------

/**
 * Reducer that manages pagination, given the action types to handle and a
 * function that extracts a key from the action that controls how the items
 * are grouped.
 *
 * For example, if the results are filtered by a particular query string, the
 * key would be the query string passed in from the action. If the results are
 * unfiltered (we're getting an entire set of entities), the key should be the
 * string 'all'.
 *
 * This isn't meant to be used directly; use the `pagination` reducer below.
 */
export const _paginate = (args: tPaginateArgs) => {
    const { types, mapActionToKey } = args

    // Update pagination state
    const [requestType, successType, finishedType, failureType] = types

    const updatePagination = (
        state: iKeyedResourcePaginationState = {
            error: null,
            invalidate: false,
            isFetching: false,
            nextPageUrl: undefined,
            pageCount: 0,
        },
        action: iAnyAction
    ) => {
        const newState = cloneDeep(state) as iKeyedResourcePaginationState

        switch (action.type) {
            case requestType:
                return {
                    ...newState,
                    error: null,
                    isFetching: true,
                }
            case successType:
                return {
                    ...newState,
                    error: null,
                    isFetching: true,
                    nextPageUrl: action.payload.response.nextPageUrl,
                    pageCount: state.pageCount + 1,
                }
            case finishedType:
                return {
                    ...newState,
                    error: null,
                    invalidate: false,
                    isFetching: false,
                }
            case failureType:
                return {
                    ...newState,
                    error: action.payload && action.payload.error ? action.payload.error : "Something went wrong.",
                    isFetching: false,
                }
            default:
                return state
        }
    }

    // Reducer logic
    return (state: iResourcePaginationState, action: iAnyAction): iResourcePaginationState => {
        // Update pagination by key
        switch (action.type) {
            case requestType:
            case successType:
            case finishedType:
            case failureType:
                const newState = cloneDeep(state) as iResourcePaginationState
                const key = mapActionToKey(action)
                const keyedResourceState = newState[key] as iKeyedResourcePaginationState
                return {
                    ...newState,
                    [key]: updatePagination(keyedResourceState, action),
                }
            default:
                return state
        }
    }
}

/**
 * Reducer that updates the pagination data for various actions
 */
const initialPaginationState: tPaginationState = {
    cohorts: {},
    cohortEmployees: {},
    companyAbsenceTypes: {},
    companyFormSchemas: {},
    companyFormStores: {},
    companyStartStopTypes: {},
    costCodes: {},
    costItems: {},
    changeOrders: {},
    costCodeControls: {},
    companyCrewTypes: {},
    employees: {},
    employeeTrades: {},
    employeeClassifications: {},
    projectEmployees: {},
    guestFormShares: {},
    employeeSchemas: {},
    equipment: {},
    projectEquipment: {},
    companyGroups: {},
    projects: {},
    materials: {},
    projectMaterials: {},
    companies: {},
    schemaStatusNames: {},
    analyticsDashboards: {},
    companyTrades: {},
    companyClassifications: {},
    picklistItems: {},
    timekeepingStatuses: {},
    timelineEntryVersions: {},
    workShifts: {},
}

export const pagination = (
    state: tPaginationState = initialPaginationState,
    page_action: iAnyAction
): tPaginationState => {
    // Perform any necessary cache invalidation
    if (CACHE_INVALIDATING_ACTIONS.includes(page_action.type) && page_action.payload) {
        const resourceName = page_action.payload.entityType
            ? page_action.payload.entityType
            : (Object.keys(page_action.payload)[0] as tCachedResourceName)

        if (CACHED_RESOURCE_NAMES.includes(resourceName)) {
            return _invalidatePaginationCache(state, resourceName)
        }
    }

    return {
        cohorts: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["cohorts"],
        })(state.cohorts, page_action),
        cohortEmployees: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["cohortEmployees"],
        })(state.cohorts, page_action),
        companyAbsenceTypes: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["companyAbsenceTypes"],
        })(state.companyAbsenceTypes, page_action),
        companyFormSchemas: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["companyFormSchemas"],
        })(state.companyFormSchemas, page_action),
        companyFormStores: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["companyFormStores"],
        })(state.companyFormStores, page_action),
        companyStartStopTypes: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["companyStartStopTypes"],
        })(state.companyStartStopTypes, page_action),
        costCodes: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["costCodes"],
        })(state.costCodes, page_action),
        costItems: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["costItems"],
        })(state.costItems, page_action),
        changeOrders: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["changeOrders"],
        })(state.changeOrders, page_action),
        costCodeControls: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["costCodeControls"],
        })(state.costCodeControls, page_action),
        companyCrewTypes: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["companyCrewTypes"],
        })(state.companyCrewTypes, page_action),
        employees: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["employees"],
        })(state.employees, page_action),
        employeeTrades: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["employeeTrades"],
        })(state.employees, page_action),
        employeeClassifications: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["employeeClassifications"],
        })(state.employees, page_action),
        projectEmployees: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["projectEmployees"],
        })(state.projectEmployees, page_action),
        guestFormShares: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["guestFormShares"],
        })(state.guestFormShares, page_action),
        employeeSchemas: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["employeeSchemas"],
        })(state.employeeSchemas, page_action),
        equipment: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["equipment"],
        })(state.equipment, page_action),
        projectEquipment: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["projectEquipment"],
        })(state.projectEquipment, page_action),
        companyGroups: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["companyGroups"],
        })(state.companyGroups, page_action),
        projects: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["projects"],
        })(state.projects, page_action),
        companies: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["companies"],
        })(state.companies, page_action),
        schemaStatusNames: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["schemaStatusNames"],
        })(state.schemaStatusNames, page_action),
        materials: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["materials"],
        })(state.materials, page_action),
        projectMaterials: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["projectMaterials"],
        })(state.projectMaterials, page_action),
        analyticsDashboards: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["analyticsDashboards"],
        })(state.analyticsDashboards, page_action),
        companyTrades: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["companyTrades"],
        })(state.companyTrades, page_action),
        companyClassifications: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["companyClassifications"],
        })(state.companyClassifications, page_action),
        picklistItems: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["picklistItems"],
        })(state.picklistItems, page_action),
        timekeepingStatuses: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["timekeepingStatuses"],
        })(state.workShifts, page_action),
        timelineEntryVersions: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["timelineEntryVersions"],
        })(state.workShifts, page_action),
        workShifts: _paginate({
            mapActionToKey: action => action.key,
            types: actionTypesForResource["workShifts"],
        })(state.workShifts, page_action),
    }
}

// Entity Loading State -------------------------------------------------------

const initialEntityLoadingState: tLoadingState = {
    cohorts: { isLoading: false, error: null },
    cohortEmployees: { isLoading: false, error: null },
    companyAbsenceTypes: { isLoading: false, error: null },
    companyFormSchemas: { isLoading: false, error: null },
    companyFormStores: { isLoading: false, error: null },
    companyStartStopTypes: { isLoading: false, error: null },
    costCodes: { isLoading: false, error: null },
    costItems: { isLoading: false, error: null },
    changeOrders: { isLoading: false, error: null },
    costCodeControls: { isLoading: false, error: null },
    companyCrewTypes: { isLoading: false, error: null },
    employees: { isLoading: false, error: null },
    employeeTrades: { isLoading: false, error: null },
    employeeClassifications: { isLoading: false, error: null },
    projectEmployees: { isLoading: false, error: null },
    guestFormShares: { isLoading: false, error: null },
    employeeSchemas: { isLoading: false, error: null },
    companyGroups: { isLoading: false, error: null },
    equipment: { isLoading: false, error: null },
    projectEquipment: { isLoading: false, error: null },
    projects: { isLoading: false, error: null },
    companies: { isLoading: false, error: null },
    schemaStatusNames: { isLoading: false, error: null },
    materials: { isLoading: false, error: null },
    projectMaterials: { isLoading: false, error: null },
    analyticsDashboards: { isLoading: false, error: null },
    companyTrades: { isLoading: false, error: null },
    companyClassifications: { isLoading: false, error: null },
    picklistItems: { isLoading: false, error: null },
    timekeepingStatuses: { isLoading: false, error: null },
    timelineEntryVersions: { isLoading: false, error: null },
    workShifts: { isLoading: false, error: null },
}

/**
 * Reducer that manages the loading state of entities. This is used to control
 * the loading spinner on the custom dashboard component.
 *
 * Although much of this information is also recorded in the pagination
 * reducer, the pagination reducer keys the loading state for each entity by
 * its filters/query string. Since the custom dashboard has no insight into
 * the specific query for, say, cost codes are being generated by its row data
 * and settings file, it has no way of knowing which key to use.
 *
 * We should probably revisit the overall design of our request lifecycle
 * management. This is likely much more complicated than it needs to be.
 */
export const entitiesLoading = (
    state: tLoadingState = initialEntityLoadingState,
    action: iAnyAction
): tLoadingState => {
    const newState = cloneDeep(state) as iMutableLoadingState
    const resourceTypes = Object.keys(initialEntityLoadingState) as tCachedResourceName[]

    resourceTypes.forEach(resourceType => {
        const actionTypes = actionTypesForResource[resourceType]
        const [pendingAction, successAction, finishedAction, failedAction] = actionTypes

        switch (action.type) {
            case pendingAction:
            case successAction:
                newState[resourceType].isLoading = true
                newState[resourceType].error = null
                break
            case finishedAction:
                newState[resourceType].isLoading = false
                newState[resourceType].error = null
                break
            case failedAction:
                newState[resourceType].isLoading = false
                newState[resourceType].error = action.payload ? `${action.payload.error}` : "Something went wrong"
        }
    })

    return newState
}
