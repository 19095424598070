import React from "react"
import "lockable-option-list-renderer.less"
import { IconLock } from "@rhumbix/rmbx_design_system_web"

const LockableOptionListRenderer = (isLockedCallback: (data: any) => boolean) => {
    return (props: Record<string, any>): JSX.Element => {
        if (isLockedCallback(props.data)) {
            return (
                <div className="locked-option">
                    <div>
                        <IconLock width={16} style={{ marginRight: 5 }} />
                        <div>{props.value.join(", ")}</div>
                    </div>
                </div>
            )
        } else {
            return <div>{props.value.join(", ")}</div>
        }
    }
}

export default LockableOptionListRenderer
