import CollapsibleMenuGroup from "../collapsible-menu-group"
import { connect } from "react-redux"
import MenuOptions from "../menu-options"
import { isFeatureEnabledSelector } from "../../../selectors"
import { navCategories } from "../../../common/constants"

import React from "react"
import { IconForms } from "@rhumbix/rmbx_design_system_web"

const TimeCards = props => {
    return (
        <CollapsibleMenuGroup
            {...props}
            icon={<IconForms width={20} height={20} />}
            iconName="time-cards"
            menuTitle="Time Cards"
            navCategory="time-cards"
            isHidden={!props.isTimekeepingFeatureEnabled}
        >
            <MenuOptions {...props} options={props.routes[navCategories.timeCards]} />
        </CollapsibleMenuGroup>
    )
}

const mapStateToProps = state => ({
    isTimekeepingFeatureEnabled: isFeatureEnabledSelector(state, "timekeeping"),
})

export default connect(mapStateToProps)(TimeCards)
