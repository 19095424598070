import { Thunk } from "../common/types"
import * as API from "../api"
import { iActionData } from "../common/types"

export const CLEAR_SCHEMA_WORKFLOW = "CLEAR_SCHEMA_WORKFLOW"
export const SCHEMA_WORKFLOW_UPDATE_SUCCESSFUL = "SCHEMA_WORKFLOW_UPDATE_SUCCESSFUL"

export interface iSchemaUpdateSucceeded extends iActionData {
    type: typeof SCHEMA_WORKFLOW_UPDATE_SUCCESSFUL
}
export interface iSchemaWorkflowCleared {
    type: typeof CLEAR_SCHEMA_WORKFLOW
}

export type tWorkflowActions = iSchemaUpdateSucceeded | iSchemaWorkflowCleared

export function getCompanyFormSchemaWorkflow(params: Record<string, any>): Thunk {
    return dispatch => {
        return API.getCompanyFormSchemaWorkflow(params).then(data => {
            dispatch(schemaWorkflowUpdateSuccessful(data))
        })
    }
}

export const schemaWorkflowUpdateSuccessful = (data: any): tWorkflowActions => {
    return {
        type: SCHEMA_WORKFLOW_UPDATE_SUCCESSFUL,
        data,
    }
}

export const clearCompanyFormSchemaWorkflow = (): tWorkflowActions => {
    return {
        type: CLEAR_SCHEMA_WORKFLOW,
    }
}
