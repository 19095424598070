import { MARKUPS } from "./constants"

export const handleMarkup = (data: Map<string, object>): Map<string, object> => {
    const newMarkups = new Map()
    MARKUPS.forEach(markup => {
        const key: any = data ? data.get(markup.name) : null
        const obj: any = {}
        markup.inputs.forEach(input => {
            obj[input] = key && key[input] ? key[input] : ""
        })
        newMarkups.set(markup.name, obj)
    })

    return newMarkups
}

export const setCheckboxes = (data: Map<string, object>): Array<string> => {
    const checkboxes: any = []
    data.forEach((value: any) => {
        const markup_type = value["markup_type"]
        Object.entries(value).forEach(([key, val]) => {
            if (!["markup_type", "id", "project_id"].includes(key) && val && !checkboxes.includes(markup_type)) {
                checkboxes.push(markup_type)
            }
        })
    })

    return checkboxes
}
