import React from "react"
import { connect } from "react-redux"
import { Action, bindActionCreators, Dispatch } from "redux"
import { ReduxState, Thunk } from "../../common/types"
import { closeDeleteSavedFilterModal } from "./actions"
import { deleteSavedFilterSet } from "../../filters/actions"

import StandardModal from "./modal-standard"
import { activeSavedFilterSelector } from "../../filters/selectors"
import { tSavedFilter } from "../../filters/types"
import {
    logUserAmplitudeEvent,
    SAVED_FILTER_SET_DELETED,
    SAVED_FILTER_SET_DELETE_MODAL_CANCEL,
} from "../../common/amplitude-event-logging"

type tProps = {
    modalIsOpen: boolean
    id: number
    page: string
    message: string
    closeDeleteSavedFilterModal: () => void
    activeSavedFilter: tSavedFilter
    deleteSavedFilterSet: (
        savedFilterSetId: number,
        activeSavedFilterSetId: number | undefined,
        page: string
    ) => Thunk
}

const DeleteSavedFilterModal = (props: tProps) => {
    const amplitudeInfo = {
        pageIdentifier: props.page,
        savedFilterSet: props.id,
    }

    // Breaking out a separate handler for the cancel button allows us specifically to track
    // users who close the modal on purpose, as opposed to all the other times that the modal is
    // ordered to close
    const handleCancel = () => {
        logUserAmplitudeEvent(SAVED_FILTER_SET_DELETE_MODAL_CANCEL, amplitudeInfo)
        closeModal()
    }

    const closeModal = () => {
        if (props.modalIsOpen) {
            props.closeDeleteSavedFilterModal()
        }
    }

    const deleteSavedFilter = () => {
        props.deleteSavedFilterSet(props.id, props.activeSavedFilter?.id, props.page)
        logUserAmplitudeEvent(SAVED_FILTER_SET_DELETED, amplitudeInfo)
    }

    return (
        <StandardModal
            autoClose={false}
            open={props.modalIsOpen}
            closeMethod={closeModal}
            primaryBtnText="Delete Filter"
            primaryBtnOnClick={deleteSavedFilter}
            primaryBtnDisabled={false}
            secondaryBtnText="Cancel"
            secondaryBtnOnClick={handleCancel}
            id={"delete-saved-filter-set-modal"}
        >
            <h1 className="modal-title">Delete filter</h1>
            <div className="modal-description">
                Are you sure you want to delete this filter? You will not be able to reverse this action.
            </div>
            {props.message && props.message.length > 0 ? (
                <div className="modal-input-error">{props.message}</div>
            ) : null}
        </StandardModal>
    )
}

function mapStateToProps(state: ReduxState) {
    return {
        modalIsOpen: state.modals.modalDeleteSavedFilter.open,
        id: state.modals.modalDeleteSavedFilter.id,
        page: state.modals.modalDeleteSavedFilter.page,
        message: state.modals.modalDeleteSavedFilter.message,
        activeSavedFilter: activeSavedFilterSelector(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
            closeDeleteSavedFilterModal,
            deleteSavedFilterSet,
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSavedFilterModal)
