import { SearchBarAction } from "../actions/search-bar"
import { SearchBarState } from "../common/types"
import { getCookie } from "../common/ts-utils"

const initialState: SearchBarState = {
    searchTerm: "",
    fancySearchTerm: { term: "", category: "", doFilter: true },
    isFancySearchVisible: false,
    placeholdersVisible: true,
    extraTimelineVisible: false,
    cicoExceptions: false,
    cicoVisible: false,
    cicoExceptionValue: {
        value: getCookie("exception_threshold") || "",
        units: (getCookie("exception_units") as "hours" | "percent") || "hours",
    },
    hoursExceptions: false,
    hoursExceptionValues: {
        minHours: getCookie("min_hours_exception_threshold") || "",
        maxHours: getCookie("max_hours_exception_threshold") || "",
    },
}

const searchBar = (state = initialState, action: SearchBarAction): SearchBarState => {
    switch (action.type) {
        case "SET_SEARCH_TERM":
            return { ...state, searchTerm: action.searchTerm }
        case "SET_FANCY_SEARCH_TERM":
            return { ...state, fancySearchTerm: action.searchTerm }
        case "TOGGLE_FANCY_SEARCH_VISIBLE":
            return { ...state, isFancySearchVisible: !state.isFancySearchVisible }
        case "TOGGLE_PLACEHOLDERS_VISIBLE":
            return { ...state, placeholdersVisible: !state.placeholdersVisible }
        case "TOGGLE_CICO_VISIBLE":
            return { ...state, cicoVisible: !state.cicoVisible }
        case "TOGGLE_CICO_EXCEPTIONS":
            return { ...state, cicoExceptions: !state.cicoExceptions }
        case "SET_CICO_EXCEPTION_VALUE":
            return {
                ...state,
                cicoExceptionValue: action.value,
            }
        case "TOGGLE_HOURS_EXCEPTIONS":
            return { ...state, hoursExceptions: !state.hoursExceptions }
        case "SET_HOURS_EXCEPTION_VALUES":
            return {
                ...state,
                hoursExceptionValues: action.value,
            }
        case "TOGGLE_SEARCH_BAR_STATE":
            return {
                ...state,
                [action.attr]: !state[action.attr],
            }
        default:
            return state
    }
}

export default searchBar
