import { aggregateAllSameOrMixed } from "../../../common/ag-grid-utils"
import {
    companyGroupsFilterDef,
    dateRangeFilterDef,
    projectStatusFilterDef,
    multiProjectFilterDef,
} from "../../../filters/filter-defs"
import {
    employeeColDefCreator,
    dateColDefCreator,
    checkboxColDefCreator,
    stringColDefCreator,
    viewButtonColDefCreator,
} from "./standard-col-def-creators"
import { stringFilterDefCreator } from "../../../filters/filter-def-creators"

export function getEnhancedTandMDemoSettings() {
    return {
        tableName: "Enhanced T&M Tracking",
        navId: "enhanced-t-and-m",
        resources: ["companyFormStores"],
        filters: [
            companyGroupsFilterDef,
            projectStatusFilterDef,
            multiProjectFilterDef,
            dateRangeFilterDef,
            stringFilterDefCreator({
                label: "Description of Work",
                field: "Subcontractor Use__Description of Work",
                key: "Subcontractor Use__Description of Work",
                required: true,
            }),
        ],
        relatedNames: {
            companyFormStores: {
                companyFormSchemas: ["/schema"],
                employees: ["/last_field-approved_by", "/last_gc-approved_by"],
            },
        },
        additionalQueryParams: {
            // reenable when WA-2528 is complete
            //date_field: 'store__Subcontractor Use__Work Performed On',
            date_field: "created_on",
            schema_name: "T&M Tracking",
            history_for_status: ["GC Approved", "Field Approved"],
        },
        colDefs: [
            checkboxColDefCreator(),
            stringColDefCreator({
                headerName: "Tag ID #",
                field: "/id",
                width: 115,
            }),
            stringColDefCreator({
                headerName: "Description",
                field: "/store/Subcontractor Use/Description of Work",
                editable: true,
            }),
            employeeColDefCreator({ headerName: "Created By" }),
            dateColDefCreator({
                headerName: "Created On",
                field: "/created_on",
            }),
            employeeColDefCreator({
                headerName: "Field Approved By",
                field: "/last_field-approved_by",
            }),
            dateColDefCreator({
                headerName: "Field Approved On",
                field: "/last_field-approved_on",
            }),
            employeeColDefCreator({
                headerName: "GC Approved By",
                field: "/last_gc-approved_by",
            }),
            dateColDefCreator({
                headerName: "GC Approved On",
                field: "/last_gc-approved_on",
            }),
            viewButtonColDefCreator(),
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                aggFunc: aggregateAllSameOrMixed,
            },
            autoGroupColumnDef: {
                minWidth: 200,
            },
            suppressCellSelection: true,
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            groupDisplayType: "multipleColumns",
        },
        otherSettings: {
            embedFormView: true,
            enableSearchBar: false,
            hideCellActions: true,
            showExportButton: true,
            showCreateButton: true,
            groupBy: [
                {
                    cols: [],
                    label: "None",
                },
                {
                    cols: ["/employee"],
                    label: "Created By",
                },
                {
                    cols: ["/last_field-approved_by"],
                    label: "Field Approved By",
                },
                {
                    cols: ["/last_gc-approved_by"],
                    label: "GC Approved By",
                },
            ],
            buttons: {
                cell: [],
                row: [
                    {
                        label: "Update Statuses ▾",
                        icon: "status",
                        action: "performWorkflowActionFromSchema",
                    },
                ],
                table: [
                    {
                        label: "Create",
                        icon: "add",
                        action: "addNewCustomForm",
                    },
                ],
            },
        },
    }
}
