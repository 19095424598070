import React from "react"
import numeral from "numeral"
import { getFlagEnabled } from "../../../getFlagValue"
import { Tooltip as ReactTooltip } from "react-tooltip"
import Portal from "react-overlays/Portal"

export const HoursSegment = props => {
    const hours = []
    let maxDigits = 0

    // Attempt to find a good font size for the various potential numbers of digits displayed
    // It's hideous. I'm sorry. kind of.
    for (const time_type of ["ST", "OT", "DT"]) {
        if (props.value[time_type]) {
            const c = numeral(props.value[time_type]).format("0.[00]")
            if (c.length > maxDigits) maxDigits = c.length
        }
    }

    let fontSize = 14
    fontSize = `${fontSize}px`
    if (maxDigits > 3) {
        fontSize = 11 - (maxDigits - 3) * 0.85
        fontSize = `min(${fontSize}cqw, 14px)`
    }

    for (const time_type of ["ST", "OT", "DT"]) {
        const css = { width: "28px" }
        if (getFlagEnabled("WA-8239-weekly-view-hour-crowding")) {
            css["fontSize"] = fontSize
            css["flex"] = 1
        }
        let content = "-"
        if (props.value[time_type]) {
            content = numeral(props.value[time_type]).format("0.[00]")
        } else {
            css.opacity = "0.5"
        }
        hours.push(
            <div style={css} key={time_type}>
                {content}
            </div>
        )
    }
    const tooltipSpot = document.getElementById("master-container")
    const tooltipAttrs =
        props.tooltips && props.tooltips.length
            ? { "data-tooltip-id": `tk-tooltip-${props.nodeId}`, "data-messages": props.tooltips }
            : {}

    if (getFlagEnabled("WA-8239-weekly-view-hour-crowding"))
        return getFlagEnabled("WA-8340-cico-web") ? (
            <div>
                {props.tooltips && props.tooltips.length ? (
                    <Portal container={tooltipSpot}>
                        <ReactTooltip
                            id={`tk-tooltip-${props.nodeId}`}
                            place="bottom"
                            variant="dark"
                            style={{ width: 200 }}
                            render={attrs => {
                                const { activeAnchor } = attrs
                                const messages = activeAnchor?.getAttribute("data-messages") || []
                                const tooltipArray = Array.isArray(messages) ? messages : [messages]

                                return tooltipArray ? (
                                    <span style={{ textAlign: "left", display: "block" }}>
                                        {tooltipArray.map((msg, idx) => (
                                            <span key={`tooltip-${idx}`}>{msg}</span>
                                        ))}
                                    </span>
                                ) : null
                            }}
                        />
                    </Portal>
                ) : null}
                <div className="scalable-hours-row" {...tooltipAttrs}>
                    {hours}
                </div>
            </div>
        ) : (
            <div className="scalable-hours-row">{hours}</div>
        )
    return <div className="hours-row">{hours}</div>
}

export const TotalColumnHoursSegment = props => {
    const hours = []
    const total = props.value["ST"] + props.value["OT"] + props.value["DT"]
    const tooltipSpot = document.getElementById("master-container")
    const tooltipAttrs = props.tooltip
        ? { "data-tooltip-id": `tk-tooltip-${props.nodeId}`, "data-messages": props.tooltip }
        : {}
    // Ridiculous wizardry to try and get a good looking font size
    const maxDigits = numeral(total).format("0.[00]").length
    let fontSize = 14
    fontSize = `${fontSize}px`
    if (maxDigits > 3) {
        fontSize = 11 - (maxDigits - 3) * 0.85
        fontSize = `min(${fontSize}cqw, 14px)`
    }

    if (total) {
        if (getFlagEnabled("WA-8239-weekly-view-hour-crowding")) {
            hours.push(
                <div style={{ fontSize, flex: 1 }} key="Total">
                    {numeral(total).format("0.[00]")}
                </div>
            )
        } else {
            hours.push(<div key="Total">{numeral(total).format("0.[00]")}</div>)
        }
    } else {
        hours.push(
            <div style={{ opacity: 0.5 }} key="Total">
                --
            </div>
        )
    }

    for (const time_type of ["ST", "OT", "DT"]) {
        const timeColumnCss = {}

        if (getFlagEnabled("WA-8239-weekly-view-hour-crowding")) {
            timeColumnCss["fontSize"] = fontSize
            timeColumnCss["flex"] = 1
        }

        let content = "-"
        if (props.value[time_type]) {
            content = numeral(props.value[time_type]).format("0.[00]")
        } else {
            timeColumnCss.opacity = "0.5"
        }

        hours.push(
            <div style={timeColumnCss} key={time_type}>
                {content}
            </div>
        )
    }
    return getFlagEnabled("WA-8239-weekly-view-hour-crowding") ? (
        getFlagEnabled("WA-8340-cico-web") ? (
            <div>
                {!!props.tooltip && (
                    <Portal container={tooltipSpot}>
                        <ReactTooltip
                            id={`tk-tooltip-${props.nodeId}`}
                            place="bottom"
                            variant="dark"
                            style={{ width: 200 }}
                            render={attrs => {
                                const { activeAnchor } = attrs
                                const messages = activeAnchor?.getAttribute("data-messages") || []
                                const tooltipArray = Array.isArray(messages) ? messages : [messages]

                                return tooltipArray ? (
                                    <span style={{ textAlign: "left", display: "block" }}>
                                        {tooltipArray.map((msg, idx) => (
                                            <span key={`tooltip-${idx}`}>{msg}</span>
                                        ))}
                                    </span>
                                ) : null
                            }}
                        />
                    </Portal>
                )}
                <div className="scalable-hours-row" {...tooltipAttrs}>
                    {hours}
                </div>
            </div>
        ) : (
            <div>
                <div className="scalable-hours-row" {...tooltipAttrs}>
                    {hours}
                </div>
            </div>
        )
    ) : (
        <div className="hours-row">{hours}</div>
    )
}
