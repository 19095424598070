import React, { useImperativeHandle, useState, forwardRef, useEffect, useRef } from "react"

type tProps = {
    value: string
    width: number
    maxLength: number
}

const LimitedLengthStringCellEditor: React.FunctionComponent<tProps> = forwardRef((props: tProps, ref) => {
    const [currentValue, setCurrentValue] = useState(props.value || "")
    const refInput = useRef<HTMLInputElement>(null)

    useEffect(() => {
        // focus on the input
        setTimeout(() => refInput.current?.focus())
    }, [])

    // This is required to have the cell editor pop out of the cell when the contents are too large for the cell
    useImperativeHandle(ref, () => {
        return {
            getValue: () => currentValue,
        }
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        // we just want to save the user role string - so pull the value from the selection
        setCurrentValue(e.target.value)

    return (
        <input
            ref={refInput}
            placeholder={`Max ${props.maxLength} characters`}
            onChange={handleChange}
            value={currentValue}
            type={"text"}
            maxLength={props.maxLength}
            style={{ width: props.width - 4 }}
        />
    )
})

export default LimitedLengthStringCellEditor
