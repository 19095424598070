import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { colorFunctionalGray10, colorFunctionalGray30, colorAttentionBlue50 } from "@rhumbix/rmbx_design_system_web"
import { zIndex } from "../../common/styles"

type Props = {
    sideRailRef: React.RefObject<HTMLDivElement>
    setWidth: (width: number) => void
}
export const SideRailResizer: React.FC<Props> = ({ sideRailRef, setWidth }) => {
    const [resizeState, setResizeState] = useState({
        active: false,
        initialWidth: 0,
        mouseX: 0,
    })

    useEffect(() => {
        const { active, initialWidth, mouseX } = resizeState

        // Only add event listeners to the page when a resize is active.
        if (!active) return

        const handleResize = (e: MouseEvent) => {
            e.preventDefault()
            // Set new width based on how far the mouse has been moved.
            const dx = e.clientX - mouseX
            setWidth(initialWidth - dx)
        }

        const handleResizeEnd = () => {
            // Once the user lifts up, they're no longer resizing.
            setResizeState({ ...resizeState, active: false })
        }

        document.addEventListener("mousemove", handleResize)
        document.addEventListener("mouseup", handleResizeEnd)

        return () => {
            document.removeEventListener("mousemove", handleResize)
            document.removeEventListener("mouseup", handleResizeEnd)
        }
    }, [resizeState])

    const handleResizeStart: React.MouseEventHandler<HTMLDivElement> = e => {
        setResizeState({ active: true, mouseX: e.clientX, initialWidth: sideRailRef?.current?.offsetWidth ?? 0 })
    }

    return (
        <Container onMouseDown={handleResizeStart} id="side-rail-resizer">
            <div className="highlight" />
            <div className="grabber">
                <div className="portholes">
                    <div className="porthole" />
                    <div className="porthole" />
                    <div className="porthole" />
                </div>
            </div>
        </Container>
    )
}

const Container = styled.div`
    cursor: col-resize;
    height: 100%;
    width: 10px;

    // Center the grabber
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // Have the resizer overlap a bit with the side rail content.
    margin-right: -5px;

    // Appears above the side rail content.
    z-index: ${zIndex.SideRail + 1};

    &:hover {
        div.highlight,
        div.porthole {
            background-color: ${colorAttentionBlue50};
        }
    }

    div.highlight,
    div.porthole {
        transition: background-color 300ms ease 100ms;
    }

    div.highlight {
        position: absolute;
        height: 100%;
        width: 3px;
        left: 3px;
    }

    div.grabber {
        width: 8px;
        height: 60px;
        background-color: white;
        border: 1px solid ${colorFunctionalGray10};
        box-shadow: 0px 1px 3px 0px;
        z-index: ${zIndex.SideRail + 2};

        // Position the "portholes"
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    div.portholes {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 40%;
    }

    div.porthole {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${colorFunctionalGray30};
    }
`
