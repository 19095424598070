const initialState = {
    editableWorkShiftId: null,
    headerHoverId: null,
}

export default function dailyTimeCardPage(state = initialState, action) {
    switch (action.type) {
        case "DAILY_REPORT_HEADER_ENTER":
            return Object.assign({}, state, { headerHoverId: action.id })
        case "DAILY_REPORT_HEADER_LEAVE":
            return Object.assign({}, state, { headerHoverId: null })
        case "TOGGLE_EDIT_MODE":
            const editableWorkShiftId = action.workShiftId == state.editableWorkShiftId ? null : action.workShiftId
            return Object.assign({}, state, {
                editableWorkShiftId: editableWorkShiftId,
            })
        default:
            return state
    }
}
