import asyncComponent from "./async-component"

export const CmicIntegration = asyncComponent(() => import("../components/integration/cmic"))

export const CompanyPicklists = asyncComponent(() => import("../components/company-options/company-picklists"))

export const CompanySignatures = asyncComponent(() => import("../components/company-options/company-signatures-ts"))

export const CostCodesUploadFromCSVForm = asyncComponent(() => import("../forms/upload/cost-codes-upload-csv"))

export const CreateProjectForm = asyncComponent(() => import("../forms/create-project"))

export const CustomDashboard = asyncComponent(() => import("../custom-dashboard"))

export const CustomFormWrapper = asyncComponent(() => import("../new-custom-forms/components/form-view-wrapper"))

export const DailyProfitLoss = asyncComponent(() => import("../daily-profit-loss"))

export const DailySummaryEmailSettings = asyncComponent(() => import("../components/daily-summary-email-settings"))

export const DailyTimeCard = asyncComponent(() => import("../components/timecard/time-cards-page"))

export const EmployeesUploadFromCSVForm = asyncComponent(() => import("../forms/upload/EmployeeCSVUploadForm"))

// This can be changed back to the upload form component when we remove WA-8391-equipment-upload-refactor
export const EquipmentUploadFromCSVForm = asyncComponent(() =>
    import("../forms/upload/equipment-upload-csv-router")
)

// EwsSignatureWrapper and TKSignatureRouter can be removed with WA-5644-tk-signatures
export const EwsSignatureWrapper = asyncComponent(() => import("../components/timecard/ews-signature-wrapper"))

export const TKTextToSignRouter = asyncComponent(() => import("../components/timecard/tk-text-to-sign-router"))

export const TKSignatureRouter = asyncComponent(() => import("../components/timecard/tk-signature-router"))

export const TKSignatureWrapper = asyncComponent(() => import("../components/timecard/tk-signature-wrapper"))

export const ExportCSV = asyncComponent(() => import("../export-csv"))

export const ForemenInvite = asyncComponent(() => import("../invite"))

export const GuestLoginPage = asyncComponent(() => import("../components/guest-login/guest-login-page"))

export const GroupMembersCSVUploadForm = asyncComponent(() => import("../forms/upload/GroupMembersCSVUploadForm"))

export const Integrations = asyncComponent(() => import("../components/integration/autodesk"))

export const LockedTimePeriod = asyncComponent(() =>
    import("../components/company-options/locked-time-period-container")
)

export const LoginPage = asyncComponent(() => import("../components/login/login-page"))

export const LoginLockoutPage = asyncComponent(() => import("../components/login/login-lockout-page"))

export const AnalyticsDashboard = asyncComponent(() => import("../analytics-dashboard"))

export const ProjectConfigDashboard = asyncComponent(() => import("../project-config"))

export const ForgotPassword = asyncComponent(() => import("../forms/forgot-password"))

export const MassText = asyncComponent(() => import("../components/mass-text"))

export const MaterialsUploadFromCSVForm = asyncComponent(() => import("../forms/upload/material-upload-csv"))

export const PageForbidden = asyncComponent(() => import("../components/error-pages/PageForbidden"))

export const PageNotFound = asyncComponent(() => import("../components/error-pages/PageNotFound"))

export const ProjectPage = asyncComponent(() => import("../components/project-settings/project-page"))

export const ProjectsUploadFromCSVForm = asyncComponent(() => import("../forms/upload/ProjectsCSVUploadForm"))

export const ProtectedApiIntegration = asyncComponent(() =>
    import("../components/integration/protected-api-integration")
)

export const QuantityEdit = asyncComponent(() => import("../forms/quantity-edit/quantity-edit"))

export const ReportsPageContainer = asyncComponent(() => import("../reports-page-container"))

export const ResetPassword = asyncComponent(() => import("../forms/password-reset"))

export const RoleNotAvailablePage = asyncComponent(() => import("../role-not-available-page"))

export const SetNewPassword = asyncComponent(() => import("../forms/set-new-password"))

export const SetPassword = asyncComponent(() => import("../forms/set-password"))

export const SSOConfiguration = asyncComponent(() => import("../components/sso-configuration/SSOConfiguration"))

export const TrialDeactivated = asyncComponent(() => import("../components/trials/TrialDeactivated"))

export const CompanyLogoUpload = asyncComponent(() => import("../logo-upload/CompanyLogoUpload/index"))
