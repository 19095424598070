// TODO: We could obviously cover more of the application as it's needed -
// these identifiers just get us started

export const PageIdentifiers = {
    WeeklyView: "weekly_view",
}

export const SectionIdentifiers = {
    ProjectSettings: "project_settings",
    CompanySettings: "company_settings",
}

/**
 * A map of unique page identifiers to route, for example, to identify a page to the backend
 * using something that's more reliable than its route.
 * The key is the identifier, and the value is an array containing possible routes.
 *
 * Typically there is only one route for a page, but if routes change over time,
 * they can be added to the array and mapped to the same identifier. Note: the latest route
 * should be the first element in the array - see getPathForPageIdentifier below,
 * which defaults to returning the first route it finds
 */
export const pageIdentifiersByRoute = {
    [PageIdentifiers.WeeklyView]: {
        routes: ["/rhumbix/time-cards/weekly/"],
    },
}

/**
 * Helper function that takes a path and returns a unique page identifier.
 *
 * @param The route to match
 * @returns The PageIdentifiers enum for this route, or if there was no match, undefined
 * */
export const getPageIdentifierForPath = (route: string | undefined): string | undefined => {
    if (route && route.length) {
        // Make sure the route ends with a trailing slash, for consistency
        if (!route.endsWith("/")) route = route + "/"

        for (const [key, value] of Object.entries(pageIdentifiersByRoute)) {
            if (value.routes.find((val: string) => val === route)) return key
        }
    }

    // If no match was found, just return undefined
    return undefined
}

/**
 * Helper function to retrieve the route for the specified page identifier
 *
 * @param The PageIdentifiers enum value for the requested page
 * @returns A string containing the route for that page, or if there was no match, undefined
 */
export const getPathForPageIdentifier = (pageIdentifier: string): string | undefined => {
    if (pageIdentifier) {
        const value = pageIdentifiersByRoute[pageIdentifier]
        if (value && value.routes?.length > 0) return value.routes[0]
    }

    return undefined
}

/**
 * Identifies which section of the application a user is in. This can be helpful for example to
 * separate the Project Settings version of a page from the Company Settings version
 *
 * @param route The current page route
 * @returns A SectionIdentifiers enum identifying the current section, or if there was no match,
 *          undefined
 */
export const getSectionIdentifierForPath = (route: string | undefined): string | undefined => {
    if (route && route.length) {
        if (route.indexOf("/rhumbix/projects/") > -1) return SectionIdentifiers.ProjectSettings
        if (route.indexOf("/rhumbix/company-admin/") > -1) return SectionIdentifiers.CompanySettings
    }

    return undefined
}
