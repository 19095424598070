import CollapsibleMenuGroup from "../collapsible-menu-group"
import React from "react"
import { connect } from "react-redux"
import { isFeatureEnabledSelector } from "../../../selectors"
import { navCategories } from "../../../common/constants"
import MenuOptions from "../menu-options"
import { IconReports } from "@rhumbix/rmbx_design_system_web"

const Reports = props => {
    let isHidden = true
    if (props.isTimekeepingFeatureEnabled) {
        isHidden = false
    } else if (props.analyticsDashboards.some(dashboard => dashboard.category === "REPORTS" && dashboard.url)) {
        isHidden = false
    }

    return (
        <CollapsibleMenuGroup
            {...props}
            icon={<IconReports width={20} height={20} />}
            iconName="reports"
            menuTitle="Reports"
            navCategory="reports"
            isHidden={isHidden}
        >
            <MenuOptions {...props} options={props.routes[navCategories.reports]} />
        </CollapsibleMenuGroup>
    )
}

const mapStateToProps = state => ({
    isTimekeepingFeatureEnabled: isFeatureEnabledSelector(state, "timekeeping"),
})

export default connect(mapStateToProps)(Reports)
