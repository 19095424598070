import { TableIds } from "../../../common/table-identifier-utils"
import {
    checkboxColDefCreator,
    companyStartStopTypeColDefCreator,
    dateColDefCreator,
    datetimeColDefCreator,
    editableTimeCardColDefCreator,
    employeeColDefCreator,
    stringColDefCreator,
    timekeepingStatusColDefCreator,
} from "./standard-col-def-creators"
import {
    addEmployeeRowsTableAction,
    addRowTableAction,
    alertCellClassRule,
    editTimecardDetailsAction,
    getAvailableStatusValues,
    isGroupRow,
    timekeepingDataIsEditable,
    timekeepingRowIsEditable,
} from "../../../common/ag-grid-utils"
import GroupRowFormattingDefaultCellRenderer from "../cell-renderers/GroupRowFormattingDefaultCellRenderer"
import { getSelectorActionButtons } from "./action-button-settings"
import { isNumber } from "../../../common/validators"
import { getFlagEnabled } from "../../../getFlagValue"
import {
    doesShiftEndingNextDayExist,
    findShiftRowData,
    getObjectId,
    lockedPeriodCellClassRule,
} from "../../../common/ag-grid-ts-utils"
import { addDays, addMinutes, differenceInMinutes } from "date-fns"
import { simpleTimeValueFormatter } from "../../../common/ag-grid-value-formatters"
import { compareGroupColumns } from "../../../common/ag-grid-comparators"
import { timekeepingEntryGeneralErrorsTooltip } from "../../../common/ag-grid-custom-tooltip-formatters"
import { weeklyTkDummyProject } from "../../../common/constants"
import { toggleCicoVisible, togglePlaceholdersVisible } from "../../../actions"
import { getAsDate } from "../../../common/ts-utils"
import { colorAttentionBlue50, colorAttentionRed60, colorFunctionalGray50 } from "@rhumbix/rmbx_design_system_web"
import { cicoEnabledFromLockedColumns } from "../utils"

const getLocalWorkshiftDate = params => {
    let date = new Date(params.data?.date)
    date = addMinutes(date, date.getTimezoneOffset())
    return date
}

const getLocalWorkshiftStartOrStop = params => {
    if (getFlagEnabled("WA-7827-date-picker-default") && params.data?.company_start_stop_type?.is_break === false) {
        if (params.colDef.field === "/start_time") return getAsDate(params.data.shift_start_time)
        else if (params.colDef.field === "/stop_time") return getAsDate(params.data.shift_end_time)
    }
    return null
}

export const getShiftsAndBreaksTable = (featureFlags, currentProject, user) => {
    const userRole = user ? user.user_role : undefined
    return {
        tableName: "Shifts and Breaks",
        id: TableIds.ShiftsAndBreaksModal,
        resources: ["ewsStartStopTimes"],
        cicoResource: "timelineEntryVersions",
        colDefs: [
            dateColDefCreator(),
            {
                ...checkboxColDefCreator(),
                checkboxSelection: p => !isGroupRow(p),
            },
            {
                ...employeeColDefCreator({
                    cellRenderer: GroupRowFormattingDefaultCellRenderer,
                }),
                ignoreRelatedFilters: ["projectId"],
            },
            {
                ...editableTimeCardColDefCreator({
                    headerName: "Time Card",
                    required: true,
                    width: 300,
                }),
                ignoreRelatedFilters: ["projectId"],
                extraFilterQueryParams: ["start_date", "foreman_id"],
                extraSearchFilters: { allow_empty: true },
                actionOptions: [getSelectorActionButtons().createNewTimecardButton],
            },
            companyStartStopTypeColDefCreator({
                required: true,
                cellRenderer: GroupRowFormattingDefaultCellRenderer,
            }),
            {
                ...datetimeColDefCreator({
                    cellEditorParams: {
                        minuteInterval: 1,
                        getOpenToDate: getLocalWorkshiftDate,
                        getMinDate: getLocalWorkshiftDate,
                        getMaxDate: params => {
                            const localWorkshiftDate = getLocalWorkshiftDate(params)
                            const employeeId = isNumber(params.data?.employee)
                                ? params.data?.employee
                                : params.data?.employee?.id

                            if (getFlagEnabled("WA-7464-no-next-day-date-picker")) {
                                // Be more restrictive, not allowing selection of the next
                                // day unless we're sure it's a break and there's a shift
                                // for the same person which ends on the next day
                                if (
                                    params.data?.company_start_stop_type?.is_break === true &&
                                    doesShiftEndingNextDayExist(params.api, localWorkshiftDate, employeeId)
                                ) {
                                    return addDays(localWorkshiftDate, 1)
                                } else return localWorkshiftDate
                            } else {
                                if (params.data?.is_break === false) {
                                    // Limit Shift start to the workshift date.
                                    return localWorkshiftDate
                                }
                                return addDays(localWorkshiftDate, 1)
                            }
                        },
                        getInitialValue: getLocalWorkshiftStartOrStop,
                        stopEditAfterSelectTime: true,
                    },
                    cellRenderer: GroupRowFormattingDefaultCellRenderer,
                    field: "/start_time",
                    headerName: "Start Time",
                    required: true,
                    valueFormatter: params => {
                        if (isGroupRow(params)) {
                            const shift = findShiftRowData(params.node)
                            if (!shift) return ""
                            return simpleTimeValueFormatter({
                                value: shift.data.start_time,
                            })
                        }
                        return simpleTimeValueFormatter(params)
                    },
                }),
                minWidth: 320,
            },
            {
                ...datetimeColDefCreator({
                    cellEditorParams: {
                        minuteInterval: 1,
                        getOpenToDate: getLocalWorkshiftDate,
                        getMinDate: getLocalWorkshiftDate,
                        getMaxDate: params => {
                            const workShiftDate = getLocalWorkshiftDate(params)
                            const nextDay = addDays(workShiftDate, 1)
                            const isBreak = params.data?.company_start_stop_type?.is_break
                            const employeeId = isNumber(params.data?.employee)
                                ? params.data?.employee
                                : params.data?.employee?.id

                            if (getFlagEnabled("WA-7464-no-next-day-date-picker")) {
                                // Early out for shifts. We explicitly check for false
                                // so that undefined stuff doesn't allow for next day
                                if (isBreak === false) return nextDay

                                // Allow the next day if we can find a shift with an end
                                // date as the next day
                                const nextDayShiftEnd = doesShiftEndingNextDayExist(
                                    params.api,
                                    workShiftDate,
                                    employeeId
                                )
                                return nextDayShiftEnd ? nextDay : workShiftDate
                            }
                            return nextDay
                        },
                        getInitialValue: getLocalWorkshiftStartOrStop,
                        stopEditAfterSelectTime: true,
                    },
                    cellRenderer: GroupRowFormattingDefaultCellRenderer,
                    field: "/stop_time",
                    headerName: "Stop Time",
                    required: true,
                    valueFormatter: params => {
                        if (isGroupRow(params)) {
                            const shift = findShiftRowData(params.node)
                            if (!shift) return ""
                            return simpleTimeValueFormatter({
                                value: shift.data.stop_time,
                            })
                        }
                        return simpleTimeValueFormatter(params)
                    },
                }),
                minWidth: 320,
            },
            {
                ...stringColDefCreator({
                    cellRenderer: GroupRowFormattingDefaultCellRenderer,
                    editable: false,
                    headerName: "Duration",
                    valueGetter: params => {
                        let { data } = params
                        if (isGroupRow(params)) {
                            const shift = findShiftRowData(params.node)
                            data = shift?.data
                        }
                        if (!data) return

                        const { is_break, start_time, stop_time } = data
                        if (!start_time || !stop_time) {
                            return ""
                        }

                        const durationInMinutes = differenceInMinutes(new Date(stop_time), new Date(start_time))

                        if (is_break) {
                            return `${durationInMinutes} minutes`
                        }

                        const shiftHours = Math.floor(durationInMinutes / 60)
                        const shiftMinutes = durationInMinutes % 60
                        let shiftDurationText = ""
                        if (shiftHours) {
                            shiftDurationText += `${shiftHours} hours`
                        }
                        if (shiftMinutes) {
                            shiftDurationText += `${shiftHours ? ", " : ""}${shiftMinutes} minutes`
                        }

                        return shiftDurationText
                    },
                }),
            },
            // This value gets computed on the fly
            ...(featureFlags.cico
                ? [
                      {
                          headerName: "CI/CO",
                          field: "/cico_diff",
                          minWidth: 100,
                          editable: false,
                          cellStyle: params => {
                              if (params.value === "--") return { color: colorFunctionalGray50 }
                              const val = parseFloat(params.value)
                              if (val > 0) return { color: colorAttentionBlue50 }
                              else if (val < 0) return { color: colorAttentionRed60 }
                          },
                          valueGetter: params => {
                              const cicoEnabled = cicoEnabledFromLockedColumns(params.context)
                              const { referenceableData } = params.context
                              let timecardCicoEnabled = true
                              if (params.data.work_shift_id) {
                                  const workShift =
                                      referenceableData.workShifts[getObjectId(params.data.work_shift_id)]

                                  timecardCicoEnabled = workShift?.projects?.some(
                                      pId => referenceableData.projects[pId]?.cico_settings?.enabled
                                  )
                              }
                              return !getFlagEnabled("WA-8499-enforce-cico-settings") ||
                                  (cicoEnabled && timecardCicoEnabled)
                                  ? params.data.cico_diff
                                  : "--"
                          },
                      },
                  ]
                : []),
            {
                ...timekeepingStatusColDefCreator({
                    enum: getAvailableStatusValues(userRole),
                }),
                cellRenderer: GroupRowFormattingDefaultCellRenderer,
                aggFunc: "statusColumnAggFunc",
            },
        ],
        gridSettings: {
            autoGroupColumnDef: {
                headerName: "Type",
                suppressMenu: true,
                sort: "asc",
                unSortIcon: true,
                cellRendererParams: {
                    suppressCount: true,
                },
                comparator: compareGroupColumns,
                checkboxSelection: p => isGroupRow(p),
                groupSelectsChildren: false,
                minWidth: 250,
            },
            defaultColDef: {
                cellClassRules: {
                    "alert-cell": alertCellClassRule,
                    "period-locked": lockedPeriodCellClassRule,
                    readonly: params => !timekeepingDataIsEditable(params).canEdit,
                },
                editable: params => timekeepingDataIsEditable(params).canEdit,
                filter: false,
                customTooltip: params => timekeepingEntryGeneralErrorsTooltip(params, true),
            },
            enableRangeSelection: true,
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            suppressMultiRangeSelection: true,
            isRowSelectable: rowNode => timekeepingRowIsEditable(rowNode),
        },
        otherSettings: {
            pasteRangeEnabled: true,
            enableClipboard: true,
            filterByLockedColumns: true,
            hideLockedColumns: true,
            highlightErrorRows: true,
            isInModalTab: true,
            sizeColumnsToFitSize: 915,
            rowLevelErrorDisplay: true,
            suppressExportButton: true,
            hiddenColumnDefaults: {
                project: {
                    value: weeklyTkDummyProject,
                },
            },
            groupBy: [
                {
                    cols: [],
                    label: "None",
                },
                {
                    cols: ["/employee"],
                    label: "Employee",
                },
            ],
            buttons: {
                cell: [],
                row: [
                    {
                        label: "Delete",
                        icon: "delete",
                        action: "deleteSelectedRows",
                        args: {
                            local_state_update: true,
                        },
                    },
                    ...editTimecardDetailsAction(),
                ],
                groupRow: [
                    {
                        label: "Add Row",
                        icon: "add",
                        action: "addNewRowToGroup",
                    },
                ],
                table: [
                    {
                        label: "Group By",
                        action: "groupBy",
                        icon: "group",
                    },
                    addRowTableAction(featureFlags),
                    addEmployeeRowsTableAction,
                    ...editTimecardDetailsAction(),
                    ...(featureFlags.cico
                        ? [
                              {
                                  label: "Show/Hide ▾",
                                  icon: "view",
                                  args: {
                                      extraArgs: {
                                          options: [
                                              {
                                                  label: "Placeholders",
                                                  action: togglePlaceholdersVisible,
                                                  // The state parameter we look at to see if this should be
                                                  // checked on
                                                  activeStateParams: ["placeholdersVisible"],
                                              },
                                              {
                                                  label: "CI/CO Data",
                                                  action: toggleCicoVisible,
                                                  // The state parameter we look at to see if this should be
                                                  // checked on
                                                  activeStateParams: ["cicoVisible"],
                                                  disabled: ctx => {
                                                      return (
                                                          getFlagEnabled("WA-8499-enforce-cico-settings") &&
                                                          !cicoEnabledFromLockedColumns(ctx)
                                                      )
                                                  },
                                              },
                                          ],
                                      },
                                  },
                                  checklist: true,
                              },
                          ]
                        : []),
                ],
            },
        },
    }
}
