import { createSelector } from "reselect"

import { filtersSelector } from "../filters/selectors"

/**
 * Return the current project ID for legacy views that only support a
 * single project or all projects.
 */
export const currentProjectIdSelector = createSelector(filtersSelector, filters => {
    if (Array.isArray(filters.projectId)) {
        return filters.projectId.length ? filters.projectId[0] : null
    }
    return filters.projectId || null
})

/**
 * Return the list of current project IDs
 */
export const currentProjectIdsListSelector = createSelector(filtersSelector, filters =>
    Array.isArray(filters.projectId) ? filters.projectId : filters.projectId ? [filters.projectId] : []
)

/**
 * Return the current project status ("ACTIVE", "INACTIVE", or null)
 */
export const projectStatusSelector = createSelector(filtersSelector, filters => filters.projectStatus || null)

/**
 * Return the entire pagination state of projects
 */
export const projectsPaginationStateSelector = state => state.pagination.projects || {}

/**
 * Return all cached projects as an object keyed by ID
 */
export const projectsSelector = state => (state.entities.projects ? state.entities.projects.objects : {})

/**
 * Return the current project
 */
export const currentProjectSelector = createSelector(
    currentProjectIdSelector,
    projectsSelector,
    (currentProjectId, projects) => projects[currentProjectId]
)

/**
 * Return all cached projects as an array of objects
 */
export const projectsListSelector = createSelector(
    projectsSelector,
    projectsObj => Object.values(projectsObj) || []
)

/**
 * Return the pagination state of all projects
 */
export const allProjectsPaginationSelector = state => state.pagination.projects.all || {}

/**
 * Return true if the projects list is currently fetching data
 */
export const projectsLoadingSelector = createSelector(
    allProjectsPaginationSelector,
    allProjects => allProjects.isFetching
)

/**
 * Return the error message associated with loading projects, if any
 */
export const projectsErrorSelector = createSelector(
    allProjectsPaginationSelector,
    allProjects => allProjects.error || null
)

/**
 * Return true if the request for the first project completed, even if it had
 * empty results
 */
export const firstProjectLoadedSelector = createSelector(projectsPaginationStateSelector, pagination => {
    const key = "page_size=1"
    return pagination[key] && pagination[key].pageCount > 0
})

/**
 * Return true if the entire projects list has been loaded */
export const allProjectsLoadedSelector = createSelector(
    allProjectsPaginationSelector,
    allProjects => allProjects.pageCount > 0 && !allProjects.nextPageUrl
)

/**
 * Return a list of IDs for projects that are currently selected.
 *
 * If all projects are selected, this list will be empty. That's OK because
 * this list is only ever used as the basis for filtering other lists by
 * project; the filter logic should just bail out on empty lists that don't
 * have a project status set.
 */
export const selectedProjectIdsListSelector = createSelector(
    currentProjectIdSelector,
    projectStatusSelector,
    projectsListSelector,
    (currentProjectId, projectStatus, projects) => {
        let selectedProjects = []

        if (currentProjectId) {
            selectedProjects = [currentProjectId]
        } else if (projectStatus === "ACTIVE") {
            selectedProjects = projects
                .filter(project => project.status === "ACTIVE")
                .reduce((acc, project) => {
                    acc.push(project.id)
                    return acc
                }, [])
        } else if (projectStatus === "INACTIVE") {
            selectedProjects = projects
                .filter(project => project.status === "INACTIVE")
                .reduce((acc, project) => {
                    acc.push(project.id)
                    return acc
                }, [])
        }

        return selectedProjects
    }
)
