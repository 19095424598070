import * as API from "../../api"
import { iActionData, Thunk } from "../../common/types"

export const FETCHING_COMPANY_FORM_STORES = "FETCHING_COMPANY_FORM_STORES"
export const FETCHING_COMPANY_FORM_STORES_SUCCESSFUL = "FETCHING_COMPANY_FORM_STORES_SUCCESSFUL"
export const FETCHING_COMPANY_FORM_STORES_FAILED = "FETCHING_COMPANY_FORM_STORES_FAILED"
export const COMPANY_FORM_STORES_CLEARED = "COMPANY_FORM_STORES_CLEARED"

export interface fetchingCompanyFormStores extends iActionData {
    type: typeof FETCHING_COMPANY_FORM_STORES
}

export interface fetchingCompanyFormStoresSuccessful extends iActionData {
    type: typeof FETCHING_COMPANY_FORM_STORES_SUCCESSFUL
}

export interface fetchingCompanyFormStoresFailed extends iActionData {
    type: typeof FETCHING_COMPANY_FORM_STORES_FAILED
}

export interface companyFormStoresCleared extends iActionData {
    type: typeof COMPANY_FORM_STORES_CLEARED
}

export type BulkCompanyFormStoreActions =
    | fetchingCompanyFormStores
    | fetchingCompanyFormStoresFailed
    | fetchingCompanyFormStoresSuccessful
    | companyFormStoresCleared

export const getBulkFormStores = (selectedIds: number[], page: number): Thunk => {
    return dispatch => {
        dispatch(bulkCompanyFormStoreFetchStarted())
        return API.getBulkFormStores(selectedIds, page)
            .then((data: any) => {
                // add form stores to redux store
                dispatch(bulkCompanyFormStoreFetchSuccessful(data))
            })
            .catch(() => {
                dispatch(bulkCompanyFormStoreFetchFailed())
            })
    }
}

const bulkCompanyFormStoreFetchStarted = () => {
    return {
        type: FETCHING_COMPANY_FORM_STORES,
    }
}

const bulkCompanyFormStoreFetchFailed = () => {
    return {
        type: FETCHING_COMPANY_FORM_STORES,
    }
}

export const bulkCompanyFormStoresCleared = () => {
    return {
        type: COMPANY_FORM_STORES_CLEARED,
    }
}

const bulkCompanyFormStoreFetchSuccessful = (data: any) => {
    return {
        type: FETCHING_COMPANY_FORM_STORES_SUCCESSFUL,
        data,
    }
}

export const FETCHING_COMPARE_FORM_STORE = "FETCHING_COMPARE_FORM_STORE"
export const FETCHING_COMPARE_FORM_STORE_SUCCESSFUL = "FETCHING_COMPARE_FORM_STORE_SUCCESSFUL"
export const FETCHING_COMPARE_FORM_STORE_FAILED = "FETCHING_COMPARE_FORM_STORE_FAILED"
export const COMPARE_FORM_STORE_CLEARED = "COMPARE_FORM_STORE_CLEARED"

export interface fetchingCompareFormStore extends iActionData {
    type: typeof FETCHING_COMPARE_FORM_STORE
}

export interface fetchingCompareFormStoreSuccessful extends iActionData {
    type: typeof FETCHING_COMPARE_FORM_STORE_SUCCESSFUL
}

export interface fetchingCompareFormStoreFailed extends iActionData {
    type: typeof FETCHING_COMPARE_FORM_STORE_FAILED
}

export interface compareFormStoreCleared extends iActionData {
    type: typeof COMPARE_FORM_STORE_CLEARED
}

export type BulkCompareFormStoreActions =
    | fetchingCompareFormStore
    | fetchingCompareFormStoreFailed
    | fetchingCompareFormStoreSuccessful
    | compareFormStoreCleared

export const getCompareBulkFormStore = (selectedIds: number[]): Thunk => {
    return dispatch => {
        dispatch(bulkCompareFormStoreFetchStarted())
        return API.getCompareBulkFormStore(selectedIds)
            .then((data: any) => {
                dispatch(bulkCompareFormStoreFetchSuccessful(data))
            })
            .catch(() => {
                dispatch(bulkCompareFormStoreFetchFailed())
            })
    }
}

const bulkCompareFormStoreFetchStarted = () => {
    return {
        type: FETCHING_COMPARE_FORM_STORE,
    }
}

const bulkCompareFormStoreFetchFailed = () => {
    return {
        type: FETCHING_COMPARE_FORM_STORE,
    }
}

export const bulkCompareFormStoreCleared = () => {
    return {
        type: COMPARE_FORM_STORE_CLEARED,
    }
}

const bulkCompareFormStoreFetchSuccessful = (data: any) => {
    return {
        type: FETCHING_COMPARE_FORM_STORE_SUCCESSFUL,
        data,
    }
}
