const initialState = {
    data: null,
    inviteSentResponseObj: null,
    selectedForemen: [],
    sendToSelf: false,
    showInvitesSuccess: false,
    showInvitesFail: false,
}

export default function invitePage(state = initialState, action) {
    switch (action.type) {
        case "FOREMAN_SELECTED": {
            const selectedForemen = state.selectedForemen
            if (action.isSelected) {
                selectedForemen.push(action.foreman)
            } else {
                selectedForemen.splice(selectedForemen.indexOf(action.foreman), 1)
            }
            return Object.assign({}, state, {
                selectedForemen: selectedForemen,
            })
        }
        case "SELECT_ALL_FOREMEN":
            return Object.assign({}, state, {
                selectedForemen: action.allForemen,
            })
        case "UNSELECT_ALL_FOREMEN":
            return Object.assign({}, state, { selectedForemen: [] })
        case "INVITES_SENT": {
            let showInvitesSuccess = false
            let showInvitesFail = false
            if (action.data.success && action.data.success.length > 0) {
                showInvitesSuccess = true
            }
            if (action.data.errors && action.data.errors.length > 0) {
                showInvitesFail = true
            }
            return Object.assign({}, state, {
                selectedForemen: [],
                inviteSentResponseObj: action.data,
                showInvitesSuccess: showInvitesSuccess,
                showInvitesFail: showInvitesFail,
                sendToSelf: false,
            })
        }
        case "INVITES_SUCCESS_DISMISSED":
            return Object.assign({}, state, { showInvitesSuccess: false })
        case "INVITES_FAIL_DISMISSED":
            return Object.assign({}, state, { showInvitesFail: false })
        case "TOGGLE_SEND_TO_SELF":
            return Object.assign({}, state, { sendToSelf: !state.sendToSelf })
        default:
            return state
    }
}
