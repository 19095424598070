import { iCohort } from "../cached-data/types"
import { CohortActionTypes } from "./types"

export type tCohortState = {
    cohorts: iCohort[]
}

const initialState: tCohortState = {
    cohorts: [],
}

export default function cohorts(state: tCohortState = initialState, action: CohortActionTypes): tCohortState {
    switch (action.type) {
        case "FETCH_COHORT_FAILURE":
            return state
        case "FETCH_COHORT_SUCCESS":
            return {
                ...state,
                cohorts: action.payload,
            }
        default:
            return state
    }
}
