import React from "react"
import { Route } from "react-router-dom"

const InnerRoute = ({ settings, listView, component: Component, wrapper: Wrapper, ...props }) => (
    <Route
        {...props}
        render={renderProps =>
            // Need to pass both the route props and the render props to the
            // component so it can access React Router props like `match`.
            //
            // If the Component is connected to redux and needs to access the
            // `location` prop, you need to use `withRouter` (otherwise, you
            // can experience problems with blocked updates).
            //
            // See: https://reacttraining.com/react-router/core/guides/redux-integration
            //      https://reacttraining.com/react-router/core/api/withRouter
            //      https://github.com/Rhumbix/rmbx_django/pull/1542
            Wrapper ? (
                <Wrapper {...props} {...renderProps}>
                    <Component route={{ settings, listView, ...props }} {...renderProps} {...props} />
                </Wrapper>
            ) : (
                <Component route={{ settings, listView, ...props }} {...renderProps} {...props} />
            )
        }
    />
)

export default InnerRoute
