import React, { Component } from "react"
import { getCellValue } from "../../../common/ag-grid-utils"
import { dateValueFormatter } from "../../../common/ag-grid-value-formatters"
import { TIME_FORMAT } from "../../../common/constants"

class TimeCardTitleCellRenderer extends Component {
    refresh() {
        return false
    }

    render() {
        const value = getCellValue(this.props)

        // value should never be undefined, but occasionally it is (probably while the page
        // is still loading), so catch that here
        if (!value) {
            return <div className="group-cell"></div>
        }

        const title = this.props.valueFormatter({ value })
        const startTime = dateValueFormatter({ value: value.start_time }, TIME_FORMAT)
        const endTime = dateValueFormatter({ value: value.end_time }, TIME_FORMAT)

        return (
            <div className="group-cell">
                <div>
                    <div className="group-cell-title">{title}</div>
                    <div className="group-cell-text">
                        {startTime} - {endTime}
                    </div>
                </div>
            </div>
        )
    }
}

export default TimeCardTitleCellRenderer
