import CollapsibleMenuGroup from "../collapsible-menu-group"
import { navCategories } from "../../../common/constants"
import MenuOptions from "../menu-options"
import React from "react"
import { IconFlag } from "@rhumbix/rmbx_design_system_web"

const DemoDashboards = props => {
    return (
        <CollapsibleMenuGroup
            {...props}
            iconName="demo-beta"
            icon={<IconFlag width={20} height={20} />}
            menuTitle="Demo Dashboards"
            navCategory="demo-dashboards"
            isHidden={!props.features["custom_dashboards_demo"]}
        >
            <MenuOptions {...props} options={props.routes[navCategories.demo]} />
        </CollapsibleMenuGroup>
    )
}

export default DemoDashboards
