interface iPerformDataRefresh {
    type: "PERFORM_DATA_REFRESH"
    shouldRefresh: boolean
}

export type ServerSideRowModelAction = iPerformDataRefresh

export const performDataRefresh = (shouldRefresh: boolean): iPerformDataRefresh => {
    return {
        type: "PERFORM_DATA_REFRESH",
        shouldRefresh,
    }
}
