import {
    AGGRID_HIGHLIGHTED_ROW,
    AGGRID_HIGHLIGHTED_ROW_RESET,
    FETCH_SCHEMA_PERMISSIONS_FAILED,
    FETCH_SCHEMA_PERMISSIONS_SUCCESSFUL,
    LIST_VIEW_SCHEMA_ERROR,
    LIST_VIEW_SCHEMA_FETCHED,
    RELATED_SCHEMA_CLEAR,
    RELATED_SCHEMA_ERROR,
    RELATED_SCHEMA_FETCHED,
    RELATED_TRANSFORMS_ERROR,
    RELATED_TRANSFORMS_UPDATED,
    TRANSFORMS_FOR_IMPORT_ERROR,
    TRANSFORMS_FOR_IMPORT_UPDATED,
    ListViewSchemaActionTypes,
    RelatedSchemaActionTypes,
    RelatedTransformActionTypes,
    SchemaPermissionsActionTypes,
    tListViewSchemaState,
    tRelatedSchemaState,
    tRelatedTransformState,
    tSchemaPermissionsState,
    iAggridAction,
    AGGRID_ROW_FLASHED,
    tTransformForImportState,
    TransformForImportActionTypes,
    TRANSFORMS_FOR_IMPORT_PROCESS_DATA_SUCCEEDED,
    TRANSFORMS_FOR_IMPORT_PROCESS_DATA_FAILED,
    TRANSFORMS_FOR_IMPORT_RESET_RESULTS,
} from "./types"

const initialState: tRelatedTransformState = {
    relatedTransforms: [],
}

const relatedSchemaInitialState: tRelatedSchemaState = {
    relatedSchemas: [],
}

export function relatedTransforms(
    state: tRelatedTransformState = initialState,
    action: RelatedTransformActionTypes
) {
    switch (action.type) {
        case RELATED_TRANSFORMS_ERROR:
            return { ...state, relatedTransforms: [] }
        case RELATED_TRANSFORMS_UPDATED:
            return { ...state, relatedTransforms: action.data }
        default:
            return state
    }
}

const transformsForImportInitialState: tTransformForImportState = {
    transformsForImport: [],
    importedStore: {},
    importedStoreNames: undefined,
    message: undefined,
}

export function transformsForImport(
    state: tTransformForImportState = transformsForImportInitialState,
    action: TransformForImportActionTypes
) {
    switch (action.type) {
        case TRANSFORMS_FOR_IMPORT_ERROR:
            return { ...state, transformsForImport: [] }
        case TRANSFORMS_FOR_IMPORT_UPDATED:
            return { ...state, transformsForImport: action.data }
        case TRANSFORMS_FOR_IMPORT_PROCESS_DATA_SUCCEEDED:
            return {
                ...state,
                importedStore: action.importedStore?.results,
                importedStoreNames: action.importedStoreNames,
            }
        case TRANSFORMS_FOR_IMPORT_PROCESS_DATA_FAILED:
            return { ...state, message: action.message }
        case TRANSFORMS_FOR_IMPORT_RESET_RESULTS:
            return {
                ...state,
                message: undefined,
                importedStore: undefined,
                importedStoreNames: undefined,
            }
        default:
            return state
    }
}

export function relatedSchemas(
    state: tRelatedSchemaState = relatedSchemaInitialState,
    action: RelatedSchemaActionTypes
) {
    switch (action.type) {
        case RELATED_SCHEMA_ERROR:
            return { ...state, relatedSchemas: [] }
        case RELATED_SCHEMA_FETCHED:
            return { ...state, relatedSchemas: action.data }
        case RELATED_SCHEMA_CLEAR:
            return { ...state, relatedSchemas: [] }
        default:
            return state
    }
}

const listViewSchemasInitialState: tListViewSchemaState = {
    schemas: [],
}

export function listViewSchemas(
    state: tListViewSchemaState = listViewSchemasInitialState,
    action: ListViewSchemaActionTypes
) {
    switch (action.type) {
        case LIST_VIEW_SCHEMA_FETCHED: {
            return { ...state, schemas: action.data }
        }
        case LIST_VIEW_SCHEMA_ERROR: {
            return { ...state, schemas: [] }
        }
        default:
            return state
    }
}

const schemaPermissionsInitialState: tSchemaPermissionsState = {
    schemaPermissions: [],
}
export function schemaPermissions(
    state: tSchemaPermissionsState = schemaPermissionsInitialState,
    action: SchemaPermissionsActionTypes
) {
    switch (action.type) {
        case FETCH_SCHEMA_PERMISSIONS_SUCCESSFUL:
            return { ...state, schemaPermissions: action.data }
        case FETCH_SCHEMA_PERMISSIONS_FAILED:
            return { ...state, schemaPermissions: [] }
        default:
            return state
    }
}

const aggridInitialState: iAggridAction = {
    highlightRow: -1,
    flashedRows: [],
}
export function aggridState(state: iAggridAction = aggridInitialState, action: any) {
    switch (action.type) {
        case AGGRID_HIGHLIGHTED_ROW:
            return { ...state, highlightRow: action.rowIndex }
        case AGGRID_HIGHLIGHTED_ROW_RESET:
            return { ...state, highlightRow: aggridInitialState.highlightRow }
        case AGGRID_ROW_FLASHED:
            return { ...state, flashedRows: [...state.flashedRows, action.formId] }
        default:
            return state
    }
}
