import { SearchBarState } from "../common/types"

interface iSetSearchTerm {
    type: "SET_SEARCH_TERM"
    searchTerm: string
}

interface iSetFancySearchTerm {
    type: "SET_FANCY_SEARCH_TERM"
    searchTerm: { term: string[] | string; category: string; doFilter: boolean }
}

interface iSetCicoExceptionValue {
    type: "SET_CICO_EXCEPTION_VALUE"
    value: { value: string; units: "hours" | "percent" }
}

interface iSetHoursExceptionValues {
    type: "SET_HOURS_EXCEPTION_VALUES"
    value: { minHours: string; maxHours: string }
}

interface iToggleFancySearchVisible {
    type: "TOGGLE_FANCY_SEARCH_VISIBLE"
}

interface iTogglePlaceholdersVisible {
    type: "TOGGLE_PLACEHOLDERS_VISIBLE"
}

interface iToggleCicoVisible {
    type: "TOGGLE_CICO_VISIBLE"
}

interface iToggleCicoExceptions {
    type: "TOGGLE_CICO_EXCEPTIONS"
}

interface iToggleHoursExceptions {
    type: "TOGGLE_HOURS_EXCEPTIONS"
}

interface iToggleSearchBarState {
    type: "TOGGLE_SEARCH_BAR_STATE"
    attr: keyof SearchBarState
}

export type SearchBarAction =
    | iSetSearchTerm
    | iSetFancySearchTerm
    | iSetCicoExceptionValue
    | iSetHoursExceptionValues
    | iToggleFancySearchVisible
    | iTogglePlaceholdersVisible
    | iToggleCicoExceptions
    | iToggleHoursExceptions
    | iToggleCicoVisible
    | iToggleSearchBarState

export const setSearchTerm = (searchTerm: string): iSetSearchTerm => {
    return {
        type: "SET_SEARCH_TERM",
        searchTerm,
    }
}

export const setFancySearchTerm = (searchTerm: {
    term: string[] | string
    category: string
    doFilter: boolean
}): iSetFancySearchTerm => {
    return {
        type: "SET_FANCY_SEARCH_TERM",
        searchTerm,
    }
}

export const toggleFancySearchVisible = (): iToggleFancySearchVisible => {
    return {
        type: "TOGGLE_FANCY_SEARCH_VISIBLE",
    }
}
