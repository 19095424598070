import { format } from "date-fns"
import { loadAllEntities } from "../../../cached-data/actions"
import { SchemaDashboardRowData, tProject } from "../../../cached-data/types"
import { DATE_ONLY_FORMAT } from "../../../common/constants"
import { iCurrentUser, tFeatures, Thunk } from "../../../common/types"
import ManageSchemaAccessButton from "../schemas/ManageSchemaAccessButton"
import {
    integerColDefCreator,
    stringColDefCreator,
    buttonColDefCreator,
    slideToggleColDefCreator,
    workflowButtonColDefCreator,
} from "./standard-col-def-creators"
import { getFlagEnabled } from "../../../getFlagValue"

type AgRowNode = {
    data: SchemaDashboardRowData
}

export const getFieldFormsDashboardSettings = (
    featureFlags: tFeatures,
    currentProject: tProject,
    currentUser: iCurrentUser
) => {
    const { user_role } = currentUser
    const canManage = user_role === "ADMIN"
    const settings = {
        tableName: "Field Forms",
        navId: "fieldForms",
        resources: ["companyFormSchemasForDashboard"],
        filters: [],
        additionalQueryParams: {
            include_annotations: true,
            "fields!": [
                "available_statuses",
                "initial_status",
                "permissions",
                "schema",
                "schema_type",
                "status_transitions",
                "template",
                "ui_schema",
            ],
            ...(getFlagEnabled("WA-8120-ff-projects-company-settings") ? { skip_projects: true } : null),
        },
        initAction: (): Thunk => dispatch => {
            dispatch(loadAllEntities("companyGroups"))
            dispatch(loadAllEntities("projects", { forceBigPageSize: true }))
        },
        colDefs: [
            stringColDefCreator({
                field: "/name",
                headerName: "Name",
                sort: "asc",
            }),
            stringColDefCreator({
                editable: canManage,
                field: "/description",
                headerName: "Description",
                width: 300,
            }),
            {
                ...slideToggleColDefCreator({
                    headerName: "Notifications",
                    field: "/send_email",
                    shouldPreventActionFocus: true,
                    hide: !getFlagEnabled("WA-7979-ff-notifications"),
                    editable: true,
                }),
                headerTooltip:
                    "Enable this to send email notifications for this form. This setting impacts all users.",
            },
            stringColDefCreator({
                headerName: "Groups with Auto-Add",
                valueGetter: ({ data: { number_groups_with_auto_add, total_groups } }: AgRowNode) => {
                    if (getFlagEnabled("WA-7632-project-specific-modifiers")) {
                        return `${number_groups_with_auto_add} of ${total_groups}`
                    }

                    if (number_groups_with_auto_add === total_groups) {
                        return total_groups === 0 ? "none" : `all ${total_groups}`
                    }

                    return `${number_groups_with_auto_add} of ${total_groups}`
                },
                width: 300,
            }),
            stringColDefCreator({
                headerName: "Projects",
                valueGetter: ({ data: { number_projects, total_projects } }: AgRowNode) => {
                    if (getFlagEnabled("WA-7632-project-specific-modifiers")) {
                        return `${number_projects} of ${total_projects}`
                    }

                    if (number_projects === total_projects) {
                        return total_projects === 0 ? "none" : `all ${total_projects}`
                    }

                    return `${number_projects} of ${total_projects}`
                },
                width: 100,
            }),
            integerColDefCreator({
                cellStyle: { textAlign: "right" },
                field: "/number_generated",
                headerName: "Forms Generated",
                width: 150,
            }),
            stringColDefCreator({
                headerName: "Last Used",
                field: "/last_used",
                valueFormatter: ({ value }) => {
                    if (!value) return "never"

                    const lastUsed = new Date(value)
                    return format(lastUsed, DATE_ONLY_FORMAT)
                },
                width: 100,
            }),
            {
                ...buttonColDefCreator({ width: 150 }),
                cellRenderer: ManageSchemaAccessButton("field-forms"),
                headerName: "Actions",
                hide: !canManage,
            },
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: false,
            },
        },
        otherSettings: {
            enableSearchBar: true,
        },
    }

    if (getFlagEnabled("Field-Form-Workflow"))
        settings.colDefs.push(
            workflowButtonColDefCreator({
                headerName: "Workflow",
                field: "/workflow",
            })
        )

    return settings
}

export const getGroupsFieldFormsTabSettings = (
    featureFlags: tFeatures,
    currentProject: tProject,
    currentUser: iCurrentUser
) => {
    const base = getFieldFormsDashboardSettings(featureFlags, currentProject, currentUser)
    // Filter out any unnecessary columns
    const groupsDashboardExcludedColumns = ["Groups with Auto-Add", "Actions"]
    base.colDefs = base.colDefs.filter(
        colDef => colDef.headerName && !groupsDashboardExcludedColumns.includes(colDef.headerName)
    )
    // Make everything read-only
    base.colDefs.forEach(colDef => (colDef.editable = false))

    return base
}
