import React from "react"
import rhumbixLogoImg from "../../../images/rhumbix-logo.png"
import arrow from "../../../images/sidebar_icons/arrow-collapsible-white.svg"
import { actOnEnterOrSpace } from "../../common/js-utils"

import "sidebar.less"

type tProps = {
    isSidebarCollapsed: boolean
    toggleSideMenu: { (arg: boolean): void }
}

export const Logo = (props: tProps): JSX.Element => {
    const { isSidebarCollapsed, toggleSideMenu } = props

    const sidebarStyle: string = isSidebarCollapsed ? "expand" : "shrink"

    const toggleSidebar = (): void => {
        toggleSideMenu(!isSidebarCollapsed)

        //TODO: REMOVE ONCE MS EDGE IS NO LONGER SUPPORTED
        //https://techcrunch.com/2018/12/04/microsoft-is-reportedly-replacing-edge-with-a-chromium-based-browser/
        //unfortunately, display flex is not calculated correctly for all
        //view on MS Edge, so we need to max out the width of content-viewport
        //in order to make sure the grid and toolbars are in view
        const contentView = document.querySelector(".content-viewport")
        if (contentView) {
            if (isSidebarCollapsed) {
                contentView.classList.remove("mini-sidebar")
            } else {
                contentView.classList.add("mini-sidebar")
            }
        }
    }

    const toggleContainer: JSX.Element = (
        <div
            className={`${sidebarStyle}-sidebar-button`}
            onClick={toggleSidebar}
            onKeyDown={e => actOnEnterOrSpace(e, toggleSidebar)}
            role="button"
            tabIndex={0}
        >
            <img alt={`Click to ${sidebarStyle} sidebar`} src={arrow} />
        </div>
    )

    return isSidebarCollapsed ? (
        toggleContainer
    ) : (
        <div className="sidebar-logo-container sidebar-left-padding">
            <img className="sidebar-logo" src={rhumbixLogoImg} alt="Rhumbix Logo" />
            {toggleContainer}
        </div>
    )
}

export default Logo
