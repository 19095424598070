import { createSelector } from "reselect"
import sortBy from "lodash/sortBy"

import { filtersSelector } from "../filters/selectors"

/**
 * Return the current company group ID
 */
export const currentCompanyGroupIdSelector = createSelector(filtersSelector, filters => filters.groupId || null)

export const areAllCompanyGroupsSelectedSelector = createSelector(
    currentCompanyGroupIdSelector,
    currentCompanyGroupId => currentCompanyGroupId == null
)

/**
 * Return the filtered cohorts
 */
export const currentCohortIdSelector = createSelector(filtersSelector, filters => filters.cohortId || null)

/**
 * Return all cached company groups as an object keyed by ID
 */
export const companyGroupsSelector = state =>
    state.entities.companyGroups ? state.entities.companyGroups.objects : {}

export const companyGroupsListSelector = createSelector(companyGroupsSelector, companyGroupsById =>
    sortBy(Object.values(companyGroupsById), group => group.name.toLowerCase())
)

/*
 * Return the currently selected group if there is just one selected,
 * or null if none or multiple are selected
 */
export const singleSelectedGroupIdSelector = createSelector(
    [currentCompanyGroupIdSelector, companyGroupsListSelector],
    (amalgam, availableGroups) =>
        // Selected group could be an ID
        Number.isInteger(amalgam)
            ? Number(amalgam)
            : // Or, selected group could be an array of IDs. If there's just one, use it.
            Array.isArray(amalgam) && amalgam.length === 1
            ? amalgam[0]
            : // Or, if no group is selected yet, and there's only one group available, use it.
            availableGroups.length === 1
            ? availableGroups[0].id
            : null
)
