import React from "react"
import styled from "styled-components"
import { colorBrandRhumbixBlack, colorFunctionalWhite, CTAButton, fontSizeM } from "@rhumbix/rmbx_design_system_web"

type Props = {
    // The deactivation date for this trial account, as a UNIX timestamp
    deactivationDate: number
}

const TrialAccountInfoBar = (props: Props): React.ReactElement => {
    const daysLeft = Math.round((props.deactivationDate * 1000 - Date.now()) / (1000 * 60 * 60 * 24))

    const message =
        daysLeft >= 0 ? (
            <div>
                You have{" "}
                <span style={{ fontWeight: 700 }}>
                    {daysLeft} {daysLeft === 1 ? "day" : "days"}
                </span>{" "}
                left on your trial. Ready to purchase?
            </div>
        ) : (
            <div>
                <span style={{ fontWeight: 700 }}>Your trial has expired.</span> Ready to purchase?
            </div>
        )

    const openPricingPage = () => {
        window.location.replace("https://www.rhumbix.com/pricing")
    }

    return (
        <TrialInfoBar data-testid="trial-info-bar">
            {message}
            <CTAButton
                theme="primary"
                style={{
                    marginLeft: "16px",
                    marginRight: "8px",
                    minHeight: "40px",
                    minWidth: "170px",
                    padding: "9px 24px",
                }}
                text="Explore Plans"
                onClick={openPricingPage}
            />
        </TrialInfoBar>
    )
}

export default TrialAccountInfoBar

const TrialInfoBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    white-space: pre-wrap;
    justify-items: center;
    background-color: ${colorBrandRhumbixBlack};
    font: Roboto;
    font-weight: 400;
    font-size: ${fontSizeM};
    height: 56px;
    color: ${colorFunctionalWhite};
`
