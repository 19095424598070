import { tReferenceableInactiveChecks } from "./types"
import { JsonPointer as jsonpointer } from "json-ptr"

export const referenceableInactiveChecks: tReferenceableInactiveChecks = {
    costCodes: (value: Record<string, any>) => {
        return (
            jsonpointer.get(value, "/project/status") === "INACTIVE" ||
            jsonpointer.get(value, "/is_active") === false
        )
    },
    employees: (value: Record<string, any>) => jsonpointer.get(value, "/is_active") === false,
    projects: (value: Record<string, any>) => jsonpointer.get(value, "/status") === "INACTIVE",
}
