const initialState = {
    markups: new Map(),
    markupsChecked: false,
    laborChecked: false,
    equipmentChecked: false,
    materialsChecked: false,
}

type tAction = {
    type: string
    data: any
}

export default function markups(state = initialState, action: tAction) {
    switch (action.type) {
        case "PROJECT_MARKUP_TOGGLE":
            return {
                ...state,
                markupsChecked: !state.markupsChecked,
                laborChecked: !state.markupsChecked ? state.laborChecked : false,
                equipmentChecked: !state.markupsChecked ? state.equipmentChecked : false,
                materialsChecked: !state.markupsChecked ? state.materialsChecked : false,
            }
        case "PROJECT_MARKUP_LABOR_TOGGLE":
            return {
                ...state,
                laborChecked: !state.laborChecked,
            }
        case "PROJECT_MARKUP_EQUIPMENT_TOGGLE":
            return {
                ...state,
                equipmentChecked: !state.equipmentChecked,
            }
        case "PROJECT_MARKUP_MATERIALS_TOGGLE":
            return {
                ...state,
                materialsChecked: !state.materialsChecked,
            }
        case "PROJECT_MARKUPS_UPDATE_MARKUPS_DATA":
            return {
                ...state,
                markups: action.data,
            }
        case "PROJECT_MARKUPS_UPDATE_MARKUPS_DATA_INITIAL":
            return {
                ...state,
                markups: action.data.markups,
                markupsChecked: action.data.markupsChecked,
                laborChecked: action.data.laborChecked,
                equipmentChecked: action.data.equipmentChecked,
                materialsChecked: action.data.materialsChecked,
            }
        default:
            return state
    }
}
