import { _delete } from "./util"
import { urlForResource } from "../common/constants"

export const deleteAbsence = absenceId => _delete(`/api/v3/absences/${absenceId}/`, null, 204)

export const deleteAutodeskToken = () => _delete(urlForResource.autodeskToken, null, 200)

export const deleteCompanyFormStore = id =>
    _delete(`/api/v4/company_form_stores/${id}/`, null, 200, false, false, { ignore_schema_types: true })

export const deleteRow = (resource, data) => _delete(`${urlForResource[resource]}${data.id}/`, null, 204)

export const deleteWorkshift = id => _delete(`/api/v3/work_shifts/${id}/`, null, 204)

export const deleteTmInviteCampaignCookie = () => _delete("/api/v3/users/delete_tm_invite_campaign_cookie/")

export const deleteSavedFilterSet = id => _delete(`/api/v4/saved_filter_sets/${id}`, null, 204)
