import {
    convertUtcToTimezone,
    getAsDate,
    getAssumedLocalTimezone,
    getStyledIcon,
    getTimezoneAcronym,
    IconName
} from "../../common/ts-utils"
import { renderToStaticMarkup } from "react-dom/server"
import { isValid, format } from "date-fns"
import { DATE_ONLY_FORMAT } from "../../common/constants"
import rmbx from "../../util"

/**
 * Format the given datetime object, return a string in the given format
 */
export const formatDate = (
    dateVal: string | null,
    dateFormat = DATE_ONLY_FORMAT,
    tz = getAssumedLocalTimezone()
): string => {
    if (!dateVal) return ""
    // this is a safety net if any old template gets added with Moment style formats
    dateFormat = dateFormat
        .replace(/D/g, "d")
        .replace(/Y/g, "y")
        .replace(/A/g, "a")
    // we only want to apply timezone functions to datetimes, not date only strings
    const isDatetime = dateFormat.includes("H")
    // created on dates come in as datetimes, but we display as date-only
    // check for UTC timestamp (ends in Z) and convert to zoned time so dates are not offset
    const date = isDatetime || dateVal.includes("Z") ? convertUtcToTimezone(dateVal, tz) : getAsDate(dateVal)
    // add the tz acronym to the string if a timezone is passed in and the date format includes a timestamp
    const tzAcronym = isDatetime && typeof tz === "string" ? getTimezoneAcronym(tz) : ""
    return isValid(date) ? `${format(date, dateFormat)}${tzAcronym}` : ""
}

/**
 * Takes in a number and an optional totalDigits number (defaults to 4 digits)
 * Returns a string with the number with leading zeros for any open digits in the string
 * ex. addLeadingZeros(1) -> "0001"  or  addLeadingZeros(55, 10) -> "0000000055"
 */
export const addLeadingZeros = (number: number, totalDigits = 4): string => {
    // if handlebars doesn't have a value for an optional parameter, it will pass in an object with the instance
    // it will ignore the default value - so only use totalDigits if it is a number otherwise fallback to default
    totalDigits = rmbx.util.isNumber(totalDigits) ? totalDigits : 4
    return `${String(number).padStart(totalDigits, "0")}`
}

/**
 * Gets an SVG using an icon from the design system.
 * @param { string } resourceName Name of the icon - check IconName type for options
 * @param { Record<string, any> } styles Optional if you want to pass specific css styles to the icon
 * @returns { string } HTML containing svg
 */
export const getResourceIcon = (
    resourceName: IconName | "none",
    styles: Record<string, string> | string
): string => {
    if (resourceName === "none" || !resourceName) {
        return ""
    }
    if (typeof styles === "string") {
        styles = JSON.parse(styles)
    }
    // get the icon from the design system
    // the `any` type casting fixes a weird typescript conflict in the ReactNode type
    const icon = getStyledIcon(resourceName, styles as Record<string, string>) as any
    // convert it to html so we can plug it into the template
    return renderToStaticMarkup(icon)
}
