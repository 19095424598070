import form from "./form"

const initialState = {
    reason: "",
}

export default function absenceForm(state = initialState, action) {
    if (action.form == "absenceForm") {
        return form(state, action)
    } else {
        return state
    }
}
