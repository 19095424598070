import {
    FETCH_ANALYTICS_DASHBOARDS_SUCCESS,
    AnalyticsDashboardSuccessAction,
    tAnalyticsDashboardsState,
} from "./types"

const initialState: tAnalyticsDashboardsState = {
    analyticsDashboards: {},
}

export default function analyticsDashboards(
    state: tAnalyticsDashboardsState = initialState,
    action: AnalyticsDashboardSuccessAction
) {
    switch (action.type) {
        case FETCH_ANALYTICS_DASHBOARDS_SUCCESS:
            return { ...state, analyticsDashboards: action.payload.analyticsDashboards }
        default:
            return state
    }
}
