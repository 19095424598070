import Rmbx from "../util"
import { getDataFromSearchv4 } from "./get"
import { updateCacheSuccess } from "../cached-data/actions"

/** Types */
import { tNormalizedData } from "../cached-data/types"
import { referenceablesToValueFormatters } from "../common/referenceable-value-formatters"
import { tFilterResourceName } from "../filters/types"
import { Dispatch } from "redux"
import { tSelectCacheProps, tSelectorOption } from "../SelectorV3/types"

type tConfig = {
    cache: Record<string, { hasMore: boolean; options: Array<Record<string, any>> }>
    extraFilters: Record<string, any>
    idsToExclude?: Array<string>
    inputValue: string
    resourceName: string
    total: number
    disableInactive?: (option: Record<string, any>) => boolean
}
type tStateConfig = {
    setCache: (record: Record<string, tSelectCacheProps>) => void
    setIsLoading: (isLoading: boolean) => void
    setIsListBottom: (IsListBottom: boolean) => void
    setOptions: (loadedOptions: Array<tSelectorOption | Record<string, any>>) => void
    setOptionsCheck: (loadedOptions: Array<tSelectorOption | Record<string, any>>, oldInputValue: string) => void
}

/** Default page size for pagination. */
const pageSize = 25

/**
 * "Get" request to find list options.
 * @param {Object} options Debounce options
 * @param {Function} dispatch React redux dispatch.
 * @prop {Function} setCache Set cache state.
 * @prop {Function} setIsLoading Set the loading status for the dropdown list.
 * @prop {Function} setIsListBottom Set the isListBottom state.
 * @prop {Function} setOptionsCheck Set dropdown list options.
 * @param {Object} config Configuration including query params.
 * @prop {Object} config.cache Cache to store fetched list info.
 * @prop {boolean} config.cache.hasMore Whether there is more option to be fetched.
 * @prop {Object[]} config.cache.options Cached list options.
 * @prop {string} config.inputValue Search input value.
 * @prop {tFilterResourceName} config.resourceName Resource name.
 * @prop {number} config.total Total number of currently fetched options.
 * @prop {Object} [config.extraFilters] Extra filters to be applied.
 * @prop {string[]} [config.idsToExclude] Ids to be excluded.
 */
export const autocompleteSearch = async (
    dispatch: Dispatch<any>,
    { setCache, setIsLoading, setIsListBottom, setOptionsCheck }: tStateConfig,
    config: tConfig
) => {
    const { cache, extraFilters, inputValue, resourceName, disableInactive } = config

    const queryParams = {
        ...extraFilters,
        page_size: pageSize,
        page: Math.floor(config.total / pageSize) + 1,
        exclude: extraFilters?.exclude_project_id ? null : config.idsToExclude,
    }
    const resourceFormatters = referenceablesToValueFormatters[resourceName as tFilterResourceName]
    try {
        const response = await getDataFromSearchv4(inputValue, resourceName, queryParams)
        const results = response.results

        const normalizedData = Rmbx.util.formatApiResponse(results, resourceName) as tNormalizedData
        dispatch(updateCacheSuccess(normalizedData, "AUTOCOMPLETE_SEARCH_SUCCESS"))
        const fetchedOptions = results.map((item: Record<string, any>) => {
            // Need to set value & label for React-Select.
            const keyValue = resourceFormatters?.titleFormatter?.({ value: item })
            /**
             * If "options" attribute exists, React Select will treat it as the option's categories.
             * Our "projects" data has the "options" attribute. This will cause an error.
             * So we are storing the original data under "data" and access it after selecting an option.
             * Also, attempt to use the item's id if it's a referenceable in order to make the values unique
             * and avoid issues where two options have the same value.
             */
            const disabled = disableInactive ? disableInactive(item) : false
            return {
                data: item,
                value: item.id || keyValue,
                label: keyValue,
                isDisabled: disabled,
            }
        })
        const currentOptions = cache[inputValue]?.options || []
        const listOptions = [...currentOptions, ...fetchedOptions]
        setOptionsCheck(listOptions, inputValue)
        setCache({
            ...cache,
            [inputValue]: { options: listOptions, hasMore: !!response.next },
        })
        setIsLoading(false)
        setIsListBottom(false)
    } catch (error) {
        // Same logic as the original "search" in AsyncPaginate
        setIsLoading(false)
        setIsListBottom(false)
    }
}
