import React from "react"
import { connect } from "react-redux"
import { closeErrorModal } from "./actions"

import StandardModal from "./modal-standard"

/**
 * Generic error modal. The title, subtitle and body can be configured and can deliver multiple error messages.
 * Most of the parameters are passed in when the action is dispatched to open the modal; the modal can be
 * defined on the page once and styled with the additionalClasses prop, but called multiple times with multiple
 * messages
 *
 * @param title - The title to display. Passed in via the openErrorModal action
 * @param subtitle - The subtitle to display. Passed in via the openErrorModal action
 * @param errors - One or more error message strings, passed in as a single string or an array.
 *          Passed in via the openErrorModal action
 * @param open - The flag that manages the modal's open state
 * @param additionalClasses - Additional classes can be passed to the modal
 *          to change its styling, z-index, etc.
 * @returns
 */
const ErrorModal = props => {
    const closeModal = () => {
        props.dispatch(closeErrorModal())
    }

    return (
        <StandardModal
            open={props.modalIsOpen}
            title={props.title}
            description={props.subtitle}
            primaryBtnOnClick={closeModal}
            closeMethod={closeModal}
            singleButton={true}
            primaryBtnDisabled={false}
            backgroundCloseEnabled={true}
            additionalClasses={props.additionalClasses}
        >
            {props.errors?.map((individualMessage, index) => {
                return individualMessage === "new-line" ? (
                    <br />
                ) : (
                    <div key={index} className={"form-modal-error"}>
                        {individualMessage}
                    </div>
                )
            })}
        </StandardModal>
    )
}

function mapStateToProps(state) {
    return {
        modalIsOpen: state.modals.modalError.open,
        errors:
            typeof state.modals.modalError.errors === "string"
                ? [state.modals.modalError.errors]
                : state.modals.modalError.errors,
        title: state.modals.modalError.title,
        subtitle: state.modals.modalError.subtitle,
    }
}

export default connect(mapStateToProps)(ErrorModal)
