import React from "react"
import { GridApi, RowNode, ColumnApi, ICellRendererParams } from "ag-grid-community"
import { tSourceData } from "../../../dashboard-data/types"
import { isTokenRowActive } from "../../../common/ag-grid-utils"
import styled from "styled-components"
import { colorAttentionRed50 } from "@rhumbix/rmbx_design_system_web"
import { tBulkActionParams } from "../../../toolbar/types"
import { bulkUpdateSourceDataField } from "../../../toolbar/actions"

const TokenRowButtonCellRenderer = (params: ICellRendererParams) => {
    const onClickCopy = () => {
        if (params) {
            // Typecasting to prevent type conflicts between AG Grid v22 and v26
            const {
                columnApi,
                api: gridApi,
                node: rowNode,
            }: { columnApi: ColumnApi; api: GridApi; node: RowNode } = params
            if (gridApi && columnApi && rowNode) {
                navigator?.clipboard?.writeText(rowNode.data.company_integration_token_value)
                const companyIntegrationNameCol = columnApi.getColumn("/company_integration_name") ?? ""
                gridApi.clearFocusedCell()
                gridApi.flashCells({
                    rowNodes: [rowNode],
                    columns: [companyIntegrationNameCol],
                })
            }
        }
    }
    const onClickDeactivate = () => {
        if (params) {
            const { api: gridApi, context, node: rowNode }: { api: GridApi; node: RowNode; context: any } = params
            if (rowNode && gridApi && context) {
                // if any rows are selected, make sure to unselect all rows before performing this operation
                // to avoid any confusion, as clicking the deactivate button deactivates the token for this row only
                if (gridApi.getSelectedRows().length > 0) {
                    gridApi.deselectAll()
                }

                // get source data from the current rowNode only instead of all selected rows
                const sourceDataToUpdate: tSourceData = {
                    [context.settings.resources[0]]: [rowNode.data],
                }
                // in api-integration-settings.ts, currently it doesn't pass any args for deactivation
                // so this can be left empty
                const typedArgs = {} as tBulkActionParams
                context.createModalAction({
                    title: "Deactivate Token",
                    description: "This action may break existing integrations. Are you sure you want to continue?",
                    action: async () => {
                        await context.dispatch(bulkUpdateSourceDataField(sourceDataToUpdate, typedArgs, true))
                        // force a refresh on the current row as the data passed down from
                        // cell renderer params doesn't seem to update without doing this
                        gridApi.refreshCells({ force: true, rowNodes: [rowNode] })
                        gridApi.clearFocusedCell()
                    },
                    buttonClass: "continueButton",
                    close: () => context.createModalAction(null),
                })
                gridApi.clearFocusedCell()
            }
        }
    }
    return (
        isTokenRowActive(params.node.data) && (
            <ButtonContainer>
                <ActionButton onClick={onClickCopy}>
                    <div>Copy API Key</div>
                </ActionButton>
                <DangerButton onClick={onClickDeactivate}>
                    <div>Deactivate</div>
                </DangerButton>
            </ButtonContainer>
        )
    )
}

export default TokenRowButtonCellRenderer

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 1px;
    justify-content: space-between;
`
/* pointer-events: auto overrides default css that allows this toggle to register the click/change events,
as there is css defined in aggrid.less that sets pointer-events: none */
const ActionButton = styled.button`
    line-height: 10px;
    pointer-events: auto;
    min-width: 30px;
    height: 35px;
    text-align: center;
`
const DangerButton = styled(ActionButton)`
    border-color: ${colorAttentionRed50};
    color: ${colorAttentionRed50};
`
