import React from "react"

import * as helpers from "@rhumbix/rmbx_templates/dist/helpers/index.js"
// we use this to get the helper names below to import them into Handlebars
const modHelpers = helpers

import * as partials from "@rhumbix/rmbx_templates"
// we use this to get the partial names below to import them into Handlebars
const modPartials = partials

// ts formatted helpers that can't live in rmbx_templates
// when possible, all template helpers should be added to https://github.com/Rhumbix/rmbx_templates
import {
    addLeadingZeros,
    formatDate,
    getResourceIcon,
} from "./template_helpers"
import repeatHelper from "./handlebars-helper-repeat"

// The default Handlebars import is for server-side rendering with Node.js
// we have to be this specific to get the client-side code
import Handlebars from "handlebars/dist/handlebars.js"

import "custom-form-templates.less"

// Register Helpers -----------------------------------------------------------

// Don't do this in the React component's `render()` function, since we don't
// need/want to re-register helpers every time the component re-renders
// Most helpers are pulled from the rmbx_templates repo, but a few rely on other internal methods
Handlebars.registerHelper("formatDate", formatDate)
Handlebars.registerHelper("repeat", repeatHelper)
Handlebars.registerHelper("addLeadingZeros", addLeadingZeros)
Handlebars.registerHelper("getResourceIcon", getResourceIcon)

// bring in all the helpers we have added from the rmbx_templates module
Object.keys(modHelpers).forEach(helperName => {
    Handlebars.registerHelper(helperName, helpers[helperName])
})

// Handlebar partials - universal template snippets
// to use in a template use a > inside the brackets ex - {{> header}}
// We maintain partials in a separate repo - here they are dynamically imported and added to Handlebars
// this way we don't have to manually add every new partial for it to be available
Object.keys(modPartials).forEach(partialName => {
    Handlebars.registerPartial(partialName, partials[partialName])
})


// React Component ------------------------------------------------------------

/**
 * Renders a Handlebars.js template.
 *
 * The context for the Handlebars template is value of the context prop. The
 * template is also passed as a prop.
 *
 * Because we're setting HTML directly, it's not going to be managed by the
 * React lifecycle (e.g. when the value of a prop changes). To force this
 * component to re-render when a prop changes, you should pass it a `key` that
 * is guaranteed to be unique each time the props change, like:
 *
 *     <HandlebarsTemplate key={Date()} ...props />
 *
 * NOTE: Although precompilation is much more performant, its use would require
 * either `eval`ing a string of JavaScript passed in from the database or
 * dynamically `import`ing a file whose name/path was retrieved from the
 * database. Neither option is secure.
 */
const renderTemplate = props => {
    // takes in a handlebars template expression and pulls that info from the given context obj
    const template = Handlebars.compile(props.template)
    return template(props.context)
}

export const RenderedHTMLTemplate = props => {
    // Render template
    const html = renderTemplate(props)
    return <div className="HandlebarsTemplate" id="form-template-view" dangerouslySetInnerHTML={{__html: html}}/>
}

export const renderFieldTemplate = props => {
    // templates should be formatted that the resulting string can be parsed as json
    // an example template for a string would be "\"{{project.name}}\""
    // not every context will have the value, so we check for a value before parsing the results
    const val = renderTemplate(props)
    return JSON.parse(val)
}
