const initialState = {
    customUserLinks: [],
}

export default function sidebar(state = initialState, action) {
    switch (action.type) {
        case "CUSTOM_USER_LINKS_UPDATED":
            return Object.assign({}, state, {
                customUserLinks: action.data.results,
            })
        default:
            return state
    }
}
