import {
    apiIntegrationTokenColDefCreator,
    checkboxColDefCreator,
    stringColDefCreator,
    datetimeColDefCreator,
    slideToggleColDefCreator,
    tokenRowButtonColDefCreator,
} from "./standard-col-def-creators"
import { CellClassParams, RowNode, ITooltipParams } from "ag-grid-community"
import { tFeatures } from "../../../common/types"
import { isTokenRowActive } from "../../../common/ag-grid-utils"
import { apiIntegrationNewFieldNamesTooltip } from "../../../common/ag-grid-custom-tooltip-formatters"
import { getFlagEnabled } from "../../../getFlagValue"

export const getApiIntegrationDashboardSettings = (featureFlags: tFeatures) => {
    // hide the show new field names toggle if:
    // customer isn't using shift extras and custom forms, or
    // force new field names flag is turned on
    const shouldHideNewFieldNamesToggle =
        (!featureFlags.shift_extras && !featureFlags.custom_form) || featureFlags.force_new_field_names

    return {
        tableName: "API Integration",
        navId: "apiIntegration",
        resources: ["apiIntegration"],
        filters: [],
        colDefs: [
            checkboxColDefCreator({}),
            stringColDefCreator({
                headerName: "ID #",
                field: "/id",
                sort: "desc",
                // the shouldPreventActionFocus prevents the cells in this column from
                // showing the action bar when being clicked, as in this dashboard
                // only the "Integration Name" column should show the action bar
                // so the users can copy the token values to clipboard.
                shouldPreventActionFocus: true,
                width: 100,
            }),
            apiIntegrationTokenColDefCreator({
                headerName: "Integration Name",
                field: "/company_integration_name",
                width: 250,
            }),
            stringColDefCreator({
                headerName: "Company",
                field: "/company_name",
                shouldPreventActionFocus: true,
            }),
            stringColDefCreator({
                headerName: "Created By",
                field: "/creator",
                shouldPreventActionFocus: true,
            }),
            datetimeColDefCreator({
                headerName: "Created Date",
                field: "/created_date",
                width: 130,
                shouldPreventActionFocus: true,
            }),
            {
                ...slideToggleColDefCreator({
                    headerName: "New Field Names",
                    // because options is an object unlike the other fields, get the field names from
                    // the options obj by extending the field like so (/a/b)
                    field: "/options/show_new_field_names",
                    shouldPreventActionFocus: true,
                    hide: shouldHideNewFieldNamesToggle,
                    ...(getFlagEnabled("WA-7979-ff-notifications")
                        ? { editable: params => isTokenRowActive(params?.data) }
                        : {}),
                }),
                customTooltip: (params: ITooltipParams) => apiIntegrationNewFieldNamesTooltip(params),
                headerTooltip: "Enable this option to return newest field names, which may impact macros.",
            },
            stringColDefCreator({
                headerName: "Deactivated By",
                field: "/deactivator",
                shouldPreventActionFocus: true,
                width: 130,
            }),
            datetimeColDefCreator({
                headerName: "Deactivated Date",
                field: "/deactivation_date",
                shouldPreventActionFocus: true,
                width: 150,
            }),
            tokenRowButtonColDefCreator({
                headerName: "Actions",
                field: "/actions",
                shouldPreventActionFocus: true,
            }),
        ],
        gridSettings: {
            rowHeight: 60,
            defaultColDef: {
                editable: false,
                // this overrides a default styling of white-space: nowrap in aggrid.less
                // to allow for text wrap
                cellClass: "wraptext",
                cellClassRules: {
                    readonly: (params: CellClassParams) => !isTokenRowActive(params.data),
                },
            },
            rowSelection: "multiple",
            // setting this to true allows the onClick and onFocus events from custom-table.jsx to run
            suppressRowClickSelection: true,
            // prevent deactivated rows from being selected
            isRowSelectable: (rowNode: RowNode) => {
                if (rowNode) {
                    return isTokenRowActive(rowNode.data)
                }
            },
        },
        otherSettings: {
            buttons: {
                cell: [
                    {
                        label: "Copy API Key",
                        icon: "copy",
                        action: "copyFocusedCellToClipboardOnly",
                    },
                ],
                row: [
                    {
                        label: "Deactivate",
                        icon: "deactivate",
                        action: "bulkUpdateField",
                        args: {},
                        showWarningModal: true,
                        modalTitle: "Deactivate Tokens",
                        modalDescription: "This action may break integrations. Are you sure you want to continue?",
                        updateUsingResponseData: true,
                    },
                ],
                table: [
                    {
                        label: "Generate New Token",
                        icon: "add",
                        action: "addApiIntegrationToken",
                    },
                    {
                        separator: true,
                    },
                    {
                        label: "Documentation",
                        icon: "launch",
                        action: "navigateToExternal",
                        args: {
                            url: "http://docs.rhumbix.com",
                        },
                    },
                ],
            },
        },
    }
}
