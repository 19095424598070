import {
    colorAttentionBlue50,
    colorFunctionalGray10,
    colorFunctionalGray70,
    colorFunctionalWhite,
    colorLayoutDefaultBg,
    spacingM,
} from "@rhumbix/rmbx_design_system_web"
import styled from "styled-components"
import { zIndex } from "../../common/styles"

/** Const */
export const InitialSideRailWidth = {
    Normal: 30,
    Wide: 55,
}

/** Side Rail Styles */
type StyledSideRailProps = {
    initialWidthPercentage: number
    width: number
}
export const StyledSideRail = styled.div.attrs<StyledSideRailProps>(({ initialWidthPercentage, width }) => ({
    style: {
        // Width can be dynamically set by the resizer
        width: width ? `${width}px` : `${initialWidthPercentage}%`,
    },
}))<StyledSideRailProps>`
    min-width: 720px;
    // Leave some space on the left
    max-width: 90%;
    // Pin the div at the top right, make it page-height
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    // Appears above the overlay
    z-index: ${zIndex.SideRail};
    // Let the resizer and inner content flow from left-to-right
    display: flex;
`

// Wraps the Header, Content, and Footer
export const StyledSideRailInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-color: ${colorLayoutDefaultBg};
    box-shadow: -0.1px 0px 2px 0px;
`

const HeaderFooterBase = styled.div`
    min-height: 50px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
`

export const StyledSideRailHeader = styled(HeaderFooterBase)`
    border-bottom: 1px solid ${colorFunctionalGray70};
    background-color: ${colorFunctionalGray10};
    font-size: 18px;

    .default-header {
        padding-left: ${spacingM};
        display: flex;
        align-items: center;
    }
`

export const StyledSideRailContent = styled.div`
    overflow-y: auto;
    height: 100%;
    flex: 1;
`

export const StyledSideRailFooter = styled(HeaderFooterBase)`
    padding: 0 ${spacingM};
    border-top: ridge;

    button {
        border-radius: 2px;
        width: 10%;
        max-height: 80%;
        background-color: ${colorFunctionalGray10};
    }

    .apply {
        // Make space between this button and the cancel button
        margin-right: 7px;
        // Button will grow to fit longer text
        width: fit-content;
        background-color: ${colorAttentionBlue50};
        color: ${colorFunctionalWhite};
    }
`

export const StyledOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${zIndex.SideRail - 1};
    background-color: rgba(0, 0, 0, 0.5);
`
