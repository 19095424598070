import React, { Component } from "react"
import { connect } from "react-redux"

import { setFilterValue } from "../actions"
import { tFilterState, tFilterKey } from "../types"

import "../FilterController/FilterController.less"
import "./BooleanFilter.less"
import { pendingFiltersSelector } from "../selectors"
import StyledCheckbox from "../../components/checkbox"
import { AppDispatch } from "../../common/types"

type tProps = {
    className?: string
    field: string
    label: string
    primaryKey: tFilterKey
    filters: tFilterState
    dispatch: AppDispatch
}

type tState = {
    value: any
}

class BooleanFilter extends Component<tProps, tState> {
    constructor(props: tProps) {
        super(props)
        this.state = {
            value: props.primaryKey in props.filters ? props.filters[props.primaryKey] : false,
        }
    }

    onChange = () => {
        const toggledVal = !this.state.value
        this.setState({ value: toggledVal })
        this.props.dispatch(setFilterValue(this.props.primaryKey, toggledVal))
    }

    render() {
        const { value } = this.state
        const { primaryKey } = this.props
        return <StyledCheckbox id={`filter-${primaryKey}`} isChecked={value} onChange={this.onChange} />
    }
}

const mapStateToProps = (state: any) => ({
    filters: pendingFiltersSelector(state),
})

export default connect(mapStateToProps)(BooleanFilter)
