import { GroupByAction, tClipboardAction, tPivotAction, tPivotClipboard } from "./types"

const initialPivotClipboardState: tPivotClipboard = {}

export const pivotClipboard = (state: tPivotClipboard = initialPivotClipboardState, action: tPivotAction) => {
    switch (action.type) {
        case "PIVOT_CELL_COPY": {
            if (action.payload && action.payload.data) {
                return { ...action.payload.data }
            }
            return state
        }
        default: {
            return state
        }
    }
}

const initialClipboardState = ""

export const clipboard = (state: string = initialClipboardState, action: tClipboardAction) => {
    switch (action.type) {
        case "CELL_COPY": {
            if (action.payload && action.payload.data) {
                return action.payload.data
            }
            return state
        }
        default: {
            return state
        }
    }
}

const initialGroupByState = {}
export const groupBy = (state = initialGroupByState, action: GroupByAction) => {
    switch (action.type) {
        case "SET_GROUP_BY_STATE":
            return { ...state, [action.tableName]: action.label }
        default: {
            return state
        }
    }
}
