import React from "react"
import MaskedInput from "react-maskedinput"
import { connect } from "react-redux"
import { update_field } from "./actions/index"
import PropTypes from "prop-types"
import styled from "styled-components"

import passwordIconView from "../images/view-password-icon.svg"
import passwordIconHide from "../images/hide-password-icon.svg"

export class Textarea extends React.Component {
    /**
     * Be explicit that the name prop is required
     */
    static propTypes = {
        name: PropTypes.string.isRequired,
    }

    /**
     * Set the default values for specific properties
     * @returns {{errors: Array, is_valid: is_valid}}
     */
    static defaultProps = {
        errors: [],
        is_valid: () => [],
    }

    /**
     * Initial component state when it first loads
     * @returns {{errors: (*|Array)}}
     */
    state = {
        errors: this.props.errors || [],
    }

    /**
     * The validator callback 'is_valid' needs to return an array
     * of error message(s), or empty error for no errors.
     * @param event
     */
    validate = () => {
        const errors = this.props.is_valid()
        this.setState({ errors: errors })
    }

    /**
     * Determine if there are errors present - i.e. invalid
     * @returns {boolean}
     */
    hasErrors = () => {
        return this.state.errors.length != 0 || this.props.errors.length != 0
    }

    /**
     * Fix the class names so we can properly layout this element on the UI
     * @returns {string}
     */
    classNames = () => {
        return "form-control " + (this.hasErrors() ? "field-error" : "")
    }

    /**
     * Retrieve a concatenated representation of the errors associated with this component
     * @returns {string|*|{options, browsertest, dist, rhino, rhinolessc}|Array.<T>}
     */
    getErrors = () => {
        return this.state.errors.concat(this.props.errors)
    }

    /**
     * Render this react component
     * @returns {XML}
     */
    render() {
        const inputProps = {
            name: this.props.name,
            id: this.props.id ? this.props.id : this.props.name,
            className: this.classNames(),
            valueLink: this.props.valueLink,
            onBlur: this.validate,
            cols: this.cols,
            rows: this.props.rows,
            placeholder: this.props.placeholder,
            maxLength: this.props.maxLength,
            readOnly: this.props.readOnly,
            autoFocus: this.props.requestFocus, // not supported by IE 9 - they'll have to tab in
        }

        return (
            <div className="form-group">
                <label htmlFor={this.props.name}>{this.props.name}</label>
                <textarea {...inputProps} />
                {this.getErrors().map(error => {
                    // React freaks out when adding/removing or otherwise shuffling
                    // components around that get mounted on the DOM. One way to reconcile
                    // this is by adding keys to the components...testing out this approach;
                    // will keep an eye out since these are non-numeric and non-unique...
                    // https://facebook.github.io/react/docs/multiple-components.html#child-reconciliation
                    return <InputError key={this.props.name} errorMessage={error} />
                })}
            </div>
        )
    }
}

export class Input extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
    }

    static defaultProps = {
        errors: [],
        type: "text",
        is_valid: () => [],
    }

    /**
     * Using refs to access DOM nodes as suggested by the react docs (v0.14+):
     *
     * https://facebook.github.io/react/docs/top-level-api.html#reactdom.finddomnode
     * https://facebook.github.io/react/blog/2015/10/07/react-v0.14.html#dom-node-refs
     */
    componentDidMount() {
        // Listen to the parent form submit to run validations on submit
        const form = document.querySelector(".form-group").closest("form")
        if (form) {
            form.addEventListener("submit", this.validate)
        }
    }

    componentWillUnmount() {
        const form = document.querySelector(".form-group").closest("form")
        if (form) {
            form.removeEventListener("submit", this.validate)
        }
    }

    // The validator callback 'is_valid' needs to return an array
    // of error message(s), or empty error for no errors.
    validate = () => {}

    has_errors = () => {
        return this.props.errors.length != 0
    }

    classNames = () => {
        return "form-control " + (this.has_errors() ? "field-error" : "")
    }

    errors = () => {
        return this.props.errors || []
    }

    render() {
        const inputProps = {
            name: this.props.name,
            id: this.props.id ? this.props.id : this.props.name,
            className: this.classNames() + (this.props.className ? " " + this.props.className : ""),
            type: this.props.type,
            onChange: e => {
                this.props.valueLink.requestChange(e.target.value)
            },
            value: this.props.valueLink.value,
            onBlur: this.props.onBlur || this.validate,
            placeholder: this.props.placeholder,
            maxLength: this.props.maxLength,
            readOnly: this.props.readOnly,
            onKeyPress: this.props.onKeyPress,
            required: this.props.required,
            autoFocus: this.props.requestFocus, // not supported by IE 9 - they'll have to tab in
        }

        return (
            <div className="form-group" hidden={this.props.type === "hidden"}>
                <label htmlFor={this.props.name}>{this.props.name} </label>
                <input {...inputProps} />
                {this.errors().map((error, index) => {
                    // React freaks out when adding/removing or otherwise shuffling
                    // components around that get mounted on the DOM. One way to reconcile
                    // this is by adding keys to the components...testing out this approach;
                    // will keep an eye out since these are non-numeric and non-unique...
                    // https://facebook.github.io/react/docs/multiple-components.html#child-reconciliation
                    return <InputError key={this.props.name + index} errorMessage={error} />
                })}
            </div>
        )
    }
}

export class Password extends React.Component {
    static defaultProps = { name: "Password *" }
    state = { viewPassword: false }

    toggleVisibility = () => {
        this.setState({ viewPassword: !this.state.viewPassword })
    }

    getPasswordImage = () => {
        return this.state.viewPassword ? passwordIconView : passwordIconHide
    }

    render() {
        const type = this.state.viewPassword ? "text" : "password"

        return (
            <React.Fragment>
                {this.props.addViewPassword && (
                    <PasswordVisibilityToggle onClick={this.toggleVisibility} type="button">
                        <img
                            id="passwordVisibilityToggle"
                            src={this.getPasswordImage()}
                            className="pull-right"
                            width="22"
                            height="20"
                            alt="Toggle the masking of the password text"
                        />
                    </PasswordVisibilityToggle>
                )}
                <Input
                    type={type}
                    name={this.props.name}
                    required={this.props.required}
                    valueLink={this.props.valueLink}
                    errors={this.props.errors}
                    is_valid={this.props.is_valid}
                    requestFocus={this.props.requestFocus}
                >
                    <span className="float-right"> </span>
                </Input>
            </React.Fragment>
        )
    }
}

export class PhoneNumber extends React.Component {
    static defaultProps = {
        errors: [],
        name: "Mobile Phone Number *",
        is_valid: () => [],
    }

    // The validator callback 'is_valid' needs to return an array
    // of error message(s), or empty error for no errors.
    validate = () => {}

    has_errors = () => {
        return this.props.errors.length != 0
    }

    classNames = () => {
        return (
            "form-control " +
            (this.has_errors() ? "field-error" : "") +
            (this.props.className ? " " + this.props.className : "")
        )
    }

    errors = () => {
        return this.props.errors || []
    }

    render() {
        return (
            <div className="form-group">
                <label htmlFor={this.props.name}> {this.props.name} </label>
                <MaskedInput
                    onBlur={this.validate}
                    onChange={this.props.valueLink.requestChange.bind(this)}
                    value={this.props.valueLink.value}
                    name={this.props.name}
                    mask="(111) 111-1111"
                    placeholder="(555) 555-5555"
                    className={this.classNames()}
                />
                {this.errors().map((error, index) => (
                    <InputError key={this.props.name + index} errorMessage={error} />
                ))}
            </div>
        )
    }
}

// The first option passed in is set to the default, and disabled.
// i.e. ["Choose a state", "California", "Ohio"]
// "Choose a state" would be shown to be selected by default and
// if not changed will just send an empty string to the server.
export class Select extends React.Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
    }

    render() {
        const className = this.props.valueLink.value == "" ? this.props.cn + " font-gray" : this.props.cn
        return (
            <div className="form-group">
                <label htmlFor={this.props.name}>{this.props.name} </label>
                <select
                    name={this.props.name}
                    onChange={this.props.valueLink.requestChange.bind(this)}
                    value={this.props.valueLink.value}
                    multiple={this.props.isMultiple === true || false}
                    size={this.props.size}
                    className={className}
                >
                    <option disabled hidden value="">
                        {this.props.options[0]}
                    </option>
                    {this.props.options.slice(1).map((option, index) => (
                        <option className="windows-option-black" key={option + index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        )
    }
}

class BaseCheckboxSelect extends React.Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
    }

    static defaultProps = {
        errors: [],
    }

    state = {
        options: {},
        selected: [],
    }

    componentDidMount() {
        this.mapOptions(this.props)
    }

    componentDidUpdate() {
        this.mapOptions(this.props)
    }

    mapOptions = props => {
        const options = {}
        const value = props.valueLink.value
        props.options.forEach(option => {
            options[option] = { selected: value.includes(option) }
        })
        this.setState({
            options: options,
        })
    }

    handleChange = event => {
        const options = Object.assign({}, this.state.options)
        const selected = []
        options[event.target.value].selected = !options[event.target.value].selected
        Object.keys(options).forEach(option => {
            if (options[option].selected) {
                selected.push(option)
            }
        })
        this.setState({ options: options, selected: selected })
        this.props.dispatch(update_field(this.props.formFor, this.props.name, selected))
    }

    render() {
        const handleChange = this.handleChange
        const options = this.state.options
        return (
            <div className="form-group">
                <label htmlFor={this.props.name}>{this.props.title} </label>

                {Object.keys(options).map((option, index) => (
                    <div key={option + index}>
                        <label>
                            <input
                                type="checkbox"
                                value={option}
                                checked={options[option].selected}
                                onChange={handleChange}
                            />
                            {option}
                        </label>
                    </div>
                ))}

                {this.props.errors.map((error, index) => (
                    <InputError key={this.props.name + index} errorMessage={error} />
                ))}
            </div>
        )
    }
}

export const CheckboxSelect = connect()(BaseCheckboxSelect)

export class Checkbox extends React.Component {
    static defaultProps = { errors: [] }
    state = { isChecked: false }

    handleChange = event => {
        this.setState({ isChecked: event.target.checked })
        this.props.toggleFunction()
    }

    render() {
        const className = this.props.className ? this.props.className : ""
        const errors = this.props.errors
        return (
            <div className={className}>
                <label>
                    <input
                        type="checkbox"
                        name={this.props.name}
                        id={this.props.id}
                        checked={this.state.isChecked}
                        onChange={this.handleChange}
                    />
                    {this.props.label}
                    {this.props.children}
                </label>
                {errors.map((error, index) => (
                    <InputError key={index} errorMessage={error} />
                ))}
            </div>
        )
    }
}

export class InputError extends React.Component {
    render() {
        const errorClass = ["error"]
        if (this.props.className) errorClass.push(this.props.className)

        return (
            <div className={errorClass.join(" ")} id={this.props.id}>
                {this.props.errorMessage}
            </div>
        )
    }
}

const PasswordVisibilityToggle = styled.button`
    border: 0;
    background-color: "transparent";
    float: right;
    vertical-align: middle;
    height: 22px;
    width: 20px;
    minwidth: 20px;
    padding: 0 0 2px 0;
`
