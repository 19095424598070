import amplitude from "amplitude-js"
import { iCurrentUser, tSelectedParty } from "./types"
import { VIEW_ONLY, REQUEST_SIGNATURE } from "./constants"

// In order to have some contiunity/organization around event names, easiest to keep track of them in one place
export const LOGGED_IN = "Login"
export const PAGE_VISITED = "Page Visited"
export const FIELD_FORM_VIEW = "Field Form Viewed"
export const FIELD_FORM_SAVE = "Field Form Saved"
export const FORM_SHARE = "Form Shared"
export const FORM_SIGNED = "Form Signed"
export const GUEST_LOGGED_IN = "Guest Login"
export const FORM_BULK_ACTION = "Field Form Bulk Action"
export const FORM_BULK_MODIFY_SAVE = "Field Form Bulk Modify Saved"
export const CSV_EXPORT_USING_NEW_NAMES = "Using New Field Names On CSV Export"

// Saved Filter Sets
export const SAVED_FILTER_SET_CREATED = "Created a Saved Filter Set"
export const SAVED_FILTER_SET_CREATE_MODAL_CANCEL = "Cancelled from the Create Saved Filter Set Modal"
export const SAVED_FILTER_SET_APPLIED = "Applied a Saved Filter Set"
export const SAVED_FILTER_SET_UPDATED = "Updated a Saved Filter Set"
export const SAVED_FILTER_SET_DELETED = "Deleted a Saved Filter Set"
export const SAVED_FILTER_SET_DELETE_MODAL_CANCEL = "Cancelled from the Delete Saved Filter Set Modal"
export const SAVED_FILTER_SET_INVALID_SELECTIONS_REPORTED =
    "Reported invalid selections when a Saved Filter Set was applied"
export const CLEAR_FILTERS = "Clear Filters"

// Create a Time Card and Wekly Timekeeping
export const CREATE_TIME_CARD_MODAL_OPENED = "Opened the Create Time Card modal"
export const CREATE_TIME_CARD_MODAL_CANCELLED = "Cancelled out of the Create Time Card modal"
export const CREATE_TIME_CARD_MODAL_COMPLETED = "Created a Time Card using the modal"
export const WEEKLY_TIMEKEEPING_MODAL_ADD_COST_CODES_OPENED = "Opened the Add Cost Codes modal"
export const WEEKLY_TIMEKEEPING_MODAL_ADD_COST_CODES_CANCELLED = "Cancelled out of the Add Cost Codes modal"
export const WEEKLY_TIMEKEEPING_MODAL_ADD_COST_CODES_COMPLETED = "Added Cost Codes using the modal"
export const WEEKLY_TIMEKEEPING_MODAL_ADD_EMPLOYEES_OPENED = "Opened the Add Employees modal"
export const WEEKLY_TIMEKEEPING_MODAL_ADD_EMPLOYEES_CANCELLED = "Cancelled out of the Add Employees modal"
export const WEEKLY_TIMEKEEPING_MODAL_ADD_EMPLOYEES_COMPLETED = "Added Employees using the modal"

/**
 * Initialize the amplitude tracking and set the capn version so we can correlate events with releases
 * @param apiKey string - this is the API key for the different amplitude projects - comes from the env
 * @param capnVersion string - this is the current release version number ex. 3.133.0
 */
export const setCapnVersionForAmplitude = (capnVersion: string): void => {
    if (!capnVersion) return
    amplitude.getInstance().setVersionName(capnVersion)
}

/**
 * Logs an event to amplitude tied to the given event name, adding any extra properties in key/value pairs
 * ex. logUserAmplitudeEvent(PAGE_VISITED, { path: varForPath, url: varForUrl }) - adds path/url to event properties
 * @param eventName string - The name for the event logged in Amplitude
 * @param extraProperties object - any key values pairs given will be added to the event's properties
 */
export const logUserAmplitudeEvent = (eventName: string, extraProperties?: Record<string, any>): void => {
    amplitude.getInstance().logEvent(eventName, extraProperties)
}

/**
 * Once a user logs in, we want to tie the amplitude events to the user. We only need to run this on login.
 * This is used in the buildTrackerUtil in capn/assets.js while setting up other tracking settings
 * @param userInfo iCurrentUser - User information that we can use to populate amplitude events
 * @returns
 */
export const setAmplitudeUser = (userInfo: iCurrentUser, capnVersion: string): void => {
    const { id, company_id, company_role, is_staff, user_role, employee_id, company } = userInfo
    if (!id) return
    amplitude.getInstance().setUserId(id.toString())
    amplitude.getInstance().setUserProperties({
        company_id,
        company_role,
        is_staff,
        user_role,
        capn_version: capnVersion,
        employee_id,
        company_name: company,
    })
}

/**
 * Once a guest user logs in, we want to tie the amplitude events to the guest user.
 * @param isGuest boolean - User is guest or not
 */
export const setUserAsGuest = (isGuest: boolean): void => {
    const identify = new amplitude.Identify().set("is_guest", isGuest)
    amplitude.getInstance().identify(identify)
}

/**
 * Logs a FORM_SHARE event to amplitude with details about the guest's form permssions and form id.
 * @param selectedForms Array<number> - Array of form ids
 * @param selectedParties Array<tGuestUser> - Array of objects representing guests that the form is shared with
 */
export const amplitudeFormShare = (selectedForms: Array<number>, selectedParties: Array<tSelectedParty>): void => {
    const viewOnly = (element: tSelectedParty) => {
        return element.permissions === VIEW_ONLY
    }
    // determine if guest is assigned REQUEST_SIGNATURE permissions
    const requestSig = (element: tSelectedParty) => {
        return element.permissions === REQUEST_SIGNATURE
    }

    // create an eventInfo for each selected form
    selectedForms.forEach(form_id => {
        const formPermissions = []

        // determine if some guests have VIEW_ONLY or REQUEST_SIGNATURE permission
        if (selectedParties.some(viewOnly)) {
            formPermissions.push(VIEW_ONLY)
        }
        if (selectedParties.some(requestSig)) {
            formPermissions.push(REQUEST_SIGNATURE)
        }

        const eventInfo = {
            form_id: form_id,
            permissions: formPermissions,
            shared_with_guests: true,
        }
        logUserAmplitudeEvent(FORM_SHARE, eventInfo)
    })
}
