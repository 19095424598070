import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { connect } from "react-redux"
import __kebabCase from "lodash/kebabCase"
import sortBy from "lodash/sortBy"
import { logout as logoutAction } from "../../actions/index"
import { openChangePasswordModal } from "../modals/actions"
import { getCustomUserLinks } from "../../actions/reports"
import {
    currentProjectIdsListSelector,
    materialPricingEnabledSelector,
    listViewSelector,
    analyticsSelector,
} from "../../selectors"

import Rmbx from "../../util"
import { navCategories } from "../../common/constants"
import { menuRoutes } from "../../router/routes"
import analyticsIconSrc from "../../../images/sidebar_icons/analytics.svg"
import { getListViewRoutes } from "../../router/list-view-routes"
import { getAnalyticsRoutes } from "../../router/analytics-routes"
import { getReportsRoutes } from "../../router/reports-routes"
import { getBetaAnalyticsRoutes } from "../../router/beta-analytics-routes"
import { client } from "../../networkClient"
import Sidebar from "./sidebar"
import { getFlagEnabled } from "../../getFlagValue"
import { nestedFolderPathSelector } from "../../selectors/list-view"

class SidebarWrapper extends Component {
    componentDidMount() {
        if (this.props.employee) {
            this.props.dispatch(getCustomUserLinks())
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.current_user && prevProps.current_user !== this.props.current_user) {
            this.props.dispatch(getCustomUserLinks())
        }
    }

    logout = e => {
        e.preventDefault()
        client.logout().finally(this.props.dispatch(logoutAction()))
    }

    changePassword = e => {
        e.preventDefault()
        this.props.dispatch(openChangePasswordModal())
    }

    // Analytics dashboard routes are generated dynamically. Currently I am hard-coding this to
    // live in the "beta dashboards" section, but this will change pretty soon.
    getAllRoutes = () => {
        const customizedMenuRoutes = { ...menuRoutes }

        if (getFlagEnabled("WA-7796-enhance-custom-links")) {
            Object.entries(customizedMenuRoutes).forEach(([category, routes]) => {
                customizedMenuRoutes[category] = [...routes, ...this.getCustomUserLinkRoutes(category)]
            })
        }

        const categorizedRoutes = {
            ...customizedMenuRoutes,
            [navCategories.beta]: [
                ...getBetaAnalyticsRoutes(this.props.analyticsDashboards),
                ...customizedMenuRoutes[navCategories.beta],
            ],
            [navCategories.analytics]: [
                ...getAnalyticsRoutes(this.props.analyticsDashboards),
                ...customizedMenuRoutes[navCategories.analytics],
                ...(!getFlagEnabled("WA-7796-enhance-custom-links") ? this.getCustomUserLinkRoutes() : []),
            ],
            [navCategories.fieldForms]: [
                ...customizedMenuRoutes[navCategories.fieldForms],
                // don't include the nested paths for the sidebar wrapper since it would duplicate menu items
                ...getListViewRoutes(this.props.listViews, []),
            ],
            [navCategories.reports]: [
                ...getReportsRoutes(this.props.analyticsDashboards),
                ...customizedMenuRoutes[navCategories.reports],
            ],
        }

        if (getFlagEnabled("WA-7796-enhance-custom-links")) {
            categorizedRoutes[[navCategories.externalLinks]] = [
                ...this.getCustomUserLinkRoutes(navCategories.externalLinks),
            ]
        }

        // Individually alphabetize each category.
        Object.entries(categorizedRoutes).forEach(([category, routes]) => {
            switch (category) {
                // Custom sort for "Time Cards" section.
                case "time-cards":
                    categorizedRoutes[category] = routes.sort((a, b) => {
                        const navTitleA = a.navTitle
                        // Have "Production" as the last item in this section.
                        return navTitleA === "Production" ? 1 : navTitleA.localeCompare(b.navTitle)
                    })
                    break
                default:
                    categorizedRoutes[category] = sortBy(routes, ["navTitle"])
            }
        })

        return categorizedRoutes
    }

    renderMenuItemLink = route => {
        const queryString = route.query ? "?" + Rmbx.util.serializeQueryParams(route.query) : ""
        const shortPath = route.query ? route.query.linkResourceLocator : route.path
        let fullPath = `${route.path}${queryString}`
        const linkId = __kebabCase(shortPath)
        const className = `sidebar-menu-options ${route.navClassName || ""}`

        if (getFlagEnabled("WA-7796-enhance-custom-links") && route.navCategory === navCategories.externalLinks) {
            fullPath = { pathname: route.path }
        }

        return (
            <NavLink
                id={linkId}
                key={shortPath}
                className={className}
                activeClassName="selected-menu-item"
                to={fullPath}
                exact={!!route.exact}
                isActive={(match, location) => {
                    if (
                        getFlagEnabled("WA-7796-enhance-custom-links") &&
                        route.navCategory === navCategories.externalLinks
                    ) {
                        return true
                    }
                    // react-router doesn't consider query string params for its matching algorithm, so we
                    // need to manually account for it to properly match custom user links. Additionally,
                    // we need to support the router's inexact match on paths that are non CULs.
                    // e.g. /company-admin/users => /company-admin/users/edit
                    // So, essentially, we need an exact match with query strings for all CULs, otherwise
                    // fallback to the router's default matching algo. on all non CUL paths.
                    const fullLocationPath = `${location.pathname}${queryString}`
                    const isMatchWithQueryString = fullLocationPath === fullPath
                    const isAnalyticsPath = ~fullLocationPath.indexOf("/analytics/profit-loss")

                    return isAnalyticsPath ? isMatchWithQueryString : match
                }}
                target={
                    getFlagEnabled("WA-7796-enhance-custom-links") &&
                    route.navCategory === navCategories.externalLinks
                        ? "_blank"
                        : null
                }
            >
                <span>{route.navTitle}</span>
            </NavLink>
        )
    }

    getCustomUserLinkRoutes = category => {
        if (!getFlagEnabled("WA-7052-restore-custom-user-links")) return []

        let customUserLinks = this.props.sidebar ? this.props.sidebar.customUserLinks || [] : []

        if (getFlagEnabled("WA-7796-enhance-custom-links")) {
            customUserLinks = customUserLinks.filter(link => link.navCategory === category)
        }

        return customUserLinks.map(cul => {
            const kebabedLabel = __kebabCase(cul.label)
            let path = `/rhumbix/analytics/profit-loss/${kebabedLabel}`

            if (getFlagEnabled("WA-7796-enhance-custom-links")) {
                // This is a bit forward thinking since most of these paths won't be immediately supported
                // other than analytics and external links
                path = {
                    [navCategories.analytics]: `/rhumbix/analytics/profit-loss/${kebabedLabel}`,
                    [navCategories.beta]: `/rhumbix/beta/${kebabedLabel}`,
                    [navCategories.companySettings]: `/rhumbix/company-admin/${kebabedLabel}`,
                    [navCategories.demo]: `/rhumbix/demo/${kebabedLabel}`,
                    [navCategories.externalLinks]: cul.link,
                    [navCategories.fieldForms]: `/rhumbix/field-forms/${kebabedLabel}`,
                    [navCategories.projectSettings]: `/rhumbix/projects/${kebabedLabel}`,
                    [navCategories.reports]: `/rhumbix/reports/${kebabedLabel}`,
                    [navCategories.timeCards]: `/rhumbix/time-cards/${kebabedLabel}`,
                    [navCategories.emailAlerts]: `/rhumbix/${kebabedLabel}`, // This is legacy for User
                }[category]
            }

            return {
                path,
                query: this.getLinkQueryParams(cul.link, cul.label),
                navTitle: cul.label,
                navCategory: getFlagEnabled("WA-7796-enhance-custom-links") ? category : navCategories.analytics,
                navIconSrc: analyticsIconSrc,
                navClassName: getFlagEnabled("WA-7796-enhance-custom-links") ? category : null,
            }
        })
    }

    getLinkQueryParams = (locator, slug) => {
        return {
            linkResourceLocator: locator,
            slug: slug,
            allProjectsSelected: !this.props.currentProjectIds.length,
        }
    }

    render() {
        //show the upgrade link if budget codes either doesn't exist or is set to false
        const showUpgradeLink =
            this.props.features["budget_cost_codes"] === undefined || !this.props.features["budget_cost_codes"]
        const routes = this.getAllRoutes()

        return (
            <Sidebar
                {...this.props}
                showUpgradeLink={showUpgradeLink}
                logout={this.logout}
                changePassword={this.changePassword}
                routes={routes}
                renderMenuItemLink={this.renderMenuItemLink}
                pushNewRoute={this.pushNewRoute}
            />
        )
    }
}

function mapStateToProps(state) {
    return Object.assign({}, state.sidebar, state.current_user, {
        currentProjectIds: currentProjectIdsListSelector(state),
        features: state.featureFlags.features,
        user_role: state.current_user.user_role,
        material_pricing_enabled: materialPricingEnabledSelector(state),
        listViews: listViewSelector(state),
        nestedListViewPaths: nestedFolderPathSelector(state),
        analyticsDashboards: analyticsSelector(state),
        sidebar: state.sidebar,
    })
}

export default connect(mapStateToProps)(SidebarWrapper)
