import React from "react"
import { colorFunctionalGray50, colorFunctionalGray70 } from "@rhumbix/rmbx_design_system_web"
import styled from "styled-components"

const Option = props => {
    const formatOption = (formatter, className, defaultValue = "") => {
        const value = formatter
            ? formatter({
                  value: props.option ?? null,
                  context: props.context,
              })
            : defaultValue

        // Make sure "value" is not null|undefined|"", else will cause CSS issue.
        if (value != null && value !== "") return <div className={className}>{value}</div>
    }

    const titleFormatter = props.titleFormatter ? props.titleFormatter : props.valueFormatter
    return (
        <StyledOption className="option-content">
            {formatOption(props.prefixFormatter, "option-prefix")}
            {formatOption(titleFormatter, "option-title", props.option?.label)}
            {formatOption(props.primarySubtitleFormatter, "option-primarySubtitle")}
            {formatOption(props.secondarySubtitleFormatter, "option-secondarySubtitle")}
        </StyledOption>
    )
}
export default Option

const StyledOption = styled.div`
    color: ${colorFunctionalGray70};
    .option-primarySubtitle {
        color: ${colorFunctionalGray50};
    }
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 2px;
`
