import React from "react"
import { isNumber } from "../../../common/validators"

const SumFieldsCellRenderer = (params: Record<string, any>) => (props: Record<string, any>): JSX.Element => {
    const { inputFields, resultType } = params
    let summedValue = 0.0
    inputFields?.forEach((fieldName: string) => {
        const fieldValue = props.data?.[fieldName] ?? 0.0
        // if working with a numeric currency number, we need to divide by 100 to get comparable values to strings
        const stringTotal = isNumber(fieldValue)
            ? fieldValue / (resultType === "currency" ? 100 : 1) // number currency values come in cents
            : fieldValue.replace("$", "")?.replace(",", "") // currency values come as $12.34 - no division needed
        summedValue += Number(stringTotal) ? Number(stringTotal) : 0.0
    })
    return (
        <div>{`${resultType === "currency" ? "$" : ""}${summedValue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })}`}</div>
    )
}

export default SumFieldsCellRenderer
