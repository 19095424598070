import { createSelector } from "reselect"

import { currentProjectSelector } from "./projects"

/**
 * Returns true if the current project has T&M pricing for materials enabled
 */
export const materialPricingEnabledSelector = createSelector(
    currentProjectSelector,
    currentProject => currentProject && currentProject.options && currentProject.options.material_pricing
)

/**
 * Returns true if the current project has T&M pricing enabled
 */
export const pricingEnabledSelector = createSelector(
    currentProjectSelector,
    currentProject => currentProject && currentProject.options && currentProject.options.enable_pricing
)
