import { iCompanyFormStore } from "../cached-data/types"
import { tJSONValue } from "../common/types"
import {
    BulkCompanyFormStoreActions,
    BulkCompareFormStoreActions,
    FETCHING_COMPANY_FORM_STORES,
    FETCHING_COMPANY_FORM_STORES_FAILED,
    COMPANY_FORM_STORES_CLEARED,
    FETCHING_COMPANY_FORM_STORES_SUCCESSFUL,
    FETCHING_COMPARE_FORM_STORE,
    FETCHING_COMPARE_FORM_STORE_FAILED,
    COMPARE_FORM_STORE_CLEARED,
    FETCHING_COMPARE_FORM_STORE_SUCCESSFUL,
} from "../forms/BulkReviewForm/actions"

export type tCompanyFormStoreState = {
    all: iCompanyFormStore[]
    compare: tJSONValue
}

const initialState: tCompanyFormStoreState = {
    all: [],
    compare: null,
}

export default function companyFormStore(
    state: tCompanyFormStoreState = initialState,
    action: BulkCompanyFormStoreActions | BulkCompareFormStoreActions
): tCompanyFormStoreState {
    // Most of this this will going away when we remove the old dashboard
    switch (action.type) {
        case FETCHING_COMPANY_FORM_STORES:
        case FETCHING_COMPARE_FORM_STORE:
            return state
        case FETCHING_COMPANY_FORM_STORES_SUCCESSFUL:
            return {
                ...state,
                all: action.data.results,
            }
        case FETCHING_COMPANY_FORM_STORES_FAILED:
        case COMPANY_FORM_STORES_CLEARED:
            return {
                ...state,
                all: [],
            }
        case FETCHING_COMPARE_FORM_STORE_SUCCESSFUL:
            return {
                ...state,
                compare: action.data,
            }
        case FETCHING_COMPARE_FORM_STORE_FAILED:
        case COMPARE_FORM_STORE_CLEARED:
            return {
                ...state,
                compare: null,
            }
        default:
            return state
    }
}
