import * as API from "../api"

export const LOCKED_PERIOD_UPDATED = "LOCKED_PERIOD_UPDATED"
export const COMPANY_SIGNATURES_UPDATED = "COMPANY_SIGNATURES_UPDATED"

export const lockedPeriodUpdated = company_options => {
    return {
        type: LOCKED_PERIOD_UPDATED,
        data: company_options,
    }
}

export const companySignaturesUpdated = company_options => {
    return {
        type: COMPANY_SIGNATURES_UPDATED,
        data: company_options,
    }
}

export const updateLockedPeriod = newLockEndDate => {
    return dispatch => {
        return API.updateLockedPeriod(newLockEndDate).then(response => {
            dispatch(lockedPeriodUpdated(response))
        })
    }
}

export const removeLockedPeriod = id => {
    return dispatch => {
        return API.removeLockedPeriod(id).then(response => {
            dispatch(lockedPeriodUpdated(response))
        })
    }
}

// take in signature settings as an object and send via the API
export const updateCompanySignatureSettings = signatureSettings => {
    return dispatch => {
        return API.updateCompanySignatureSettings(signatureSettings).then(response => {
            dispatch(companySignaturesUpdated(response))
        })
    }
}
