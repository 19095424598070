import CollapsibleMenuGroup from "../collapsible-menu-group"
import { navCategories } from "../../../common/constants"
import MenuOptions from "../menu-options"
import React from "react"
import { IconFlag } from "@rhumbix/rmbx_design_system_web"

const BetaDashboards = props => {
    let isHidden = true
    if (
        props.features["custom_dashboards_beta"] &&
        props.routes["beta-dashboards"].some(route => route.path.includes("/rhumbix/beta/"))
    ) {
        isHidden = false
    } else if (props.analyticsDashboards.some(dashboard => dashboard.category === "BETA" && dashboard.url)) {
        isHidden = false
    }

    return (
        <CollapsibleMenuGroup
            {...props}
            iconName="demo-beta"
            icon={<IconFlag width={20} height={20} />}
            menuTitle="Beta Dashboards"
            navCategory="beta-dashboards"
            isHidden={isHidden}
        >
            <MenuOptions {...props} options={props.routes[navCategories.beta]} />
        </CollapsibleMenuGroup>
    )
}

export default BetaDashboards
