import React, { Component } from "react"
import { getCellValue, isGroupRow } from "../../../common/ag-grid-utils"

class DefaultCellRenderer extends Component {
    refresh() {
        return false
    }

    renderNormalCell(value) {
        // if schema data shapes change over time, don't crash the page if a field has an unexpected value
        // if we get an object or array value - return a null value so the page still renders properly
        if (typeof value === "object" || Array.isArray(value)) return null
        return value !== undefined ? value : null
    }

    renderSubtitle(value, formatter) {
        const subtitle = formatter ? formatter({ value: value, context: this.props.context }) : null

        return subtitle ? <div className="group-cell-text">{subtitle}</div> : null
    }

    renderDetailCell(value) {
        const title = this.props.titleFormatter
            ? this.props.titleFormatter({
                  value: value,
                  context: this.props.context,
              })
            : value
        return (
            <div className="group-cell">
                <div>
                    <div className="group-cell-title">{title}</div>
                    {this.renderSubtitle(value, this.props.primarySubtitleFormatter)}
                    {this.renderSubtitle(value, this.props.secondarySubtitleFormatter)}
                </div>
            </div>
        )
    }

    render() {
        if (!isGroupRow(this.props) && this.props.valueFormatted) {
            return this.renderNormalCell(this.props.valueFormatted)
        }

        // Group row cells are either displayed as "normal" (just the value) or in a more detailed
        // presentation with multiple rows of text
        const value = getCellValue(this.props)
        if (!this.props.moreDetail) {
            const formatter = this.props.titleFormatter
                ? this.props.titleFormatter
                : this.props.colDef.valueFormatter
            const formattedValue = formatter
                ? formatter({
                      ...this.props,
                      value: value,
                  })
                : value
            return this.renderNormalCell(formattedValue)
        }

        return this.renderDetailCell(value)
    }
}

export default DefaultCellRenderer
