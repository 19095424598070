import * as Sentry from "@sentry/react"

import makeRequest from "../networkClient"
import Rmbx from "../util"

/**
 * Fetch data from the REST API and normalize it according to the provided
 * schema. If the browser supports web workers, the work is done in a separate
 * browser thread.
 */
const fetchFromApi = async (
    endpoint,
    resourceName,
    expectedStatus,
    body = {},
    method = "GET",
    simulatedLatencyMs = 0 // for development & testing
) => {
    const request = {
        url: endpoint,
        method,
        body: body ? JSON.stringify(body) : null,
    }
    const useWorker = true
    const skipDedupe = false
    const json = await makeRequest(request, expectedStatus, skipDedupe, useWorker)

    if (simulatedLatencyMs && process.env.NODE_ENV !== "production") {
        await new Promise(resolve => setTimeout(resolve, simulatedLatencyMs))
    }

    const results = json.results
    const nextPageUrl = json.next

    return Object.assign({}, Rmbx.util.formatApiResponse(results, resourceName), { nextPageUrl })
}

/**
 * Fetch *all* pages of data from a REST API endpoint sequentially and
 * normalize it.
 */
const fetchAllFromApi = async function* (endpoint, resourceName, expectedStatus, body = {}, method = "GET") {
    let nextPageUrl = endpoint

    while (nextPageUrl) {
        const response = await fetchFromApi(nextPageUrl, resourceName, expectedStatus, body, method)
        nextPageUrl = response.nextPageUrl
        yield response
    }
}

// Action Types ---------------------------------------------------------------

export const FETCH_FROM_API = "FETCH_FROM_API"

// Middleware -----------------------------------------------------------------

/**
 * Handle the FETCH_FROM_API action type.
 */
const middleware = () => next => async action => {
    const fetchFromApiAction = action[FETCH_FROM_API]

    if (typeof fetchFromApiAction === "undefined") {
        return next(action)
    }

    // Validate arguments
    const {
        endpoint,
        expectedStatus,
        resourceName,
        types,
        all = false,
        body = {},
        method = "GET",
    } = fetchFromApiAction

    let error = null

    if (typeof endpoint !== "string") {
        error = new Error("FETCH_FROM_API: Specify a string endpoint URL.")
    }

    if (!resourceName) {
        error = new Error('FETCH_FROM_API: Specify the resource name (e.g. "costCodes").')
    }

    if (!Array.isArray(types) || types.length !== 4) {
        error = new Error("FETCH_FROM_API: Expected an array of four action types.")
    }

    if (!types.every(type => typeof type === "string")) {
        error = new Error("FETCH_FROM_API: Expected action types to be strings.")
    }

    if (error) {
        Sentry.captureMessage(error.toString())
        throw error
    }

    // A helper function to prevent the FETCH_FROM_API action from getting
    // handled repeatedly, which would result in multiple network requests
    const actionWith = data => {
        const finalAction = Object.assign({}, action, data)
        delete finalAction[FETCH_FROM_API]
        return finalAction
    }

    // Update the store to indicate the request has started
    const [requestType, successType, finishedType, failureType] = types
    next(actionWith({ type: requestType }))

    try {
        // Update the store to indicate that the request succeeded
        if (all) {
            const iterator = fetchAllFromApi(endpoint, resourceName, expectedStatus, body, method)
            for await (const response of iterator) {
                next(actionWith({ type: successType, payload: { response } }))

                if (response.nextPageUrl) {
                    // Update the store to indicate that another request has started
                    next(actionWith({ type: requestType }))
                }
            }
        } else {
            const response = await fetchFromApi(endpoint, resourceName, expectedStatus, body, method)
            next(actionWith({ type: successType, payload: { response } }))
        }

        next(actionWith({ type: finishedType }))
    } catch (update_error) {
        // Update the store to indicate that the request failed
        next(
            actionWith({
                type: failureType,
                error: true,
                payload: {
                    error: update_error.message || "Something went wrong.",
                },
            })
        )
    }
}

export default middleware
