import { iCurrentUser } from "./types"

// Intercom seems to be doing something goofy so we can't destructure it here
const { capn_version, intercom_api_key, rmbx_env } = window
let intercomBooted = false

type IntercomProps = {
    hide_default_launcher?: boolean
    capn_version: string
    email?: string
    user_id?: number
    company_id?: number
    company_name?: string
    company_role?: string
    employee_id?: number
    is_staff?: boolean
    user_role?: string
    environment: string
    last_ping_time: number
}

const lastPingTime = () => {
    return Math.floor(Date.now() / 1000)
}

/**
 * Boots intercom
 */
export const bootIntercom = (): void => {
    // Don't reboot if it's allready booted
    if (("rc" == rmbx_env || "hf" == rmbx_env || "prod" == rmbx_env) && !intercomBooted) {
        try {
            window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: intercom_api_key,
                custom_launcher_selector: ".launch-intercom",
                hide_default_launcher: true,
                capn_version: capn_version,
                environment: rmbx_env,
                last_ping_time: lastPingTime(),
            })
            intercomBooted = true
        } catch (e) {
            // There's really nothing to be done here other than try again later.
            // Let's just make sure intercom doesn't try to do anything in the mean time.
            intercomBooted = false
        }
    }
}

/**
 * Pings intercom to show activity so we can get new messages
 * When testing, be aware that we are throttled to 20 updates per 30 minutes, but refreshing the page will
 * reset this state.
 * https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomupdate
 * @param userInfo iCurrentUser - User information that we can use to populate intercom events
 */
export const pingIntercom = (userInfo?: iCurrentUser): void => {
    // We have a race condition with initializing Launch Darkly and booting Intercom so rather than
    // fight the system we'll just check it here before trying to use it.
    bootIntercom()

    if (!intercomBooted) return

    let updateProps: IntercomProps = {
        last_ping_time: lastPingTime(),
        capn_version: capn_version,
        environment: rmbx_env,
    }

    // If the user is logged in lets add that info too
    if (userInfo?.email && userInfo.id) {
        updateProps = {
            ...updateProps,
            email: userInfo.email,
            user_id: userInfo.id,
            hide_default_launcher: false,
        }
    }

    window.Intercom("update", updateProps)
}

/**
 * Once a user logs in, we want to tie the intercom events to the user. We only need to run this on login.
 * @param userInfo iCurrentUser - User information that we can use to populate intercom events
 * @returns
 */
export const setIntercomUser = (userInfo: iCurrentUser): void => {
    // We have a race condition with initializing Launch Darkly and booting Intercom so rather than
    // fight the system we'll just check it here before trying to use it.
    bootIntercom()

    const { company, company_id, company_role, email, employee_id, id, is_staff, user_role } = userInfo

    if (!id || !intercomBooted) return

    window.Intercom("update", {
        hide_default_launcher: true,
        capn_version: capn_version,
        email,
        user_id: id,
        company_id,
        company_name: company,
        company_role,
        employee_id,
        is_staff,
        user_role,
        environment: rmbx_env,
        last_ping_time: lastPingTime(),
    })
}

/**
 * Once a user logs out, we want to disconnect intercom events to the user.
 */
export const unsetIntercomUser = () => {
    if (!intercomBooted) return

    window.Intercom("shutdown")
    intercomBooted = false

    // When the user logs out we need to make sure an anoymous user still has access to intercom
    // so we need to reboot
    bootIntercom()
}
