import React from "react"
import { weekUnitRateValueGetter } from "../../../common/ag-grid-value-getters"
import { significantFigureMaker } from "../../../common/ag-grid-utils"

const UnitRateWeekValueRenderer = props => {
    const weekValue = props.value
    const thisWeek = weekUnitRateValueGetter(props, 0)
    const lastWeek = weekUnitRateValueGetter(props, 1)
    const prettyValue = significantFigureMaker(weekValue, 3)

    let klassy = ""
    if (isNaN(weekValue)) {
        klassy = klassy + " orange"
    } else if (thisWeek !== 0) {
        klassy = thisWeek >= lastWeek ? `${klassy} green` : `${klassy} red`
    }

    return <div className={klassy}>{prettyValue}</div>
}

export default UnitRateWeekValueRenderer
