import {
    attachmentsColDefCreator,
    checkboxColDefCreator,
    datetimeColDefCreator,
    foremanColDefCreator,
    projectColDefCreator,
    stringColDefCreator,
    viewCicoHistoryButtonColDefCreator,
} from "./standard-col-def-creators"
import { addDays, isValid } from "date-fns"
import { getAsDate, getTimezoneAcronym } from "../../../common/ts-utils"
import { CICO_LOOKUP } from "../../../common/constants"
import { tGroupKeyInfo } from "../types"
import { iTimelineEntryVersion } from "../../../cached-data/types"
import {
    CellClassParams,
    EditableCallbackParams,
    GridApi,
    ValueFormatterParams,
    ValueSetterParams,
} from "ag-grid-community"
import { alertCellClassRule, errorCellClassRule } from "../../../common/ag-grid-utils"
import { getFlagEnabled } from "../../../getFlagValue"

export const getCicoTableSettings = (lockedColumns: tGroupKeyInfo[], cicoDate: string) => {
    const dateParts = lockedColumns.find(col => col.colDef.field === "/date")!.value.split("-")
    const currentDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
    const dateIsEditable = (params: any) => !params.data.id || params.data.current_shift_date === cicoDate
    return {
        tableName: "Cico",
        navId: "cico",
        resources: ["timelineEntries"],
        filters: [],
        additionalQueryParams: {
            for_dashboard: true,
        },
        relatedNames: {
            timelineEntryVersions: {
                employees: ["/employee"],
            },
        },
        colDefs: [
            checkboxColDefCreator({}),
            stringColDefCreator({
                headerName: "Type",
                field: "/entry_type",
                editable: dateIsEditable,
                enum: Object.values(CICO_LOOKUP),
                getQuickFilterText: params => CICO_LOOKUP[params.value as keyof typeof CICO_LOOKUP],
                valueFormatter: (params: ValueFormatterParams) => {
                    return params.value in CICO_LOOKUP
                        ? CICO_LOOKUP[params.value as keyof typeof CICO_LOOKUP]
                        : params.value === "CHANGE_TASK"
                        ? "Change Task"
                        : params.value
                },
                valueSetter: params => {
                    params.data.entry_type = Object.keys(CICO_LOOKUP).find(
                        key => CICO_LOOKUP[key as keyof typeof CICO_LOOKUP] === params.newValue
                    )
                    return params.data.entry_type
                },
            }),
            {
                ...datetimeColDefCreator({
                    headerName: "Timestamp",
                    field: "/entered_time",
                    editable: dateIsEditable,
                    extraParams: {
                        minuteInterval: 1,
                    },
                    cellEditorParams: {
                        getMinDate: () => currentDate,
                        // Set the maximum date to the next day for clock-out entries or to the clock out time
                        // if one exists. Otherwise limit it to today.
                        getMaxDate: (params: { data: iTimelineEntryVersion; api: GridApi }) => {
                            if (params.data.entry_type === "CLOCK_OUT") return addDays(currentDate, 1)

                            let shiftEndDate
                            params.api.forEachNode(node => {
                                if (node.data.entry_type === "CLOCK_OUT") shiftEndDate = node.data.entered_time
                            })
                            if (shiftEndDate) return new Date(shiftEndDate)
                            return currentDate
                        },
                        getOpenToDate: () => currentDate,
                    },
                    valueFormatter: params => {
                        if (!params.value) {
                            return ""
                        }
                        // add the timezone acronym to the timestamp unless it is a date-only cell
                        const tz = getTimezoneAcronym(
                            params.data.audit?.client_created_on_timezone ||
                                Intl.DateTimeFormat().resolvedOptions().timeZone
                        )
                        const date = getAsDate(params.value)
                        return isValid(date)
                            ? `${date.toLocaleString("en-US", {
                                  timeZone: params.data.audit?.client_created_on_timezone,
                              })} ${tz}`
                            : ""
                    },
                }),
                valueSetter: (params: ValueSetterParams) => {
                    if (!params.data.audit) params.data.audit = {}
                    params.data.audit.client_created_on_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
                    params.data.entered_time = params.newValue
                    params.data.current_shift_date = cicoDate
                    return true
                },
            },
            {
                ...foremanColDefCreator({
                    field: "/references/foreman",
                }),
                editable: (params: EditableCallbackParams) =>
                    !params.data.no_foreman && dateIsEditable(params) && params.data.entry_type === "CLOCK_IN",
                cellStyle: (params: CellClassParams) =>
                    params.data.no_foreman || params.data.entry_type !== "CLOCK_IN" ? { opacity: 0.5 } : {},
            },
            {
                ...projectColDefCreator({
                    field: "/references/project",
                }),
                editable: (params: EditableCallbackParams) =>
                    !params.data.no_project &&
                    dateIsEditable(params) &&
                    ["CLOCK_IN", "CHANGE_TASK"].includes(params.data.entry_type),
                cellStyle: (params: CellClassParams) => {
                    return params.data.no_project || !["CLOCK_IN", "CHANGE_TASK"].includes(params.data.entry_type)
                        ? { opacity: 0.5 }
                        : {}
                },
                extraSearchFilters: getFlagEnabled("WA-8499-enforce-cico-settings") ? { cico_only: "true" } : {},
            },
            attachmentsColDefCreator({
                headerName: "Photo",
                field: "/audit/photo",
                editable: false,
                width: 100,
            }),
            attachmentsColDefCreator({
                headerName: "Location",
                field: "/audit/map_image",
                editable: false,
                width: 100,
            }),
            viewCicoHistoryButtonColDefCreator({
                cellRendererParams: {
                    lockedColumns,
                },
                headerName: "",
                field: "/history",
            }),
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: true,
                cellClassRules: {
                    "alert-cell": alertCellClassRule,
                    "error-cell": errorCellClassRule,
                    readonly: (params: CellClassParams) => !dateIsEditable(params),
                },
                customTooltip: (params: any) => {
                    return {
                        tooltipShouldRender: params.data.current_shift_date !== cicoDate && params.data.id,
                        data: { messages: ["You cannot modify shifts that began before the focused date"] },
                    }
                },
            },
            rowSelection: "multiple",
            // allow our click events to happen
            suppressRowClickSelection: true,
        },
        otherSettings: {
            pageTitle: "Clock In/Clock Out",
            rowLevelErrorDisplay: true,
            enableSearchBar: true,
            buttons: {
                row: [
                    {
                        label: "Delete",
                        icon: "delete",
                        args: { local_state_update: true },
                        action: "deleteSelectedRows",
                    },
                ],
                table: [
                    {
                        label: "Add Row",
                        icon: "add",
                        action: "addNewRow",
                    },
                    {
                        label: "Hide Surrounding Timelines",
                        action: "toggleStatefulButton",
                        icon: "view",
                        customButtonType: "stateful",
                        args: {
                            extraArgs: {
                                activeText: "Hide Surrounding CI/CO",
                                inactiveText: "Show Surrounding CI/CO",
                                stateAttribute: "extraTimelineVisible",
                                activeIcon: "hide",
                                inactiveIcon: "view",
                            },
                        },
                    },
                ],
            },
        },
    }
}
