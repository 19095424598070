const initialState = {
    response: [],
    isAudit: false,
    loadingAuditHistory: false,
    auditHistoryTableViewModalOpen: false,
    tagId: 0,
}

export const addGridIdToRows = (rows, gridIdCounter) => {
    const updatedRows = {}
    for (const resource in rows) {
        const row = rows[resource]

        updatedRows[resource] = row.map(row => {
            return { ...row, gridId: gridIdCounter++ }
        })
    }
    return updatedRows
}

export default (state = initialState, action) => {
    const newGridIdCounter = 0

    switch (action.type) {
        case "START_AUDIT_HISTORY": {
            return {
                ...state,
                loadingAuditHistory: true,
            }
        }
        case "GET_AUDIT_HISTORY": {
            return {
                ...state,
                response: action.data.response,
            }
        }
        case "FINISH_AUDIT_HISTORY": {
            return {
                ...state,
                loadingAuditHistory: false,
            }
        }
        case "UPDATE_AUDIT_FLAG": {
            return {
                ...state,
                isAudit: action.data.isAudit,
            }
        }
        case "GET_AUDIT_HISTORY_BY_ID": {
            return {
                ...state,
                response: addGridIdToRows(action.data.auditHistory, newGridIdCounter),
                isAudit: true,
                loadingAuditHistory: false,
                auditHistoryTableViewModalOpen: true,
                tagId: action.data.tagId,
            }
        }
        case "HIDE_AUDIT_HISTORY_MODAL": {
            return {
                ...state,
                response: [],
                isAudit: false,
                auditHistoryTableViewModalOpen: false,
            }
        }
        default: {
            return state
        }
    }
}
