import Rmbx from "./util"
import * as Sentry from "@sentry/react"
import XhrWorker from "./workers/threadedXhr.worker"
import { getCookie, deleteCookie } from "./common/ts-utils"
import { getAuthToken, getAuthType } from "./util"
import { getFlagEnabled } from "./getFlagValue"

/**
 * An array of valid HTTP verbs
 */
const HTTP_VERBS = ["CONNECT", "DELETE", "GET", "HEAD", "OPTIONS", "PATCH", "POST", "PUT", "TRACE"]

/**
 * An Error that contains details about the HTTP response that triggered it
 */
export class HttpError extends Error {
    constructor(message, response) {
        super(message)
        this.response = response
    }
}

/**
 * A de-duping network client that uses XHR under the hood.
 */
export class NetworkClient {
    constructor() {
        // An array of middleware to apply to the response
        this.afterMiddleware = []

        // An array of middleware to apply to the request
        this.beforeMiddleware = []

        // True if we've already sent at least one request with this client
        this.requestSent = false

        // A Map of in-flight network requests. The keys are pseudo-random
        // unique IDs.
        this.requests = new Map()

        // A Map of web workers that are currently processing requests. The
        // keys are pseudo-random unique IDs.
        this.workers = new Map()

        // A Map of handlers for deduped requests. Each key uniquely identifies
        // a request and maps it to a stack of Promises. When the request is
        // resolved, the result is pushed to all of the Promises.
        this.requestHandlers = new Map()
    }

    /**
     * Apply middleware to the response (after fetching & parsing)
     */
    applyAfterMiddleware = response => {
        return new Promise(resolve => {
            this.buildMiddlewareStack([...this.afterMiddleware], response, resolve)
        })
    }

    /**
     * Apply middleware to the request (before fetching)
     */
    applyBeforeMiddleware = request => {
        return new Promise(resolve => {
            this.buildMiddlewareStack([...this.beforeMiddleware], request, resolve)
        })
    }

    /**
     * Given an array of middleware functions, apply them to a given request or
     * response
     */
    buildMiddlewareStack = (functions, obj, resolve) => {
        const next = () => {
            if (functions.length) {
                const fn = functions.shift()
                if (fn) {
                    fn.apply(this, [obj, next])
                }
            } else {
                resolve(obj)
            }
        }

        return next()
    }

    /**
     * Log the user out of the app
     */
    logout = async () => {
        this.cancelAllRequests()

        const authType = getFlagEnabled("WA-7694-cookies-for-auth")
            ? getAuthType()
            : Rmbx.store.get("authorization_type")
        if (authType === "Token") {
            try {
                await this.makeRequest(
                    {
                        url: "/api/v3/users/logout/",
                        method: "POST",
                        body: null,
                    },
                    204,
                    false,
                    false
                )
            } catch (error) {
                // Do nothing with the error -- just make sure the user still
                // gets redirected to login
            }
            Rmbx.util.history.push("/rhumbix/")
        }

        Sentry.configureScope(scope => scope.setUser(null))
        // If we have a cookie because the flag was on and then we turn the flag off, we still
        // want to get rid of that cookie or else you'll stay logged in.
        if (getFlagEnabled("WA-7694-cookies-for-auth") || getCookie("token")) {
            deleteCookie("token")
            deleteCookie("authorization_type")
        }
        Rmbx.util.destroySession()
        Rmbx.tracker.logout()
    }

    /**
     * Cancel all in-flight requests
     */
    cancelAllRequests = () => {
        for (const [, xhr] of this.requests) {
            xhr.abort()
        }

        for (const [workerId, worker] of this.workers) {
            this.abortWorkerRequest(workerId, worker).finally(() => worker.terminate())
        }

        this.requestHandlers.clear()
        this.requests.clear()
        this.workers.clear()
    }

    /**
     * Abort an in-flight request managed by a web worker
     */
    abortWorkerRequest = (workerId, worker) =>
        new Promise((resolve, reject) => {
            worker.postMessage({
                type: "ABORT",
            })

            worker.onmessage = e => {
                const { ok } = e.data

                if (ok) {
                    resolve()
                } else {
                    reject(new Error("Unknown error"))
                }
            }

            worker.onerror = e => {
                this.workers.delete(workerId)
                reject(new Error(e))
            }
        })

    /**
     * Given a request, make a network request only if there are no identical
     * requests in-flight. All of the promise handlers for duplicated requests
     * are passed the response (with its body parsed as JSON) when the response
     * eventually comes back from the server.
     *
     * The response is parsed here to ensure compatibility in cases where the
     * response body is a stream (which is the case for all fetch requests);
     * streams can only be consumed once, so they can't be parsed by multiple
     * handlers.
     */
    dedupedNetworkRequest = ({ method, url, body = null, headers = new Map() }, threaded = false) => {
        const requestKey = this.getRequestKey({ method, url, body })

        if (!this.requestHandlers.has(requestKey)) {
            this.requestHandlers.set(requestKey, [])
        }

        const handlers = this.requestHandlers.get(requestKey)
        const isInFlight = !!handlers.length
        const requestHandler = {}
        const proxyRequest = new Promise((resolve, reject) => {
            requestHandler.resolve = resolve
            requestHandler.reject = reject
        })

        handlers.push(requestHandler)
        this.requestHandlers.set(requestKey, handlers)

        if (isInFlight) {
            return proxyRequest
        }

        this.networkRequest({ method, url, body, headers }, threaded)
            .then(response => {
                response.data = response.body ? JSON.parse(response.body) : {}
                this.resolveRequest(requestKey, response)
            })
            .catch(error => {
                this.resolveRequest(requestKey, null, error)
            })

        return proxyRequest
    }

    /**
     * Generate an absolute URL (relative to the current window location) from
     * a relative URL
     */
    getAbsoluteUrl = url => {
        return new URL(url, window.location.href).href
    }

    /**
     * Generate a key that uniquely identifies a request (for the purposes of
     * de-duping)
     */
    getRequestKey = req => {
        return [req.url, req.method, req.body || ""].join("||")
    }

    /**
     * Generate a pseudo-random ID.
     *
     * In theory, this could start seeing collisions past ~10k keys. In
     * practice, if someone is sending us 10k requests at once, they're DDoSing
     * us. Still, you should check for collisions before using these IDs as
     * keys.
     *
     * It almost goes without saying that this isn't cryptographically secure.
     */
    generatePseudoRandomId = () => Math.random().toString(36).substr(2, 9).toUpperCase()

    /**
     * Get a pseudo-random ID that's unique for the given Map
     */
    getUniqueId = map => {
        let id = this.generatePseudoRandomId()

        while (map.has(id)) {
            id = this.generatePseudoRandomId()
        }

        return id
    }

    /**
     * Send a request to a network resource. If the expected status code is
     * provided, unexpected responses from the network resource will trigger an
     * exception.
     *
     * If the request was de-duped, returns the parsed body of the JSON response
     * from the server. If skipDedupe was true and the request was NOT de-duped,
     * returns the entire, raw response.
     *
     * The reason deduping parses the body is to ensure compatibility with
     * responses whose bodies are streams. If the response body is a stream,
     * the body of the request can only be consumed once. This is a concern for
     * fetch() requests (whose bodies are always streams), which we may want to
     * support at some point in the future.
     *
     * Threaded requests are run in a Web Worker from the app's thread pool.
     * This prevents the main thread from being blocked and also opens up the
     * possibility of making multiple concurrent requests.
     */
    makeRequest = (request, expectedStatusCode = 0, skipDedupe = false, threaded = false) => {
        this.requestSent = true

        // Supply some default values & normalization for the request object
        // without mutating the object that was passed in as a parameter
        const { url, body = null, headers = new Map() } = request
        const method = request.method.toUpperCase()

        const request_obj = { method, url, body, headers }

        this.validateRequest(request_obj)

        const requestFunction = skipDedupe ? this.networkRequest : this.dedupedNetworkRequest

        return this.applyBeforeMiddleware(request_obj)
            .then(req => requestFunction(req, threaded))
            .then(res => this.applyAfterMiddleware(res))
            .then(res => {
                if (expectedStatusCode && res.status !== expectedStatusCode) {
                    this.throwHTTPError(res, `Unexpected server response ${res.status}`)
                } else if (res.status === 401) {
                    // TODO: Get new token and retry

                    // Cancel any other in-flight requests (which will just 401)
                    client.cancelAllRequests()

                    // Log the user out if they aren't using SSO
                    if (Rmbx.store.get("authorization_type") === "Token") {
                        client.logout()
                    }
                } else if (res.status >= 300) {
                    this.throwHTTPError(res)
                }

                return skipDedupe ? res : res.data
            })
    }

    /**
     * Make a network request on the main application thread. Returns a Promise.
     */
    makeBlockingRequest = ({ method, url, body = null, headers = new Map() }) => {
        const { requests, getUniqueId, parseHeaders } = this

        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()

            // Track this request object
            const requestId = getUniqueId(requests)
            requests.set(requestId, xhr)

            // The request "succeeded", insofar as the server returned some
            // kind of valid response (even if its HTTP status is an error code
            // like 500).
            xhr.onload = function () {
                requests.delete(requestId)

                const parsed_headers = parseHeaders(xhr.getAllResponseHeaders())

                resolve({
                    parsed_headers,
                    body: xhr.response,
                    status: this.status,
                    statusText: xhr.statusText,
                })
            }

            // The request failed with a network-level error, such as a reset
            // or closed connection. There's no consistent, cross-browser way
            // to get more detailed error information here than "something went
            // wrong".
            xhr.onerror = function () {
                requests.delete(requestId)

                reject(new Error("A network-level error occurred"))
            }

            xhr.open(method, url, true)

            for (const [key, value] of headers.entries()) {
                xhr.setRequestHeader(key, value)
            }

            xhr.send(body)
        })
    }

    /**
     * Make a network request in a separate thread with a Web Worker. Returns a
     * Promise.
     */
    makeThreadedRequest = ({ method, url, body = null, headers = new Map() }) => {
        const { getUniqueId, workers, getAbsoluteUrl, parseHeaders } = this
        return new Promise((resolve, reject) => {
            // Clone the arguments passed to the worker
            const payload = JSON.parse(
                JSON.stringify({
                    method,
                    url: getAbsoluteUrl(url),
                    body,
                    headers: [...headers],
                })
            )

            const worker = new XhrWorker()
            worker.postMessage({
                type: "REQUEST",
                payload,
            })

            // Track this worker object
            const workerId = getUniqueId(workers)
            workers.set(workerId, worker)

            worker.onmessage = e => {
                const { ok, response } = e.data

                if (ok) {
                    const res = Object.assign({}, response)
                    res.headers = parseHeaders(res.rawHeaders)
                    delete res.rawHeaders
                    workers.delete(workerId)
                    resolve(res)
                } else {
                    workers.delete(workerId)
                    reject(new Error(response))
                }
            }

            worker.onerror = e => {
                workers.delete(workerId)
                reject(new Error(e))
            }
        })
    }

    /**
     * Make a network request. Returns a promise.
     */
    networkRequest = async (request, threaded = false) => {
        // Browser-detection is gross, but IE11's implementation of web workers
        // doesn't allow them to be created from blob URLs which makes them
        // incompatible with our setup. Edge, Chrome, Firefox, and Safari are
        // all fine.
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

        if (!isIE11 && window.Worker && threaded) {
            return await this.makeThreadedRequest(request)
        }

        return await this.makeBlockingRequest(request)
    }

    /**
     * Parse a raw header string into a Map() of header names -> values
     */
    parseHeaders = rawHeaders => {
        const arrHeaders = rawHeaders.trim().split(/[\r\n]+/)
        const headerMap = new Map()

        arrHeaders.map(line => {
            const parts = line.split(": ", 2)
            headerMap.set(parts[0], parts[1])
        })

        return headerMap
    }

    /**
     * Resolve a de-duped network request
     *
     * This iterates over all the Promise handlers associated with a de-duped
     * request and either resolves or rejects them
     */
    resolveRequest = (requestKey, response, error) => {
        const handlers = this.requestHandlers.get(requestKey) || []

        handlers.forEach(handler => {
            if (response) {
                handler.resolve(response)
            } else {
                handler.reject(error)
            }
        })

        this.requestHandlers.delete(requestKey)
    }

    /**
     * Throw an HTTP error that contains details about the response that
     * triggered it
     */
    throwHTTPError = (response, errorMsg) => {
        let httpError

        if (errorMsg) {
            httpError = new HttpError(`Network request failed with error "${errorMsg}"`, response)
        } else if (response.status && (response.status < 200 || response.status >= 400)) {
            httpError = new HttpError(
                `Network request failed with status ${response.status} - ${response.statusText}`,
                response
            )
        } else {
            httpError = new HttpError("Network request failed with unknown error", response)
        }

        Sentry.captureMessage(httpError.toString())

        throw httpError
    }

    /**
     * Register a middleware function that operates on the Request object
     */
    useBefore = fn => {
        if (this.requestSent === true) {
            throw new Error("Must define middleware before making requests")
        } else if (typeof fn !== "function") {
            throw new Error("Middleware must be a function")
        } else {
            this.beforeMiddleware.push(fn)
        }
    }

    /**
     * Register a middleware function that operates on the Response object
     *
     * Note that because we're using `fetch-dedupe`, the request body will
     * already have been parsed at this point.
     */
    useAfter = fn => {
        if (this.requestSent === true) {
            throw new Error("Must define middleware before making requests")
        } else if (typeof fn !== "function") {
            throw new Error("Middleware must be a function")
        } else {
            this.afterMiddleware.push(fn)
        }
    }

    /**
     * Validate the request object passed to the client. Throws an exception on
     * validation errors.
     */
    validateRequest = request => {
        let error
        const { method, url, body, headers } = request

        if (!HTTP_VERBS.includes(method)) {
            error = Error(`request.method must be a valid HTTP method (got "${method}")`)
        }

        if (typeof url !== "string") {
            error = Error(`request.url must be a string (got "${url}")`)
        }

        // Technically, other data types are valid for the body of an
        // XmlHttpRequest (such as Blob or ReadableStream), but these are the
        // only two types we expect to actually use.
        if (body !== null && typeof body !== "string" && !(body instanceof FormData)) {
            error = Error(`request.body must be a string or null (got "${body}")`)
        }

        if (!(headers instanceof Map)) {
            error = Error(`request.headers must be a Map (got "${headers}")`)
        }

        if (error) {
            Sentry.captureMessage(error.toString())
            throw error
        }
    }
}

/**
 * The app's network client
 */
export const client = new NetworkClient()

/**
 * Add a default Content-Type header to every request, if one was not already
 * added.
 */
export const contentTypeMiddleware = (request, next) => {
    const contentType = request.headers.get("content-type")

    if (!contentType) {
        request.headers.set("content-type", "application/json")
    }

    next()
}

/**
 * Add a default Accept header to every request, if one was not already added.
 */
export const acceptMiddleware = (request, next) => {
    const accept = request.headers.get("accept")

    if (!accept) {
        request.headers.set("accept", "application/json")
    }

    next()
}

/**
 * Add an Authorization header to every request.
 */
export const authorizationMiddleware = (token, type) => (request, next) => {
    const authToken = token ? token : getAuthToken() ? getAuthToken() : null
    const authType = type ? type : getAuthType() ? getAuthType() : "Token"

    if (authToken) {
        request.headers.set("Authorization", `${authType} ${authToken}`)
    }

    next()
}

// All of the before middleware that should be used
export const beforeMiddleware = [contentTypeMiddleware, acceptMiddleware, authorizationMiddleware()]

// All of the after middleware that should be used
export const afterMiddleware = []

// Apply the middleware to client
beforeMiddleware.forEach(middleware => {
    client.useBefore(middleware)
})

afterMiddleware.forEach(middleware => {
    client.useAfter(middleware)
})

// The default export is the makeRequest method of the configured client, since
// this is almost always the only thing that the rest of the app needs to call
export default client.makeRequest
