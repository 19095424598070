/**
 * Created by jongon on 5/10/16.
 *
 * Specify the Reports Page(s) Reducers; i.e. how to update the application state in response
 *  to project change and date change actions.
 */

"use strict"
// initial reports state - Cost Code Log
const initialState = {
    costCodeDropdowns: {},
    costCodeAjaxReferences: [],
}

export default function reports(state = initialState, action) {
    switch (action.type) {
        case "TOGGLE_COST_CODE_DROPDOWN_FOR_EMPLOYEE":
            const x = {}
            x[action.id] = !state.costCodeDropdowns[action.id]
            const n = Object.assign({}, state.costCodeDropdowns, x)
            return Object.assign({}, state, { costCodeDropdowns: n })
        case "ADD_COST_CODE_DAY_AJAX_REFERENCE":
            return Object.assign({}, state, {
                costCodeAjaxReferences: state.costCodeAjaxReferences.concat(action.ajax),
            })
        case "CLEAR_COST_CODE_DAY_AJAX_REFERENCES":
            return Object.assign({}, state, { costCodeAjaxReferences: [] })
        default:
            return state
    }
}
