import numeral from "numeral"
import React, { Component } from "react"
import { HoursSegment } from "./cell-renderer-util"
import { isPivotRowTotalColumn } from "../../../common/ag-grid-utils"
import { getFlagEnabled } from "../../../getFlagValue"

// https://www.ag-grid.com/javascript-grid-cell-rendering-components/#cell-renderer-component
class SmallTimecardCellRenderer extends Component {
    // might want to actually implement this
    refresh() {
        return false
    }

    render() {
        if (this.props.value["ST"] === undefined) {
            return null
        }
        const total = this.props.value["ST"] + this.props.value["DT"] + this.props.value["OT"]

        const style = { textAlign: "center" }
        if (getFlagEnabled("WA-8239-weekly-view-hour-crowding") && !isPivotRowTotalColumn(this.props.colDef))
            style.width = "100%"

        return (
            <div style={style}>
                {isPivotRowTotalColumn(this.props.colDef) ? (
                    total ? (
                        numeral(total).format("0.[00]")
                    ) : (
                        "--"
                    )
                ) : (
                    <HoursSegment value={this.props.value} />
                )}
            </div>
        )
    }
}

export default SmallTimecardCellRenderer
