import React from "react"
import styled from "styled-components"
import { colorFunctionalGray10, colorFunctionalGray30 } from "@rhumbix/rmbx_design_system_web"

type tProps = {
    fieldNames: Record<string, string>
}

/* 
	Shows a little preview table with all column field names and fade out effect at the bottom.
*/
export const CSVUploadPreview: React.FC<tProps> = ({ fieldNames }) => {
    return (
        <FadeOutContainer>
            <PreviewTable>
                <thead>
                    <tr>
                        {Object.keys(fieldNames).map(fieldName => (
                            <th key={fieldName}>{fieldName}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {[...Array(2)].map(rowIndex => (
                        <tr key={rowIndex}>
                            {Object.keys(fieldNames).map(fieldName => (
                                <td key={fieldName}>&nbsp;</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </PreviewTable>
        </FadeOutContainer>
    )
}

const FadeOutContainer = styled.div`
    -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
`

const PreviewTable = styled.table`
    border-collapse: collapse;
    width: 100%;

    // the .01px border width is a hack to prevent a strange visual bug
    // where some borders would appear darker than others
    td,
    th {
        border: 0.01px solid ${colorFunctionalGray30};
        text-align: left;
        padding: 8px;
    }

    th {
        background-color: ${colorFunctionalGray10};
    }
`
