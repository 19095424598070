import PivotHeader from "../custom-headers/pivot-header"
import SmallTimecardCellRenderer from "../cell-renderers/small-timecard-cell-renderer"
import { pivotValueValueGetter } from "../../../common/ag-grid-value-getters"
import { aggregateObject } from "../../../common/ag-grid-utils"
import { filterFormatter } from "../../../common/ag-grid-value-formatters"
import {
    companyGroupsFilterDef,
    weekFilterDef,
    projectStatusFilterDef,
    multiProjectFilterDef,
} from "../../../filters/filter-defs"
import { compareGroupColumns, compareTimekeepingEntryObjects } from "../../../common/ag-grid-comparators"
import {
    dateColDefCreator,
    costCodeColDefCreator,
    employeeColDefCreator,
    foremanColDefCreator,
} from "./standard-col-def-creators"

export function getCostCodeAccrualSettings() {
    return {
        tableName: "Cost Code Accrual",
        navId: "cc-accrual",
        resources: ["timekeepingEntries"],
        filters: [companyGroupsFilterDef, projectStatusFilterDef, multiProjectFilterDef, weekFilterDef],
        relatedNames: {
            timekeepingEntries: {
                employees: ["/employee", "/foreman"],
                costCodes: ["/cost_code"],
            },
        },
        colDefs: [
            {
                ...dateColDefCreator(),
                pivot: true,
            },
            {
                ...costCodeColDefCreator({ moreDetail: true }),
                rowGroup: true,
            },
            {
                ...foremanColDefCreator({ moreDetail: true }),
                rowGroup: true,
            },
            {
                ...employeeColDefCreator({ moreDetail: true }),
                rowGroup: true,
            },
            {
                headerName: "Value",
                width: 107,
                aggFunc: aggregateObject,
                cellRenderer: SmallTimecardCellRenderer,
                valueGetter: pivotValueValueGetter,
            },
        ],
        gridSettings: {
            pivotHeaderHeight: 60,
            rowHeight: 50,
            pivotMode: true,
            pivotRowTotals: "after",
            autoGroupColumnDef: {
                // might need an OtherSetting to make this based off of vars in the grid
                headerName: "Cost Code",
                suppressMenu: true, // this too
                sort: "asc",
                unSortIcon: true,
                cellRendererParams: {
                    suppressCount: true,
                },
                menuTabs: ["filterMenuTab"],
                comparator: compareGroupColumns,
                minWidth: 250,
            },
            defaultColDef: {
                getQuickFilterText: filterFormatter,
            },
            rowSelection: "multiple",
            suppressCellSelection: true,
            // hide the group header, we don't like it
            pivotGroupHeaderHeight: 0,
        },
        otherSettings: {
            forceWeeklyPivotColumns: true,
            showExportButton: true,
            hideCellActions: true,
            pivotColDef: {
                headerComponent: PivotHeader,
                headerComponentParams: {
                    subtitles: ["ST", "OT", "DT"],
                },
                minWidth: 107,
                comparator: compareTimekeepingEntryObjects,
                dayOfWeekHeaderName: true,
            },
            pivotRowTotalColDef: {
                headerName: "Total",
                headerComponent: null,
            },
            enableSearchBar: true,
            buttons: {
                cell: [],
                row: [],
                table: [
                    {
                        label: "Expand/Collapse All",
                        action: "toggleRowExpansion",
                        icon: "expand",
                    },
                ],
            },
        },
    }
}
