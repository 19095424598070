import { referenceableToRelatedFilters, getResourceByFilterKey } from "../filters/constants"

import {
    iMutableFilterState,
    iSetMultipleFilterValuesAction,
    tFilterKey,
    tFilterResourceName,
    tRelatedFilter,
    tResourceToRelatedFilter,
    iSetSavedFilterSetAction,
    iSetStateToPendingFiltersAction,
    iInitialFiltersAppliedAction,
    iFetchSavedFilterSetsSuccessful,
    iFetchSavedFilterSetsFailed,
    iApplySavedFilterSetFailed,
    tFilterState,
    iSetInvalidSelectionsForSavedFilterSetAction,
    iClearInvalidSelectionsForSavedFilterSet,
    tSavedFilter,
    iApplySavedFilterSetSucceeded,
    iUpdateSavedFilterSetSuccessful,
    iUpdateSavedFilterSetFailed,
    iDeleteSavedFilterSetSuccessful,
    iDeleteSavedFilterSetFailed,
    tInvalidFilterSelections,
    tFilterDef,
    tErrorFilterDef,
} from "./types"
import {
    closeCreateSavedFilterModal,
    closeDeleteSavedFilterModal,
    createSavedFilterSetFailed,
} from "../components/modals/actions"
import { isApplicableFilterValue } from "./utils"
import * as API from "../api"
import { AppDispatch, AsyncThunk, ReduxState, Thunk } from "../common/types"
import Rmbx from "../util"
import { iUpdateCacheSuccessAction, tNormalizedData } from "../cached-data/types"
import { setSaveStatus } from "../dashboard-data/actions/network-status"
import { getFlagEnabled } from "../getFlagValue"

// Actions: Set pending filters to current page filters------------------------

export const setFilterState = (filterState: iMutableFilterState, storeFilters = true) => {
    return (dispatch: AppDispatch, getState: () => ReduxState) => {
        const currentUser = getState().current_user
        dispatch({
            type: "SET_PENDING_FILTERS_TO_STATE",
            payload: {
                error: null,
                filtersToAdd: filterState,
                currentUser: storeFilters ? currentUser : null,
            },
        })
    }
}

// Actions: Set current page filters to the pending filters------------------------

export const setPendingFilters = (filterState: iMutableFilterState): iSetStateToPendingFiltersAction => ({
    type: "SET_STATE_TO_PENDING_FILTERS",
    payload: {
        error: null,
        filtersToAdd: filterState,
    },
})

// Action: Set initialFiltersApplied to true-----------------------------------------

export const setInitialFiltersApplied = (): iInitialFiltersAppliedAction => ({
    type: "SET_INITIAL_FILTERS_APPLIED",
    payload: {
        initialized: true,
    },
})

// Actions & Utilities: Not Exported ------------------------------------------

/**
 * Updates the filter value map in redux with whatever key/value pairs are
 * passed to this function.
 */
const _setMultipleFilterValues = (
    filterUpdatesIncludingChained: iMutableFilterState
): iSetMultipleFilterValuesAction => ({
    type: "SET_MULTIPLE_FILTER_VALUES",
    payload: {
        error: null,
        filtersToAdd: filterUpdatesIncludingChained,
    },
})

/**
 * Given a filter key, returns its related filter info (if any)
 */
const _getRelatedFilterInfoForFilterKey = (filterKey: tFilterKey): tResourceToRelatedFilter =>
    Array.from(referenceableToRelatedFilters.keys()).reduce((relatedFilterInfo, referenceable) => {
        const filterInfo = referenceableToRelatedFilters.get(referenceable)

        if (
            filterInfo !== undefined &&
            filterInfo.relatedFilters
                .map((relatedFilter: tRelatedFilter) => relatedFilter.filterKey)
                .includes(filterKey)
        ) {
            relatedFilterInfo[referenceable] = filterInfo
        }
        return relatedFilterInfo
    }, {} as tResourceToRelatedFilter)

/**
 * Given an array of filter keys, merges their related filter info into a
 * single object and returns it.
 */
const _getRelatedFilterInfoForFilterKeys = (filterKeys: Array<tFilterKey>): tResourceToRelatedFilter =>
    filterKeys.reduce((relatedFilterInfo, filterKey) => {
        return {
            ...relatedFilterInfo,
            ..._getRelatedFilterInfoForFilterKey(filterKey),
        }
    }, {} as tResourceToRelatedFilter)

/**
 * Given the current filter state in redux, and a filterkey to look up, check to see
 * if there is a valid filter value in state for the given filter key, if so, clear
 * that filter.
 *
 * @param filterState the current filter state in redux
 * @param filterKey the filter key we want to check to see if we should clear.
 */
const _clearFilterIfExistsInFilterState = (
    filterState: iMutableFilterState,
    filterKey: tFilterKey
): iMutableFilterState | null => {
    if (!filterState) {
        return null
    }
    return isApplicableFilterValue(filterState[filterKey]) ? { [filterKey]: undefined } : null
}

/**
 * Given a map of filter key/value pairs to update in redux, returns a map of
 * filter key/value pairs that includes updated values for any related filters
 * that we need to clear.
 *
 * Example: The current filters are `{ projectId: 1, groupId: 1 }`, where
 * project 1 is a member of group 1. If filterUpdateMap is `{ groupId: 2 }`,
 * we will clear any filter which are related to groupId's changing, so in this case,
 * we will clear the project filter.
 */
const _getRefreshedChainedFiltersForFilterUpdate = (
    filterUpdateMap: iMutableFilterState,
    filterState: iMutableFilterState
): iMutableFilterState => {
    //Creates an array of filter keys from the filterUpdateMap
    const filterKeys = Object.keys(filterUpdateMap) as Array<tFilterKey>
    //Use the array of keys to get an object that is the combination of all related filters.
    const filterInfoForResourcesToUpdate = _getRelatedFilterInfoForFilterKeys(filterKeys)

    //There are no related filters we need to worry about, return the original filter map.
    if (Object.keys(filterInfoForResourcesToUpdate).length === 0) {
        return filterUpdateMap
    }

    const queryFilters = { ...filterState, ...filterUpdateMap } as iMutableFilterState

    let filtersToClear = {} as iMutableFilterState

    for (const filterResourceName in filterInfoForResourcesToUpdate) {
        const referenceable = filterResourceName as tFilterResourceName
        const filterInfo = filterInfoForResourcesToUpdate[referenceable]

        filtersToClear = {
            ...filtersToClear,
            ..._clearFilterIfExistsInFilterState(queryFilters, filterInfo.defaultFilterKey),
        }
    }

    return {
        ...filterUpdateMap,
        ...filtersToClear,
    }
}

// Actions: Set Filter Values -------------------------------------------------

export const setMultipleFilterValues = (
    filterUpdateMap: iMutableFilterState
): Thunk<iSetMultipleFilterValuesAction | undefined> => (dispatch, getState) => {
    const filterUpdatesIncludingChained = _getRefreshedChainedFiltersForFilterUpdate(
        filterUpdateMap,
        getState().pendingFilters
    )
    return dispatch(_setMultipleFilterValues(filterUpdatesIncludingChained))
}

export const setFilterValue = (name: tFilterKey, value: any) => setMultipleFilterValues({ [name]: value })

// Actions: Clear related filters -------------------------------------------

/**
 * Clears filter if we've created/updated the underlying referenceable data.
 *
 * e.g. If we edit a project and update its group. We will now clear the project filter if it's active.
 */
export const clearRelatedFilter = (filterKey: tFilterKey): Thunk<iSetMultipleFilterValuesAction | undefined> => (
    dispatch,
    getState
) => {
    const filtersToClear = _clearFilterIfExistsInFilterState(getState().pendingFilters, filterKey)
    if (filtersToClear) {
        return dispatch(_setMultipleFilterValues(filtersToClear))
    }
}

// Actions: Saved filters -------------------------------------

export const createSavedFilter = (
    filterState: tFilterState,
    name: string,
    page: string,
    employee: number
): Thunk => {
    return dispatch => {
        return API.createSavedFilter({ name, page, filter_selections: filterState, employee })
            .then(response => {
                dispatch(fetchSavedFilterSets(page))
                // Set the savedFilterSet property, mostly so that SavedFilterSetController reflects the
                // existence of the new entity

                dispatch(setSavedFilterSet(response))
                dispatch(closeCreateSavedFilterModal())
                dispatch(setSaveStatus("saved"))

                // TODO: Shouldn't it return a success action, even if it doesn't do anything? Seems
                // weird to leave that out, especially now that there's a success action for.
                // Maybe also the success actions are where these setSaveStatus things belong
            })
            .catch(error => {
                dispatch(setSaveStatus("failed"))

                // The error message will fall back to a generic message, but we'll try to dig out
                // a better one
                // TODO: Consolidate this code with the convertErrorFieldsToJsonPointer helper function, which is
                // unfortunately not exported
                let errorMessage = error.message
                const errors = error.response?.data?.errors?.non_field_errors

                if (errors && errors.length > 0) {
                    errorMessage = errors.join(" ")
                }

                dispatch(createSavedFilterSetFailed(errorMessage))
            })
    }
}

export const fetchSavedFilterSets = (page: string): Thunk => {
    return dispatch => {
        API.getSavedFilterSets({ page })
            .then(payload => {
                dispatch(fetchSavedFilterSetsSuccessful(payload))
            })
            .catch(() => {
                // Return a generic error message, to avoid displaying say, a gnarly network error
                dispatch(
                    fetchSavedFilterSetsFailed({
                        error: "Your saved filter has failed to load. Please refresh to try again.",
                    })
                )
            })
    }
}

export const fetchSavedFilterSetsSuccessful = (payload: any): iFetchSavedFilterSetsSuccessful => {
    return {
        type: "FETCH_SAVED_FILTER_SET_SUCCESSFUL",
        payload,
    }
}

export const fetchSavedFilterSetsFailed = (payload: any): iFetchSavedFilterSetsFailed => {
    return {
        type: "FETCH_SAVED_FILTER_SET_FAILED",
        payload,
    }
}

export const updateSavedFilterSet = (savedFilterSet: tSavedFilter): Thunk => {
    return dispatch => {
        return API.updateSavedFilterSet(savedFilterSet)
            .then(() => {
                dispatch(setSaveStatus("saved"))
                dispatch(updateSavedFilterSetSuccessful())
                dispatch(fetchSavedFilterSets(savedFilterSet.page))

                // Make sure that the active saved filter set has been updated with the
                // changes
                dispatch(setSavedFilterSet(savedFilterSet))
            })
            .catch(() => {
                dispatch(setSaveStatus("failed"))
                // Return a generic error message, to avoid displaying say, a gnarly network error
                dispatch(
                    updateSavedFilterSetFailed({
                        error: "Your saved filter has failed to update. Please wait and try again.",
                    })
                )
            })
    }
}

export const updateSavedFilterSetSuccessful = (): iUpdateSavedFilterSetSuccessful => {
    return {
        type: "UPDATE_SAVED_FILTER_SET_SUCCESSFUL",
    }
}

export const updateSavedFilterSetFailed = (payload: any): iUpdateSavedFilterSetFailed => {
    return {
        type: "UPDATE_SAVED_FILTER_SET_FAILED",
        payload,
    }
}

export const deleteSavedFilterSet = (
    savedFilterSetId: number,
    activeSavedFilterSetId: number | undefined,
    pageIdentifier: string
): Thunk => {
    return dispatch => {
        return API.deleteSavedFilterSet(savedFilterSetId)
            .then(() => {
                dispatch(setSaveStatus("saved")) // "Changes saved" is the best available messaging
                dispatch(deleteSavedFilterSetSuccessful())
                dispatch(closeDeleteSavedFilterModal())

                // If the active saved filter set is the one that got deleted, set it to undefined
                if (activeSavedFilterSetId === savedFilterSetId) {
                    dispatch(setSavedFilterSet(undefined))
                }
                dispatch(fetchSavedFilterSets(pageIdentifier))
            })
            .catch(() => {
                dispatch(setSaveStatus("failed"))
                // Return a generic error message, to avoid displaying say, a gnarly network error
                dispatch(
                    deleteSavedFilterSetFailed({
                        error: "Your saved filter could not be deleted. Please wait and try again.",
                    })
                )
            })
    }
}

export const deleteSavedFilterSetSuccessful = (): iDeleteSavedFilterSetSuccessful => {
    return {
        type: "DELETE_SAVED_FILTER_SET_SUCCESSFUL",
    }
}

export const deleteSavedFilterSetFailed = (payload: any): iDeleteSavedFilterSetFailed => {
    return {
        type: "DELETE_SAVED_FILTER_SET_FAILED",
        payload,
    }
}

// Similar to the other actions that return iUpdateCacheSuccessAction, this
// takes the data pulled from the API and add it to the local cache
export const filterInitiatedUpdateCacheSuccess = (
    normalizedData: tNormalizedData,
    actionType: string
): iUpdateCacheSuccessAction => ({
    type: actionType,
    payload: {
        response: normalizedData,
    },
})

export const loadEntitiesForFilter = (
    filterKey: string,
    resource: string,
    ids: number[]
): AsyncThunk<{ filterKey: string; unavailableIds: number[] }> => {
    return dispatch => {
        // we still need to return a promise in the case where there are no entities that to load
        const dummy = { filterKey: filterKey, unavailableIds: [] }
        if (ids?.length > 0 && !isNaN(ids[0])) {
            // Load all of the IDs. This does not leverage the cache, since it may be out of date
            return API.getReferenceableDataByResourceAndIds(resource, ids).then(response => {
                let unavailableIds = []
                const entitiesLoaded = response
                const entities = entitiesLoaded
                // Add the newly-loaded entities to state
                if (entities?.results?.length) {
                    const normalizedData = Rmbx.util.formatApiResponse(
                        entities.results,
                        resource
                    ) as tNormalizedData
                    dispatch(filterInitiatedUpdateCacheSuccess(normalizedData, "ENTITIES_LOADED_FOR_FILTER"))
                }
                // Identify the IDs that could not be loaded, because
                // they're no longer available to the user
                if (entities?.results?.length !== ids.length) {
                    unavailableIds = ids
                        .filter(
                            (id: any) =>
                                !entities.results.find(
                                    (r: any) => r["id"] && !isNaN(id) && r["id"] === parseInt(id)
                                )
                        )
                        .map((id: any) => (typeof id === "string" ? parseInt(id) : id))
                }

                return { filterKey, unavailableIds }
            })
        }
        return Promise.resolve(dummy)
    }
}

export const loadStringEntitiesForFilter = (
    filterKey: string,
    resource: string,
    values: string[],
    queryParam: string
): AsyncThunk<{ filterKey: string; unavailableIds: number[] }> => {
    return dispatch => {
        // we still need to return a promise in the case where there are no entities that to load
        const dummy = { filterKey: filterKey, unavailableIds: [] }
        if (values?.length > 0) {
            // Load all of the IDs. This does not leverage the cache, since it may be out of date
            return API.getV4Resources(resource, { [queryParam]: values }).then(response => {
                let unavailableIds = []
                const entities = response
                // Add the newly-loaded entities to state
                if (entities?.results?.length) {
                    const normalizedData = Rmbx.util.formatApiResponse(
                        entities.results,
                        resource
                    ) as tNormalizedData
                    dispatch(filterInitiatedUpdateCacheSuccess(normalizedData, "ENTITIES_LOADED_FOR_FILTER"))
                }
                // Identify the IDs that could not be loaded, because
                // they're no longer available to the user
                if (entities?.results?.length !== values.length) {
                    unavailableIds = values
                        .filter(
                            (id: any) =>
                                !entities.results.find(
                                    (r: any) => r["id"] && !isNaN(id) && r["id"] === parseInt(id)
                                )
                        )
                        .map((id: any) => (typeof id === "string" ? parseInt(id) : id))
                }

                return { filterKey, unavailableIds }
            })
        }
        return Promise.resolve(dummy)
    }
}

/**
 * Applies the specified saved filter set to the current filters.
 *
 * @param savedFilterSet The saved filter set to apply
 * @param filterState The current filter state in redux. Used to help fill in any values not covered by the
 *        selections in the saved filter set
 * @param filterDefs The current set of filter defs for this page. Used to help check that the selections in the
 *        saved filter set actually match the filters on the page - for example, if one of the filters
 *        is no longer available to this user, it should be removed from the saved filter set
 */
export const applySavedFilterSet = (
    savedFilterSet: tSavedFilter,
    currentFilterState: tFilterState,
    filterDefs: Array<tFilterDef | tErrorFilterDef>
): Thunk => {
    return dispatch => {
        // Set the saved filter, to make sure the dropdown is updated
        dispatch(setSavedFilterSet(savedFilterSet))

        // Keep track of entities that could not be loaded, so the user can be notified
        const invalidEntities: { [key: string]: tInvalidFilterSelections } = {}
        let invalidFilters: tFilterKey[] = []

        const resourcePromises: Array<Promise<any>> = []

        // For each resource, create a promise that will load its entities
        Object.entries(savedFilterSet.filter_selections).map(([filterKey, selectionValues]: [string, any]) => {
            // Find the resource that matches the key
            const resource = getResourceByFilterKey(filterKey)

            if (getFlagEnabled("WA-8085-fix-filter-sets-with-number-labels")) {
                const resourceIsForeignKey = !(
                    resource === "employeeTrades" ||
                    resource === "employeeClassifications" ||
                    resource === "companyTextFieldOptions"
                )

                if (
                    !resource ||
                    !resourceIsForeignKey ||
                    !selectionValues ||
                    !Object.keys(selectionValues).length
                ) {
                    return
                }

                // Convert values to numbers and only keep those that are valid as FKs
                // the reload off filtered select of the resource will remove any
                // invalid references from the selection
                const ids = Object.keys(selectionValues)
                    .map(Number)
                    .filter(Number.isInteger)
                    .filter(value => value)

                resourcePromises.push(dispatch(loadEntitiesForFilter(filterKey, resource, ids)))

                return
            }

            if (resource && selectionValues && Object.keys(selectionValues).length > 0) {
                // get only numeric ids,
                // and remove any string values that come from non-referenceable filter values
                // (i.e trade and classification) that are just strings rather than ids
                const ids = Object.keys(selectionValues)
                    .map(Number)
                    .filter(value => value) // removes 0 or other falsey values
                // save the promises returned from loadEntitiesForFilter to be executed later
                resourcePromises.push(dispatch(loadEntitiesForFilter(filterKey, resource, ids)))
            }
        })

        // Execute the promises and then deal with any invalid selections that turned up along the way
        Promise.all(resourcePromises)
            // Deal with any invalid IDs
            .then(response => {
                let updateSavedFilterSet = false

                // Make sure that there is actually a filter on the page for each filter selection.
                // If any of the filters is missing - for example, because a company feature toggle
                // was switched off - make a note of it and remove it from the saved filter set
                if (filterDefs) {
                    invalidFilters = Object.keys(savedFilterSet.filter_selections).filter(
                        key => !filterDefs.find(def => def.key === key)
                    ) as tFilterKey[]

                    if (invalidFilters.length > 0) {
                        invalidFilters.forEach(key => delete savedFilterSet.filter_selections[key])
                        updateSavedFilterSet = true
                    }
                }

                response.forEach(r => {
                    const { filterKey, unavailableIds } = r
                    if (unavailableIds?.length > 0) {
                        const validFilterSelections: { [key: string]: any } = {}
                        const invalidFilterSelections: { [key: string]: any } = {}

                        // The entities to track come from the saved filter set, which stores these
                        // entities for just this occasion
                        Object.values(savedFilterSet.filter_selections[filterKey]).forEach((entity: any) => {
                            if (unavailableIds.find((id: number) => id === entity.id)) {
                                invalidFilterSelections[entity.id] = entity
                            } else {
                                validFilterSelections[entity.id] = entity
                            }
                        })

                        // Remove the invalid filter selections from the saved filter set.
                        // They are deleted even before the user sees the notification,
                        // so that the selections don't hang around in the filters
                        savedFilterSet.filter_selections[filterKey] = validFilterSelections
                        updateSavedFilterSet = true

                        invalidEntities[filterKey] = {
                            invalidFilterSelections: Object.values(invalidFilterSelections),
                        }
                    }
                })

                if (updateSavedFilterSet) API.updateSavedFilterSet(savedFilterSet)

                // Dispatch the invalid selections
                dispatch(setInvalidSelectionsForSavedFilterSet(invalidEntities, invalidFilters))

                // ... And after all that, apply the selections to the filters
                const filterState = (Object.entries(savedFilterSet.filter_selections) as [string, any][]).reduce(
                    (updatedState: any, [key, value]) => {
                        const filterValues = Object.values(value).map((selection: any) =>
                            selection["id"] ? selection["id"] : selection["name"]
                        )
                        updatedState[key] = filterValues
                        return updatedState
                    },
                    {}
                )

                // The reducer uses a spread operator to combine this filter state
                // with the original one, which can risk leaving
                // old selections in place. Handle any fields from the currentFilterState
                // that weren't covered by the saved filter set
                Object.keys(currentFilterState).forEach((field: string) => {
                    if (!filterState[field]) {
                        // Any fields that are excluded from the saved filter set must be copied over by hand.
                        // For now, that includes the dates
                        if (field === "startDate" || field === "endDate")
                            filterState[field] = currentFilterState[field]
                        // Every other field should just be set as an empty array.
                        // For example, if the user had values in companyCrewType and then applies a new
                        // saved filter set that leaves that field blank
                        else filterState[field] = []
                    }
                })

                dispatch(setSaveStatus("off"))
                dispatch(setPendingFilters(filterState))
                dispatch(applySavedFilterSetSucceeded())
            })
            .catch(() => {
                dispatch(setSaveStatus("failed"))

                // So many different errors could pop up along the way, some of them
                // untested, that in the event of a failure, it's safest to display
                // a generic error message
                dispatch(
                    applySavedFilterSetFailed({
                        error: "Your selection has failed to load. Please refresh to try again.",
                    })
                )
            })
    }
}

export const applySavedFilterSetSucceeded = (): iApplySavedFilterSetSucceeded => {
    return {
        type: "APPLY_SAVED_FILTER_SET_SUCCEEDED",
        payload: { error: undefined },
    }
}

export const applySavedFilterSetFailed = (payload: any): iApplySavedFilterSetFailed => {
    return {
        type: "APPLY_SAVED_FILTER_SET_FAILED",
        payload,
    }
}

/**
 * Set the active saved filter set. This updates the field in state (for example, to set the selection
 * on the saved filters dropdown on the page) but does not actually apply the selections to the filters.
 *
 * @param savedFilterSet The saved filter set to make active
 */
export const setSavedFilterSet = (savedFilterSet: tSavedFilter | undefined): iSetSavedFilterSetAction => {
    return {
        type: "SET_SAVED_FILTER_SET",
        payload: savedFilterSet,
    }
}

/**
 * Update the set of invalid selections that applies to the active saved filter set.
 * Because this is used in an additive way as the data is loaded and checked
 * (e.g. add some invalid selections for Projects, then add some for Employees, etc.),
 * the simplest way to clear out all of the selections is with the separate
 * clearInvalidSelectionsForSavedFilterSet function
 *
 * @param invalidSelections The invalid selections, grouped by filter key
 * @param invalidFilters The invalid filters, identified by filter key
 */
export const setInvalidSelectionsForSavedFilterSet = (
    invalidSelections: { [key: string]: tInvalidFilterSelections } | undefined,
    invalidFilters: tFilterKey[] | undefined
): iSetInvalidSelectionsForSavedFilterSetAction => {
    return {
        type: "SET_INVALID_SELECTIONS_FOR_SAVED_FILTER_SET",
        payload: { invalidSelections, invalidFilters },
    }
}

/**
 * Clear the list of invalid selections. Once they've been reported to the user, they are no longer
 * needed
 */
export const clearInvalidSelectionsForSavedFilterSet = (): iClearInvalidSelectionsForSavedFilterSet => {
    return {
        type: "CLEAR_INVALID_SELECTIONS_FOR_SAVED_FILTER_SET",
        payload: {},
    }
}

// ACTIONS FOR UPDATING START OF WEEK

export interface iEmployeeStartOfWeekAction {
    type: "FETCH_EMPLOYEE_START_OF_WEEK_SUCCESSFUL" | "FETCH_EMPLOYEE_START_OF_WEEK_FAILED"
    data?: Record<string, string | null>
}

export const fetchEmployeeStartOfWeekSuccessful = (
    data: Record<string, string | null>
): iEmployeeStartOfWeekAction => {
    return {
        type: "FETCH_EMPLOYEE_START_OF_WEEK_SUCCESSFUL",
        data,
    }
}

export const fetchEmployeeStartOfWeekFailed = (): iEmployeeStartOfWeekAction => {
    return {
        type: "FETCH_EMPLOYEE_START_OF_WEEK_FAILED",
    }
}

export const fetchEmployeeStartOfWeek = (empId: number): Thunk => {
    return dispatch => {
        API.getCurrentUserStartOfWeek(empId)
            .then(payload => {
                dispatch(fetchEmployeeStartOfWeekSuccessful(payload))
            })
            .catch(() => {
                // Return a generic error message, to avoid displaying say, a gnarly network error
                dispatch(fetchEmployeeStartOfWeekFailed())
            })
    }
}

export const updateEmployeeStartOfWeek = (empId: number, startOfWeek: string): Thunk => {
    return dispatch => {
        API.updateEmployeeStartOfWeek(empId, startOfWeek).then(payload => {
            dispatch(fetchEmployeeStartOfWeekSuccessful(payload))
        })
    }
}
