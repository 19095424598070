import {
    ATTACHMENTS_UPLOAD_COMPLETE,
    ATTACHMENTS_UPLOAD_FAILED,
    ATTACHMENTS_UPLOAD_STARTED,
    CLEAR_CUSTOM_FORM_DATA,
    COMPANY_FORM_UPDATE_SUCCESSFUL,
    CUSTOM_FORM_MAX_HISTORY_RETRIEVED,
    CUSTOM_FORM_TOGGLE_EDITING,
    FORM_ATTACHMENTS_CHANGED,
    GET_CUSTOM_FORM_PROJECT_FAILED,
    GET_CUSTOM_FORM_PROJECT_SUCCEEDED,
    PDF_KEY_RELOAD_ATTEMPTS,
    PDF_LINK_FAILED,
    PDF_LINK_SUCCESSFUL,
    PDF_STATUS_UPDATE,
    tFormViewActions,
    UPDATE_CUSTOM_FORM,
} from "../actions/form-view-actions"
import { tFileAttachment } from "../common/types"

export type tCustomFormState = {
    customFormData: Record<string, any> | null
    editingActive: boolean
    downloadUrl: string
    downloadEnabled: boolean
    pdfStatus: string
    pdfMessage: string
    pdfKeyReloadAttempts: number
    project: Record<string, any> | null
    attachmentArray: Array<tFileAttachment>
    isUploading: boolean
    uploadErrorMessage: string | null
    maxHistory: Record<string, any> | null
}

const initialState: tCustomFormState = {
    customFormData: null,
    editingActive: false,
    downloadUrl: "",
    downloadEnabled: false,
    pdfStatus: "LOADING",
    pdfMessage: "PDF is processing. This may take a moment.",
    pdfKeyReloadAttempts: 0,
    project: null,
    attachmentArray: [],
    isUploading: false,
    uploadErrorMessage: null,
    maxHistory: null,
}

export default function customForm(
    state: tCustomFormState = initialState,
    action: tFormViewActions
): tCustomFormState {
    // Most of this this will going away when we remove the old dashboard
    switch (action.type) {
        case COMPANY_FORM_UPDATE_SUCCESSFUL:
        case UPDATE_CUSTOM_FORM: // single detail page
            return {
                ...state,
                customFormData: action.data,
            }
        case CUSTOM_FORM_TOGGLE_EDITING:
            return {
                ...state,
                editingActive:
                    action.data.isEditingActive !== undefined ? action.data.isEditingActive : !state.editingActive,
            }
        case CLEAR_CUSTOM_FORM_DATA: {
            return {
                ...state,
                customFormData: null,
            }
        }
        case PDF_LINK_SUCCESSFUL:
            return {
                ...state,
                downloadUrl: action.data.download_url,
            }
        case PDF_LINK_FAILED:
            return {
                ...state,
                downloadUrl: "",
            }
        case PDF_STATUS_UPDATE:
            return {
                ...state,
                pdfStatus: action.status,
                pdfMessage: action.msg,
            }
        case PDF_KEY_RELOAD_ATTEMPTS:
            return {
                ...state,
                pdfKeyReloadAttempts: action.pdfKeyReloadAttempts,
            }
        case FORM_ATTACHMENTS_CHANGED:
            return {
                ...state,
                attachmentArray: action.attachments,
            }
        case ATTACHMENTS_UPLOAD_STARTED:
            return {
                ...state,
                uploadErrorMessage: null,
                isUploading: true,
            }
        case ATTACHMENTS_UPLOAD_COMPLETE:
            return {
                ...state,
                isUploading: false,
            }
        case ATTACHMENTS_UPLOAD_FAILED:
            return {
                ...state,
                uploadErrorMessage: "An error occurred during upload",
                isUploading: false,
            }
        case GET_CUSTOM_FORM_PROJECT_SUCCEEDED:
            return {
                ...state,
                project: action.data,
            }
        case GET_CUSTOM_FORM_PROJECT_FAILED:
            return {
                ...state,
                project: null,
            }
        case CUSTOM_FORM_MAX_HISTORY_RETRIEVED:
            return {
                ...state,
                maxHistory: action.data,
            }
        default:
            return state
    }
}
