import React from "react"
import { connect } from "react-redux"

import CollapsibleMenuGroup from "../collapsible-menu-group"
import MenuOptions from "../menu-options"
import { isFeatureEnabledSelector } from "../../../selectors"
import { navCategories } from "../../../common/constants"
import { IconBarGraph } from "@rhumbix/rmbx_design_system_web"

const category = navCategories.analytics

const Analytics = props => {
    let isHidden = true
    if (props.isTimekeepingFeatureEnabled) {
        isHidden = false
    } else if (props.analyticsDashboards.some(dashboard => dashboard.category === "ANALYTICS" && dashboard.url)) {
        isHidden = false
    }

    return (
        <CollapsibleMenuGroup
            {...props}
            iconName="analytics"
            icon={<IconBarGraph width={20} height={20} />}
            menuTitle={"Field Analytix\u2122"}
            navCategory="analytics"
            isCore={true}
            isHidden={isHidden}
        >
            <MenuOptions {...props} options={props.routes[category]} />
        </CollapsibleMenuGroup>
    )
}

const mapStateToProps = state => ({
    isTimekeepingFeatureEnabled: isFeatureEnabledSelector(state, "timekeeping"),
})

export default connect(mapStateToProps)(Analytics)
