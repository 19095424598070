import CollapsibleMenuGroup from "../collapsible-menu-group"
import React from "react"
import { navCategories } from "../../../common/constants"
import Authorized from "../../authorized"
import MenuOptions from "../menu-options"
import { IconSettings } from "@rhumbix/rmbx_design_system_web"

const ProjectSettings = props => (
    <CollapsibleMenuGroup
        {...props}
        iconName="project-settings"
        icon={<IconSettings width={24} height={24} style={{ marginRight: "-5px", marginTop: "4px" }} />}
        menuTitle="Project Settings"
        navCategory="project-settings"
        isHidden={!["ADMIN", "PM", "PAYROLL_ADMIN"].includes(props.user_role)}
    >
        <Authorized model="project" action="add">
            <MenuOptions {...props} options={props.routes[navCategories.projectSettings]} />
        </Authorized>
    </CollapsibleMenuGroup>
)

export default ProjectSettings
