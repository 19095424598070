import { AnalyticsDashboard, ReportsPageContainer } from "./lazy-components"
import { navCategories } from "../common/constants"
import reportsIconSrc from "../../images/sidebar_icons/reports.svg"

import { tRoute, tDashboard } from "./types"

// This method handles the dynamic creation of routes for Report-type analytics dashboards.
// The /api/v3/users/me method populates an array of dashboards that a user has access to
// based on their role and company. This dashboard list is stepped through to find reports
export const getReportsRoutes = (dashboards: Array<tDashboard>): Array<tRoute> => {
    const reportsRoutes: Array<tRoute> = []
    for (const dashboard of dashboards) {
        if (dashboard.category === "REPORTS" && dashboard.url) {
            const route = {
                path: `/rhumbix/reports/${dashboard.url_slug}/`,
                authRequired: true,
                component: AnalyticsDashboard,
                navTitle: dashboard.name,
                navIconSrc: reportsIconSrc,
                navCategory: navCategories.reports,
                wrapper: ReportsPageContainer,
            }
            reportsRoutes.push(route)
        }
    }
    return reportsRoutes
}
