import {
    ActionBarSelector,
    IconCheckThick,
    IconSettings,
    colorFunctionalGray60,
    colorMiscGray80,
    colorMiscGray90,
    spacingM,
    spacingS,
} from "@rhumbix/rmbx_design_system_web"
import React, { useState } from "react"
import { WEEKDAY_INDEX } from "../../common/constants"
import { titleCaseFormatter } from "../../common/ag-grid-value-formatters"
import styled from "styled-components"
import { DayOfWeek } from "../../common/types"
import { tSelectorOption } from "../../SelectorV3/types"

type DayOfWeekSelectorProps = {
    companyStartOfWeek?: string
    employeeStartOfWeek?: string | null
    updateEmployeeStartOfWeek: (empId: number, startOfWeek: DayOfWeek) => void
    employeeId?: number
}

const convertToOption = (option: string, label?: string): tSelectorOption => {
    return { value: option.toUpperCase(), label: label ?? `${titleCaseFormatter({ value: option })}` }
}

const DayOfWeekSelector = (props: DayOfWeekSelectorProps): JSX.Element => {
    const { companyStartOfWeek, employeeId, employeeStartOfWeek, updateEmployeeStartOfWeek } = props
    const initialSelection = employeeStartOfWeek
        ? convertToOption(employeeStartOfWeek)
        : convertToOption("DEFAULT", `Company Default ${titleCaseFormatter({ value: companyStartOfWeek })}`)

    const [selectedDate, setSelectedDate] = useState<tSelectorOption | undefined>(initialSelection)

    const options = Object.keys(WEEKDAY_INDEX)
    return (
        <ActionBarSelector
            id={"day-of-week-selector"}
            menuStyles={{ right: 0, marginTop: 6 }}
            dropdownSubtitle="Start of Week"
            onlyDisplayIcon={true}
            options={[
                {
                    value: "DEFAULT",
                    label: "Company Default",
                },
                ...options.map<Record<string, any>>(day => {
                    return { value: day.toUpperCase(), label: `${titleCaseFormatter({ value: day })}` }
                }),
            ]}
            onChange={(e: any) => {
                setSelectedDate(e.value ? e : undefined)
                if (employeeId) updateEmployeeStartOfWeek(employeeId, e.value)
            }}
            placeholder={""}
            renderIcon={(color: string) => <IconSettings color={color} height={spacingM} />}
            renderOption={(option: Record<string, string>) => {
                const { label } = option
                const isCompanyDefault = label.startsWith("Company Default")
                const showCheck = label === selectedDate?.label
                return (
                    <Option isDefault={isCompanyDefault}>
                        <OptionCheck>
                            {showCheck && <IconCheckThick height={spacingM} color={colorMiscGray80} />}
                        </OptionCheck>
                        <OptionLabel isActive={showCheck}>{label}</OptionLabel>
                        <CompanyDefault>
                            {isCompanyDefault && `(${titleCaseFormatter({ value: companyStartOfWeek })})`}
                        </CompanyDefault>
                    </Option>
                )
            }}
            value={selectedDate}
        />
    )
}

const Option = styled.div<{ isDefault: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: ${spacingS};
    height: 28px;
    border-bottom: 1px solid ${({ isDefault }) => (isDefault ? colorMiscGray80 : "none")};
`

const OptionCheck = styled.div`
    width: ${spacingM};
`

const OptionLabel = styled.div<{ isActive: boolean }>`
    font-size: ${spacingM};
    font-weight: 700;
    margin-left: ${spacingS};
    color: ${colorMiscGray90};
`

const CompanyDefault = styled.div`
    margin-left: ${spacingS};
    margin-right: ${spacingS};
    font-size: 12px;
    color: ${colorFunctionalGray60};
    font-weight: 700;
`

export default DayOfWeekSelector
