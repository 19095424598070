import { Checkbox } from "@rhumbix/rmbx_design_system_web"
import React, { useState } from "react"
import styled from "styled-components"
import { ICellRendererParams } from "ag-grid-community"

/*
    A checkbox component to use in ag-grid tables for boolean values
*/
const BooleanCheckboxCellRenderer = (params: ICellRendererParams) => {
    // we don't want to show checkboxes in the footer row of tables
    if (params.node.footer) return null

    const { colDef, node: row } = params
    const colId = colDef?.field ?? ""
    const rowId = row.id

    const [checked, setChecked] = useState(!!params.value)
    const handleChange = () => {
        if (colId) {
            row.setDataValue(colId, !checked)
            setChecked(!checked)
        }
    }
    return (
        <CheckboxContainer id={`${colId.substring(1).replace("/", "__")}-${rowId}`}>
            <Checkbox onChange={handleChange} checked={checked} className="checkbox-toggle" />
        </CheckboxContainer>
    )
}

export default BooleanCheckboxCellRenderer

/* pointer-events: auto overrides default css that allows this toggle to register the click/change events,
as there is css defined in aggrid.less that sets pointer-events: none */
const CheckboxContainer = styled.span`
    display: flex;
    align-items: center;

    .checkbox-toggle {
        margin-right: 8px;
        pointer-events: auto;
    }
`
