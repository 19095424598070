import {
    CLEAR_SINGLE_COMPANY_FORM_SCHEMA,
    GET_SINGLE_COMPANY_FORM_SCHEMA,
    tFormViewActions,
} from "../actions/form-view-actions"
import {
    CLEAR_SCHEMA_WORKFLOW,
    SCHEMA_WORKFLOW_UPDATE_SUCCESSFUL,
    tWorkflowActions,
} from "../actions/workflow-actions"

type tCustomFormSchemaState = {
    single: Record<string, any>
    single_workflow: Record<string, any>
}

const initialState: tCustomFormSchemaState = {
    single: {},
    single_workflow: {},
}

export default function companyFormSchema(
    state: tCustomFormSchemaState = initialState,
    action: tFormViewActions | tWorkflowActions
): tCustomFormSchemaState {
    switch (action.type) {
        case GET_SINGLE_COMPANY_FORM_SCHEMA:
            return {
                ...state,
                single: action.data[0],
            }
        case CLEAR_SINGLE_COMPANY_FORM_SCHEMA:
            return {
                ...state,
                single: {},
            }
        case SCHEMA_WORKFLOW_UPDATE_SUCCESSFUL:
            return {
                ...state,
                single_workflow: action.data,
            }
        case CLEAR_SCHEMA_WORKFLOW:
            return {
                ...state,
                single_workflow: {},
            }
        default:
            return state
    }
}
