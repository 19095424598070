import React from "react"
import { actOnEnterOrSpace } from "../../common/js-utils"
import "./Checkbox.less"

type tProps = {
    id?: string
    isChecked: boolean
    onChange: () => void
}

class StyledCheckbox extends React.Component<tProps & React.DOMAttributes<HTMLSpanElement>> {
    render() {
        const { id, isChecked, onChange } = this.props

        return (
            <span
                id={id ? id : ""}
                className="styled-checkbox"
                onClick={onChange}
                onKeyDown={e => actOnEnterOrSpace(e, onChange)}
                tabIndex={0}
                role="checkbox"
                aria-checked={isChecked}
            >
                <input className="styled-checkbox-input" type="checkbox" checked={isChecked} readOnly />
                <span className="styled-checkbox-box"></span>
                <span className={`styled-checkbox-val ${isChecked ? "styled-checkbox-yes" : "styled-checkbox-no"}`}>
                    {isChecked ? "Yes" : "No"}
                </span>
            </span>
        )
    }
}

export default StyledCheckbox
