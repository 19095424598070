import { ReferralActionTypes } from "../actions/rhumbix-referral"

const initialState = {
    inviteModalIsOpen: false,
    sendingInProgress: false,
    referralsSentSuccessfully: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ReferralActionTypes.OPEN_REFERRAL_MODAL:
            return { ...state, inviteModalIsOpen: true }
        case ReferralActionTypes.CLOSE_REFERRAL_MODAL:
            return { ...state, inviteModalIsOpen: false }
        case ReferralActionTypes.SENDING_REFERRALS:
            return { ...state, sendingInProgress: true }
        case ReferralActionTypes.SEND_REFERRALS_SUCCEDED:
            return {
                ...state,
                sendingInProgress: false,
                referralsSentSuccessfully: true,
                inviteModalIsOpen: false,
            }
        case ReferralActionTypes.SEND_REFERRALS_FAILED:
            return { ...state, sendingInProgress: false }
        case ReferralActionTypes.CLOSE_REFERRALS_SUCCESS_WINDOW:
            return { ...state, referralsSentSuccessfully: false }
        default:
            return state
    }
}
