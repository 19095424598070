import React from "react"
import { tRoute } from "../../router/types"

type tProps = {
    className: string
    route: tRoute
}

export class Breadcrumb extends React.PureComponent<tProps> {
    render() {
        const { navTitle, navIconObj: NavIconObj, navIconSrc } = this.props.route
        return navTitle ? (
            <div className={this.props.className}>
                {navIconSrc ? <img alt="" className={`${this.props.className}-img`} src={navIconSrc} /> : null}
                {NavIconObj ? (
                    <div
                        style={{
                            width: 20,
                            display: "inline-flex",
                            marginRight: 8,
                            verticalAlign: "middle",
                            marginTop: -3,
                        }}
                    >
                        {/*// @ts-ignore*/}
                        <NavIconObj />{" "}
                    </div>
                ) : null}
                {navTitle}
            </div>
        ) : null
    }
}

export default Breadcrumb
