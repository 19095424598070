import React, { useEffect, useState } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import ReactDOM from "react-dom"
import fileDownload from "js-file-download"
import JSZip from "jszip"
import {
    openDeleteConfirmationModal,
    closeDeleteConfirmationModal,
    openErrorModal,
} from "../../components/modals/actions"
import DeleteConfirmationModal from "../../components/modals/modal-generic-delete-confirmation"
import ErrorModal from "./modal-generic-error"
import LoadingIndicator from "../../components/loading-indicator"
import { generateUrlAndUploadAttachment } from "../../actions/form-view-actions"
import { userSelector } from "../../selectors/user"
import {
    colorFunctionalGray10,
    colorAttentionRed60,
    IconDownload,
    IconDownloadAll,
    IconShoveling,
    IconTrash,
    IconUpload,
    IconX,
    NavArrows,
    colorFunctionalWhite,
    colorMiscGray80,
    IconButton,
} from "@rhumbix/rmbx_design_system_web"
import { Button, ToolbarButtonSeparator } from "../../toolbar/Toolbar/styles"
import { downloadImage as downloadImageUtil, getFileIconOrImage } from "../../common/ts-utils"
import { getFlagEnabled } from "../../getFlagValue"
import { resizeImage } from "../../common/image-utils"

const AttachmentsLightboxModal = props => {
    const { resourceName } = props
    const [imageIndex, setImageIndex] = useState(props.imageIndex)
    const [images, setImages] = useState(props.images)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setImages(props.images)

        if (props.uploadErrorMessage) {
            props.openErrorModal(props.uploadErrorMessage, "Attachment Upload Error", "Network Error")
            setIsLoading(false)
        }
    }, [props])

    /////////////////////////////////////////////////////////////////////
    // Toolbar commands

    // Close the lightbox.
    const close = () => {
        props.onClose(images)
    }

    // Delete the image currently featured in the lightbox. The button opens the modal,
    // and this function handles the actual deletion
    const confirmDelete = () => {
        if (hasImages()) {
            const updatedImages = [...images]
            updatedImages.splice(imageIndex, 1)

            // If this was the last image in the set, imageIndex will now be -1,
            // which is its state when the lightbox is empty
            if (imageIndex >= updatedImages.length) setImageIndex(updatedImages.length - 1)

            // Explicitly close the modal, because the onUpdate function can interrupt
            // the flow before the modal can close itself
            props.closeDeleteConfirmationModal()

            props.onUpdate(updatedImages)
        }

        // TODO: Should deleting the file here also delete it on S3? Or does something else handle that?
    }

    // Download a single image
    const downloadImage = () => {
        if (hasImages()) {
            let url = `${images[imageIndex].url}?as_attachment=true`
            // only custom form uploads have a type - and we want to use the filename from the image data
            if (images[imageIndex].type) {
                url += `&filename=${images[imageIndex]?.name}`
            }
            downloadImageUtil(url)
        }
    }

    // Download all of the images into a .zip file
    const downloadImages = () => {
        if (hasImages()) {
            const downloads = images.map(img =>
                fetch(img.url).then(res => {
                    return {
                        name: img.name,
                        blob: res.blob(),
                    }
                })
            )
            const date = new Date()
            const year = date.getFullYear()
            const month = `${date.getMonth() + 1}`.padStart(2, "0")
            const day = `${date.getDate()}`.padStart(2, "0")
            const hours = `${date.getHours()}`.padStart(2, "0")
            const minutes = `${date.getMinutes()}`.padStart(2, "0")
            const timestamp = `-${year}${month}${day}-${hours}${minutes}`

            const username = props.currentUser?.last_name ? `${props.currentUser.last_name}-` : ""
            const schemaName = props.tableName ? `${props.tableName}-` : ""

            const filename = `${schemaName}${username}download${timestamp}`
            const zip = new JSZip()
            const folder = zip.folder(filename)

            Promise.all(downloads)
                .then(res =>
                    res.forEach(item => {
                        folder.file(item.name, item.blob)
                    })
                )
                .then(() => {
                    return zip.generateAsync({ type: "blob" })
                })
                .then(zipBlob => {
                    fileDownload(zipBlob, `${filename}.zip`)
                })
                .catch(() => {
                    props.openErrorModal(
                        "The images could not be downloaded.",
                        "Attachment Download Error",
                        "Network Error"
                    )
                })
        }
    }

    // Upload button click handler
    const uploadImg = () => {
        uploadFileInput.current.click()
    }

    // Get a reference to the hidden <input> control so that the visible Button can trigger it
    const uploadFileInput = React.useRef(null)

    const handleResizedBlob = (uri, uploadedFile, filename = undefined) => {
        const downloadName = filename || uploadedFile.name
        // Handle the resized image blob. The name gets dropped when resized, convert back to a file with a filename
        if (getFlagEnabled("WA-8278-fix-pt-attachments"))
            props.generateUrlAndUploadAttachment(
                new File([uri], downloadName, { type: uploadedFile.type }),
                downloadName,
                addImageToLightbox,
                resourceName
            )
        else
            props.generateUrlAndUploadAttachment(
                new Blob([uri], { type: uploadedFile.type }),
                uploadedFile.name,
                addImageToLightbox,
                resourceName
            )
    }

    // Start the process of uploading files to the server and generating their proxy URLs
    const handleUpload = files => {
        setIsLoading(true)

        // Although this is set up to iterate over multiple files, the operation breaks if
        // you upload more than one at a time. Until that's corrected, the <input type="file ...">
        // control in the toolbar is set to handle single uploads
        Array.from(files).forEach(async attachment => {
            if (
                getFlagEnabled("WA-7857-se-attachment-icons") &&
                attachment.type.indexOf("image/") >= 0 &&
                attachment.type !== "image/svg+xml" &&
                attachment.type !== "image/gif"
            ) {
                // If it's an image resize it. SVGs are actually XML so can't be resized. Skip gifs in case animated
                resizeImage(attachment, handleResizedBlob)
            } else {
                props.generateUrlAndUploadAttachment(attachment, attachment.name, addImageToLightbox, resourceName)
            }
        })
    }

    // After the upload is done, tell the modal to add the image to the gallery
    const addImageToLightbox = (proxyUrl, name, type) => {
        const updatedImages = images ? [...images] : []

        const uploadedImage = { url: proxyUrl, name, type }

        // Move the image to the front of the array, so that the change is easier to spot
        updatedImages.unshift(uploadedImage)
        setImageIndex(0)
        setIsLoading(false)

        props.onUpdate(updatedImages)
    }

    //////////////////////////////////////////////////////////////
    // Utility methods
    const TRUNCATED_FILENAME_LENGTH = 25

    // Make sure the specified filename doesn't include path information, and truncate it
    // if necessary

    // Remove the path and any query params to get to the filename. A character count can be specified
    // in order to truncate the filename and ensure that it fits cleanly at smaller screen sizes

    const formatFilename = (imagePath, characterCount = -1) => {
        if (props.forceHeader) return props.forceHeader
        if (!imagePath) return ""

        let filename

        // Remove the path, if any
        if (imagePath.lastIndexOf("/") > -1) {
            const filenameIndex = imagePath.lastIndexOf("/") + 1
            const queryParamsIndex = imagePath.indexOf("?")
            filename =
                queryParamsIndex > -1
                    ? imagePath.substring(filenameIndex, queryParamsIndex)
                    : imagePath.substring(filenameIndex)
        } else filename = imagePath

        // Truncate it if necessary. This operation keeps the extension, so the final name
        // will look something like: receipt_from_los_ange....jpg
        if (characterCount != -1 && filename.length > characterCount) {
            const ellipses = "..."
            let amountToTrim = filename.length - characterCount + ellipses.length
            let extension = ""
            if (filename.indexOf(".") > -1) {
                const filenameArray = filename.split(".")
                filename = filenameArray.slice(0, -1).join(".")
                extension = filenameArray[filenameArray.length - 1]
            }

            // Make sure that the remaining filename is long enough to trim - in an
            // extreme case, it could be that the extension is using up most the
            // characterCount allotment. This doesn't make the length perfect (it'll be
            // close enough) but it prevents an error
            if (amountToTrim > filename.length) amountToTrim = filename.length

            filename = filename.substring(0, filename.length - amountToTrim + 1) + ellipses + extension
        }

        return filename
    }

    const advanceImageLeft = () => {
        if (hasImages()) setImageIndex(imageIndex === 0 ? images.length - 1 : imageIndex - 1)
    }

    const advanceImageRight = () => {
        if (hasImages()) setImageIndex(imageIndex < images.length - 1 ? imageIndex + 1 : 0)
    }

    const hasImages = () => {
        // The images array and the imageIndex may not finish updating at the exact same moment
        // (for example after a delete), so both are checked
        return images && images.length > 0 && imageIndex > -1 && images.length > imageIndex
    }

    //////////////////////////////////////////////////////////////
    // Rendering

    const attachmentThumbnails = () => {
        if (!images) return null
        const iconStyle = { color: colorFunctionalWhite }
        return images.map((img, index) => {
            const thumb = getFileIconOrImage(img, iconStyle, "lightbox-thumbnail")
            return (
                <button
                    key={index}
                    className={`lightbox-thumbnail-button${imageIndex === index ? " lightbox-thumb-selected" : ""}`}
                    onClick={() => setImageIndex(index)}
                >
                    {thumb}
                </button>
            )
        })
    }

    const getDisplayImage = img => {
        if (!img || Object.keys(img)?.length === 0) return null
        if (img?.type?.startsWith("image/")) {
            return (
                <div className="lightbox-element-wrapper">
                    <img src={img.url} alt="Featured attachment" className="lightbox-featured-element" />
                </div>
            )
        }
        const iconStyle = { color: colorMiscGray80, height: 100, width: 100 }
        const displayImg = getFileIconOrImage(img, iconStyle)
        return (
            <div className="lightbox-element-wrapper">
                <div className="lightbox-element-wrapper-non-img">
                    <div>{displayImg}</div>
                    <div className="lightbox-filename">{`${img.name ?? "File Attachment"}`}</div>
                    {img?.url ? (
                        <IconButton
                            text="Download to Review"
                            onClick={downloadImage}
                            icon={__ => <IconDownload color={colorMiscGray80} height={16} />}
                            className="lightbox-non-img-download"
                        />
                    ) : (
                        <div>
                            There is an issue with this attachment and it cannot be downloaded. Make sure that the
                            initial upload completed.
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const lightboxModal = (
        <>
            {isLoading ? (
                <LoadingIndicator
                    active={true}
                    additionalClasses={"lightbox-loader"}
                    options={{ color: colorFunctionalGray10 }}
                />
            ) : null}
            <DeleteConfirmationModal deleteAction={confirmDelete} additionalClasses={"lightbox-alert-overlay"} />
            <ErrorModal additionalClasses={"lightbox-alert-overlay"} />
            <div className="lightbox-toolbar">
                <div className="buttons">
                    <Button onClick={close} id="toolbarBtn-Close" data-testid="toolbarBtn-Close">
                        <IconX />
                        <div className="toolbar-button-label">Close</div>
                    </Button>
                    <Button
                        onClick={uploadImg}
                        id="toolbarBtn-Upload"
                        data-testid="toolbarBtn-Upload"
                        disabled={!props.userCanEdit}
                    >
                        <IconUpload />
                        <div className="toolbar-button-label">Upload</div>
                    </Button>
                    <input
                        id="attachments"
                        data-testid="attachments-input"
                        type="file"
                        ref={uploadFileInput}
                        onChange={e => {
                            if (e.target.files) {
                                handleUpload(e.target.files)
                            }
                        }}
                        style={{ display: "none" }}
                    />
                    <ToolbarButtonSeparator />
                    <Button
                        onClick={downloadImage}
                        id="toolbarBtn-Download"
                        data-testid="toolbarBtn-Download"
                        disabled={!hasImages()}
                    >
                        <IconDownload />
                        <div className="toolbar-button-label">Download</div>
                    </Button>
                    <Button
                        onClick={() => {
                            if (hasImages()) props.openDeleteConfirmationModal()
                        }}
                        id="toolbarBtn-Delete"
                        data-testid="toolbarBtn-Delete"
                        disabled={!props.userCanEdit || !hasImages()}
                        style={{ color: colorAttentionRed60 }}
                    >
                        <IconTrash style={{ color: colorAttentionRed60 }} />
                        <div className="toolbar-button-label">Delete</div>
                    </Button>
                    <ToolbarButtonSeparator />
                    <Button
                        onClick={images.length === 1 ? downloadImage : downloadImages}
                        id="toolbarBtn-DownloadAll"
                        disabled={!hasImages()}
                    >
                        <IconDownloadAll />
                        <div className="toolbar-button-label">Download All</div>
                    </Button>
                </div>
                <span className="filename">
                    {hasImages() ? formatFilename(images[imageIndex].name, TRUNCATED_FILENAME_LENGTH) : ""}
                </span>
                <div className="nav-container">
                    <label className="nav-description">
                        {hasImages() ? `Viewing attachment ${imageIndex + 1} of ${images.length}` : null}
                    </label>
                    <NavArrows leftAction={advanceImageLeft} rightAction={advanceImageRight} />
                </div>
            </div>
            <div id="lightboxModal">
                <div className="lightbox-thumbnail-gallery">
                    <div className="lightbox-thumbnail-gallery-wrapper">
                        {getFlagEnabled("WA-7857-se-attachment-icons")
                            ? attachmentThumbnails()
                            : images
                            ? images.map((img, index) => (
                                  <button
                                      key={index}
                                      className="lightbox-thumbnail-button"
                                      onClick={() => setImageIndex(index)}
                                  >
                                      <img src={img.url} alt="Thumbnail" className="lightbox-thumbnail" />
                                  </button>
                              ))
                            : null}
                    </div>
                </div>
                {hasImages() ? (
                    <div className="lightbox-element-wrapper">
                        {getFlagEnabled("WA-7857-se-attachment-icons") ? (
                            getDisplayImage(images[imageIndex])
                        ) : (
                            <img
                                src={images[imageIndex].url}
                                alt="Featured attachment"
                                className="lightbox-featured-element"
                            />
                        )}
                    </div>
                ) : (
                    <div className="lightbox-placeholder-wrapper">
                        <IconShoveling className="lightbox-placeholder-icon" />
                        <div className="lightbox-placeholder-message-head">No attachments available.</div>
                        <div className="lightbox-placeholder-message-body">
                            Upload an attachment to view it here.
                        </div>
                    </div>
                )}
            </div>
        </>
    )

    return ReactDOM.createPortal(lightboxModal, document.body)
}

// TODO: It seems weird to check for the upload error message off of customForm, but that's where
// the generateUrlAndUploadAttachment function that we're using puts the error. It's not a bug,
// but I'd prefer to communicate it without having to go through customForm
const mapStateToProps = state => ({
    currentUser: userSelector(state),
    uploadErrorMessage: state.customForm.uploadErrorMessage,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            openErrorModal,
            openDeleteConfirmationModal,
            closeDeleteConfirmationModal,
            generateUrlAndUploadAttachment,
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentsLightboxModal)
