import {
    REQUEST_TIMEKEEPING_SIGNATURES_SUCCESS,
    REQUEST_TIMEKEEPING_SIGNATURES_FAILED,
    requestTimekeepingSignaturesActionTypes,
    requestTimekeepingSignaturesActionState,
} from "../actions/timekeeping-signatures"

const initialState: requestTimekeepingSignaturesActionState = {
    signatureUrl: "",
}

const requestTimekeepingSignatures = (
    state: requestTimekeepingSignaturesActionState = initialState,
    action: requestTimekeepingSignaturesActionTypes
): Record<string, any> => {
    switch (action.type) {
        case REQUEST_TIMEKEEPING_SIGNATURES_SUCCESS:
            if (action.payload.url) {
                return { ...state, signatureUrl: action.payload.url }
            }
            return state
        case REQUEST_TIMEKEEPING_SIGNATURES_FAILED:
            return Object.assign({}, state, {
                errors: action.payload.error,
            })
        default:
            return state
    }
}

export default requestTimekeepingSignatures
