import { costCodeControlColDefCreator } from "./standard-col-def-creators"

export const getCostCodeControlsDashboardSettings = () => {
    return {
        tableName: "Cost Code Controls",
        navId: "companyCrewTypes",
        resources: ["companyCrewTypes"],
        filters: [],
        enableSearchBar: true,
        colDefs: [
            costCodeControlColDefCreator({
                field: "/name",
                headerName: "Labor Type",
                hide: true,
                rowGroup: true,
            }),
            costCodeControlColDefCreator({
                headerName: "Cost Code Type",
                field: "/cost_code_control/name",
            }),
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: false,
                suppressCount: true,
            },
            autoGroupColumnDef: {
                headerName: "Labor Type",
                suppressMenu: true,
                sort: "asc",
                unSortIcon: true,
                cellRendererParams: {
                    suppressCount: true,
                },
                minWidth: 200,
                maxWidth: 400,
            },
        },
        otherSettings: {
            requiresCostCodeControlGrouping: true,
            groupBy: [
                {
                    cols: ["/name"],
                    label: "Labor Type",
                },
                {
                    cols: ["/cost_code_control/name"],
                    label: "Cost Code Type",
                },
            ],
            buttons: {
                cell: [],
                row: [],
                table: [
                    {
                        label: "Group By",
                        action: "groupBy",
                        icon: "group",
                    },
                ],
            },
        },
    }
}
