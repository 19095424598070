import React from "react"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { wsConnect, wsDisconnect } from "./actions"

type tWebSocketConnectionProps = {
    dispatch: Dispatch
    host?: string
    children: JSX.Element[]
}

const WebSocketConnection = (props: tWebSocketConnectionProps) => {
    const { dispatch, host } = props
    // only connect if we have a host - this makes it easier to control with flags
    if (host) {
        dispatch(wsConnect(host))
    } else {
        dispatch(wsDisconnect())
    }

    return <div>{props.children}</div>
}

export default connect()(WebSocketConnection)
