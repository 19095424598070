import { Selector } from "@rhumbix/rmbx_design_system_web"
import React, { useImperativeHandle, useState, forwardRef } from "react"
import { tSelectorOption } from "../../../SelectorV3/types"

type tProps = {
    value: string[]
    disableOptions: string[]
    choices: tSelectorOption[]
    rowIndex: number
    fixedOptions: string[]
}

const MultiChoiceCellEditor: React.FunctionComponent<tProps> = forwardRef((props: tProps, ref) => {
    const [currentValue, setCurrentValue] = useState(props.value)

    // This is required to have the cell editor pop out of the cell when the contents are too large for the cell
    useImperativeHandle(ref, () => {
        return {
            getValue: () => currentValue,
        }
    })

    const handleChange = (selectedValues: tSelectorOption[]) => {
        // we just want to save the user role string - so pull the value from the selection
        const newValue = selectedValues?.map(selection => selection.value)
        setCurrentValue(newValue)
    }

    return (
        <div className="multi-choice-editor" id={`multi-choice-editor-${props.rowIndex}`}>
            <Selector
                isClearable={true}
                innerRef={ref}
                isMulti={true}
                isDisabled={false}
                onChange={handleChange}
                openMenuOnFocus={true}
                options={props.choices}
                /**
                 * Filter the fixedOptions down to only ones that exist in the currentValue.
                 * Fixed options cannot be removed from the selector.
                 * When the selector is cleared, it resets to the fixedOptions,
                 * and we don't want to add a selection that didn't already exist
                 */
                fixedOptions={currentValue?.filter(choice => props?.fixedOptions?.includes(choice))}
                initialSelectedOption={props.choices.filter(choice => currentValue?.includes(choice.value))}
            />
        </div>
    )
})

export default MultiChoiceCellEditor
