import { socketMessageReceived, wsConnected, wsDisconnected } from "../websockets/actions"
import { WS_CONNECT, WS_DISCONNECT } from "../websockets/types"
import { getAuthHeaders } from "../websocket-utils"

const socketMiddleware = () => {
    let socket = null
    const onOpen = store => event => {
        store.dispatch(wsConnected(event.target.url, socket))
    }
    const onClose = store => event => {
        // if a container rolls over or for some reason the connection dies - try to reconnect
        if (!event.wasClean) {
            setTimeout(() => connectSocket(event.currentTarget.url, store), 2000)
        } else {
            store.dispatch(wsDisconnected())
        }
    }
    const onMessage = store => event => {
        store.dispatch(socketMessageReceived(event))
    }

    const connectSocket = (host, store, isGuest = false) => {
        if (socket !== null) {
            // if we already have a socket open for the requested host, we don't need another one
            // if it's a new host - close the existing and we will open a new one
            if (socket.url !== host) socket.close()
        }
        // connect to the remote host
        socket = new WebSocket(host, getAuthHeaders(isGuest))
        // websocket handlers
        socket.onclose = onClose(store)
        socket.onopen = onOpen(store)
        socket.onmessage = onMessage(store)
        socket.keepAlive = true
    }
    // the middleware part of this function
    return store => next => action => {
        switch (action.type) {
            case WS_CONNECT:
                connectSocket(action.host, store, !!action.isGuest)
                break
            case WS_DISCONNECT:
                if (socket !== null) {
                    socket.close()
                }
                socket = null
                break
            default:
                // this is middleware, so we need to pass the action along if it isn't the one we want
                return next(action)
        }
    }
}
export default socketMiddleware()
