import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
/** Components */
import { GroupedSelector, Selector, TitleAndDetailsSelectorOption } from "@rhumbix/rmbx_design_system_web"
import SelectorOption from "../Selector/Option"
/** Utils */
import { autocompleteSearch } from "../api/searchV3"
/** Resources */
import {
    detailedContentForCustomFormStores,
    detailedContentForWorkShifts,
    referenceablesToValueFormatters,
} from "../common/referenceable-value-formatters"
import { getValueFormatter } from "../common/ag-grid-value-formatters"
/** Types */
import { tSelectCacheProps, tSelectorOption, tSelectorProps } from "./types"
import { getFlagEnabled } from "../getFlagValue"
import { projectsSelector } from "../selectors"

type tProps = {
    projects: Array<Record<string, any>>
}

/**
 * Wrapper component for the design system Selector component
 *
 * @param id string The id of the component
 *
 * @param groupHeaderFormatter tValueFormatter For grouped selectors, a value formatter that formats the text
 *      of each group header. The presence of this formatter is what dictates
 *      whether this is a grouped or regular selector
 * @param isClearable boolean  Determines whether the selector options can be cleared using a "clear" button
 * @param isDisabled boolean Sets the disabled state
 * @param isMulti boolean Sets whether this is a single- or multi-select selector
 * @param maxHeight string The maximum height of the selector. Applies styling to add a scroll bar for overflow.
 *      Values should be CSS-friendly like "150px".
 * @param name string The name of the selector
 * @param onAction the action to perform when an action option is selected.
 * @param openMenuOnFocus boolean Whether to default the menu to open when the field is focused
 * @param optionList (Record<string, any> | tSelectorOption)[] A set of static options to display in the selector.
 *      If this is provided, the selector will only display the options it's given (for example on Project Status,
 *      it might display "Inactive" or "Active"). Otherwise, it will populate itself by looking up entities of the
 *      specified resourceName from the backend (for example, an Employees selector will load the employees
 *      that it displays)
 * @param actionOptions An optional array of actions to prepend to the options list
 * @param resourceName string   The name of the resource to load. If the selector doesn't get an optionList,
 *      it will look up entities of this resource type from the backend
 * @param shouldClearCache boolean Set this to "true" to clear any options
 *      it has loaded, when prompted by updateClearCache. For example,
 *      a Projects filter may have a set of Projects loaded, but if the Project Status filter is changed,
 *      that will impact which Projects we want to display - so it's time to clear the cache and start over
 * @param updateClearCache boolean A callback function that is called after the cache is cleared.
 *      Typically it is used to set shouldClearCache back to false
 * @param valueFormatter tValueFormatter Can be used to specify a valueFormatter to use in place of the default one
 *      for this resource
 * @param placeholder string    The placeholder text to display in the selector when it has no selections
 * @returns A Selector component configured as specified
 */
const SelectorWrapper: React.FC<tSelectorProps & tProps> = props => {
    const {
        id,
        CustomOption,
        groupHeaderFormatter,
        isClearable,
        isCreatable,
        isDisabled,
        isLoading: propsIsLoading,
        isMulti,
        maxHeight,
        name,
        onAction,
        onCreateOption,
        openMenuOnFocus,
        optionList,
        actionOptions,
        projects, // The Projects selector - do not pass this as a param
        resourceName,
        shouldClearCache,
        updateClearCache,
        valueFormatter,
        placeholder,
    } = props
    const value = props.value ?? (isMulti ? [] : {})
    const resourceFormatters = referenceablesToValueFormatters[resourceName]

    // Some selectors may not have a titleFormatter or valueFormatter specified. For example, if this dropdown
    // displays enums and is not connected to a resource, it will not have a formatter defined. In that case,
    // this formatter will display the name, which is the only value the options probably have
    const defaultValueFormatter = getValueFormatter(["/name"])
    const styles = maxHeight
        ? {
              control: (baseStyles: Record<string, any>) => ({ ...baseStyles, maxHeight }),
              valueContainer: (baseStyles: Record<string, any>) => {
                  return {
                      ...baseStyles,
                      maxHeight: "inherit",
                      overflowY: "auto",
                      marginRight: "7px",
                      scrollbarWidth: "thin",
                      paddingLeft: 4,
                  }
              },
              multiValue: (baseStyles: Record<string, any>) => {
                  return { ...baseStyles, padding: 7, backgroundColor: "rgb(231, 239, 249)", margin: 3 }
              },
          }
        : {}

    let initialSelectedOption
    if (isMulti) {
        initialSelectedOption = value.map((item: Record<string, any>) => {
            const keyValue = resourceFormatters?.titleFormatter?.({ value: item })
            // Get a matching option in the event that this is a multiselect enum
            const matchingOption = optionList?.find(op => op.data.name === item.name)
            /**
             * If "options" attribute exists, React Select will treat it as the option's categories.
             * Our "projects" data has the "options" attribute. This will cause an error.
             * So we are storing the original data under "data" and access it after selecting an option.
             * Also, attempt to use the item's id if it's a referenceable in order to make the values unique
             * and avoid issues where two options have the same value.
             */
            return {
                data: item,
                value: item.id || keyValue || matchingOption?.value,
                label: keyValue || matchingOption?.label,
            }
        })
    } else {
        // Try to use the specified valueFormatter before resorting to the default formatter for this resource
        const keyValue = getFlagEnabled("WA-7649-web-transforms")
            ? (valueFormatter && valueFormatter({ value })) || resourceFormatters?.titleFormatter?.({ value })
            : resourceFormatters?.titleFormatter?.({ value }) || (valueFormatter && valueFormatter({ value }))

        if (keyValue) {
            initialSelectedOption = { data: value, value: value, label: keyValue }
        } else {
            // initialSelectedOption needs to be null if there is no initial value
            // in order for the placeholder to be displayed
            initialSelectedOption = null
        }
    }
    // List options.
    const [options, setOptions] = useState<Array<tSelectorOption | Record<string, any>>>([])
    // Select loading status.
    const [isLoading, setIsLoading] = useState<boolean>(false)
    // Search input value.
    const [inputValue, setInputValue] = useState<string>("")
    const [debouncedInputValue, setDebouncedInputValue] = useState<string>("")
    // Cache to store searched results.
    const [cache, setCache] = useState<Record<string, tSelectCacheProps>>({})
    // Whether is at the bottom of the option list, for pagination purpose.
    const [isListBottom, setIsListBottom] = useState(false)
    // Whether the dropdown menu is open.
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const inputRef = useRef("")
    inputRef.current = inputValue

    /** Get option list based on the inputValue. Use the cached result if exists. */
    useEffect(() => {
        const cachedResult = cache[debouncedInputValue]
        if (cachedResult) updateOptions(cachedResult.options)
        else if (isMenuOpen) getOptions()
    }, [debouncedInputValue])

    /** Get more options if scrolling to the bottom of the option list. */
    useEffect(() => {
        if (isListBottom) getOptions()
        else setIsListBottom(false)
    }, [isListBottom])

    /** If any other filters have changed in the Side Rail, reset cache. */
    useEffect(() => {
        if (shouldClearCache && updateClearCache) {
            setCache({})
            updateClearCache(false)
        }
    }, [shouldClearCache])

    useEffect(() => {
        // We don't need to trigger off an update to the debounced
        // input value if it's already the same as the regular one
        if (inputValue === debouncedInputValue) {
            return
        }
        // Set the debounced value after 500ms, which
        // will trigger a re-render and result in
        // an API call to fetch options
        const timerId = setTimeout(() => {
            setDebouncedInputValue(inputValue)
        }, 500)

        // Return a clearTimeout to cancel the timer if
        // this component is re-rendered, skipping setting
        // the debounced value
        return () => clearTimeout(timerId)

        // This uses inputValue instead of debouncedInputValue
        // because we need to queue up debouncedInputValue changes
        // every time inputValue changes
    }, [inputValue])

    /**
     * If the groupHeaderFormatter prop is set, the selector should be grouped.
     * This function takes the set of options and performs
     * the grouping based on the string returned by groupHeaderFormatter
     *
     * @param options The options to group, using the groupHeaderFormatter property that was set in props
     */
    const groupOptions = (options: any) => {
        const groupedOptions: Record<string, any> = {}

        options.forEach((option: tSelectorOption, index: number) => {
            if (groupHeaderFormatter) {
                let formattedOption
                // Get the formatted value of the field on which we're grouping
                const groupHeaderText = groupHeaderFormatter({ value: option.data, context: props.context })

                // Build the option display
                if (resourceName === "workShifts") {
                    formattedOption = detailedContentForWorkShifts(option, projects)
                } else if (resourceName === "companyFormStores") {
                    formattedOption = detailedContentForCustomFormStores(option)
                }

                if (!groupedOptions[groupHeaderText])
                    // If this is the first instance of a new group, create that group
                    // in the format the selector expects
                    groupedOptions[groupHeaderText] = {
                        label: groupHeaderText,
                        value: `group_${index}`,
                        options: [formattedOption],
                    }
                // ... and otherwise, just push it onto the array
                else groupedOptions[groupHeaderText].options.push(formattedOption)
            }
        })

        return Object.values(groupedOptions)
    }

    // Wraps around the basic setter for options. If this is a grouped selector, organize the options
    // into their groups
    const updateOptions = (options: Array<tSelectorOption | Record<string, any>>) => {
        if (actionOptions) {
            options = [...actionOptions, ...options]
        }

        getFlagEnabled("WA-7649-web-transforms") && groupHeaderFormatter
            ? setOptions(groupOptions(options))
            : setOptions(options)
    }

    /**
     * This function gets passed to autocompleteSearch, once the API returns, it will first check
     * if the inputValue that it was working with is current, if so, call setOptions
     * @param loadedOptions the options returned by the API
     * @param oldInputValue the inputValue that autocompleteSearch had at the time of it's invocation
     */
    const setOptionsCheck = (
        loadedOptions: Array<tSelectorOption | Record<string, any>>,
        oldInputValue: string
    ) => {
        if (oldInputValue === inputRef.current) {
            updateOptions(loadedOptions)
        }
    }

    /** Fetch list options. */
    const getOptions = () => {
        const cachedResult = cache[debouncedInputValue]
        // Check if there are more options to be fetched.
        if (cachedResult && !cachedResult.hasMore) return
        setIsLoading(true)
        const currentTotal = cachedResult?.options?.length || 0

        autocompleteSearch(
            props.dispatch,
            {
                setCache,
                setIsLoading,
                setIsListBottom,
                setOptions,
                setOptionsCheck,
            },
            {
                cache,
                extraFilters: props.filters,
                idsToExclude: props.idsToExclude,
                inputValue: debouncedInputValue,
                resourceName: props.resourceName,
                total: currentTotal,
                disableInactive: props.disableInactive,
            }
        )
    }

    /** Callback invoked when dropdown menu opens. */
    const onMenuOpen = () => {
        setIsMenuOpen(true)
        getOptions()
    }

    /** Callback invoked when dropdown menu closes. */
    const onMenuClose = () => {
        const { onMenuCloseCallBack } = props
        setIsMenuOpen(false)
        if (onMenuCloseCallBack) onMenuCloseCallBack()
    }

    /**
     * Update search input state.
     * @param {string} search New input field.
     */
    const onInputChange = (search: any, action: any) => {
        if (
            // If we set the input value to any incoming search, the user's search string
            // will clear every time they make another selection, whether they want it to or not.
            // It should only clear when the menu closes
            action.action === "input-change" ||
            action.action === "menu-close"
        )
            setInputValue(search)
    }

    /**
     * Fetch more list options after scrolling to the bottom of the current list.
     * Notes:
     * - onMenuScrollToBottom doesn't have access to the current states.
     * - Use "isListBottom" to trigger "getOptions".
     */
    const onMenuScrollToBottom = () => setIsListBottom(true)

    /**
     * Callback invoked after adding/removing a selected option.
     * Pass in the original data from the "data" attribute.
     * @param {Object[]|Object} selectedOption Selected option.
     */
    const onChange = (selectedOption: any) =>
        props.onChange(
            isMulti ? selectedOption.map((option: Record<string, any>) => option.data) : selectedOption?.data
        )

    const selectorOptionConfig = {
        context: props.context,
        prefixFormatter: props.prefixFormatter,
        primarySubtitleFormatter: props.primarySubtitleFormatter,
        secondarySubtitleFormatter: props.secondarySubtitleFormatter,
        titleFormatter: props.titleFormatter ?? defaultValueFormatter,
        valueFormatter: props.valueFormatter ?? defaultValueFormatter,
    }

    // If a set of static options is passed to the selector, the data may not be
    // formatted in the way that the control expects. Take care of that here
    const formatOptionList = (
        optionList: Array<tSelectorOption | Record<string, any>>
    ): Array<tSelectorOption | Record<string, any>> => {
        if (Array.isArray(optionList) && optionList.length) {
            const formattedOptionList = optionList.map(option => {
                if (option.value && option.label && !option.data) {
                    option.data = { name: option.label }
                }
                return option
            })

            return formattedOptionList
        }

        return optionList
    }

    const loading = getFlagEnabled("WA-7926-rr-filter-save")
        ? propsIsLoading || isLoading || inputValue !== debouncedInputValue
        : isLoading || inputValue != debouncedInputValue

    // Provide a list of options.
    if (optionList) {
        const optionListFormatted = formatOptionList(optionList)
        // This version of the selector defaults to true for openMenuOnFocus
        const isMenuOpenOnFocus = openMenuOnFocus ?? true
        return (
            <Selector
                id={id}
                CustomOption={CustomOption}
                fixedOptions={props.fixedOptions}
                isClearable={isClearable}
                isCreatable={isCreatable}
                isDisabled={isDisabled}
                initialSelectedOption={initialSelectedOption}
                innerRef={props.innerRef}
                isMulti={isMulti}
                onMenuClose={onMenuClose}
                onAction={onAction}
                onChange={onChange}
                onCreateOption={onCreateOption}
                openMenuOnFocus={isMenuOpenOnFocus}
                options={optionListFormatted as tSelectorOption[]}
                SelectorOption={SelectorOption}
                selectorOptionConfig={selectorOptionConfig}
                placeholder={placeholder || "Showing All..."}
                styles={styles ?? {}}
            />
        )
    }

    return getFlagEnabled("WA-7649-web-transforms") && groupHeaderFormatter ? (
        <GroupedSelector
            id={id}
            CustomOption={CustomOption}
            fixedOptions={props.fixedOptions}
            isClearable={isClearable}
            isCreatable={isCreatable}
            isDisabled={isDisabled}
            initialSelectedOption={initialSelectedOption}
            inputValue={inputValue}
            isLoading={loading}
            isMulti={isMulti}
            name={name}
            onAction={onAction}
            onChange={onChange}
            onCreateOption={onCreateOption}
            onInputChange={onInputChange}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            onMenuScrollToBottom={onMenuScrollToBottom}
            openMenuOnFocus={openMenuOnFocus}
            options={options as tSelectorOption[]}
            innerRef={props.innerRef}
            selectorOptionConfig={selectorOptionConfig}
            SelectorOption={TitleAndDetailsSelectorOption}
            placeholder={placeholder || "Showing All..."}
            styles={styles ?? {}}
        />
    ) : (
        <Selector
            id={id}
            CustomOption={CustomOption}
            fixedOptions={props.fixedOptions}
            isClearable={isClearable}
            isCreatable={isCreatable}
            isDisabled={isDisabled}
            initialSelectedOption={initialSelectedOption}
            inputValue={inputValue}
            isLoading={loading}
            isMulti={isMulti}
            name={name}
            onAction={onAction}
            onChange={onChange}
            onCreateOption={onCreateOption}
            onInputChange={onInputChange}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            onMenuScrollToBottom={onMenuScrollToBottom}
            openMenuOnFocus={openMenuOnFocus}
            options={options as tSelectorOption[]}
            innerRef={props.innerRef}
            SelectorOption={SelectorOption}
            selectorOptionConfig={selectorOptionConfig}
            styles={styles ?? {}}
            placeholder={placeholder || "Showing All..."}
        />
    )
}

const mapStateToProps = (state: any) => {
    return {
        projects: projectsSelector(state),
    }
}

export default connect(mapStateToProps)(SelectorWrapper)
