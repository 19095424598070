import {
    analyticsDashboardColDefCreator,
    stringColDefCreator,
    userRoleChoiceColDefCreator,
    stringEmptyColDefCreator,
} from "./standard-col-def-creators"

export const getAnalyticsSettings = () => {
    return {
        tableName: "Analytics Dashboards",
        navId: "analytics",
        resources: ["companyAnalyticsDashboards"],
        filters: [],
        relatedNames: {
            companyAnalyticsDashboards: {
                analyticsDashboards: ["/analytics_dashboard"],
            },
        },
        colDefs: [
            {
                ...analyticsDashboardColDefCreator({
                    editable: false,
                }),
            },
            stringColDefCreator({
                headerName: "Description",
                field: "/description",
                editable: false,
                referenceableMap: {
                    referenceableKey: "analyticsDashboards",
                    rowKey: "analytics_dashboard",
                },
            }),
            stringEmptyColDefCreator({
                headerName: "Web",
                field: "/url",
                editable: false,
                referenceableMap: {
                    referenceableKey: "analyticsDashboards",
                    rowKey: "analytics_dashboard",
                },
            }),
            stringEmptyColDefCreator({
                headerName: "Tablet",
                field: "/tablet_url",
                editable: false,
                referenceableMap: {
                    referenceableKey: "analyticsDashboards",
                    rowKey: "analytics_dashboard",
                },
            }),
            stringEmptyColDefCreator({
                headerName: "Mobile",
                field: "/mobile_url",
                editable: false,
                referenceableMap: {
                    referenceableKey: "analyticsDashboards",
                    rowKey: "analytics_dashboard",
                },
            }),
            userRoleChoiceColDefCreator({
                headerName: "Allowed Roles",
                field: "/allowed_roles",
                attribute: "allowed_roles",
                editable: true,
            }),
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: true,
            },
            rowSelection: "multiple",
            // allow our click events to happen
            suppressRowClickSelection: true,
        },
        otherSettings: {
            pageTitle: "Analytics Dashboards",
            subcontent:
                "Material includes any building materials used at the company. " +
                "Add materials by uploading a .CSV file or manually typing in the material information.",
            rowLevelErrorDisplay: true,
            enableSearchBar: true,
        },
    }
}
