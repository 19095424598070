import React from "react"
import { tRoute } from "../../router/types"
import { tErrorFilterDef, tFilterDef, tFilterState } from "../types"
import { removeFilterDefByKey, requiredFilterValidator } from "../utils"
import SavedFilterSetController from "./SavedFilterSetController"
import "./WrapFilterComponents.less"

type tProps = {
    filterState: tFilterState
    filterDefs: Array<tFilterDef | tErrorFilterDef>
    route: tRoute
}

type tErrorDivProps = {
    msg: string
    index: number
}

type tCollectFiltersProps = {
    filterDefs: Array<tFilterDef | tErrorFilterDef>
    route: tRoute
}

const ErrorDiv = (props: tErrorDivProps) => {
    const { msg, index } = props
    return (
        <div key={`fError_${index}`} className="filter-required-error">
            {msg}
        </div>
    )
}

/**
 * Takes in an array of filterDefs and returns an array of filter components
 */
const getComponentsFromFilterDefs = (props: tCollectFiltersProps): Array<JSX.Element> => {
    const { filterDefs, route } = props
    const filterComponents = Array.isArray(filterDefs)
        ? filterDefs.map((errorOrFilterSettings: tFilterDef | tErrorFilterDef) => {
              // The `any` casting fixes a weird typescript conflict
              const Component = errorOrFilterSettings.component as any

              return errorOrFilterSettings.key === "error" ? (
                  <Component className="filterBar-filter" key={`error_${new Date()}`} />
              ) : (
                  <Component
                      className="filterBar-filter"
                      primaryKey={errorOrFilterSettings.key}
                      route={route}
                      {...errorOrFilterSettings}
                  />
              )
          })
        : []
    return filterComponents
}

/**
 * Takes in the filter stage, filterDefs and a route
 * Passes the filterDefs and the route to getComponentsFromFilterDefs
 * Wrap the components that getComponentsFromFilterDefs returns with labels, and divs to control styling
 */
export const WrapFilterComponents = (props: tProps): JSX.Element => {
    const { filterState, filterDefs, route } = props
    const filterDefsNoErrors = removeFilterDefByKey(filterDefs, "error") as Array<tFilterDef>

    return (
        <>
            <SavedFilterSetController filterState={filterState} filterDefs={filterDefs} path={props.route.path} />
            {getComponentsFromFilterDefs({ filterDefs, route }).map((filter, index) => {
                const filterDef = filterDefsNoErrors.filter(item => item.key == filter.key)
                const filterRequiredNotSet =
                    filterDef.length === 1 ? !requiredFilterValidator(filterState, filterDef[0]) : false
                const isBoolFilter = filter.type.displayName === "Connect(BooleanFilter)"
                return (
                    <div key={`fWrapper_${index}`} className="filter-wrapper">
                        <div
                            key={`fDiv_${index}`}
                            className={`filter-section ${filterRequiredNotSet ? "filter-required-border" : ""}`}
                        >
                            <label
                                key={`fLabel_${index}`}
                                className={
                                    `${filterRequiredNotSet ? "filter-required-label" : ""}` +
                                    `${isBoolFilter ? "boolean-filter-wrapper" : ""}`
                                }
                                htmlFor={`filter-${filter.key}`}
                            >
                                {!filter.props
                                    ? "No Label"
                                    : filterRequiredNotSet
                                    ? filter.props.label + " (Required)"
                                    : filter.props.label}
                            </label>
                            {filter}
                        </div>
                        {filterRequiredNotSet ? (
                            <ErrorDiv msg="This field is required to view this page." index={index} />
                        ) : null}
                    </div>
                )
            })}
        </>
    )
}
