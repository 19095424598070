/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react"
import { getCellValue } from "../../../common/ag-grid-utils"

class SignatureRenderer extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const value = getCellValue(this.props)
        return value ? (
            <div className="signature-renderer">
                {/* // TODO: Not clear why this label is sitting here with no control nearby - it must be
                // attached to the tooltip that displays the signature, but I doubt that's a form control
                // that could be connected to a label. Maybe this should be a span? */}
                <label className="signature-signed">Signed</label>
            </div>
        ) : (
            <div />
        )
    }
}
export default SignatureRenderer
