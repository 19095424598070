const initialState = {
    projectMaterials: {},
}

export default function projectMaterials(state = initialState, action) {
    switch (action.type) {
        case "FETCH_PROJECT_MATERIALS_SUCCESS":
            return Object.assign({}, state, { projectMaterials: action.data })
        default:
            return state
    }
}
