const initialState = {
    tooltipPayload: null,
    tooltipType: null,
    customTooltipXPosition: 0,
    customTooltipYPosition: 0,
    customTooltipClasses: "",
}

export default function customTooltipReducer(state = initialState, action) {
    switch (action.type) {
        case "CUSTOM_TOOLTIP_UPDATE": {
            return Object.assign({}, state, {
                tooltipPayload: action.data.tooltipPayload,
                tooltipType: action.data.tooltipType,
                customTooltipYPosition: action.data.yPosition,
                customTooltipXPosition: action.data.xPosition,
                customTooltipClasses: action.data.customClasses,
            })
        }
        default: {
            return state
        }
    }
}
