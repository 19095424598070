import {
    slideToggleColDefCreator,
    colorPickerColDefCreator,
    numberColDefCreator,
    checkboxColDefCreator,
    lengthLimitedColDefCreator,
} from "./standard-col-def-creators"
import { ValueSetterParams } from "ag-grid-community"
import { tContext } from "../types"
import SliderCellEditor from "../cell-editors/slider-cell-editor"
import LockedDisabledStringRenderer from "../cell-renderers/locked-disabled-string-renderer"
import { tResourceObject } from "../../../dashboard-data/types"
import { loadEntities } from "../../../cached-data/actions"
import { AnyAction } from "redux"
import { ReduxState } from "../../../common/types"
import { ThunkDispatch } from "redux-thunk"
import { update_current_user } from "../../../actions"

export const getTimekeepingStatusSettings = () => {
    const slideToggleDefaults = {
        cellEditorParams: {
            onText: "Allowed",
            offText: "View Only",
            isListValueType: true,
        },
        cellEditor: SliderCellEditor,
        field: "/can_edit_cell",
        isListValueType: true,
        onText: "Allowed",
        offText: "View Only",
        sortable: false,
        editable: (params: Record<string, any>) => !params?.data.built_in_status_id,
        maxWidth: 250,
        valueSetter: (params: ValueSetterParams) => {
            params.data.can_change_to_status = params.newValue
            params.data.can_edit_cell = params.newValue
            return true
        },
    }

    return {
        tableName: "Timekeeping Statuses",
        navId: "timekeepingStatuses",
        resources: ["timekeepingStatuses"],
        additionalQueryParams: {
            include_item_count: true,
        },
        filters: [],
        colDefs: [
            checkboxColDefCreator({
                rowDrag: true,
                width: 100,
                headerCheckboxSelection: false,
            }),
            lengthLimitedColDefCreator({
                headerName: "Name",
                maxLength: 32,
                width: 225,
                cellRenderer: LockedDisabledStringRenderer,
                field: "/label",
                editable: (params: Record<string, any>) => !params.data.built_in_status_id,
                shouldPreventActionFocus: true,
            }),
            numberColDefCreator({
                field: "/sort_order",
                default: 0,
                hide: true,
            }),
            colorPickerColDefCreator({
                headerName: "Color",
                field: "/selected_color",
                valueSetter: () => true,
            }),
            slideToggleColDefCreator({
                headerName: "Admin",
                onValue: "ADMIN",
                ...slideToggleDefaults,
                cellEditorParams: { ...slideToggleDefaults.cellEditorParams, onValue: "ADMIN" },
            }),
            slideToggleColDefCreator({
                headerName: "Payroll Admin",
                onValue: "PAYROLL_ADMIN",
                ...slideToggleDefaults,
                cellEditorParams: { ...slideToggleDefaults.cellEditorParams, onValue: "PAYROLL_ADMIN" },
            }),
            slideToggleColDefCreator({
                headerName: "PM",
                onValue: "PM",
                ...slideToggleDefaults,
                cellEditorParams: { ...slideToggleDefaults.cellEditorParams, onValue: "PM" },
            }),
            slideToggleColDefCreator({
                headerName: "Foreman",
                onValue: "FOREMAN",
                ...slideToggleDefaults,
                cellEditorParams: { ...slideToggleDefaults.cellEditorParams, onValue: "FOREMAN" },
            }),
            slideToggleColDefCreator({
                headerName: "Office Staff",
                onValue: "OFFICE_STAFF",
                ...slideToggleDefaults,
                cellEditorParams: { ...slideToggleDefaults.cellEditorParams, onValue: "OFFICE_STAFF" },
            }),
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: true,
                filter: true,
                sortable: false,
            },
            rowDragManaged: true,
        },
        otherSettings: {
            rowDragField: "sort_order",
            updateReferenceableData: true,
            customSaveCallback: (
                dispatch: ThunkDispatch<ReduxState, null, AnyAction>,
                context: tContext,
                updatedRowData: tResourceObject
            ) => {
                // For the timekeeping status table, we need to update the user object's allowed statuses
                // (maybe) if we've messed with something so that the changes are immediately visible without
                // a /me call
                const { employee } = context.currentUser
                if (!employee.allowed_tk_statuses) return
                const newAllowedStatuses = employee.allowed_tk_statuses
                if (
                    updatedRowData.can_edit_cell.includes(employee.user_role) &&
                    !employee.allowed_tk_statuses.includes(updatedRowData.name)
                ) {
                    newAllowedStatuses.push(updatedRowData.name)
                } else if (
                    !updatedRowData.can_edit_cell.includes(employee.user_role) &&
                    employee.allowed_tk_statuses.includes(updatedRowData.name)
                ) {
                    newAllowedStatuses.splice(newAllowedStatuses.indexOf(updatedRowData.name), 1)
                }

                dispatch(
                    update_current_user({
                        ...context.currentUser,
                        employee: { ...context.currentUser.employee, allowed_tk_statuses: newAllowedStatuses },
                    })
                )

                // We also need to update the referenceable table
                dispatch(
                    loadEntities({
                        [context.settings.resources[0]]: {
                            // @ts-ignore
                            ...context.referenceableData[context.settings.resources[0]],
                            [updatedRowData.id as number]: updatedRowData,
                        },
                    })
                )
            },
            hiddenColumnDefaults: {
                sort_order: {
                    value: 0,
                },
            },
            buttons: {
                cell: [],
                row: [
                    {
                        label: "Hide Status",
                        icon: "hide",
                        action: "deleteStatus",
                        disabled: (context: tContext) =>
                            context.selectedRows.length > 1 ||
                            context.selectedRows[0].is_custom ||
                            ["PENDING", "SYNCED"].includes(context.selectedRows[0].name) ||
                            context.currentUser.employee.user_role !== "ADMIN" ||
                            context.selectedRows[0].is_hidden,
                        tooltip: (context: tContext) =>
                            ["PENDING", "SYNCED"].includes(context.selectedRows[0].name)
                                ? "You may not hide this status"
                                : context.selectedRows.length > 1 || context.selectedRows[0].is_custom
                                ? "You may not hide custom statuses"
                                : context.currentUser.employee.user_role !== "ADMIN"
                                ? "Only Admins can perform this operation"
                                : context.selectedRows[0].is_hidden
                                ? "This status is already hidden"
                                : "Hide the selected status",
                    },
                    {
                        label: "Unhide Status",
                        icon: "view",
                        action: "unhideStatus",
                        disabled: (context: tContext) =>
                            context.currentUser.employee.user_role !== "ADMIN" ||
                            context.selectedRows.length > 1 ||
                            context.selectedRows[0].is_custom ||
                            !context.selectedRows[0].is_hidden,
                        tooltip: (context: tContext) =>
                            context.selectedRows.length > 1
                                ? "Please select a single row"
                                : context.selectedRows[0].is_custom
                                ? "You may not unhide custom statuses"
                                : context.currentUser.employee.user_role !== "ADMIN"
                                ? "Only Admins can perform this operation"
                                : !context.selectedRows[0].is_hidden
                                ? "This row is not hidden"
                                : "Unhide the selected status",
                    },
                    {
                        label: "Delete Status",
                        icon: "delete",
                        action: "deleteStatus",
                        disabled: (context: tContext) =>
                            context.selectedRows.length > 1 ||
                            !context.selectedRows[0].is_custom ||
                            context.currentUser.employee.user_role !== "ADMIN",
                        tooltip: (context: tContext) =>
                            context.selectedRows.length > 1 || !context.selectedRows[0].is_custom
                                ? "You may not delete built-in statuses"
                                : context.currentUser.employee.user_role !== "ADMIN"
                                ? "Only Admins can perform this operation"
                                : "Delete the selected status",
                    },
                ],
                table: [
                    {
                        label: "Create Status",
                        icon: "add",
                        action: "addNewRow",
                        disabled: (context: tContext) => context.currentUser.employee.user_role !== "ADMIN",
                        tooltip: (context: tContext) =>
                            context.currentUser.employee.user_role !== "ADMIN"
                                ? "Only Admins can perform this operation"
                                : "Create a new timekeeping status",
                    },
                ],
            },
        },
    }
}
