import React, { Component } from "react"
import styled from "styled-components"
import {
    colorAttentionBlue60,
    colorAttentionRed60,
    IconCicoDefault,
    IconCicoDeficit,
    IconCicoExcess,
} from "@rhumbix/rmbx_design_system_web"
import { connect } from "react-redux"
import { getCellValue, isGroupRow } from "../../../common/ag-grid-utils"

class CicoEmployeeCellRenderer extends Component {
    refresh() {
        return true
    }

    render() {
        // value should never be undefined, but occasionally it is (probably while the page
        // is still loading), so catch that here
        if (!this.props.value) {
            return <div className="group-cell"></div>
        }

        const isGroup = isGroupRow(this.props)

        const value = getCellValue(this.props)
        const title = this.props.valueFormatter({ ...this.props, value })
        const subtitle = this.props.primarySubtitleFormatter({ ...this.props, value })
        const CicoIcon =
            value.cicoDiff === 0 ? IconCicoDefault : value.cicoDiff > 0 ? IconCicoExcess : IconCicoDeficit
        const cicoIconColor = value.cicoDiff >= 0 ? colorAttentionBlue60 : colorAttentionRed60
        const cicoTextColor = value.cicoDiff >= 0 ? colorAttentionBlue60 : colorAttentionRed60
        const formattedTime = (value.cicoDiff / 60).toFixed(2)

        return (
            <CellContainer className="group-cell">
                <div>
                    <div className="group-cell-title">{title}</div>
                    <Subtitle className="group-cell-text">
                        <span>{subtitle}</span>
                        {value.cicoDiff !== undefined && (
                            <CicoGapValue
                                data-testid={"employee-picker-cico-diff"}
                                style={{ color: cicoTextColor, marginLeft: isGroup ? 45 : 10 }}
                            >
                                <CicoIcon width={12} color={cicoIconColor} style={{ marginRight: 3 }} />
                                {value.cicoDiff > 0 ? `+${formattedTime}` : formattedTime}
                            </CicoGapValue>
                        )}
                    </Subtitle>
                </div>
            </CellContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        cicoExceptions: state.searchBar.cicoExceptions,
        // We need this so as to force a redraw when the value changes
        cicoExceptionValue: state.searchBar.cicoExceptionValue,
    }
}

export default connect(mapStateToProps)(CicoEmployeeCellRenderer)

const CellContainer = styled.div`
    width: 100%;
`

const Subtitle = styled.div`
    display: flex;
    justify-content: space-between;
`

const CicoGapValue = styled.span`
    margin-left: 10px;
`
