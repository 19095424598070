import { iCompanyAbsenceTypes } from "../cached-data/types"
import { CompanyAbsenceTypesActionTypes } from "./types"

export type tCompanyAbsenceTypesState = {
    companyAbsenceTypes: iCompanyAbsenceTypes[]
}

const initialState: tCompanyAbsenceTypesState = {
    companyAbsenceTypes: [],
}

export default function companyAbsenceTypes(
    state: tCompanyAbsenceTypesState = initialState,
    action: CompanyAbsenceTypesActionTypes
): tCompanyAbsenceTypesState {
    switch (action.type) {
        case "FETCH_COMPANY_ABSENCE_TYPES_FAILURE":
            return state
        case "FETCH_COMPANY_ABSENCE_TYPES_SUCCESS":
            return {
                ...state,
                companyAbsenceTypes: action.payload,
            }
        default:
            return state
    }
}
