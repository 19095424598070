const initialState = {
    materials: {},
}

export default function materials(state = initialState, action) {
    switch (action.type) {
        case "FETCH_MATERIALS_SUCCESS":
            return { materials: action.payload.materials }
        default:
            return state
    }
}
