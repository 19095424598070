import { GridFieldErrors, TimekeepingEntry } from "../common/types"
import { GPTreeDashboardRowData, iTkModifier } from "../cached-data/types"
import { iRmbxColDef } from "../components/custom-dashboards/settings-files/types"
import { sortBy } from "lodash"
import {
    companyClassificationColDefCreator,
    companyTradeColDefCreator,
    customModifierColDefCreator,
    equipmentColDefCreator,
    stringColDefCreator,
} from "../components/custom-dashboards/settings-files/standard-col-def-creators"
import { getFlagEnabled } from "../getFlagValue"
import ModifierCellRenderer from "../components/custom-dashboards/cell-renderers/ModifierCellRenderer"
import { WeeklyViewSourceData } from "../components/custom-dashboards/types"
import { CellClassParams } from "ag-grid-community"
import { tSourceData } from "../dashboard-data/types"

type TkEntryRow = { data: TimekeepingEntry & GridFieldErrors }

/**
 * Map an array of TK Modifier Types to an array of ColDefs, filtered by is_active, sorted by order.
 * @param tkModifierTypes
 * @returns Array of ColDefs
 */
export const createModifierColDefs = (
    tkModifierTypes: iTkModifier[],
    sourceData: WeeklyViewSourceData | undefined,
    defaultColDef: iRmbxColDef,
    equipmentRequired?: false
): iRmbxColDef[] => {
    // Find any TK Modifier slugs that are represented in the source data.
    const slugsWithData = (sourceData?.timekeepingEntries ?? []).reduce((slugsWithData, { modifier_active }) => {
        if (!modifier_active) {
            return slugsWithData
        }
        for (const slug of Object.keys(modifier_active)) {
            slugsWithData.add(slug)
        }
        return slugsWithData
    }, new Set<string>())
    // Create a mapping of slug to TK Modifier type.
    const slugToModifierType = tkModifierTypes.reduce((map, modifierType) => {
        map[modifierType.slug] = modifierType
        return map
    }, {} as Partial<Record<string, iTkModifier>>)
    // Finally, find TK Modifier slugs that are deactivated but have associated data in the source data.
    const inactiveSlugsWithData = new Set(
        [...slugsWithData.values()].filter(slug => !slugToModifierType[slug]?.is_active)
    )
    const tradeAndClassModifierTypesActive =
        slugToModifierType["trade"]?.is_active && slugToModifierType["classification"]?.is_active
    const tradeAndClassRequiredMsg = "Trade and Classification must be provided together."

    // Sort TK Modifier types by their order field.
    // Keep only TK Modifier types that are active or that are represented in the source data.
    // Finally, Map the filtered TK Modifier types to ColDefs.
    return sortBy(tkModifierTypes, ["order"])
        .filter(({ is_active, slug }) => is_active || slugsWithData.has(slug))
        .map(({ name, slug }) => {
            // Inactive modifier data can be displayed, but not edited.
            let cellClassRules: Record<string, (params: TkEntryRow) => boolean> | undefined
            // Classification can be edited once a Trade is initially set (if Trade and Classification types
            // are both enabled). Once Classification has a value, it remains editable (so the user can clear it).
            const getClassificationDisabled = (data: TimekeepingEntry) =>
                slug === "classification" &&
                tradeAndClassModifierTypesActive &&
                !data?.modifier_active?.trade &&
                !data?.modifier_active?.classification
            const getTradeNeeded = (data: TimekeepingEntry) =>
                slug === "classification" &&
                tradeAndClassModifierTypesActive &&
                !data?.modifier_active?.trade &&
                data?.modifier_active?.classification
            const getClassificationNeeded = (data: TimekeepingEntry) =>
                slug === "classification" &&
                tradeAndClassModifierTypesActive &&
                data?.modifier_active?.trade &&
                !data?.modifier_active?.classification

            // Said another way, if there's a column present for a modifier type, that means it's either
            // A) active or B) inactive but has associated data that needs to be displayed.
            const modifierTypeActive = !inactiveSlugsWithData.has(slug)

            const editable = (params: TkEntryRow) => {
                const { data } = params
                if (getFlagEnabled("WA-7632-project-specific-modifiers")) {
                    // Because modifiers are project-specific, a CC must be selected first so that
                    // modifier options can be filtered by the related project.
                    if (data && !data.cost_code) {
                        return false
                    }
                }

                if (getClassificationDisabled(data) || !modifierTypeActive) {
                    return false
                }

                if (typeof defaultColDef.editable === "function") {
                    return defaultColDef.editable(params as any)
                }
                return true
            }

            const customTooltip = (params: TkEntryRow) => {
                const { data } = params
                const messages: string[] = []

                if (typeof defaultColDef.customTooltip === "function") {
                    messages.push(...(defaultColDef.customTooltip(params)?.data?.messages ?? []))
                }

                if (getFlagEnabled("WA-7632-project-specific-modifiers")) {
                    if (data && !data.cost_code) {
                        messages.push("Add a Cost Code to enter modifiers.")
                    }
                }

                if (!messages.length) {
                    // Check for a missing modifier, but avoid doubling up on API error messages.
                    if (getTradeNeeded(data)) {
                        messages.push(`${tradeAndClassRequiredMsg} Please add a Trade.`)
                    } else if (getClassificationNeeded(data)) {
                        messages.push(`${tradeAndClassRequiredMsg} Please add a Classification.`)
                    }
                }

                if (!modifierTypeActive) {
                    messages.push("This modifier type has been deactivated.")
                } else if (data && getClassificationDisabled(data)) {
                    messages.push("Add a Trade to select a Classification.")
                }

                return {
                    tooltipShouldRender: messages.length > 0,
                    data: { messages },
                }

                cellClassRules = {
                    ...defaultColDef.cellClassRules,
                    readonly: (params: TkEntryRow) => {
                        if (!editable(params)) {
                            return true
                        }
                        if (typeof defaultColDef.cellClassRules?.readonly === "function") {
                            return defaultColDef.cellClassRules.readonly(params as CellClassParams)
                        }
                        return false
                    },
                }
            }

            const commonColDefParams = {
                customTooltip,
                cellClassRules,
                ignoreTooltipClassListCheck: true,
            }

            const tradeIsPopulatedValidator = (
                value: any,
                allRowData: tSourceData,
                field: string,
                gridId: string | number | undefined,
                data: TimekeepingEntry
            ) => !getTradeNeeded(data)
            tradeIsPopulatedValidator.error_message = " and Trade must be entered together."

            const classificationIsPopulatedValidator = (
                value: any,
                allRowData: tSourceData,
                field: string,
                gridId: string | number | undefined,
                data: TimekeepingEntry
            ) => !getClassificationNeeded(data)
            classificationIsPopulatedValidator.error_message = tradeIsPopulatedValidator.error_message

            return slug === "trade"
                ? {
                      ...companyTradeColDefCreator({
                          width: 120,
                          editable,
                      }),
                      ...commonColDefParams,
                  }
                : slug === "classification"
                ? {
                      ...companyClassificationColDefCreator({
                          width: 120,
                          editable,
                          cellValidators: [tradeIsPopulatedValidator, classificationIsPopulatedValidator],
                      }),
                      ...commonColDefParams,
                  }
                : slug === "equipment"
                ? {
                      ...equipmentColDefCreator({
                          field: "/modifier_active/equipment",
                          required: equipmentRequired,
                          editable,
                          cellRenderer: ModifierCellRenderer,
                          useSelectV3: true,
                          width: 120,
                      }),
                      ignoreRelatedFilters: ["projectId"],
                      ...commonColDefParams,
                  }
                : {
                      ...customModifierColDefCreator(name, slug, {
                          editable,
                          canFieldAddItems: slugToModifierType[slug]?.can_field_add_items,
                      }),
                      ...commonColDefParams,
                  }
        })
        .filter(colDef => colDef != null) as iRmbxColDef[]
}

type AgRowNode = {
    data: GPTreeDashboardRowData
}

export const createModifierAnnotationColDefs = (): iRmbxColDef[] => [
    {
        ...stringColDefCreator({
            headerName: "Groups with Auto-Add",
            valueGetter: ({ data: { number_groups_with_auto_add, total_groups } }: AgRowNode) => {
                // This covers the situation where we are in the process of adding a new picklist item
                if (total_groups === undefined) {
                    return ""
                }
                return `${number_groups_with_auto_add} of ${total_groups}`
            },
            width: 200,
            hide: !getFlagEnabled("WA-7632-project-specific-modifiers"),
        }),
        colId: "/groups",
    },
    {
        ...stringColDefCreator({
            headerName: "Projects",
            valueGetter: ({ data: { number_assigned_projects, total_projects } }: AgRowNode) => {
                // This covers the situation where we are in the process of adding a new picklist item
                if (total_projects === undefined) {
                    return ""
                }
                return `${number_assigned_projects} of ${total_projects}`
            },
            width: 100,
            hide: !getFlagEnabled("WA-7632-project-specific-modifiers"),
        }),
        colId: "/projects",
    },
]
