import { eEntityActionType, tCachedResourceName } from "./types"

export const actionTypesForResource: {
    [key in tCachedResourceName]: [eEntityActionType, eEntityActionType, eEntityActionType, eEntityActionType]
} = {
    cohorts: [
        eEntityActionType.FETCH_COHORTS,
        eEntityActionType.FETCH_COHORTS_SUCCESS,
        eEntityActionType.FETCH_COHORTS_FINISHED,
        eEntityActionType.FETCH_COHORTS_FAILURE,
    ],
    cohortEmployees: [
        eEntityActionType.FETCH_COHORT_EMPLOYEES,
        eEntityActionType.FETCH_COHORT_EMPLOYEES_SUCCESS,
        eEntityActionType.FETCH_COHORT_EMPLOYEES_FINISHED,
        eEntityActionType.FETCH_COHORT_EMPLOYEES_FAILURE,
    ],
    companyAbsenceTypes: [
        eEntityActionType.FETCH_COMPANY_ABSENCE_TYPES,
        eEntityActionType.FETCH_COMPANY_ABSENCE_TYPES_SUCCESS,
        eEntityActionType.FETCH_COMPANY_ABSENCE_TYPES_FINISHED,
        eEntityActionType.FETCH_COMPANY_ABSENCE_TYPES_FAILURE,
    ],
    companyFormSchemas: [
        eEntityActionType.FETCH_COMPANY_FORM_SCHEMAS,
        eEntityActionType.FETCH_COMPANY_FORM_SCHEMAS_SUCCESS,
        eEntityActionType.FETCH_COMPANY_FORM_SCHEMAS_FINISHED,
        eEntityActionType.FETCH_COMPANY_FORM_SCHEMAS_FAILURE,
    ],
    companyFormStores: [
        eEntityActionType.FETCH_COMPANY_FORM_STORES,
        eEntityActionType.FETCH_COMPANY_FORM_STORES_SUCCESS,
        eEntityActionType.FETCH_COMPANY_FORM_STORES_FINISHED,
        eEntityActionType.FETCH_COMPANY_FORM_STORES_FAILURE,
    ],
    companyGroups: [
        eEntityActionType.FETCH_COMPANY_GROUPS,
        eEntityActionType.FETCH_COMPANY_GROUPS_SUCCESS,
        eEntityActionType.FETCH_COMPANY_GROUPS_FINISHED,
        eEntityActionType.FETCH_COMPANY_GROUPS_FAILURE,
    ],
    companyStartStopTypes: [
        eEntityActionType.FETCH_COMPANY_START_STOP_TYPES,
        eEntityActionType.FETCH_COMPANY_START_STOP_TYPES_SUCCESS,
        eEntityActionType.FETCH_COMPANY_START_STOP_TYPES_FINISHED,
        eEntityActionType.FETCH_COMPANY_START_STOP_TYPES_FAILURE,
    ],
    costCodes: [
        eEntityActionType.FETCH_COST_CODES,
        eEntityActionType.FETCH_COST_CODES_SUCCESS,
        eEntityActionType.FETCH_COST_CODES_FINISHED,
        eEntityActionType.FETCH_COST_CODES_FAILURE,
    ],
    costItems: [
        eEntityActionType.FETCH_COST_ITEMS,
        eEntityActionType.FETCH_COST_ITEMS_SUCCESS,
        eEntityActionType.FETCH_COST_ITEMS_FINISHED,
        eEntityActionType.FETCH_COST_ITEMS_FAILURE,
    ],
    changeOrders: [
        eEntityActionType.FETCH_CHANGE_ORDERS,
        eEntityActionType.FETCH_CHANGE_ORDERS_SUCCESS,
        eEntityActionType.FETCH_CHANGE_ORDERS_FINISHED,
        eEntityActionType.FETCH_CHANGE_ORDERS_FAILURE,
    ],
    costCodeControls: [
        eEntityActionType.FETCH_COST_CODE_CONTROLS,
        eEntityActionType.FETCH_COST_CODE_CONTROLS_SUCCESS,
        eEntityActionType.FETCH_COST_CODE_CONTROLS_FINISHED,
        eEntityActionType.FETCH_COST_CODE_CONTROLS_FAILURE,
    ],
    companyCrewTypes: [
        eEntityActionType.FETCH_COMPANY_CREW_TYPES,
        eEntityActionType.FETCH_COMPANY_CREW_TYPES_SUCCESS,
        eEntityActionType.FETCH_COMPANY_CREW_TYPES_FINISHED,
        eEntityActionType.FETCH_COMPANY_CREW_TYPES_FAILURE,
    ],
    employees: [
        eEntityActionType.FETCH_EMPLOYEES,
        eEntityActionType.FETCH_EMPLOYEES_SUCCESS,
        eEntityActionType.FETCH_EMPLOYEES_FINISHED,
        eEntityActionType.FETCH_EMPLOYEES_FAILURE,
    ],
    employeeTrades: [
        eEntityActionType.FETCH_EMPLOYEE_TRADES,
        eEntityActionType.FETCH_EMPLOYEE_TRADES_SUCCESS,
        eEntityActionType.FETCH_EMPLOYEE_TRADES_FINISHED,
        eEntityActionType.FETCH_EMPLOYEE_TRADES_FAILURE,
    ],
    employeeClassifications: [
        eEntityActionType.FETCH_EMPLOYEE_CLASSIFICATIONS,
        eEntityActionType.FETCH_EMPLOYEE_CLASSIFICATIONS_SUCCESS,
        eEntityActionType.FETCH_EMPLOYEE_CLASSIFICATIONS_FINISHED,
        eEntityActionType.FETCH_EMPLOYEE_CLASSIFICATIONS_FAILURE,
    ],
    guestFormShares: [
        eEntityActionType.FETCH_GUEST_FORM_SHARES,
        eEntityActionType.FETCH_GUEST_FORM_SHARES_SUCCESS,
        eEntityActionType.FETCH_GUEST_FORM_SHARES_FINISHED,
        eEntityActionType.FETCH_GUEST_FORM_SHARES_FAILURE,
    ],
    projectEmployees: [
        eEntityActionType.FETCH_PROJECT_EMPLOYEES,
        eEntityActionType.FETCH_PROJECT_EMPLOYEES_SUCCESS,
        eEntityActionType.FETCH_PROJECT_EMPLOYEES_FINISHED,
        eEntityActionType.FETCH_PROJECT_EMPLOYEES_FAILURE,
    ],
    employeeSchemas: [
        eEntityActionType.FETCH_EMPLOYEE_SCHEMAS,
        eEntityActionType.FETCH_EMPLOYEE_SCHEMAS_SUCCESS,
        eEntityActionType.FETCH_EMPLOYEE_SCHEMAS_FINISHED,
        eEntityActionType.FETCH_EMPLOYEE_SCHEMAS_FAILURE,
    ],
    equipment: [
        eEntityActionType.FETCH_EQUIPMENT,
        eEntityActionType.FETCH_EQUIPMENT_SUCCESS,
        eEntityActionType.FETCH_EQUIPMENT_FINISHED,
        eEntityActionType.FETCH_EQUIPMENT_FAILURE,
    ],
    projectEquipment: [
        eEntityActionType.FETCH_PROJECT_EQUIPMENT,
        eEntityActionType.FETCH_PROJECT_EQUIPMENT_SUCCESS,
        eEntityActionType.FETCH_PROJECT_EQUIPMENT_FINISHED,
        eEntityActionType.FETCH_PROJECT_EQUIPMENT_FAILURE,
    ],
    projects: [
        eEntityActionType.FETCH_PROJECTS,
        eEntityActionType.FETCH_PROJECTS_SUCCESS,
        eEntityActionType.FETCH_PROJECTS_FINISHED,
        eEntityActionType.FETCH_PROJECTS_FAILURE,
    ],
    companies: [
        eEntityActionType.FETCH_COMPANIES,
        eEntityActionType.FETCH_COMPANIES_SUCCESS,
        eEntityActionType.FETCH_COMPANIES_FINISHED,
        eEntityActionType.FETCH_COMPANIES_FAILURE,
    ],
    schemaStatusNames: [
        eEntityActionType.FETCH_SCHEMA_STATUS_NAMES,
        eEntityActionType.FETCH_SCHEMA_STATUS_NAMES_SUCCESS,
        eEntityActionType.FETCH_SCHEMA_STATUS_NAMES_FINISHED,
        eEntityActionType.FETCH_SCHEMA_STATUS_NAMES_FAILURE,
    ],
    materials: [
        eEntityActionType.FETCH_MATERIALS,
        eEntityActionType.FETCH_MATERIALS_SUCCESS,
        eEntityActionType.FETCH_MATERIALS_FINISHED,
        eEntityActionType.FETCH_MATERIALS_FAILURE,
    ],
    projectMaterials: [
        eEntityActionType.FETCH_PROJECT_MATERIALS,
        eEntityActionType.FETCH_PROJECT_MATERIALS_SUCCESS,
        eEntityActionType.FETCH_PROJECT_MATERIALS_FINISHED,
        eEntityActionType.FETCH_PROJECT_MATERIALS_FAILURE,
    ],
    analyticsDashboards: [
        eEntityActionType.FETCH_ANALYTICS_DASHBOARDS,
        eEntityActionType.FETCH_ANALYTICS_DASHBOARDS_SUCCESS,
        eEntityActionType.FETCH_ANALYTICS_DASHBOARDS_FINISHED,
        eEntityActionType.FETCH_ANALYTICS_DASHBOARDS_FAILURE,
    ],
    companyTrades: [
        eEntityActionType.FETCH_COMPANY_TRADES,
        eEntityActionType.FETCH_COMPANY_TRADES_SUCCESS,
        eEntityActionType.FETCH_COMPANY_TRADES_FINISHED,
        eEntityActionType.FETCH_COMPANY_TRADES_FAILURE,
    ],
    companyClassifications: [
        eEntityActionType.FETCH_COMPANY_CLASSIFICATIONS,
        eEntityActionType.FETCH_COMPANY_CLASSIFICATIONS_SUCCESS,
        eEntityActionType.FETCH_COMPANY_CLASSIFICATIONS_FINISHED,
        eEntityActionType.FETCH_COMPANY_CLASSIFICATIONS_FAILURE,
    ],
    picklistItems: [
        eEntityActionType.FETCH_PICKLIST_ITEMS,
        eEntityActionType.FETCH_PICKLIST_ITEMS_SUCCESS,
        eEntityActionType.FETCH_PICKLIST_ITEMS_FINISHED,
        eEntityActionType.FETCH_PICKLIST_ITEMS_FAILURE,
    ],
    timekeepingStatuses: [
        eEntityActionType.FETCH_TIMEKEEPING_STATUSES,
        eEntityActionType.FETCH_TIMEKEEPING_STATUSES_SUCCESS,
        eEntityActionType.FETCH_TIMEKEEPING_STATUSES_FINISHED,
        eEntityActionType.FETCH_TIMEKEEPING_STATUSES_FAILURE,
    ],
    timelineEntryVersions: [
        eEntityActionType.FETCH_TIMELINE_ENTRY_VERSIONS,
        eEntityActionType.FETCH_TIMELINE_ENTRY_VERSIONS_SUCCESS,
        eEntityActionType.FETCH_TIMELINE_ENTRY_VERSIONS_FINISHED,
        eEntityActionType.FETCH_TIMELINE_ENTRY_VERSIONS_FAILURE,
    ],
    workShifts: [
        eEntityActionType.FETCH_WORK_SHIFTS,
        eEntityActionType.FETCH_WORK_SHIFTS_SUCCESS,
        eEntityActionType.FETCH_WORK_SHIFTS_FINISHED,
        eEntityActionType.FETCH_WORK_SHIFTS_FAILURE,
    ],
}
