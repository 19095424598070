/** Libraries */
import { addDays, startOfDay } from "date-fns"
/** Utils */
import { getAsDate, getDateWithWeeks } from "../../../common/ts-utils"
/** Const */
import { DATE_MM_DD, STORED_DATE_ONLY_FORMAT } from "../../../common/constants"
/** Types */
import { tProductionWeekValue } from "./types"
import { ValueGetterParams } from "ag-grid-community"
import { getFlagEnabled } from "../../../getFlagValue"

/**
 * Get production week column header, for Production Tracking.
 * @param {Object} params Column params.
 * @param {number} numberOfWeeks Number of weeks to add.
 * @param {boolean} [withHours] Including "Hours" in the header, only used for displaying hours in CSV/Excel.
 * @returns {string} Header name.
 */
export const getProductionWeekHeader = (
    params: Record<string, any>,
    numberOfWeeks: number,
    withHours?: boolean
): string => {
    const header = `Week ${getDateWithWeeks(params.context.filters.startDate, numberOfWeeks, DATE_MM_DD)}`
    if (withHours) return `${header} Hours`

    return params.colDef?.headerName || header
}

/**
 * Calculate "To Go Quantity" for cost codes in Production Tracking.
 * Note: If default_entry_type === "PERCENT_COMPLETE"
 *       Then budget_quantities - (total_quantities / 100) * budget_quantities
 *       Else Budget quantities - total quantities
 */
export const toGoQuantityValueGetter = (params: ValueGetterParams) => {
    if (!params.data) return
    const { budget_quantities, default_entry_type, total_quantities, units } = params.data
    const value =
        default_entry_type === "PERCENT_COMPLETE"
            ? budget_quantities - Math.round((total_quantities / 100) * budget_quantities)
            : budget_quantities - total_quantities
    return units ? `${value} ${units}` : value
}

/**
 * Calculate "Job To Date % Complete" for cost codes in Production Tracking.
 * Note: If default_entry_type === "PERCENT_COMPLETE"
 *       Then Total quantities
 *       Else Total quantities / budget quantities
 * - If budget_quantities is 0, return 0 as default.
 */
export const JTDCompleteValueGetter = (params: ValueGetterParams) => {
    if (!params.data) return
    const { budget_quantities, default_entry_type, total_quantities } = params.data
    if (!budget_quantities) return 0
    return default_entry_type === "PERCENT_COMPLETE"
        ? total_quantities
        : Math.round((total_quantities / budget_quantities) * 100)
}

/**
 * Calculate "Job To Date % Complete" for cost codes in Production Tracking.
 * Note: If default_entry_type === "PERCENT_COMPLETE"
 *       Then (total_quantities / 100) * budget_quantities
 *       Else Total quantities / budget quantities
 * - If budget_quantities is 0, return 0 as default.
 */
export const JTDQTYValueGetter = (params: ValueGetterParams) => {
    if (!params.data) return
    const { budget_quantities, default_entry_type, total_quantities } = params.data

    if (getFlagEnabled("WA-7106-fix-pt-dashboard-jtd-qty")) {
        return default_entry_type === "PERCENT_COMPLETE"
            ? // If budget_quantities is undefined/null/0, just multiply by 0, since you cannot
              // calculate job to date quanity without it when using percent complete
              Math.round((total_quantities / 100) * (budget_quantities ? budget_quantities : 0))
            : // But discrete quantity reporting the total quantities is the job to date quantity
              total_quantities
    }

    if (!budget_quantities) return 0
    return default_entry_type === "PERCENT_COMPLETE"
        ? Math.round((total_quantities / 100) * budget_quantities)
        : total_quantities
}

/**
 * Get hours, quantities, cost code info, and project info for a given week, for Production Tracking.
 * @param {Object} params.
 * @param {number} weekIndex Number of weeks to subtract.
 * @returns {Object} Production tracking and Modify Production info.
 */
export const ptHoursQuantitiesValueGetter = (
    params: ValueGetterParams,
    weekIndex: number
): tProductionWeekValue => {
    const {
        code,
        default_entry_type,
        description,
        group,
        id,
        project,
        units,
        week_hours,
        week_quantities,
    } = params.data
    // startDate of the week the user clicks on.
    const startDate = getDateWithWeeks(params.context.filters.startDate, -weekIndex, STORED_DATE_ONLY_FORMAT)
    // New data has date default to the last day of the week.
    const endDate = addDays(getAsDate(startDate), 6)
    const defaultDate = startOfDay(new Date())
    const hours = week_hours[weekIndex]
    const quantities = week_quantities[weekIndex]
    return {
        // Values to be displayed in UI.
        content: {
            title:
                default_entry_type === "PERCENT_COMPLETE"
                    ? `${quantities} %`
                    : units
                    ? `${quantities} ${units}`
                    : quantities,
            subtitle: `${hours} hours`,
        },
        // Filters and resource name used to fetch data for Modify Production view.
        filters: { costCodeId: id, startDate },
        resource: "modifyProduction",
        // Headers and config for Modify Production view.
        config: {
            costCodeId: id,
            defaultDate,
            default_entry_type,
            endDate,
            hours,
            startDate: getAsDate(startDate),
            quantities,
            units,
        },
        primarySubtitle: description && group ? `${description} • ${group}` : description || group,
        secondarySubtitle: `${project.name} • ${project.job_number}`,
        title: code,
        // For getting row grouping aggregation value.
        hours,
        quantities,
    }
}
