import React, { Component } from "react"
import { connect } from "react-redux"

import { setFilterValue } from "../actions"
import { tFilterState, tFilterKey } from "../types"

import "../FilterController/FilterController.less"
import "./StringFilter.less"
import { pendingFiltersSelector } from "../selectors"
import { AppDispatch } from "../../common/types"

type tProps = {
    className?: string
    field: string
    label: string
    primaryKey: tFilterKey
    filters: tFilterState
    dispatch: AppDispatch
}

type tState = {
    value: any
}

class StringFilter extends Component<tProps, tState> {
    constructor(props: tProps) {
        super(props)
        this.state = {
            value: props.filters[props.primaryKey],
        }
    }

    onKeyUp = (event: any) => {
        if (event.key === "Enter") {
            event.target.blur()
        }
    }

    onChange = (event: any) => {
        this.setState({ value: event.target.value })
    }

    dispatchFilterChange = () => {
        this.props.dispatch(setFilterValue(this.props.primaryKey, this.state.value ? this.state.value : undefined))
    }

    render() {
        const { value } = this.state
        const { primaryKey } = this.props

        return (
            <div className="string-filter" id={`filter-${primaryKey}`}>
                <input
                    className="string-filter-input"
                    type="text"
                    value={value || ""}
                    onKeyUp={this.onKeyUp}
                    onChange={this.onChange}
                    onBlur={this.dispatchFilterChange}
                />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    filters: pendingFiltersSelector(state),
})

export default connect(mapStateToProps)(StringFilter)
