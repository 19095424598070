import { getAsDate } from "./ts-utils"

/**
 * Takes in 2 date strings and returns 0 for equal dates, -1 if the date is later, and 1 if the date is prior
 */
export const compareDateStrings = (valueA: string, valueB: string) => {
    const valA = getAsDate(valueA)
    const valB = getAsDate(valueB)
    return valA < valB ? -1 : valA > valB ? 1 : 0
}
