import {
    employeeColDefCreator,
    checkboxColDefCreator,
    currencyColDefCreator,
    stringColDefCreator,
    booleanColDefCreator,
    subcontractorColDefCreator,
} from "./standard-col-def-creators"
import { isActiveValueFormatter } from "../../../common/ag-grid-value-formatters"
import { subcontractorValueSetter } from "../../../common/ag-grid-value-setters"
import ReferenceableSelectorCellEditor from "../cell-editors/referenceable-selector-cell-editor"
import { companyGroupsFilterDef, singleProjectFilterDef } from "../../../filters/filter-defs"
import { referenceablesToValueFormatters } from "../../../common/referenceable-value-formatters"
import { belongsToCurrentProject } from "../../../common/ag-grid-utils"
import { getFlagEnabled } from "../../../getFlagValue"

export function getPcoNumberSettings(featureFlags, currentProject, currentUser) {
    // we don't want subs to add their own PCOs to projects with a project share - so block that
    // GCs will use project shares to pass numbers down to subs, so we don't want to block them based on proj share
    const hasProjectShare = currentProject?.has_project_share === true
    const { company_role: companyRole } = currentUser
    const canAddNew = (!hasProjectShare && companyRole === "SUB") || companyRole === "GC"
    return {
        tableName: "PCO Numbers",
        navId: "pco-numbers",
        resources: ["changeOrders"],
        filters: [companyGroupsFilterDef, singleProjectFilterDef],
        // add current employee to list of ids to be fetched so we can properly populate the created_by column
        forceRefIds: { employees: [currentUser.employee_id] },
        // will handle fetching any other employees who have created change orders
        relatedNames: {
            changeOrders: {
                employees: ["/created_by"],
            },
        },
        colDefs: [
            {
                field: "/project",
                hide: true,
                cellEditorParams: {
                    default: currentProject?.id,
                },
            },
            checkboxColDefCreator({
                hide: !canAddNew, //only show checkboxes if they can add/delete
            }),
            stringColDefCreator({
                headerName: "PCO #*",
                field: "/number",
                minWidth: 115,
                required: true,
            }),
            stringColDefCreator({
                headerName: "Name*",
                field: "/name",
                required: true,
            }),
            stringColDefCreator({
                headerName: "Description",
                field: "/description",
            }),
            {
                ...booleanColDefCreator({
                    headerName: "Status",
                    field: "/is_active",
                    minWidth: 60,
                }),
                valueFormatter: isActiveValueFormatter,
            },
            employeeColDefCreator({
                headerName: "Created By",
                field: "/created_by",
                default: currentUser.employee_id,
                editable: function (params) {
                    // a little trickery to let us set a default but not let them edit
                    // if editable is set to false it won't populate with the default properly
                    return params.data.created_by === null
                },
            }),
            currencyColDefCreator({
                headerName: "NTE",
                field: "/not_to_exceed_amount",
                required: true,
            }),
            stringColDefCreator({
                headerName: "Class",
                field: "/classification",
                minWidth: 80,
            }),
            getFlagEnabled("WA-7484-improve-pco-error-message")
                ? { ...subcontractorColDefCreator({}, hasProjectShare, currentProject, companyRole) }
                : {
                      // hide if the user is not a GC or the project doesn't have at least one project share
                      hide: !hasProjectShare || companyRole !== "GC",
                      headerName: "Subcontractors",
                      field: "/cost_items",
                      minWidth: 250,
                      extraSearchFilters: {
                          unique_subs: true,
                          project_id: currentProject?.id,
                      },
                      valueFormatter: referenceablesToValueFormatters["projectShares"].valueFormatter,
                      cellEditor: ReferenceableSelectorCellEditor,
                      cellEditorParams: {
                          /**
                           * if a gc is self-perform (has no project share) -
                           * set the gc company as the default value for the subcontractor
                           */
                          default: !hasProjectShare ? [{ sub_company_id: currentProject?.company_id }] : null,
                          valueKey: "sub_company_id",
                      },
                      cellRendererParams: {
                          default: !hasProjectShare ? [{ sub_company_id: currentProject?.company_id }] : null,
                          ...(referenceablesToValueFormatters["projectShares"] || {}),
                      },
                      multiselect: true,
                      resourceName: "projectShares",
                      valueSetter: subcontractorValueSetter,
                      cellEditorPopup: true,
                      editable: !!currentProject,
                      useSelectV3: true,
                  },
            stringColDefCreator({
                hide: currentUser.company_role !== "GC",
                headerName: "Sub Count",
                field: "/cost_items/length",
                minWidth: 60,
                editable: false,
            }),
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: function (params) {
                    // We only allow editing of change orders if the change order belongs to the selected project.
                    // This prevents subs from changing a GC's PCO information.
                    return belongsToCurrentProject(params)
                },
            },
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            suppressCellSelection: true,
        },
        otherSettings: {
            enableSearchBar: true,
            hideCellActions: true,
            rowLevelErrorDisplay: true,
            buttons: {
                cell: [],
                row: [
                    {
                        label: "Delete",
                        icon: "delete",
                        action: "deleteSelectedRows",
                    },
                ],
                table: [...(canAddNew ? [{ label: "Add Row", icon: "add", action: "addNewRow" }] : [])],
            },
        },
    }
}
