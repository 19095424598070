import React from "react"
import { tUserRole } from "../../common/types"

type tProps = {
    isSidebarCollapsed: boolean
    user_role: tUserRole
    email: string
}

const UserInfo = (props: tProps): JSX.Element | null => {
    const { isSidebarCollapsed, user_role, email } = props
    return !isSidebarCollapsed ? (
        <div className="sidebar-user-info-section">
            <div className="sidebar-user-role-text">{user_role}</div>
            <div className="sidebar-user-email sidebar-foreground-text">{email}</div>
        </div>
    ) : null
}

export default UserInfo
