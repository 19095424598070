import { handleMarkup, setCheckboxes } from "./utils"

export function handleMarkupCheckboxChange() {
    return {
        type: "PROJECT_MARKUP_TOGGLE",
    }
}

export function handleMakupCheckboxLabelChange(label: string) {
    switch (label) {
        case "labor":
            return {
                type: "PROJECT_MARKUP_LABOR_TOGGLE",
            }
        case "equipment":
            return {
                type: "PROJECT_MARKUP_EQUIPMENT_TOGGLE",
            }
        case "materials":
            return {
                type: "PROJECT_MARKUP_MATERIALS_TOGGLE",
            }
    }
}

export function updateMarkupsData(data: Map<string, object>) {
    return {
        type: "PROJECT_MARKUPS_UPDATE_MARKUPS_DATA",
        data: handleMarkup(data),
    }
}

export function updateMarkupsInitial(data: Map<string, object>) {
    const checkboxes = data ? setCheckboxes(data) : []
    return {
        type: "PROJECT_MARKUPS_UPDATE_MARKUPS_DATA_INITIAL",
        data: {
            markupsChecked: checkboxes.length,
            laborChecked: checkboxes.includes("LABOR"),
            equipmentChecked: checkboxes.includes("EQUIPMENT"),
            materialsChecked: checkboxes.includes("MATERIALS"),
            markups: handleMarkup(data),
        },
    }
}
