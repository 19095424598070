import { SchemaVariant } from "../cached-data/types"
import { SchemaVariantActions } from "./types"

export type tSchemaVariantState = {
    availableVariants: string[]
    selectedVariantName: string | null
    variantTemplates: SchemaVariant[]
}

const initialState: tSchemaVariantState = {
    availableVariants: [],
    selectedVariantName: null,
    variantTemplates: [],
}

export default function schemaVariants(
    state: tSchemaVariantState = initialState,
    action: SchemaVariantActions
): tSchemaVariantState {
    // Most of this this will going away when we remove the old dashboard
    switch (action.type) {
        case "FETCH_SCHEMA_VARIANT_NAMES_SUCCESS":
            // if we are using nav arrows to go through forms, and we select a variant, keep it if it matches
            // an available variant name for the next form.
            return {
                ...state,
                availableVariants: action.data,
                selectedVariantName:
                    state?.selectedVariantName && action?.data?.includes(state.selectedVariantName)
                        ? state.selectedVariantName
                        : action.data[0],
            }
        case "FETCH_SCHEMA_VARIANT_NAMES_FAILURE":
            return {
                ...state,
                availableVariants: [],
            }
        case "SET_SCHEMA_VARIANT_NAME":
            return {
                ...state,
                selectedVariantName: action.selectedVariant,
            }
        case "FETCH_VARIANT_TEMPLATES_SUCCESS":
            return {
                ...state,
                variantTemplates: action.data,
                // if no selected variant is set, set the first one
                selectedVariantName: state?.selectedVariantName ?? action.data[0]?.name,
            }
        case "CLEAR_SELECTED_AND_AVAILABLE_VARIANTS":
            return {
                ...state,
                selectedVariantName: null,
                availableVariants: [],
            }
        default:
            return state
    }
}
