import { MarkupFormConfig, MarkupInput } from "./types"

export const MARKUP_INPUTS: MarkupInput[] = ["overhead", "profit", "taxes", "fees", "insurance", "bond"]

export const MARKUPS: MarkupFormConfig[] = [
    {
        name: "LABOR",
        header: "Labor",
        label: "labor",
        inputs: MARKUP_INPUTS,
    },
    {
        name: "EQUIPMENT",
        header: "Equipment",
        label: "equipment",
        inputs: MARKUP_INPUTS,
    },
    {
        name: "MATERIALS",
        header: "Materials",
        label: "materials",
        inputs: MARKUP_INPUTS,
    },
]
