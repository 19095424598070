import React from "react"
import PropTypes from "prop-types"
import { actOnEnterOrSpace } from "../common/js-utils"

class CollapsibleSection extends React.Component {
    static propTypes = {
        collapsed: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
    }

    render() {
        return (
            <div className="collapsible-section">
                <div
                    className="collapsible-header-container"
                    onClick={this.props.onClick}
                    onKeyDown={e => actOnEnterOrSpace(e, this.props.onClick)}
                    role="button"
                    tabIndex="0"
                >
                    <div className="arrow-container">
                        <i className={this.props.collapsed ? "arrow-dropdown-up" : "arrow-dropdown-down"} />
                    </div>
                    <span className="collapsible-header">{this.props.header}</span>
                </div>
                <div className="collapsible-area" hidden={this.props.collapsed}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default CollapsibleSection
