import { TimekeepingStatusActionTypes, tTimekeepingStatusState } from "./types"

const initialState: tTimekeepingStatusState = {
    timekeepingStatuses: [],
}

export default function timekeepingStatuses(
    state: tTimekeepingStatusState = initialState,
    action: TimekeepingStatusActionTypes
) {
    switch (action.type) {
        case "FETCH_TIMEKEEPING_STATUSES_SUCCESS":
            return { ...state, timekeepingStatuses: action.payload }
        default:
            return state
    }
}
