import { createSelector } from "reselect"

export const currentUserSelector = state => (state.current_user ? state.current_user : {})

export const relatedListViewIdSelector = state => {
    return state.listView.related_list_view ? state.listView.related_list_view : null
}

// Find the list view object for the related_list_view
export const relatedListViewSelector = createSelector(
    [relatedListViewIdSelector, currentUserSelector],
    (relatedListViewId, currentUser) => {
        return currentUser.list_views && relatedListViewId
            ? currentUser.list_views.find(lv => lv.id === relatedListViewId)
            : null
    }
)

export const listViewSelector = createSelector(currentUserSelector, currentUser =>
    currentUser.list_views ? currentUser.list_views : []
)

export const nestedFolderPathSelector = createSelector(
    currentUserSelector,
    currentUser => currentUser?.nested_list_view_paths ?? []
)
