import React from "react"
/* Styles */
import { StyledMultiLineContainer, StyledMultiLineSubtitle, StyledMultiLineTitle } from "./styles"
/* Types */
type tContent = {
    title: string | number
    subtitle?: string
}

type tProps = {
    value: { content: tContent }
}

const MultiLineCellRenderer: React.FC<tProps> = ({ value }) => {
    const { title, subtitle } = value.content
    return (
        <StyledMultiLineContainer>
            <StyledMultiLineTitle>{title}</StyledMultiLineTitle>
            {subtitle && <StyledMultiLineSubtitle>{subtitle}</StyledMultiLineSubtitle>}
        </StyledMultiLineContainer>
    )
}

export default MultiLineCellRenderer
