import { Action } from "redux"
import { iActionData } from "../common/types"

export type iWebsocket = WebSocket & {
    onMessage: (e: Event) => void
    connect: (host: string) => void
    url: string
}

export const WS_CONNECT = "WS_CONNECT"
export const WS_CONNECTING = "WS_CONNECTING"
export const WS_CONNECTED = "WS_CONNECTED"
export const WS_DISCONNECT = "WS_DISCONNECT"
export const WS_DISCONNECTED = "WS_DISCONNECTED"
export const WS_MESSAGE_RECEIVED = "WS_MESSAGE_RECEIVED"
export const CLEAR_NOTIFICATION_DETAILS = "CLEAR_NOTIFICATION_DETAILS"
export const SET_NOTIFICATION_MESSAGE = "SET_NOTIFICATION_MESSAGE"
export const PDF_CREATING = "PDF Create Request Sent"
export const PDF_READY = "PDF ready"
export const PDF_ERROR = "PDF error"

export type tNotificationTypes = "warning" | "info" | "error" | "success"

export type tSocketActionTypes =
    | typeof WS_CONNECTING
    | typeof WS_CONNECT
    | typeof WS_CONNECTED
    | typeof WS_DISCONNECT
    | typeof WS_DISCONNECTED
    | typeof WS_CONNECTED
    | typeof WS_MESSAGE_RECEIVED
    | typeof CLEAR_NOTIFICATION_DETAILS
interface iSocketActions extends Action {
    type: tSocketActionTypes
    host: string
    isGuest?: boolean
}

export interface iWebsocketConnected extends iSocketActions {
    socket: iWebsocket
}

export interface iWebsocketMessage extends Action {
    type: tSocketActionTypes
    event: MessageEvent
}

export type tWebsocketActions = iSocketActions | iWebsocketConnected | iWebsocketMessage | iActionData
