import {
    checkboxColDefCreator,
    dateColDefCreator,
    foremanColDefCreator,
    integerColDefCreator,
    numberColDefCreator,
    attachmentsColDefCreator,
    stringColDefCreator,
} from "./standard-col-def-creators"
/** Filters */
import { singleCostCodeFilterDef, weekStartDateOnlyFilterDef } from "../../../filters/filter-defs"
/** Utils */
import { dateValueFormatter, quantityValueFormatter } from "../../../common/ag-grid-value-formatters"
import { convertUtcToTimezone, getAsDate } from "../../../common/ts-utils"
import { isNumber, isNumberOrNull } from "../../../common/validators"
/** Const */
import { adminAndPmRoles, WEEKDAY_M_D } from "../../../common/constants"
import { getFlagEnabled } from "../../../getFlagValue"
import { alertCellClassRule } from "../../../common/ag-grid-utils"
const lengthLimit = "is over the length limit"
const notValidDate = "does not fall within the selected week"
const notValidNumber = "is not a valid number"
const locationLenLimit = 50
const descLenLimit = 1000

/**
 * Validate Discrete Quantity value.
 * @param {*} value Value to be validated.
 * @param {Object} data Row data.
 * @returns True if the value is valid.
 */
const quantityValidator = (value, a_, field_, gridId_, data) =>
    // Quantity field can only be null if it is only a WorkShift entry.
    !data.quantity_id && data.reported_hours && !data.description && !data.location
        ? isNumberOrNull(value) || value === ""
        : isNumber(value)
quantityValidator.error_message = notValidNumber

/**
 * Validate Location string length. 50 is the limit based on Quantity Model.
 * @param {*} value Value to be validated.
 * @returns True if the length is valid.
 */
const locationValidator = value => !value || value.length <= locationLenLimit
locationValidator.error_message = lengthLimit

/**
 * Validate Description string length. 1000 is the limit based on Quantity Model.
 * @param {*} value Value to be validated.
 * @returns True if the length is valid.
 */
const descriptionValidator = value => !value || value.length <= descLenLimit
descriptionValidator.error_message = lengthLimit

export const getModifyProductionSettings = (f_, p_, currentUser, pIds_, data_, config) => {
    const { defaultDate, default_entry_type, endDate, startDate } = config

    /**
     * Validate Date is within the selected week.
     * @param {*} value Value to be validated.
     * @returns True if the date is within the selected week
     */
    const dateValidator = value => {
        const valueDate = convertUtcToTimezone(value)
        // startDate and endDate are in the user's timezone. value is UTC. We only care about date comparison
        // so we're stripping it down to just the date.
        const sDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
        const eDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
        const vDate = new Date(valueDate.getFullYear(), valueDate.getMonth(), valueDate.getDate())
        return !getFlagEnabled("WA-8309-production-default-date") || (sDate <= vDate && vDate <= eDate)
    }
    dateValidator.error_message = notValidDate

    return {
        tableName: "Modify Production",
        navId: "modifyProduction",
        resources: ["modifyProduction"],
        filters: [singleCostCodeFilterDef, weekStartDateOnlyFilterDef],
        colDefs: [
            checkboxColDefCreator(),
            integerColDefCreator({
                // This field is used to assign cost code id to new entry.
                headerName: "Cost Code Id",
                field: "/cost_code_id",
                hide: true,
                setDefault: true,
                default: config.costCodeId,
            }),
            foremanColDefCreator({
                default: currentUser,
                // Only editable for a new data & user role is Admin|Payroll Admin|PM.
                editable: params => !!params.data.newRow && adminAndPmRoles.has(currentUser.user_role),
                required: true,
                suppressSizeToFit: true,
            }),
            dateColDefCreator({
                headerName: "Date",
                field: "/date",
                default: getFlagEnabled("WA-8309-production-default-date") ? defaultDate : endDate,
                editable: params => !!params.data.newRow,
                fixedWidth: true,
                suppressSizeToFit: true,
                valueFormatter: params => dateValueFormatter(params, WEEKDAY_M_D),
                width: 130,
                cellEditorParams: {
                    minDate: getAsDate(config.startDate),
                    maxDate: endDate,
                    stopEditAfterSelect: true,
                },
                cellValidators: [dateValidator],
                cellClassRules: {
                    "alert-cell": getFlagEnabled("WA-8309-production-default-date") ? alertCellClassRule : null,
                },
            }),
            numberColDefCreator({
                headerName: default_entry_type === "PERCENT_COMPLETE" ? "% Complete" : "Discrete Quantity",
                field: "/quantity",
                default: null,
                editable: params => !params.node.rowPinned,
                focusedCell: true,
                required: true,
                valueFormatter: params => quantityValueFormatter(params, default_entry_type),
                suppressSizeToFit: true,
                width: 150,
                /**
                 * For discrete quantity, if the initial value is:
                 * - null/undefined: allow null/undefined.
                 * - number: has to be a number, can't be null/undefined.
                 */
                cellValidators: [quantityValidator],
                cellClassRules: {
                    "alert-cell": getFlagEnabled("WA-8309-production-default-date") ? alertCellClassRule : null,
                },
            }),
            stringColDefCreator({
                headerName: "UoM",
                field: "/units",
                default: config.units,
                fixedWidth: true,
                setDefault: true,
                suppressSizeToFit: true,
                width: 80,
            }),
            numberColDefCreator({
                headerName: "Reported Hours",
                field: "/reported_hours",
                cellStyle: { textAlign: "right" },
                headerClass: "align-right",
                suppressSizeToFit: true,
                width: 140,
            }),
            stringColDefCreator({
                headerName: "Location",
                field: "/location",
                editable: params => !params.node.rowPinned,
                suppressSizeToFit: true,
                cellValidators: [locationValidator],
            }),
            stringColDefCreator({
                headerName: "Description",
                field: "/description",
                width: 115,
                editable: params => !params.node.rowPinned,
                suppressSizeToFit: true,
                cellValidators: [descriptionValidator],
            }),
            attachmentsColDefCreator({
                headerName: "Photos",
                field: "/quantity_pictures",
                width: 270,
                required: false,
                editable: params => !params.node.rowPinned,
            }),
        ],
        gridSettings: {
            rowHeight: 50,
            defaultColDef: {
                editable: false,
            },
            animateRows: true,
        },
        otherSettings: {
            buttons: {
                table: [
                    {
                        label: "Add Entry",
                        icon: "add",
                        action: "addNewRow",
                    },
                ],
            },
        },
        usePlaceholders: !getFlagEnabled("WA-8309-production-default-date"),
    }
}
