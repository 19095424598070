import React from "react"
import Loader from "react-loader"

const LoadingIndicator = props => {
    /**
     * For styling class, see main.less .loader.
     * If alignToParent is true, don't apply the styles in main.less
     * - so it's aligned to the parent component, instead of the whole window.
     *
     * Additional styling can be added through the additionalClasses prop
     */
    const classes = `${props.alignToParent ? "" : "loader"} ${props.additionalClasses ?? ""}`

    return (
        <Loader
            loaded={!props.active}
            left={props.left || "50%"}
            parentClassName={classes}
            options={props.options ?? {}}
        />
    )
}

export default LoadingIndicator
