import React from "react"

import { tFilterState, tFilterKey, tFilterDef, tFilterContext } from "../types"
import { tCachedResourceName } from "../../cached-data/types"
import { isApplicableFilterValue } from "../utils"

type tProps = {
    filterState: tFilterState
    filterDefs: Array<tFilterDef>
    context: tFilterContext
}

export const FilterBannerLabelGetter = (props: tProps) => {
    const { filterState, filterDefs, context } = props

    const enumMatchValueGetLabel = (element: tFilterDef): string => {
        /**
         * Match the value of the enum in the filter state against the value thats in
         * element.options.value. If it matches, return the label.
         */
        return element.options
            ? element.options.reduce(
                  (accum: string, option: { label: string; value: string; data: Record<string, string> }) => {
                      const elementValue = filterState[element.key as tFilterKey]

                      // Normally the enumFilterDef is used as a single-select control
                      // and returns a result as a string. However,
                      // the saved filter sets apply all results as arrays,
                      // even if they have a single element.
                      // The statements below cover both situations
                      if (option.value === elementValue || option.label === elementValue) {
                          accum = option.label
                      } else if (
                          Array.isArray(elementValue) &&
                          elementValue.length > 0 &&
                          elementValue[0] === option.label
                      ) {
                          accum = option.label
                      } else if (Array.isArray(elementValue) && elementValue.length > 0 && element.valueKey) {
                          // Per the previous comment, we normally don't have multi-select enums.
                          // Building out the specific needs of the tkEntryStatus filter here, which depends
                          // on the `valueKey` parameter being part of the filter def
                          if (
                              (elementValue as string[]).find(el => option.data[element.valueKey as string] === el)
                          ) {
                              if (accum) accum += ", "
                              accum += option.label
                          }
                      }
                      return accum
                  },
                  ""
              )
            : ""
    }

    const getValueFromStringGetter = (filterDef: tFilterDef): string => {
        return filterDef.filterStateStringGetter
            ? filterDef.filterStateStringGetter({
                  primaryValue: filterState[filterDef.key as tFilterKey],
                  secondaryValue: filterState[filterDef.secondaryKey as tFilterKey],
                  resourceName: filterDef.resourceName as tCachedResourceName,
                  context: context,
                  enumLabel:
                      Array.isArray(filterDef.options) &&
                      filterDef.options.length &&
                      isApplicableFilterValue(filterState[filterDef.key as tFilterKey])
                          ? enumMatchValueGetLabel(filterDef)
                          : undefined,
              })
            : ""
    }

    const extractStringFromFilters = () => {
        /**
         * First we will extract the string values from the filters, then if that value exists,
         * push the label and the value div into the accumulator.
         */
        const applicableFilterDefs = filterDefs.filter(element =>
            isApplicableFilterValue(filterState[element.key as tFilterKey])
        )
        return applicableFilterDefs.reduce((accum: Array<JSX.Element>, element: tFilterDef, index: number) => {
            let elementValue = ""

            elementValue = getValueFromStringGetter(element)

            if (elementValue) {
                accum.push(
                    <div key={`bannerLabel_${index}`}>{element.label}: </div>,
                    <div key={`bannerValue_${index}`} className="filter-bar-values">
                        {elementValue}
                        {index !== applicableFilterDefs.length - 1 ? "," : ""}
                    </div>
                )
            }
            return accum
        }, [])
    }

    return extractStringFromFilters()
}
