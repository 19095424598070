import styled from "styled-components"
import { colorBrandRhumbixBlack, colorFunctionalGray60 } from "@rhumbix/rmbx_design_system_web"

/** MultiLineCellRenderer */
export const StyledMultiLineContainer = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 4px;
`
/** Title Styles */
export const StyledMultiLineTitle = styled.div`
    color: ${colorBrandRhumbixBlack};
`

/** Subtitle Styles */
export const StyledMultiLineSubtitle = styled.div`
    color: ${colorFunctionalGray60};
`

/** Value Styles */
export const StyledCellWithIconValue = styled.span`
    margin-top: auto;
`
