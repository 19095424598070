import React from "react"

class CsvErrorList extends React.Component {
    render() {
        const isRow =
            // eslint-disable-next-line no-prototype-builtins
            this.props.errors.length > 0 && this.props.errors[0].hasOwnProperty("key")
                ? Number.parseInt(this.props.errors[0]["key"]) >= 0
                : undefined
        return (
            <div>
                <div>
                    <h5>Please fix these errors in your document and try uploading again.</h5>
                </div>
                <div>
                    <table className="table csv-modal-headers">
                        <thead>
                            <tr>
                                <td>{isRow ? "Row" : "Field"}</td>
                                <td>Error(s) found</td>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="csv-error-table">
                    <table className="table">
                        <tbody data-testid="csv-error-table">{this.props.errors}</tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default CsvErrorList
