import { createSelector } from "reselect"
// We cannot shorten this import as it will create a circular dependency
import { currentProjectSelector } from "../selectors/projects"

type tState = {
    projectMarkups: {
        markupsChecked: boolean
        laborChecked: boolean
        equipmentChecked: boolean
        materialsChecked: boolean
        markups: object
    }
}

export const markupsOldProjectSelector = createSelector(currentProjectSelector, currentProject => {
    return currentProject && currentProject.markups ? new Map(Object.entries(currentProject.markups)) : new Map()
})

export const markupCheckboxesSelector = (state: tState) => {
    return {
        markup: state.projectMarkups.markupsChecked,
        labor: state.projectMarkups.laborChecked,
        equipment: state.projectMarkups.equipmentChecked,
        materials: state.projectMarkups.materialsChecked,
    }
}

export const markupsSelector = (state: tState) => state.projectMarkups.markups
