import React, { Component } from "react"
import { isValueEmpty } from "../../../common/ts-utils"

class CheckMarkCellRenderer extends Component {
    refresh() {
        return false
    }

    render() {
        return (
            <div>
                {isValueEmpty(this.props.value) ? (
                    <img className="check-mark" alt="check-mark" src={require("green-check.png")} />
                ) : null}
            </div>
        )
    }
}

export default CheckMarkCellRenderer
