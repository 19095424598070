import React from "react"
import DateArrows from "../DateFilter/DateArrows"
import { eDatePickerType, tFilterContext } from "../types"

import "./DateQuickNav.less"

type tProps = {
    datePickerType: eDatePickerType
    startDate: Date
    endDate: Date
    context: tFilterContext
}

export const DateQuickNav = (props: tProps): JSX.Element => {
    const { datePickerType, startDate, endDate, context } = props
    const dateLengthLabel: string = datePickerType === eDatePickerType.DAILY ? "1 Day" : "7 Days"
    return (
        <div className="date-quick-nav">
            <i className="icon-calendar" id="calendar-icon" />
            <div className="quick-nav-label">{dateLengthLabel}</div>
            <DateArrows
                datePickerType={datePickerType}
                startDate={startDate}
                endDate={endDate}
                isEnabled={true}
                startOfWeekIndex={context.startOfTheWeekIndex}
            />
        </div>
    )
}
