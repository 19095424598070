import React, { useState, useEffect } from "react"
import { getCellValue } from "../../../common/ag-grid-utils"
import "lightbox.less"
import AttachmentsLightboxModal from "../../modals/modal-attachments-lightbox"
import { actOnEnterOrSpace } from "../../../common/js-utils"
import { getFlagEnabled } from "../../../getFlagValue"
import { colorFunctionalGray60, spacingXs } from "@rhumbix/rmbx_design_system_web"
import { getFileIconOrImage } from "../../../common/ts-utils"

const NUMBER_OF_THUMBNAILS_TO_DISPLAY = 5

// if you want to override the image name as the header, use like AttachmentsRenderer("Signature")
const AttachmentsRenderer = (forceHeader = undefined) => props => {
    const [images, setImages] = useState([])
    const [imageIndex, setImageIndex] = useState(0)
    const [isLightboxOpen, setIsLightboxOpen] = useState(false)

    const resourceName = props.context.settings.resources?.[0]

    // The editable value may be a function (e.g. timekeepingDataIsEditable) or a boolean -
    // handle both cases
    const [isCellEditable] = useState(
        getFlagEnabled("WA-7920-attachment-upload-button")
            ? props.colDef.uploadEnabled instanceof Function
                ? props.colDef.uploadEnabled(props)
                : props.colDef.uploadEnabled
            : props.colDef.editable instanceof Function
            ? props.colDef.editable(props)
            : props.colDef.editable
    )

    /**
     * convert raw s3 urls into proxy urls so we don't get CORS errors on download
     * Remove the aws signature string (everything after ?)
     * Get the domain in $1, we replace the aws url with the current domain and then add the rhumbix/files
     * and then add the s3 uri on the end $2
     */
    const getProxyUrlAndName = url => {
        const s3UrlRegex = /(https?:\/\/)[a-z3.-]+(\/[a-zA-Z/0-9_\-.]+)/
        let imageUrl = url
        const domain = window.location.host
        // only clean the url if it doesn't match our host name
        if (!imageUrl.includes(domain)) {
            // if we get here it is likely an s3 url, so first remove the signed ending to the url
            imageUrl = imageUrl.split("?")[0]
            // then replace the domain with a proxy url style url
            imageUrl = imageUrl.replace(s3UrlRegex, `$1${domain}/rhumbix/files$2`)
        }
        // local urls can't use the https from the s3 url
        if (domain === "localhost:8000") imageUrl = imageUrl.replace("https", "http")
        return {
            url: imageUrl,
            name: imageUrl.split("/").pop(),
            type: "image/jpg",
        }
    }

    useEffect(() => {
        const images = getCellValue(props)

        if (getFlagEnabled("WA-5644-tk-signatures")) {
            // If the cell value is not an array - for example, if a random string got in here -
            // it is ignored, and the cell is populated with an empty array. Note that the
            // unrecognized contents of the cell will be overwritten if the user makes changes.
            // However, we will check to see if we're getting a signature url -- if so, it's converted
            // to an object so that the image can be rendered properly.
            if (images && Array.isArray(images) && images.length) {
                const firstItem = images[0]
                if (typeof firstItem === "string" && firstItem.startsWith("http")) {
                    const urlList = images.map(url => {
                        return getProxyUrlAndName(url)
                    })
                    setImages(urlList)
                } else {
                    setImages(images)
                }
            } else if (images && typeof images === "string" && images.startsWith("http")) {
                setImages([getProxyUrlAndName(images)])
            } else {
                setImages([])
            }
        } else {
            // If the cell value is not an array - for example, if a random string got in here -
            // it is ignored, and the cell is populated with an empty array. Note that the
            // unrecognized contents of the cell will be overwritten if the user makes changes
            if (images && Array.isArray(images)) {
                setImages(images)
            } else {
                setImages([])
            }
        }
    }, [props])

    const openLightbox = index => {
        setImageIndex(index)
        setIsLightboxOpen(true)
    }

    const handleChange = updatedImages => {
        const rowNode = props.node
        rowNode.setDataValue(props.column, updatedImages)

        // Refreshing the cells (specifically this call) triggers the cellValueChanged event,
        // which will trigger the handler that saves the data
        // NOTE: One side effect of calling refreshCells is that when it refreshes the table,
        // it interrupts any functions that were on the call stack to get us here. For example,
        // if a confirmDelete() function calls this function, it will never proceed to the next line,
        // and any tasks it had left over will not be completed. Make sure that
        // all other business is taken care of before calling this function.
        props.api.refreshCells({ force: true, rowNodes: [rowNode], columns: [props.column] })
    }

    return (
        <>
            {isLightboxOpen ? (
                <AttachmentsLightboxModal
                    images={images}
                    imageIndex={imageIndex}
                    userCanEdit={isCellEditable}
                    onUpdate={handleChange}
                    onClose={() => setIsLightboxOpen(false)}
                    tableName={props.context.settings.tableName}
                    forceHeader={forceHeader}
                    resourceName={resourceName}
                />
            ) : null}
            {images && images.length > 0 ? (
                <div className="thumbnail-gallery">
                    {images.map((img, index) =>
                        index < NUMBER_OF_THUMBNAILS_TO_DISPLAY ? (
                            <button
                                key={index}
                                className="thumbnail-gallery-button"
                                onClick={() => openLightbox(index)}
                            >
                                {getFlagEnabled("WA-7857-se-attachment-icons") ? (
                                    getFileIconOrImage(img, { color: colorFunctionalGray60, marginLeft: spacingXs })
                                ) : (
                                    <img src={img.url} alt="Thumbnail from the lightbox" />
                                )}
                            </button>
                        ) : null
                    )}
                    {images.length > NUMBER_OF_THUMBNAILS_TO_DISPLAY && (
                        <span
                            className="thumbnail-overflow-indicator"
                            onClick={() => openLightbox(NUMBER_OF_THUMBNAILS_TO_DISPLAY)}
                            onKeyDown={e =>
                                actOnEnterOrSpace(e, () => openLightbox(NUMBER_OF_THUMBNAILS_TO_DISPLAY))
                            }
                            role="button"
                            tabIndex="0"
                        >
                            + {images.length - NUMBER_OF_THUMBNAILS_TO_DISPLAY}
                        </span>
                    )}
                    <div
                        className="clickable-area"
                        onClick={() => openLightbox(0)}
                        onKeyDown={e => actOnEnterOrSpace(e, () => openLightbox(0))}
                        role="button"
                        tabIndex="0"
                    ></div>
                </div>
            ) : (
                <div
                    className="attachments-empty-cell"
                    onClick={() => openLightbox(-1)}
                    onKeyDown={e => actOnEnterOrSpace(e, () => openLightbox(-1))}
                    role="button"
                    tabIndex="0"
                />
            )}
        </>
    )
}

export default AttachmentsRenderer
