import React from "react"
import { iCompanyGroup } from "../../../cached-data/types"
import { IconLock, IconButton, iconSizeL } from "@rhumbix/rmbx_design_system_web"
import { SideRailContextData } from "../../../components/SideRail/SideRailContext"
import { getFlagEnabled } from "../../../getFlagValue"

type GroupSettingsHeaderProps = {
    // Captures _any_ props that the parent dashboard was given
    props: any
    context: SideRailContextData
}

export const GroupSettingsHeader: React.FC<GroupSettingsHeaderProps> = ({ props, context }) => {
    const group: iCompanyGroup = props?.currentGroup

    // This disables the new header section entirely if flag is off
    if (!getFlagEnabled("WA-8439-group-right-rail")) return <></>

    if (!group) return <></>

    return (
        <div className="aggrid-header-container">
            <div className="aggrid-header-title-block-left">
                <header className="aggrid-header-title">{group.name}</header>
                <header className="aggrid-header-subtitle">{group.description}</header>
                <header className="aggrid-header-subtitle-icon">
                    <IconLock width={iconSizeL} />
                    <span>Grant Employee Access? {group.grants_employee_access ? "Yes" : "No"}</span>
                </header>
            </div>
            <div className="aggrid-header-title-block-right">
                <IconButton
                    id="modifyGroupBtn"
                    text="Modify Group"
                    onClick={() => {
                        context.enableSideRail({ flow: "GROUP_MODIFY", groupId: group.id })
                    }}
                />
            </div>
        </div>
    )
}
