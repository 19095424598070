import React, { Component } from "react"
import Modal from "react-modal"
import styled from "styled-components"
import Button from "../button"
import { getFlagEnabled } from "../../getFlagValue"

import "modals.less"

export type tStandardModalProps = {
    additionalClasses?: string
    autoClose?: boolean
    secondaryAutoClose?: boolean
    backgroundCloseEnabled?: boolean
    closeMethod: () => void
    confirmBtn?: string
    continueButtonClass?: string
    description?: string
    secondaryBtnOnClick?: () => void
    secondaryBtnText?: string
    primaryBtnDisabled?: boolean
    primaryBtnOnClick?: () => void
    primaryBtnText?: string
    header?: string
    id?: string
    noButton?: boolean
    open: boolean
    singleButton?: boolean
    size?: string
    title?: string
}

class StandardModal extends Component<tStandardModalProps> {
    static defaultProps = {
        autoClose: true,
        backgroundCloseEnabled: false,
        confirmBtn: "green",
        noButton: false,
        primaryBtnDisabled: false,
        primaryBtnText: "Continue",
        secondaryAutoClose: true,
        secondaryBtnText: "Cancel",
        singleButton: false,
    }

    componentWillUnmount(): void {
        this.props.closeMethod()
    }

    primaryBtnOnClick = (): void => {
        if (this.props.primaryBtnOnClick) {
            this.props.primaryBtnOnClick()
        }
        this.props.autoClose && this.props.closeMethod()
    }

    secondaryBtnOnClick = (): void => {
        if (this.props.secondaryBtnOnClick) {
            this.props.secondaryBtnOnClick()
        }
        this.props.secondaryAutoClose && this.props.closeMethod()
    }

    header = (): JSX.Element | undefined => {
        if (this.props.header) {
            return <div className="modal-header">{this.props.header}</div>
        }
    }

    title = (): JSX.Element | undefined => {
        if (this.props.title) {
            return <h4 className="modal-title">{this.props.title}</h4>
        }
    }

    description = (): JSX.Element | undefined => {
        if (this.props.description) {
            // We only need to protect dangerous strings, not JSX elements
            if (typeof this.props.description === "string" || !getFlagEnabled("WA-7373-csv-default-values")) {
                return (
                    <h5
                        className="modal-description"
                        dangerouslySetInnerHTML={{ __html: this.props.description }}
                    ></h5>
                )
            }
            return <h5 className="modal-description">{this.props.description}</h5>
        }
    }

    backgroundClose = (): void => {
        if (!this.props.backgroundCloseEnabled) {
            return
        }
        return this.props.closeMethod()
    }

    render(): JSX.Element {
        const { secondaryBtnText, primaryBtnText, confirmBtn } = this.props
        let left = "50%"
        let width = "480px"
        let height
        let minHeight = "100px"
        let btnContainerClass = "modal-btn-container"
        switch (this.props.size) {
            case "small":
                width = "310px"
                btnContainerClass += " split-width-btns"
                break
            case "csv_error":
                width = "967px"
                minHeight = "250px"
                break
            case "signature_modal":
                width = "650px"
                break
            case "referral-modal":
                width = "982px"
                minHeight = "492px"
                left = "55%"
                break
            case "success-modal":
                minHeight = "236px"
                width = "456px"
                break
            case "medium":
                width = "450px"
                break
            case "share-modal":
                width = "620px"
                minHeight = "295px"
                break
            case "big":
                width = "967px"
                minHeight = "700px"
                break
            case "bigger":
                width = "1060px"
                minHeight = "700px"
                break
            case "weekly-view-detail-modal":
                width = "90vw"
                height = "90vh"
                minHeight = "90vh"
                break
            default:
                break
        }

        const className = "Overlay" + (this.props.additionalClasses ? ` ${this.props.additionalClasses}` : "")

        return (
            <Modal
                className="standard-modal"
                isOpen={this.props.open}
                ariaHideApp={false}
                onRequestClose={this.backgroundClose}
                shouldCloseOnOverlayClick={this.props.backgroundCloseEnabled}
                overlayClassName={className}
                style={{
                    content: {
                        left: left,
                        width: width,
                        height: height,
                        minHeight: minHeight,
                    },
                }}
            >
                {/* react-modal doesn't pass down the ID attribute for some reason */}
                <div id={this.props.id} className="standard-modal-inner">
                    {this.header()}
                    <FlexibleContent className="flexible-content">
                        {this.title()}
                        {this.description()}
                        {this.props.children}
                    </FlexibleContent>
                    {!this.props.noButton && (
                        <div className={btnContainerClass}>
                            {!this.props.singleButton && (
                                <Button
                                    text={secondaryBtnText ? secondaryBtnText : ""}
                                    onClick={this.secondaryBtnOnClick}
                                    id="modal-cancel"
                                />
                            )}
                            <Button
                                text={primaryBtnText ? primaryBtnText : ""}
                                color={confirmBtn}
                                disabled={this.props.primaryBtnDisabled}
                                positionClass="modal-btn-spacing"
                                continueButtonClass={this.props.continueButtonClass}
                                onClick={this.primaryBtnOnClick}
                                id="modal-confirm"
                            />
                        </div>
                    )}
                </div>
            </Modal>
        )
    }
}

const FlexibleContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 50px;
    padding: 20px;
`

export default StandardModal
