import React, { Component } from "react"
import { connect } from "react-redux"
import StandardModal from "./modal-standard"
import { csvErrorModalClosed } from "./actions"
import CsvErrorList from "../csv_error_list"

class CsvErrorModal extends Component {
    constructor(props) {
        super(props)
    }

    closeModal = () => {
        this.props.dispatch(csvErrorModalClosed())
    }

    /**
     * TODO: WA-4292 needs to be done to clean this up
     * Errors returned from the server currently have a few different shapes.
     * This processing caputres all of them.
     * 1. A custom one: {"data":{"equipment_id":["error message."]}}
     * 2. Django's default: {"data":{"errors":{"non_field_errors":["error message"]}}}
     * 3. Bulk Upsert: {"data":{"errors":{"1":{"classification":["error message"]}}}}
     * We need to get rid of #1 but the others should stay supported.
     */
    parseErrors = errors => {
        const errorEntries = []

        for (const [row, value] of Object.entries(errors)) {
            const fields = Object.keys(value)
            if (fields.length > 0) {
                let rowNumber = Number.parseInt(row)
                let rowErrors = value
                // front-end validation and back-end bulk-upsert validation
                // index the errors so this conforms the error display to that
                if (rowNumber >= 0) {
                    // users get confused that the first row is row 0 because
                    // spreadsheets show it as 1, so match the spreadsheet 8-|
                    rowNumber++
                    rowErrors = []
                    fields.forEach(field => {
                        const upperCaseField = field.charAt(0).toUpperCase() + field.slice(1)
                        const messages = Object.values(value[field]).join(", ")
                        rowErrors.push(`"${upperCaseField}": ${messages}`)
                    })
                }
                errorEntries.push(
                    <tr key={rowNumber}>
                        <td>{rowNumber}</td>
                        <td data-testid={`csv-error-message ${rowNumber}`}>{rowErrors.join("; ")}</td>
                    </tr>
                )
            }
        }

        return errorEntries
    }

    render() {
        let errorEntries = []

        if (this.props.errors.non_field_errors) {
            errorEntries.push(
                <tr key="non_field">
                    <td>N/A</td>
                    <td>{this.props.errors.non_field_errors}</td>
                </tr>
            )
        } else {
            errorEntries = this.parseErrors(this.props.errors)
        }

        const modalTitle = "CSV not uploaded. Please correct the following errors."

        return (
            <StandardModal
                title={modalTitle}
                open={this.props.open}
                closeMethod={this.closeModal}
                singleButton={true}
                primaryBtnText="Done"
                primaryBtnOnClick={this.closeModal}
                size="csv_error"
            >
                <CsvErrorList errors={errorEntries} />
            </StandardModal>
        )
    }
}

function mapStateToProps(state) {
    return Object.assign({}, state.modals.modalCsvError)
}

export default connect(mapStateToProps)(CsvErrorModal)
