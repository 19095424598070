import {
    tFilterState,
    tFilterAction,
    tSavedFilterSetState,
    tSavedFilterAction,
    iInitialFiltersAppliedAction,
    iFetchSavedFilterSetsSuccessful,
    iLogout,
    iSetSavedFilterSetAction,
    iSetInvalidSelectionsForSavedFilterSetAction,
    iSetPendingFilterToStateAction,
    iUpdateCurrentUser,
} from "./types"
import { getFlagEnabled } from "../getFlagValue"
import { getCookie, setCookie } from "../common/ts-utils"

const getInitialFilters = window.sessionStorage.getItem("filters") || "{}"
const initialState = getFlagEnabled("WA-7926-rr-filter-save") ? {} : JSON.parse(getInitialFilters)

export function filters(
    state: tFilterState = initialState,
    action: iSetPendingFilterToStateAction | iLogout | iUpdateCurrentUser
): tFilterState {
    if (action.type === "LOGOUT") {
        return {}
    }

    // This action happens every refresh, which means it's an ideal time to grab any stored filters
    // and apply them.
    if (action.type === "UPDATE_CURRENT_USER") {
        if (getFlagEnabled("WA-7926-rr-filter-save")) {
            const previousFilters = getCookie(`filters_${action.data.id}`)
            if (previousFilters) {
                return { ...JSON.parse(previousFilters) }
            } else return {}
        } else return state
    }

    const { type, payload } = action

    if (payload) {
        const { filtersToAdd, currentUser } = payload

        switch (type) {
            case "SET_PENDING_FILTERS_TO_STATE":
                const newState = { ...state, ...filtersToAdd }
                if (getFlagEnabled("WA-7926-rr-filter-save")) {
                    if (currentUser) {
                        const storageKey = `filters_${currentUser.id}`
                        setCookie(storageKey, JSON.stringify(newState), "/", 30)
                    }
                } else window.sessionStorage.setItem("filters", JSON.stringify(newState))
                return newState
        }
    }
    return state
}

export function pendingFilters(state: tFilterState = initialState, action: tFilterAction): tFilterState {
    const { type, payload } = action

    if (payload) {
        const { filtersToAdd } = payload

        switch (type) {
            case "SET_MULTIPLE_FILTER_VALUES":
                return { ...state, ...filtersToAdd }
            case "SET_STATE_TO_PENDING_FILTERS":
                return { ...filtersToAdd }
        }
    }

    return state
}

// TODO: The filter state is getting written to the top level of savedFilterSets somewhere in here -
// e.g. we have properties like savedFilterSets.startDate and savedFilterSets.endDate - need to fix that

// Reducer that supports the saved filter sets that can be applid on some pages
export function savedFilterSets(
    state: tSavedFilterSetState = initialState,
    action: tSavedFilterAction
): tSavedFilterSetState {
    const { type, payload } = action
    const activeSavedFilter: any = state.activeSavedFilter ? state.activeSavedFilter : {}

    // TODO: Use string consts for the types
    switch (type) {
        case "FETCH_SAVED_FILTER_SET_SUCCESSFUL":
            return {
                ...state,
                savedFilterSets: (action as iFetchSavedFilterSetsSuccessful).payload ?? undefined,
                error: undefined,
            }
        case "FETCH_SAVED_FILTER_SET_FAILED":
            return { ...state, error: payload?.error ?? undefined }
        case "APPLY_SAVED_FILTER_SET_SUCCEEDED":
            return { ...state, error: undefined }
        case "APPLY_SAVED_FILTER_SET_FAILED":
            return { ...state, error: payload?.error ?? undefined }
        case "UPDATE_SAVED_FILTER_SET_SUCCESSFUL":
            return { ...state, error: undefined }
        case "UPDATE_SAVED_FILTER_SET_FAILED":
            return { ...state, error: payload?.error ?? undefined }
        case "SET_SAVED_FILTER_SET":
            return { ...state, activeSavedFilter: (action as iSetSavedFilterSetAction).payload ?? undefined }
        case "SET_INVALID_SELECTIONS_FOR_SAVED_FILTER_SET":
            activeSavedFilter["invalidSelections"] = {
                ...activeSavedFilter.invalidSelections,
                ...(action as iSetInvalidSelectionsForSavedFilterSetAction).payload.invalidSelections,
            }
            activeSavedFilter["invalidFilters"] = payload.invalidFilters
            return { ...state, activeSavedFilter }
        case "CLEAR_INVALID_SELECTIONS_FOR_SAVED_FILTER_SET":
            activeSavedFilter["invalidSelections"] = {}
            activeSavedFilter["invalidFilters"] = []
            return { ...state, activeSavedFilter }
    }

    return state
}

export function initialFiltersApplied(state = false, action: iInitialFiltersAppliedAction): boolean {
    const { type, payload } = action
    if (payload) {
        const { initialized } = payload
        switch (type) {
            case "SET_INITIAL_FILTERS_APPLIED":
                return initialized
        }
    }
    return state
}
