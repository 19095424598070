const initialCompanyState = {
    company_name: null,
    id: null,
    logo: null,
}

const initialLogoState = {
    errorMsg: null,
    isUploading: false,
}

type tCompanyAction = {
    type: string
    data: {
        company_name: string
        id: number
        logo: string | null
    }
}

type tLogoAction = {
    type: string
}

export function company(state = initialCompanyState, action: tCompanyAction) {
    switch (action.type) {
        case "FETCH_COMPANY_INFO":
        case "UPDATE_COMPANY_INFO_FAILED":
            return { ...state }
        case "UPDATE_COMPANY_INFO":
            return {
                ...state,
                company_name: action.data["company_name"],
                id: action.data["id"],
                logo: action.data["logo"],
            }
        default:
            return state
    }
}

export function logo(state = initialLogoState, action: tLogoAction) {
    switch (action.type) {
        case "LOGO_UPLOAD_FAILED":
            return {
                ...state,
                errorMsg: "Image upload failed. Please try again.",
                isUploading: false,
            }
        case "LOGO_UPLOAD_COMPLETE":
            return {
                ...state,
                isUploading: false,
            }
        case "LOGO_UPLOAD_STARTED":
            return {
                ...state,
                isUploading: true,
            }
        case "CLEAR_UPLOAD_ERRORS":
            return {
                ...state,
                errorMsg: null,
            }
        default:
            return state
    }
}
