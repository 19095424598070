import React, { useState } from "react"
import { connect } from "react-redux"
import { Action, bindActionCreators, Dispatch } from "redux"
import { ReduxState } from "../../common/types"
import { tFilterState } from "../../filters/types"
import { closeCreateSavedFilterModal } from "./actions"
import { createSavedFilter } from "../../filters/actions"

import StandardModal from "./modal-standard"
import {
    logUserAmplitudeEvent,
    SAVED_FILTER_SET_CREATED,
    SAVED_FILTER_SET_CREATE_MODAL_CANCEL,
} from "../../common/amplitude-event-logging"

type tProps = {
    modalIsOpen: boolean
    filterState: tFilterState
    page: string
    message: string
    employeeId: number
    closeCreateSavedFilterModal: () => void
    createSavedFilter: (filterState: tFilterState, name: string, page: string, employee: number) => void
}

const CreateSavedFilterModal = (props: tProps) => {
    const amplitudeInfo = {
        pageIdentifier: props.page,
    }

    // Breaking out a separate handler for the cancel button allows us specifically to track
    // users who close the modal on purpose, as opposed to all the other times that the modal is
    // ordered to close
    const handleCancel = () => {
        logUserAmplitudeEvent(SAVED_FILTER_SET_CREATE_MODAL_CANCEL, amplitudeInfo)
        closeModal()
    }

    const closeModal = () => {
        if (props.modalIsOpen) {
            props.closeCreateSavedFilterModal()
        }
    }

    const [savedFilterSetName, setSavedFilterName] = useState("")

    const createSavedFilter = () => {
        props.createSavedFilter(props.filterState, savedFilterSetName, props.page, props.employeeId)
        logUserAmplitudeEvent(SAVED_FILTER_SET_CREATED, amplitudeInfo)
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSavedFilterName(e.target.value)
    }

    return (
        <StandardModal
            autoClose={false}
            open={props.modalIsOpen}
            closeMethod={closeModal}
            primaryBtnText="Save New Filter"
            primaryBtnOnClick={createSavedFilter}
            primaryBtnDisabled={false}
            secondaryBtnText="Cancel"
            secondaryBtnOnClick={handleCancel}
            id={"saved-filter-set-modal"}
        >
            <h1 className="modal-title">Save current filter</h1>
            <div className="modal-description">This filter will only be visible to you.</div>
            <div className="modal-input-label">Name</div>
            <input
                id="saved-filter-name"
                className="modal-input-text"
                placeholder="Give this filter a name"
                onChange={onChange}
            />
            {props.message && props.message.length > 0 ? (
                <div className="modal-input-error">{props.message}</div>
            ) : null}
        </StandardModal>
    )
}

function mapStateToProps(state: ReduxState) {
    return {
        modalIsOpen: state.modals.modalCreateSavedFilter.open,
        filterState: state.modals.modalCreateSavedFilter.filterState,
        page: state.modals.modalCreateSavedFilter.page,
        message: state.modals.modalCreateSavedFilter.message,
        employeeId: state.current_user.employee_id,
    }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
            closeCreateSavedFilterModal,
            createSavedFilter,
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(CreateSavedFilterModal)
