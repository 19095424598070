import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { colorAttentionBlue10, colorAttentionBlue50, spacingS } from "@rhumbix/rmbx_design_system_web"
import { requestTimekeepingSignatures } from "../../../actions/timekeeping-signatures"
import debounce from "lodash/debounce"
import { ThunkDispatch } from "redux-thunk"

type tProps = {
    context: Record<string, any>
    data?: Record<string, any>
}

const SignatureActionsCellRenderer: React.FunctionComponent<tProps> = params => {
    const [textAlertsOk, setTextAlertsOk] = useState<boolean>(false)
    const [hasPhoneNumber, setHasPhoneNumber] = useState<boolean>(false)
    const [optedOutOfText, setOptedOutOfText] = useState<boolean>(false)
    const [requireSignature, setRequireSignature] = useState<boolean>(false)
    const [isDailySignature, setIsDailySignature] = useState<boolean>(false)
    const [hasSignature, setHasSignature] = useState<boolean>(false)
    const { context } = params
    const { referenceableData } = context
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()

    useEffect(() => {
        // If we're in a regular individual row
        if (params.data) {
            if (params.data?.require_signature) setRequireSignature(true)
            if (referenceableData.employees[params.data?.employee]?.text_alerts_ok) setTextAlertsOk(true)
            if (referenceableData.employees[params.data?.employee]?.phone) setHasPhoneNumber(true)
            if (referenceableData.employees[params.data?.employee]?.text_opted_out_on) setOptedOutOfText(true)
            if (params.data?.signature_period === "DAILY") setIsDailySignature(true)
            if (params.data?.signature) setHasSignature(true)
        }
    }, [])

    useEffect(() => {
        if (params.data?.signature) setHasSignature(true)
    }, [params.data?.signature])

    const dispatchOnRequestSignature = (signing = false) => {
        if (params?.data?.employee_work_shift_id && params?.data?.employee) {
            dispatch(
                requestTimekeepingSignatures({
                    start_date: params.data.date,
                    end_date: params.data.date,
                    eid: params.data.employee,
                    return_url: signing,
                    is_mobile: !signing,
                    has_signature: hasSignature,
                })
            )
        }
    }

    /**
     * Fire off the request to /api/v4/request_signature/
     */
    const onRequestSignature = () => {
        dispatchOnRequestSignature()
    }

    /**
     * TODO
     * People sign up for text right now exclusively through mobile,
     * to enable sign up for text on web, we'll need to send a patch to employees with
     * { text_alerts_ok: true, phone: `+1${this.state.phoneNumber}` }
     */
    // const onSubscribeToText = () => {
    // }

    const onSign = () => {
        dispatchOnRequestSignature(true)
    }

    /**
     * If the project requires signatures, we're not in an aggregate row, and not showing history, show buttons.
     *     If we're showing buttons, if the employee has a phone number and text_alert_ok = True
     *         Show the "Request Signature" button - disable it if it's weekly.
     *     Else
     *         Show the "Subscribe to Texts" button - disable it if there's no phone number, or they opted out.
     */
    return requireSignature && params.data !== undefined && !params.data?.history_id ? (
        <ButtonContainer>
            {requireSignature ? (
                <ActionButton
                    onClick={debounce(() => {
                        onSign()
                    }, 500)}
                    disabled={!isDailySignature}
                    id="tk-modal-sign"
                >
                    <div>{!hasSignature ? "Sign" : "Re-Sign"}</div>
                </ActionButton>
            ) : (
                <></>
            )}
            {context.currentUser?.features?.text_for_signature ? (
                <ActionButton
                    longerButton={true}
                    onClick={debounce(() => {
                        onRequestSignature()
                    }, 500)}
                    disabled={!isDailySignature || optedOutOfText || !hasPhoneNumber || !textAlertsOk}
                    id="tk-modal-request-signature"
                >
                    <div>{`Request ${hasSignature ? "New " : ""}Signature`}</div>
                </ActionButton>
            ) : (
                // We don't have text subscription from weekly view set up yet
                // <ActionButton
                //     longerButton={true}
                //     onClick={onSubscribeToText}
                //     disabled={!hasPhoneNumber || optedOutOfText}
                // >
                //     <div>Subscribe to Texts</div>
                // </ActionButton>
                <></>
            )}
        </ButtonContainer>
    ) : (
        <></>
    )
}

export default SignatureActionsCellRenderer

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: ${spacingS};
    justify-content: start;
`
/* pointer-events: auto overrides default css that allows this toggle to register the click/change events,
as there is css defined in aggrid.less that sets pointer-events: none */
const ActionButton = styled.button<{ longerButton?: boolean }>`
    background-color: ${colorAttentionBlue10};
    color: ${colorAttentionBlue50};
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    pointer-events: auto;
    min-width: 30px;
    height: 32px;
    text-align: center;
    padding: 8px;
    border: 0px;
    width: auto;
    border-radius: 2px;
}
`
