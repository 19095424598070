import CollapsibleMenuGroup from "../collapsible-menu-group"
import React from "react"
import { navCategories } from "../../../common/constants"
import MenuOptions from "../menu-options"
import { IconSigned } from "@rhumbix/rmbx_design_system_web"

const FieldForms = props => {
    return (
        <CollapsibleMenuGroup
            {...props}
            iconName="field-forms"
            icon={<IconSigned width={20} height={20} />}
            menuTitle="Field Forms"
            navCategory="field-forms"
            isHidden={!(props.features["custom_form"] || props.features["admin.quantity_edit"])}
        >
            <MenuOptions {...props} options={props.routes[navCategories.fieldForms]} />
        </CollapsibleMenuGroup>
    )
}

export default FieldForms
