import React from "react"
import { IconAddBorderless } from "@rhumbix/rmbx_design_system_web"

export const getSelectorActionButtons = () => ({
    createNewTimecardButton: {
        value: "createNewTimecardCell",
        customContent: {},
        label: "Create New Timecard",
        data: {},
        isActionButton: true,
        icon: <IconAddBorderless className="icon-action" />,
    },
})
