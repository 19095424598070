import {
    checkboxColDefCreator,
    companyAbsenceTypeColDefCreator,
    dateColDefCreator,
    editableTimeCardColDefCreator,
    employeeColDefCreator,
    timekeepingStatusColDefCreator,
} from "./standard-col-def-creators"
import { getSelectorActionButtons } from "./action-button-settings"
import {
    alertCellClassRule,
    editTimecardDetailsAction,
    getAvailableStatusValues,
    timekeepingDataIsEditable,
    timekeepingRowIsEditable,
} from "../../../common/ag-grid-utils"
import { lockedPeriodCellClassRule } from "../../../common/ag-grid-ts-utils"
import { timekeepingDisabledTooltip } from "../../../common/ag-grid-custom-tooltip-formatters"
import { weeklyTkDummyProject } from "../../../common/constants"
import { togglePlaceholdersVisible } from "../../../actions"

export const getAbsenceTable = (featureFlags, currentProject, user) => {
    const userRole = user ? user.user_role : undefined
    const showHidePlaceholders = [
        {
            label: "Show Placeholders",
            action: "togglePlaceholders",
            icon: "view",
            customButtonType: "placeholders",
        },
    ]
    return {
        tableName: "Absences",
        resources: ["absences"],
        colDefs: [
            dateColDefCreator({}),
            checkboxColDefCreator({}),
            {
                ...employeeColDefCreator({}),
                ignoreRelatedFilters: ["projectId"],
            },
            {
                ...editableTimeCardColDefCreator({
                    headerName: "Time Card",
                    required: true,
                    width: 300,
                }),
                ignoreRelatedFilters: ["projectId"],
                extraFilterQueryParams: ["start_date", "foreman_id"],
                extraSearchFilters: { allow_empty: true },
                actionOptions: [getSelectorActionButtons().createNewTimecardButton],
            },
            companyAbsenceTypeColDefCreator({
                editable: true,
            }),
            timekeepingStatusColDefCreator({
                enum: getAvailableStatusValues(userRole),
            }),
        ],
        gridSettings: {
            defaultColDef: {
                cellClassRules: {
                    "period-locked": lockedPeriodCellClassRule,
                    readonly: params => !timekeepingDataIsEditable(params).canEdit,
                    "alert-cell": alertCellClassRule,
                },
                editable: params => timekeepingDataIsEditable(params).canEdit,
                filter: false,
                customTooltip: timekeepingDisabledTooltip,
            },
            enableRangeSelection: true,
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            suppressMultiRangeSelection: true,
            isRowSelectable: rowNode => timekeepingRowIsEditable(rowNode),
        },
        otherSettings: {
            pasteRangeEnabled: true,
            enableClipboard: true,
            filterByLockedColumns: true,
            hideLockedColumns: true,
            validationErrorTriggersAlert: true,
            highlightErrorRows: true,
            sizeColumnsToFitSize: 915,
            isInModalTab: true,
            rowLevelErrorDisplay: true,
            suppressExportButton: true,
            hiddenColumnDefaults: {
                project: {
                    value: weeklyTkDummyProject,
                },
            },
            buttons: {
                cell: [],
                row: [
                    {
                        label: "Delete",
                        icon: "delete",
                        action: "deleteSelectedRows",
                        args: {
                            local_state_update: true,
                        },
                    },
                    ...editTimecardDetailsAction(),
                ],
                table: [
                    {
                        label: "Add Row",
                        icon: "add",
                        action: "addNewRow",
                    },
                    ...(!featureFlags.cico ? [...showHidePlaceholders] : []),
                    ...editTimecardDetailsAction(),
                    ...(featureFlags.cico
                        ? [
                              {
                                  label: "Show/Hide ▾",
                                  icon: "view",
                                  args: {
                                      extraArgs: {
                                          options: [
                                              {
                                                  label: "Placeholders",
                                                  action: togglePlaceholdersVisible,
                                                  // The state parameter we look at to see if this should be
                                                  // checked on
                                                  activeStateParams: ["placeholdersVisible"],
                                              },
                                          ],
                                      },
                                  },
                                  checklist: true,
                              },
                          ]
                        : []),
                ],
            },
        },
    }
}
