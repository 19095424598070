import React from "react"
import { Redirect } from "react-router-dom"

export const CompanyAdminIndexRedirect = () => <Redirect to="/rhumbix/company-admin/users/edit" />

export const CompanyAdminEquipmentIndexRedirect = () => <Redirect to="/rhumbix/company-admin/equipment/edit" />

export const CompanyAdminMaterialsIndexRedirect = () => <Redirect to="/rhumbix/company-admin/materials/edit" />

export const RelativeEditIndexRedirect = props => (
    <Redirect to={`${props.match.url}${props.match.url.substr(-1) === "/" ? "edit" : "/edit"}/`} />
)

export const RelativeWeeklyIndexRedirect = props => (
    <Redirect to={`${props.match.url}${props.match.url.substr(-1) === "/" ? "weekly" : "/weekly"}/`} />
)
