import React, { PureComponent } from "react"
import { Dispatch as iDispatch, bindActionCreators, Action } from "redux"
import { connect } from "react-redux"
import StandardModal from "./modal-standard"
import { closeDeleteConfirmationModal } from "./actions"

type tProps = {
    open: boolean
    additionalClasses?: string
    deleteAction: () => void
}

class DeleteConfirmationModal extends PureComponent<tDeleteModalProps> {
    // This can be used as a generic delete confirmation
    // just pass in a deleteAction
    render = () => {
        return (
            <StandardModal
                title="Are you sure you want to delete?"
                description="This action cannot be undone."
                open={this.props.open}
                closeMethod={this.props.closeDeleteConfirmationModal}
                primaryBtnText="Delete"
                primaryBtnOnClick={this.props.deleteAction}
                confirmBtn="red"
                secondaryBtnOnClick={this.props.closeDeleteConfirmationModal}
                additionalClasses={this.props.additionalClasses}
            />
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        open: state.modals.deleteConfirmationModal.open,
    }
}

const mapDispatchToProps = (dispatch: iDispatch<Action>) =>
    bindActionCreators({ closeDeleteConfirmationModal }, dispatch)

type tDeleteModalProps = tProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export default connect(mapStateToProps, mapDispatchToProps)(DeleteConfirmationModal)
