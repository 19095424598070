import React, { useContext } from "react"
import { GPTreeDashboardRowData } from "../../../../cached-data/types"
import { ResourceObject } from "../../../../dashboard-data/types"
import { SideRailContext } from "../../../SideRail/SideRailContext"
import { PicklistAccessItemType } from "../types"

type Props = {
    data: ResourceObject<GPTreeDashboardRowData>
}

type ComponentType = (itemType: PicklistAccessItemType) => React.FC<Props>
export const ManagePicklistAccessButton: ComponentType = itemType => ({ data }) => {
    const { enableSideRail, sideRailConfig } = useContext(SideRailContext)

    const openSideRail = () => {
        // This title should never get used, as the button is only created on data tables
        // but this gives a reasonable default if it's used from another flow
        let title = "Picklist"
        if (sideRailConfig.flow === "DATA_TABLE") {
            title = sideRailConfig.title
            if (title === "Modify Trades") {
                title = "Trades"
            } else if (title === "Modify Classifications") {
                title = "Classifications"
            } else {
                // extract the name of the picklist from the tablename of the table we're on
                // which is structured like "modify <picklist name> piclist items"
                title = title.slice("modify ".length, title.length - " picklist items".length)
            }
        }

        const openRemapModal =
            sideRailConfig.flow === "DATA_TABLE" && sideRailConfig.openRemapModal
                ? sideRailConfig.openRemapModal
                : (_: Record<string, any>) => {}

        enableSideRail({
            flow: "MANAGE_PICKLIST_ACCESS",
            type: itemType,
            ids: [data.id],
            previousConfig: sideRailConfig,
            itemTitle: `${title}, ${data.name}`,
            openRemapModal: openRemapModal,
            remapEnabled: true,
        })
    }

    // On a new row, we shouldn't render the Manage Access Button
    if (data.newRow) {
        return <span></span>
    }

    return (
        <button onClick={openSideRail} type="button" className="action-button">
            <div>Manage Access</div>
        </button>
    )
}

export default ManagePicklistAccessButton
