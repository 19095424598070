import { FETCH_COMPANY_SSO_CONFIGURATION_SUCCEEDED, tCompanySSOConfigurationActions } from "../common/types"

export type tCompanySSOConfigurationState = {
    id?: number
    assignedExceptions: any[]
    ssoEnabled: boolean
    connectionName: string
    connectionLogo: string
    oidcClientKey: string
    oidcClientSecret?: string
    hasClientSecret?: boolean
    oidcAuthEndpoint: string
    oidcUserInfoEndpoint: string
    oidcTokenEndpoint: string
    issuerReference: string
    createAccountsByDefault: string
    oidcUrl: string
    scope: string
    claimMappingEmail: string
    claimMappingFirstName: string
    claimMappingLastName: string
    claimMappingCompanySuppliedId: string
    claimMappingPhone: string
    claimMappingUserRole: string
    claimMappingTrade: string
    claimMappingClassification: string
    onCreateDefaultRole: string
    onCreateDefaultTrade: string
    onCreateDefaultClassification: string
}

const initialCompanySSOConfigurationState: tCompanySSOConfigurationState = {
    id: undefined,
    assignedExceptions: [],
    ssoEnabled: false,
    connectionName: "",
    connectionLogo: "",
    oidcClientKey: "",
    oidcClientSecret: "",
    hasClientSecret: false,
    oidcAuthEndpoint: "",
    oidcUserInfoEndpoint: "",
    oidcTokenEndpoint: "",
    issuerReference: "",
    createAccountsByDefault: "",
    oidcUrl: "",
    scope: "",
    claimMappingEmail: "",
    claimMappingFirstName: "",
    claimMappingLastName: "",
    claimMappingCompanySuppliedId: "",
    claimMappingPhone: "",
    claimMappingUserRole: "",
    claimMappingTrade: "",
    claimMappingClassification: "",
    onCreateDefaultRole: "",
    onCreateDefaultTrade: "",
    onCreateDefaultClassification: "",
}

export default function companySSOConfiguration(
    state = initialCompanySSOConfigurationState,
    action: tCompanySSOConfigurationActions
): tCompanySSOConfigurationState {
    switch (action.type) {
        case FETCH_COMPANY_SSO_CONFIGURATION_SUCCEEDED:
            const result = action.data[0]
            return result
                ? {
                      ...state,
                      id: result["id"],
                      assignedExceptions: result["assigned_exceptions"],
                      ssoEnabled: result["sso_enabled"],
                      connectionName: result["connection_name"],
                      connectionLogo: result["connection_logo"],
                      oidcClientKey: result["oidc_client_key"],
                      hasClientSecret: result["has_client_secret"],
                      oidcAuthEndpoint: result["oidc_auth_endpoint"],
                      oidcUserInfoEndpoint: result["oidc_user_info_endpoint"],
                      oidcTokenEndpoint: result["oidc_token_endpoint"],
                      issuerReference: result["issuer_reference"],
                      createAccountsByDefault: result["create_accounts_by_default"],
                      oidcUrl: result["oidc_url"],
                      scope: result["scope"],
                      claimMappingEmail: result["claim_mapping_email"],
                      claimMappingFirstName: result["claim_mapping_first_name"],
                      claimMappingLastName: result["claim_mapping_last_name"],
                      claimMappingCompanySuppliedId: result["claim_mapping_company_supplied_id"],
                      claimMappingPhone: result["claim_mapping_phone"],
                      claimMappingUserRole: result["claim_mapping_user_role"],
                      claimMappingTrade: result["claim_mapping_trade"],
                      claimMappingClassification: result["claim_mapping_classification"],
                      onCreateDefaultRole: result["on_create_default_role"],
                      onCreateDefaultTrade: result["on_create_default_trade"],
                      onCreateDefaultClassification: result["on_create_default_classification"],
                  }
                : state
        default:
            return state
    }
}
