import React, { useEffect } from "react"
import { connect } from "react-redux"
import InnerRoute from "./inner-route"
import ChangePasswordModal from "../components/modals/modal-change-password"
import LoadingIndicator from "../components/loading-indicator"
import { materialPricingEnabledSelector, analyticsSelector } from "../selectors"
import { PageForbidden } from "./lazy-components"

export const ProtectedRoute = ({ loadingIndicator, ...props }) => {
    const navConditionsMet = typeof props.navCondition !== "function" || props.navCondition(props)
    props.updateIsAuthorized(navConditionsMet)

    useEffect(() => {
        if (navConditionsMet) {
            props.updateMenuGroup(props.navCategory)
        }
    })

    return (
        <div className="rmbx logged-in" data-testid={props.path + "/"}>
            <div className="content-viewport">
                <LoadingIndicator active={loadingIndicator.active} />
                <div className="main-content logged-in">
                    {navConditionsMet ? <InnerRoute {...props} /> : <PageForbidden />}
                </div>
                <ChangePasswordModal />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    loadingIndicator: state.loadingIndicator,
    features: state.featureFlags.features,
    material_pricing_enabled: materialPricingEnabledSelector(state),
    is_staff: state.current_user?.is_staff ?? false,
    user_role: state.current_user ? state.current_user.user_role : null,
    company_role: state.current_user ? state.current_user.company_role : null,
    analytics_dashboards: analyticsSelector(state),
})

export default connect(mapStateToProps)(ProtectedRoute)
