const initialState = {
    signature: {
        origin: null,
        displayed: false,
    },
}

const popups = (state = initialState, action) => {
    switch (action.type) {
        case "SIGNATURE_OPENED":
            return Object.assign({}, state, {
                signature: { origin: action.id, displayed: true },
            })
        case "SIGNATURE_CLOSED":
            return Object.assign({}, state, {
                signature: initialState.signature,
            })
        default:
            return state
    }
}

export default popups
