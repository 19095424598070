import styled from "styled-components"
import {
    colorAttentionBlue10,
    colorAttentionBlue50,
    colorFunctionalGray50,
    colorMiscBlue70,
} from "@rhumbix/rmbx_design_system_web"
/** Types */
import { tSelectStyleProps } from "./types"

/** Styles for React Select */
export const colorStyles: tSelectStyleProps = {
    control: styles => ({ ...styles, display: "flex", flexWrap: "wrap", alignItems: "center" }),
    indicatorsContainer: styles => ({ ...styles, fontSize: "12px" }),
    multiValue: styles => ({ ...styles, backgroundColor: colorAttentionBlue10 }),
    multiValueRemove: styles => ({ ...styles, color: colorAttentionBlue50, cursor: "pointer" }),
    multiValueLabel: styles => ({
        ...styles,
        borderRadius: "2px",
        color: colorMiscBlue70,
        padding: "5px",
        height: "24px",
        lineHeight: 1,
        fontSize: "14px",
    }),
    option: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: isFocused ? colorAttentionBlue10 : null,
        cursor: "pointer",
        ":active": {
            ...styles[":active"],
            backgroundColor: null,
        },
    }),
    singleValue: styles => ({ ...styles, color: colorMiscBlue70 }),
}

/** Multi Select Checkbox */
export const StyledCheckbox = styled.input`
    margin: auto 10px auto 1px !important;
`

/** Clear Indicator */
export const StyledClearOptions = styled.div`
    font-weight: normal;
    color: ${colorFunctionalGray50};
    cursor: pointer;
`

/** Clear Symbol */
export const StyledClearX = styled.i`
    margin-right: 10px;
`
