import React from "react"
/** Components */
import { LabeledProgressBar } from "@rhumbix/rmbx_design_system_web"
/** Column Defs */
import {
    integerColDefCreator,
    multiLineColDefCreator,
    productionWeekColDefCreator,
    stringColDefCreator,
} from "./standard-col-def-creators"
import {
    JTDCompleteValueGetter,
    JTDQTYValueGetter,
    toGoQuantityValueGetter,
} from "../ProductionTracking/value-getters"
import {
    includeHiddenCostCodeFilterDef,
    productionCostCodesFilterDef,
    singleProjectFilterDef,
    weekFilterDef,
} from "../../../filters/filter-defs"
import { getAggParams } from "../../../common/ag-grid-ts-utils"

export const getProductionTrackingSettings = () => {
    const filters = [
        singleProjectFilterDef,
        weekFilterDef,
        productionCostCodesFilterDef,
        includeHiddenCostCodeFilterDef,
    ]

    // Columns with the "hide" prop are only used for exporting to CSV/Excel.
    return {
        tableName: "Production",
        navId: "production",
        resources: ["costCodes"],
        filters: filters,
        additionalQueryParams: {
            performance_weeks: 4,
            minimal_project_detail: true,
        },
        colDefs: [
            {
                ...multiLineColDefCreator({
                    headerName: "Cost Code",
                    field: "/code",
                    required: true,
                    width: 180,
                    valueGetter: params => {
                        const { code, description } = params.data
                        return { content: { title: code, subtitle: description } }
                    },
                }),
                aggFunc: params => ({ content: { title: params.value?.length } }),
                exportFormatter: value => value.content.title,
                getQuickFilterText: params => params.value.content.title,
            },
            stringColDefCreator({
                headerName: "Cost Code Description",
                field: "/description",
                hide: true,
            }),
            stringColDefCreator({
                headerName: "Cost Code Group",
                field: "/group",
                sort: "desc",
            }),
            productionWeekColDefCreator({
                field: "/three_weeks_before",
                valueIndex: 3,
            }),
            productionWeekColDefCreator({
                field: "/three_weeks_before_hours",
                hide: true,
                valueIndex: 3,
            }),
            productionWeekColDefCreator({
                field: "/two_weeks_before",
                valueIndex: 2,
            }),
            productionWeekColDefCreator({
                field: "/two_weeks_before_hours",
                hide: true,
                valueIndex: 2,
            }),
            productionWeekColDefCreator({
                field: "/previous_week",
                valueIndex: 1,
            }),
            productionWeekColDefCreator({
                field: "/previous_week_hours",
                hide: true,
                valueIndex: 1,
            }),
            productionWeekColDefCreator({
                headerName: "Selected Week",
                field: "/selected_week",
                valueIndex: 0,
            }),
            productionWeekColDefCreator({
                field: "/selected_week_hours",
                hide: true,
                valueIndex: 0,
            }),
            integerColDefCreator({
                headerName: "JTD QTY",
                field: "/total_quantities",
                valueGetter: JTDQTYValueGetter,
                width: 100,
                headerClass: "align-right",
                cellStyle: { textAlign: "right" },
            }),
            integerColDefCreator({
                headerName: "BQ",
                field: "/budget_quantities",
                width: 100,
                headerClass: "align-right",
                cellStyle: { textAlign: "right" },
            }),
            stringColDefCreator({
                headerName: "UoM",
                field: "/units",
                width: 100,
            }),
            {
                ...integerColDefCreator({
                    headerName: "JTD % Complete",
                    field: "/job_to_date_complete",
                    valueGetter: JTDCompleteValueGetter,
                    width: 150,
                    headerClass: "align-right",
                    cellStyle: { textAlign: "right" },
                }),
                // Find the average of the JTD % Complete.
                aggFunc: params =>
                    Math.round(
                        getAggParams(params).reduce((total, value) => total + value, 0) / params.values?.length
                    ),
                cellRenderer: params => (
                    <LabeledProgressBar percentComplete={params.value || 0} boldLabel={!!params.node.group} />
                ),
            },
            stringColDefCreator({
                headerName: "To Go QTY",
                field: "/to_go_quantities",
                valueGetter: toGoQuantityValueGetter,
            }),
        ],
        gridSettings: {
            getRowHeight: params => (params.node.group ? 50 : 80),
            // TODO: Remove this after the design system is set up.
            getRowStyle: params =>
                params.node.group ? { color: "#161616", "font-weight": "500" } : { color: "#161616" },
            defaultColDef: {
                editable: false,
            },
            autoGroupColumnDef: {
                cellRendererParams: {
                    suppressCount: true,
                },
                minWidth: 180,
                cellClass: "ag-row-no-checkbox",
                groupColumn: true,
            },
            animateRows: true,
            suppressRowClickSelection: true,
        },
        otherSettings: {
            groupBy: [
                {
                    cols: [],
                    label: "None",
                },
                {
                    cols: ["/group"],
                    label: "Cost Code Group",
                },
            ],
            buttons: {
                cell: [
                    {
                        label: "Edit",
                        icon: "edit",
                        action: "openSideRailTableForFocusedCell",
                    },
                ],
                table: [
                    {
                        label: "Expand/Collapse All",
                        action: "toggleRowExpansion",
                        icon: "expand",
                    },
                    {
                        label: "Group By",
                        action: "groupBy",
                        icon: "group",
                        dropdown: true,
                    },
                    {
                        label: "Export ▾",
                        icon: "export",
                        action: "export",
                    },
                ],
            },
            enableSearchBar: true,
            enableSideRail: true, // Open up the Side Rail when clicking on any of the week cells.
            /**
             * Export all the headers. Some of the productionWeek columns are not included
             * when exporting to CSV/Excel since they don't have the headerName defined.
             */
            exportAll: true,
        },
    }
}
