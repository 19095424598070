import React from "react"
import styled from "styled-components"
import { IconAddModifier } from "@rhumbix/rmbx_design_system_web"
import { TimekeepingEntry } from "../../../common/types"
import { ColDef } from "ag-grid-community"

type ModifierCellRendererProps = {
    colDef: ColDef
    data: TimekeepingEntry
    valueFormatted: string
}

const ModifierCellRenderer: React.FC<ModifierCellRendererProps> = params => {
    const { valueFormatted } = params
    return (
        <Container>
            {valueFormatted && (
                <div className="icon-container">
                    <IconAddModifier className="modifier-badge" />
                </div>
            )}
            <span>{valueFormatted || ""}</span>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;

    .icon-container {
        justify-content: space-around;
        display: flex;
        height: 17px;
        padding-right: 4px;
        margin-right: 4px;
    }
`

export default ModifierCellRenderer
