import React from "react"
import Button from "../button"
import closeWindowImg from "../../../images/sidebar_icons/close-window.svg"
import "invite.less"
import { actOnEnterOrSpace } from "../../common/js-utils"

const RhumbixReferralModalBody = props => (
    <div className="rhumbix-referral-modal">
        <div className="rhumbix-referral-close-btn">
            <button
                className="close-button"
                onClick={props.closeReferralModal}
                onKeyDown={e => actOnEnterOrSpace(e, () => props.closeReferralModal)}
            >
                <img src={closeWindowImg} alt="Close" className="close-image close-btn-pointer" />
            </button>
        </div>
        <div className="row col-md-8 referral-header">
            Share a free trial of Rhumbix <br /> Time and Materials with your peers <br /> and you&rsquo;ll receive
            free Rhumbix gear <br /> when they sign up!
        </div>
        <div className="row referral-header-additional-info col-md-8">
            <p>
                Anyone you invite to try Rhumbix will get free access to Rhumbix Time and
                <br /> Materials Tracking for three months. As a token of our appreciation for passing <br />
                Rhumbix along, you&rsquo;ll receive the latest Rhumbix swag to sport and enjoy.
            </p>
        </div>
        <div className="referral-body col-md-8">
            <div className="row referral-input-guidelines">
                <div className="col-md-12">Separate email addresses with commas, do not include spaces.</div>
                {props.emailInputHasErrors ? <div className="col-md-6">{props.errorMessage}</div> : null}
            </div>

            <div className="referral-input-container">
                <div>
                    <input
                        onChange={props.updateEmailRecipients}
                        className={`referral-email-recipients-input ${
                            props.emailInputHasErrors ? "referral-email-input-error" : ""
                        }`}
                        type="text"
                        placeholder="Ex. joe@rhumbix.com,franky@example.com"
                    />
                    <span className="referral-send-email-btn">
                        <Button
                            text="Send Email"
                            disabled={props.sendingInProgress}
                            image="send-email-icon"
                            imageFormat="svg"
                            color="rhumbix-yellow"
                            onClick={props.sendReferrals}
                        />
                    </span>
                </div>
                <div className="referral-email-body-container">
                    <textarea
                        onChange={props.updateEmailBody}
                        defaultValue={props.description}
                        className="referral-email-body"
                    />
                </div>
            </div>
        </div>
        <img
            className="col-md-4 referral-image"
            src={require("rhumbix-referral-reward.png")}
            alt="Rhumbix swag, including a hat, socks, and pool cue chalks"
        />
    </div>
)

export default RhumbixReferralModalBody
