import { checkboxColDefCreator, stringColDefCreator, booleanColDefCreator } from "./standard-col-def-creators"
import { isActiveValueFormatter } from "../../../common/ag-grid-value-formatters"
import { getFlagEnabled } from "../../../getFlagValue"

export const getCompanyEquipmentSettings = (_featureFlags, _currentProject, currentUser) => {
    const textFieldOptions =
        getFlagEnabled("WA-8402-custom-text-fields") && currentUser && currentUser.text_field_options
            ? currentUser.text_field_options.filter(option => option.model === "Equipment")
            : []

    const textFieldOptionColDefs = textFieldOptions.map(option =>
        stringColDefCreator({
            headerName: option.label,
            field: `/custom_fields/${option.name}`,
            cellClass: option.read_only_in_app ? ["readonly"] : [],
            editable: !option.read_only_in_app,
        })
    )

    const settings = {
        tableName: "Company Equipment",
        navId: "equipment",
        resources: ["equipment"],
        filters: [],
        colDefs: [
            checkboxColDefCreator(),
            stringColDefCreator({
                headerName: "Equipment ID*",
                field: "/equipment_id",
                required: true,
            }),
            stringColDefCreator({
                headerName: "Caltrans ID",
                field: "/caltrans_id",
            }),
            stringColDefCreator({
                headerName: "Type*",
                field: "/category",
                required: true,
            }),
            stringColDefCreator({
                headerName: "Description*",
                field: "/description",
                required: true,
            }),
            stringColDefCreator({
                headerName: "Rented/Owned",
                field: "/status",
                enum: ["Owned", "Rented"],
                default: "Owned",
            }),
            {
                ...booleanColDefCreator({
                    headerName: "Status",
                    field: "/is_active",
                    minWidth: 100,
                    editable: true,
                    default: true,
                }),
                valueFormatter: isActiveValueFormatter,
            },
            ...textFieldOptionColDefs,
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: true,
            },
            rowSelection: "multiple",
            // allow our click events to happen
            suppressRowClickSelection: true,
        },
        otherSettings: {
            pageTitle: "Equipment",
            subcontent: `Equipment includes any piece of rental or owned equipment assigned to this company.
            Add equipment to this company by uploading a .CSV file or manually typing in the
            equipment information.`,
            showProjectPicker: false,
            showDatePicker: false,
            rowLevelErrorDisplay: true,
            enableSearchBar: true,
            buttons: {
                cell: [
                    {
                        label: "Edit",
                        icon: "edit",
                        action: "editFocusedCell",
                    },
                    {
                        label: "Copy",
                        icon: "copy",
                        action: "copyFocusedCell",
                    },
                    {
                        label: "Paste",
                        icon: "paste",
                        action: "pasteIntoFocusedCell",
                    },
                ],
                row: [
                    {
                        label: "Activate",
                        icon: "view",
                        action: "bulkUpdateField",
                        args: {
                            field: "is_active",
                            value: true,
                        },
                    },
                    {
                        label: "Deactivate",
                        icon: "hide",
                        action: "bulkUpdateField",
                        args: {
                            field: "is_active",
                            value: false,
                        },
                    },
                    {
                        label: "Delete",
                        icon: "delete",
                        action: "deleteSelectedRows",
                    },
                ],
                table: [
                    {
                        label: "Add Row",
                        icon: "add",
                        action: "addNewRow",
                    },
                    {
                        label: "Upload",
                        icon: "upload",
                        action: "navigateTo",
                        args: {
                            url: "/rhumbix/company-admin/equipment/upload/",
                        },
                    },
                    {
                        label: "Export ▾",
                        icon: "export",
                        action: "export",
                    },
                ],
            },
        },
    }

    return settings
}
