import { isEqual } from "date-fns"
import { getAsDate } from "../../../common/ts-utils"

/**
 * Handle cell value update for Modify Production view.
 * @param {Object} params Cell editable callback params.
 */
export const handleModifyProductionCellUpdate = (params: Record<string, any>) => {
    const { data } = params
    // Check if the updated cell is the Quantity field.
    if (params.column.colId.replace("/", "") !== "quantity") return

    /**
     * If reported_hours is true and quantity_id is undefined, it is only a WorkShift entry.
     * - If location or description is not empty, set oldValue to a string so this becomes a required field.
     */
    if (data.reported_hours && !data.quantity_id)
        params.oldValue = !data.location && !data.description ? null : "required"
    else {
        /**
         * - Some entries in Modify Production view don't have discrete quantity value (= null).
         * - If a user changes this to a non-zero value, this will create a new quantities item in db.
         * - We want to keep tracking of the initial discrete quantity value to avoid creating a new quantities
         * db item when the value = 0 or null.
         */
        if (Object.prototype.hasOwnProperty.call(data, "initialQuantity")) params.oldValue = data.initialQuantity
        /**
         * When a cell updates for the first time, sets the oldValue as the initial value.
         * For new entries, set the initial value to a string so this becomes a required field.
         */ else data.initialQuantity = data.newRow ? (params.oldValue = "required") : params.oldValue
    }
}

/**
 * Determine if data in Modify Production view has changed.
 * - For reported_hours (WorkShift) entries, if location & description & quantity are null,
 * it indicates that data has not changed.
 * @param {Object} item Data to be validate.
 * @returns True if data has been modified.
 */
export const isModifyProdDataChanged = (
    item: Record<
        "reported_hours" | "location" | "description" | "quantity" | "quantity_id" | "quantity_pictures",
        any
    >
) =>
    !item.reported_hours ||
    item.quantity_id ||
    item.location ||
    item.description ||
    item.quantity ||
    item.quantity_pictures

/**
 * Create the initial top row when the table is rendered.
 * @param rowData Array of rows from ag-grid
 * @param topRow Data from the current pinned top Row
 * @param hours total hours passed down from the config initially through the value getter
 * @param quantities total quantities
 * @param units cost code units
 * @returns
 */
export const createPinnedTopRow = (
    rowData: Array<Record<string, any>>,
    topRow: Record<string, any> | undefined,
    hours: number,
    quantities: number,
    units: string
) => {
    // Count only unique foremen.
    const foremanIDSet = new Set()
    rowData.forEach(row => {
        foremanIDSet.add(row.foreman.email)
    })

    return [
        {
            foreman: {
                last_name: `${foremanIDSet.size} Total`,
            },
            quantity: topRow?.data.quantity || quantities,
            units: units,
            reported_hours: hours,
        },
    ]
}

/**
 * Recalculate the summary row when the table is updated in the RR
 * @param params from ag-grid / custom-table
 */
export const calculateSummaryRow = (params: Record<string, any>) => {
    const topRow = params.api.getPinnedTopRow(0)
    const { rowIndex, newValue, context, column, data } = params
    const { rowsToDisplay: tableRows } = params.api.rowModel
    const { default_entry_type, endDate } = context.sideRailContext.sideRailConfig.config
    /**
     * True if EITHER of the following are true:
     *  - there are no new rows at all
     *  - There are new row(s), but the new row is on the latest possible date in the week
     */
    const noNewLatestRows =
        !tableRows[0].newRow ||
        tableRows.filter((e: Record<string, any>) => e.newRow && isEqual(getAsDate(e.date), endDate)).length == 0
    let finalQuantity = topRow.data.quantity
    if (default_entry_type === "DISCRETE_QUANTITY") {
        // Discrete Quantity, sum up all the rows.
        finalQuantity = tableRows.reduce(
            (accum: number, elem: Record<string, any>) => (accum += elem.data.quantity),
            0
        )
    } else {
        // Percent Complete
        if (column.colId === "/quantity") {
            /**
             * If the quantity was updated, and EITHER of the following are true:
             *  - There are no new rows that have their end date as the latest end date,
             *    and the row being updated is the last row
             *  - New row, but the new row is on the latest possible date in the week
             *     Then update finalQuantity to the newly updated quantity value
             */
            if (
                (noNewLatestRows && rowIndex === tableRows.length - 1) ||
                (data.newRow && isEqual(getAsDate(data.date), endDate))
            ) {
                finalQuantity = newValue
            }
        } else if (column.colId === "/date") {
            /**
             * If the date was updated and the date is in the past compared to the endDate for this week
             *     finalQuantity = The quantity of the last row.
             * Else the new row is on the latest date of the week
             *     Use the new row's quantity as the final quantity.
             */
            if (getAsDate(newValue) < getAsDate(endDate)) {
                finalQuantity = tableRows[tableRows.length - 1].data.quantity
            } else {
                finalQuantity = data.quantity
            }
        }
    }

    params.api.setPinnedTopRowData([
        {
            ...topRow.data,
            quantity: finalQuantity,
        },
    ])
}
