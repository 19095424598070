import { sendReferralEmails } from "../api/post"

export const ReferralActionTypes = {
    OPEN_REFERRAL_MODAL: "OPEN_REFERRAL_MODAL",
    CLOSE_REFERRAL_MODAL: "CLOSE_REFERRAL_MODAL",
    SENDING_REFERRALS: "SENDING_REFERRALS",
    SEND_REFERRALS_SUCCEDED: "SEND_REFERRALS_SUCCEDED",
    SEND_REFERRALS_FAILED: "SEND_REFERRALS_FAILED",
    CLOSE_REFERRALS_SUCCESS_WINDOW: "CLOSE_REFERRALS_SUCCESS_WINDOW",
}

export const openRhumbixReferralModal = () => {
    return {
        type: ReferralActionTypes.OPEN_REFERRAL_MODAL,
    }
}

export const closeRhumbixReferralModal = () => {
    return {
        type: ReferralActionTypes.CLOSE_REFERRAL_MODAL,
    }
}

export const sendingReferrals = () => {
    return {
        type: ReferralActionTypes.SENDING_REFERRALS,
    }
}

const sendReferralsSucceded = () => {
    return {
        type: ReferralActionTypes.SEND_REFERRALS_SUCCEDED,
    }
}

const sendReferralsFailed = () => {
    return {
        type: ReferralActionTypes.SEND_REFERRALS_FAILED,
    }
}

export const closeReferralsSuccessDialog = () => {
    return {
        type: ReferralActionTypes.CLOSE_REFERRALS_SUCCESS_WINDOW,
    }
}

export const sendReferrals = data => {
    return dispatch => {
        dispatch(sendingReferrals())
        sendReferralEmails(data)
            .then(
                () => dispatch(sendReferralsSucceded()),
                setTimeout(() => {
                    dispatch(closeReferralsSuccessDialog())
                }, 3000)
            )
            .catch(error => {
                dispatch(sendReferralsFailed(error))
            })
    }
}
