import React from "react"
import { Provider } from "react-redux"
import { Router } from "react-router-dom"
import * as Sentry from "@sentry/react"

import AuthProvider from "./auth-provider"
import ErrorBoundary from "./error-boundary"
import { SideRailContextProvider } from "../components/SideRail/SideRailContext"
import WebsocketConnection from "../websockets/WebsocketConnection"

export const AppRouter = ({ history, store }) => (
    <Provider store={store}>
        <Sentry.ErrorBoundary fallback={ErrorBoundary}>
            <WebsocketConnection host={""}>
                <Router history={history}>
                    <SideRailContextProvider>
                        <AuthProvider location={history.location} />
                    </SideRailContextProvider>
                </Router>
            </WebsocketConnection>
        </Sentry.ErrorBoundary>
    </Provider>
)

export default AppRouter
