/**
 * Created by jongon on 7/13/17.
 */

"use strict"

const initialState = {
    operatingTokenValue: "",
    operatingTokenValueErrors: [],
    integrationTokenNames: [],
    isLoadingIndicatorShown: false,
    apiFailure: {},
    tokenMaskStatusById: [],
}

export default function apiIntegration(state = initialState, action) {
    switch (action.type) {
        case "START_API_INTEGRATION_LOADING_INDICATOR":
        case "STOP_API_INTEGRATION_LOADING_INDICATOR":
            return Object.assign({}, state, {
                isLoadingIndicatorShown: action.isLoadingIndicatorShown,
            })
        case "API_INTEGRATION_TOKEN_NAME_CHANGED":
            return Object.assign({}, state, {
                integrationTokenNames: action.data.results,
                operatingTokenValueErrors: [],
            })
        case "TOKEN_INTEGRATION_API_FAILURE": {
            let readableError = ""
            if (action.response.status === 500) {
                readableError = "There was an issue processing your request. Please try again later."
            } else if (action.response.status === 400) {
                if (action.response.data["company_integration_name"]) {
                    readableError = "The requested name is already in use, please try another name."
                } else {
                    readableError = "We could not process your request. Please try again with a different name."
                }
            }

            return Object.assign({}, state, {
                apiFailure: {
                    response: action.response,
                    textStatus: action.textStatus,
                },
                operatingTokenValueErrors: [readableError],
            })
        }
        case "UPDATE_OPERATING_TOKEN_VALUES":
            return Object.assign({}, state, {
                operatingTokenValue: action.tokenValue,
                operatingTokenValueErrors: action.tokenValueErrors,
            })
        default:
            return state
    }
}
