/**
 * These are basic javascript utitlity functions that
 * may be used in multiple places.
 */

export const convertFromJSONToCSV = (data, schema) => {
    const headerArray = [...constantHeader(), ...getHeadersForSchema(schema)]
    const csvData = []
    data.forEach(store => {
        csvData.push(...makeCSVData(store, headerArray, schema))
    })

    // Merge header and data separated with a new line
    return [...[headerArray.join(",")], ...csvData].join("\n")
}

const constantHeader = () => {
    return [
        '"id"',
        '"created_on"',
        '"submitted_on"',
        '"employee__id"',
        '"employee__company_supplied_id"',
        '"employee__last_name"',
        '"employee__first_name"',
        '"employee__classification"',
        '"employee__trade"',
        '"employee__user_role"',
        '"employee__is_active"',
        '"employee__thumbnail"',
        '"employee__fullsize"',
        '"employee__phone"',
        '"employee__email"',
        '"schema"',
        '"status"',
        '"project"',
        '"company_name"',
        '"company_address"',
    ]
}

const getHeadersForSchema = (schema, parent = "store", schemaKeys = []) => {
    Object.entries(schema).forEach(entry => {
        const [key, value] = entry
        const schemaKey = `${parent}__${key}`
        schemaKeys.push(`"${schemaKey}"`)
        if (value.type === "object" || value.type === "array") {
            const newSchema = value.type === "object" ? value.properties : value.items.properties
            getHeadersForSchema(newSchema, schemaKey, schemaKeys)
        }
    })

    return schemaKeys
}

const makeCSVData = (data, header, schema) => {
    const response = []
    const subResponse = []
    const subData = []
    let subAdded = false
    const newSubData = []
    let subKey
    header.forEach(head => {
        // remove quotes
        head = head.replace(/"/g, "")
        let value = ""
        if (head.includes("__")) {
            const headSplit = head.split("__")
            let dataValue = ""
            const len = headSplit.length
            for (let i = 0; i < len; i++) {
                const headSp = headSplit[i]
                if (dataValue !== undefined) {
                    if (dataValue === "" && i === 0) {
                        dataValue = data[headSp]
                    } else if (Array.isArray(dataValue)) {
                        if (!subData.length && dataValue.length > 1) {
                            subKey = headSplit
                            subData.push(...dataValue)
                        }
                        dataValue = dataValue[0][headSp]
                    } else if (!dataValue || (dataValue[headSp] === null && len === i)) {
                        dataValue = ""
                        break
                    } else {
                        dataValue = dataValue[headSp]
                    }
                } else {
                    break
                }
            }
            value = dataValue === undefined || isObject(dataValue) || Array.isArray(dataValue) ? "" : dataValue
        } else {
            value = data[head]
        }

        // Add sub Data
        if (subData.length && !subAdded) {
            subKey.forEach(s => {
                if (schema[s]) {
                    schema = schema[s]
                } else if (schema["properties"]) {
                    schema = schema["properties"][s]
                }
            })
            subData.forEach((sub, index) => {
                // skip first
                if (index) {
                    const subDataResponse = makeSubResponse(sub, schema["items"]["properties"])
                    newSubData.push([...subResponse, ...subDataResponse])
                }
            })
            subAdded = true
        } else if (!subAdded) {
            subResponse.push('""')
        }

        response.push(`"${value}"`)
    })

    // Add empty data for every subData
    newSubData.forEach((sub, index) => {
        const count = header.length - sub.length
        const arrayWithEmptyValues = Array(count).fill('""')
        newSubData[index] = [...sub, ...arrayWithEmptyValues].toString()
    })

    return [response.toString(), ...newSubData]
}

const makeSubResponse = (value, schema, subDataResponse = []) => {
    Object.entries(schema).forEach(entry => {
        const [key, val] = entry
        if (val.type === "object") {
            subDataResponse.push('""')
            subDataResponse = makeSubResponse(value[key], val["properties"], subDataResponse)
        } else {
            const dataValue = value[key] !== undefined ? value[key] : ""
            subDataResponse.push(`"${dataValue}"`)
        }
    })

    return subDataResponse
}

const isObject = value => {
    return value && typeof value === "object" && value.constructor === Object
}

/**
 * Accessibility function added to support onKeyDown events. If a control is not a button
 * but has an onClick handler, it also needs an onKeyDown handler that should only act if the
 * key is Enter or Space. This function will check for the appropriate keys and if it receives
 * them, will call the specified function. Other keystrokes (such as tab) are ignored
 *
 * @param event The event received by onKeyDown
 * @param f     The function to call if the key that was pressed indicates an action
 */
export const actOnEnterOrSpace = (event, f) => {
    if (event.keyCode === 13 || event.keyCode === 32) f()
}
