import { format } from "date-fns"
import makeRequest from "../networkClient"
import Rmbx from "../util"
import { STORED_DATE_ONLY_FORMAT } from "../common/constants"
import { getAsDate } from "../common/ts-utils"

export const _call = (type, url, body, expectedStatusCode = null, skipDedupe = false, threaded = false) => {
    return makeRequest(
        {
            url,
            method: type,
            body: body ? JSON.stringify(body) : null,
        },
        expectedStatusCode,
        skipDedupe,
        threaded
    )
}

export const _ensureTrailingSlash = url => {
    return url.substr(-1) === "/" ? url : `${url}/`
}

export const _formatDate = date => format(getAsDate(date), STORED_DATE_ONLY_FORMAT)

const _generateUrlWithParams = (url, params, skipTrailingSlash = false) => {
    const formattedURL = skipTrailingSlash ? url : _ensureTrailingSlash(url)
    const queryString = params ? Rmbx.util.serializeQueryParams(params) : null
    return queryString ? `${formattedURL}?${Rmbx.util.serializeQueryParams(params)}` : formattedURL
}

export const _get = (url, data, expectedStatusCode = null, skipDedupe = false, threaded = false) => {
    const formattedURL = _generateUrlWithParams(url, data)
    return _call("GET", formattedURL, null, expectedStatusCode, skipDedupe, threaded)
}

export const _delete = (
    url,
    data,
    expectedStatusCode = 200,
    skipDedupe = false,
    threaded = false,
    params = null
) => {
    const formattedURL = _generateUrlWithParams(url, params)
    return _call("DELETE", formattedURL, data, expectedStatusCode, skipDedupe, threaded)
}

export const _patch = (
    url,
    data,
    expectedStatusCode = 200,
    skipDedupe = false,
    threaded = false,
    params = null
) => {
    const formattedURL = _generateUrlWithParams(url, params)
    return _call("PATCH", formattedURL, data, expectedStatusCode, skipDedupe, threaded)
}

export const _post = (
    url,
    data,
    expectedStatusCode = 200,
    skipDedupe = false,
    threaded = false,
    skipTrailingSlash = false,
    params = null
) => {
    const formattedURL = _generateUrlWithParams(url, params, skipTrailingSlash)
    return _call("POST", formattedURL, data, expectedStatusCode, skipDedupe, threaded)
}

export const _put = (url, data, expectedStatusCode = 200, skipDedupe = false, threaded = false) => {
    const formattedURL = _ensureTrailingSlash(url)
    return _call("PUT", formattedURL, data, expectedStatusCode, skipDedupe, threaded)
}
