import { error as Errors } from "../strings"

const initialState = {
    email: "",
    errorMessage: "",
    initialized: false,
    isLoading: false,
    isLoginCheckPassed: false,
    isOidcSsoEnabled: false,
    // can be removed with RN-1757-cico-worker-experience
    unverifiedUserRole: "",
    token: null,
    guestEmail: "",
    guestFirstName: "",
    guestLastName: "",
    onAssignedExceptionsList: false,
    guestPermissions: null,
    guestCompanyLogoUrl: "",
    guestExpiration: 0,
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        case "LOGIN_BEGAN":
        case "LOGIN_CHECK_BEGAN":
            return Object.assign({}, state, {
                errorMessage: "",
                isLoading: true,
            })
        case "LOGIN_CHECK_SUCCESSFUL":
            return Object.assign({}, state, {
                email: action.data.email,
                errorMessage: "",
                isLoading: false,
                isLoginCheckPassed: true,
                isOidcSsoEnabled: action.data.isOidcSsoEnabled,
                // can be removed with RN-1757-cico-worker-experience
                unverifiedUserRole: action.data.unverifiedUserRole,
                oidcUrl: action.data.oidcUrl,
                scope: action.data.scope,
                clientId: action.data.clientId,
                connectionName: action.data.connectionName,
                connectionId: action.data.connectionId,
                connectionLogo: action.data.connectionLogo,
                onAssignedExceptionsList: action.data.onAssignedExceptionsList,
            })
        case "LOGIN_CHECK_RESET":
            return Object.assign({}, state, {
                email: "",
                errorMessage: "",
                isLoginCheckPassed: false,
            })
        case "LOGIN_SUCCESSFUL":
            return Object.assign({}, state, {
                errorMessage: "",
                isLoading: false,
                token: action.token,
            })
        case "INITIALIZED":
            return Object.assign({}, state, {
                initialized: true,
            })
        case "LOGIN_CHECK_FAILED":
            return Object.assign({}, state, {
                errorMessage: action.data.errorMessage,
                isLoading: false,
                isLoginCheckPassed: false,
            })
        case "LOGIN_FAILED":
            const errorMessage =
                action.data.errorMessage === "Password Expired."
                    ? Errors.password_expired
                    : action.data.errorMessage === "Invalid user credentials."
                    ? Errors.login_auth_failed
                    : action.data.errorMessage

            return Object.assign({}, state, {
                errorMessage: errorMessage,
                isLoading: false,
            })
        case "GET_CURRENT_USER_FAILED":
            {
                if (action.data && action.data.errorStatus === 401) {
                    return Object.assign({}, state, {
                        errorMessage: Errors.sso_login_user_not_found,
                        isLoading: false,
                    })
                }
            }
            return state
        case "GUEST_LOGIN_CHECK_SUCCESSFUL":
            return {
                ...state,
                guestEmail: action.data.email,
                guestFormId: action.data.formId,
                guestFirstName: action.data.firstName,
                guestLastName: action.data.lastName,
                guestPermissions: action.data.permissions,
                guestCompanyLogoUrl: action.data.companyLogoUrl,
                guestExpiration: action.data.expiration,
            }
        case "GUEST_LOGIN_CHECK_FAILED":
            return {
                ...state,
                guestEmail: "",
                guestFirstName: "",
                guestLastName: "",
                guestFormId: "",
                guestPermissions: "",
                guestLoginError: action.data.errorMessage,
            }
        case "GUEST_INFO_CHANGED":
            return {
                ...state,
                guestFirstName: action.data.guest_first_name,
                guestLastName: action.data.guest_last_name,
            }
        case "TRIAL_ACCOUNT_DEACTIVATED":
            return state
        default:
            return state
    }
}
