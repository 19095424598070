import { tNetworkStatusAction } from "../types"

export const requestingData = (promises: Promise<any>[]): tNetworkStatusAction => {
    return {
        type: "NETWORK_STATUS_UPDATE",
        payload: {
            promises: promises,
            dataNavId: null,
            requestingData: true,
            loadingErrorMessage: "",
            actionErrorMessage: "",
            showActionError: false,
        },
    }
}

export const loadingComplete = (dataNavId: string): tNetworkStatusAction => {
    return {
        type: "NETWORK_STATUS_UPDATE",
        payload: {
            promises: null,
            dataNavId: dataNavId,
            requestingData: false,
            loadingErrorMessage: "",
            actionErrorMessage: "",
            showActionError: false,
        },
    }
}

export const loadingError = (error: string): tNetworkStatusAction => {
    return {
        type: "NETWORK_STATUS_UPDATE",
        payload: {
            promises: null,
            dataNavId: null,
            requestingData: false,
            loadingErrorMessage: error,
            actionErrorMessage: "",
            showActionError: false,
        },
    }
}

export const actionError = (show: boolean, message: string): tNetworkStatusAction => {
    return {
        type: "NETWORK_STATUS_UPDATE",
        payload: {
            requestingData: false,
            loadingErrorMessage: "",
            actionErrorMessage: message,
            showActionError: show,
        },
    }
}

export const setSaveStatus = (status: string): tNetworkStatusAction => {
    return {
        type: "NETWORK_STATUS_UPDATE",
        payload: {
            saveStatus: status,
        },
    }
}

/** Update status and navId. */
export const updateStatusAndNavId = (dataNavId: string, saveStatus: string): tNetworkStatusAction => ({
    type: "NETWORK_STATUS_UPDATE",
    payload: {
        dataNavId,
        saveStatus,
    },
})
