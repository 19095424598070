import { getFlagEnabled } from "../../../getFlagValue"
import ReferenceableSelectorCellEditor from "./referenceable-selector-cell-editor"

// Extends ReferenceableSelectorCellEditor in order to add additional search parameters
// when populating the selector
export default class ModifierSelectorCellEditor extends ReferenceableSelectorCellEditor {
    constructor(props) {
        super(props)

        // Trades and classifications are related to one another: if a user has already selected a trade,
        // then the selector for classifications should only offer classifications that are connected to that
        // trade, and vice versa. Passing any current selections to the endpoint will allow it to return
        // only valid options
        let modifierFilters = {}
        if (props.colDef.resourceName === "companyTrades" && props.data?.modifier_active?.classification) {
            // The modifier_active values can either be an individual ID, or an entire object
            if (typeof props.data.modifier_active.classification === "number") {
                modifierFilters = { classifications: props.data.modifier_active.classification }
            } else {
                modifierFilters = { classifications: props.data.modifier_active.classification.id }
            }
        } else if (props.colDef.resourceName === "companyClassifications" && props.data?.modifier_active?.trade) {
            if (typeof props.data.modifier_active.trade === "number") {
                modifierFilters = { trades: props.data.modifier_active.trade }
            } else {
                modifierFilters = { trades: props.data.modifier_active.trade.id }
            }
        }

        if (getFlagEnabled("WA-7632-project-specific-modifiers")) {
            modifierFilters = {
                ...modifierFilters,
                // If a CC has just been selected by the user, the CC field will be a full object.
                // When viewing TK data that has already been saved, the CC and project fields will be IDs.
                // The project field may be out-of-date or nonexistent if data has been submitted to the API and
                // returned with a server error, so it's the lowest-priority fallback.
                project:
                    props.data?.cost_code?.project?.id ??
                    props.context.entities.costCodes?.objects?.[props.data?.cost_code]?.project?.id ??
                    props.data?.project,
            }
        }

        this.state = { ...this.state, modifierFilters }
    }

    getSearchFiltersForCell = () => ({
        ...this.getQueryFilters(),
        ...this.getRelatedQueryFilters(),
        ...this.getCellFiltersFromRowValue(),
        ...this.getGroupKeyFilters(),
        ...(getFlagEnabled("WA-7200-Custom-Modifiers") ? this.props.column.colDef.extraSearchFilters : {}),
        ...this.state.modifierFilters,
    })
}
