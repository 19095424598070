import { initialize } from "launchdarkly-js-client-sdk"

const anonymousUserContext = {
    kind: "user",
    key: "anonymous",
    anonymous: true,
    platform: "web",
}

const featureFlagsClient = initialize(window.ld_client_id, anonymousUserContext, {
    sendEventsOnlyForVariation: true,
})
Object.freeze(featureFlagsClient)

let ready = false
featureFlagsClient.on("ready", () => {
    ready = true
})

export const featureFlagsClientIsReady = () => ready
export default featureFlagsClient
