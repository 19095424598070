import React, { useContext } from "react"

/** Components */
import { SideRailContext } from "../../SideRail/SideRailContext"
/** Types */
import { iCompanyFormSchema } from "../../../cached-data/types"
import { ResourceObject } from "../../../dashboard-data/types"

type Props = {
    data: ResourceObject<iCompanyFormSchema>
}

export const WorkflowButtonCellRenderer: React.FC<Props> = ({ data }) => {
    const { enableSideRail } = useContext(SideRailContext)

    const startWorkflowFlow = () => {
        const schemaId = data.id
        if (schemaId) enableSideRail({ flow: "WORKFLOW", schemaId })
    }

    return (
        <button onClick={startWorkflowFlow} type="button" className="action-button">
            <div>Workflow</div>
        </button>
    )
}

export default WorkflowButtonCellRenderer
