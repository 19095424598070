import form from "./form"

const initialState = {
    errors: {},
    projectId: null,
    projectName: "",
    jobNumber: "",
    address: "",
    requireSignature: true,
    creationErrorObj: null,
}

const project_form = (state = initialState, action) => {
    switch (action.type) {
        case "CURRENT_PROJECT_ID_CHANGED":
            return Object.assign({}, initialState)
        case "CREATE_PROJECT_FAILED":
        case "UPDATE_PROJECT_FAILED":
            return Object.assign({}, state, {
                creationErrorObj: action.errorObj,
            })
        case "CREATE_PROJECT_SUCCESS":
        case "UPDATE_PROJECT_SUCCESS":
            return Object.assign({}, state, { creationErrorObj: null })
        default:
            // Use default form reducer if none of these cases match
            if (action.form == "project_form") {
                return form(state, action)
            } else {
                return state
            }
    }
}

export default project_form
