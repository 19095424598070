import {
    LOOKER_URL_SUCCESSFUL,
    LOOKER_URL_FAILED,
    LOOKER_URL_CLEARED,
    LookerActionTypes,
    tLookerUrlState,
} from "./types"

const initialState: tLookerUrlState = {
    looker_url: null,
}

export default function lookerUrl(state: tLookerUrlState = initialState, action: LookerActionTypes) {
    switch (action.type) {
        case LOOKER_URL_FAILED:
            return state
        case LOOKER_URL_SUCCESSFUL:
            return {
                ...state,
                looker_url: action.data.signed_url,
                looker_element: action.data.looker_element,
            }
        case LOOKER_URL_CLEARED:
            return { ...state, looker_url: null }
        default:
            return state
    }
}
