import React from "react"
import { connect } from "react-redux"
import {
    sendReferrals,
    closeReferralsSuccessDialog,
    closeRhumbixReferralModal,
} from "../../actions/rhumbix-referral"
import StandardModal from "./modal-standard"
import SuccessModal from "../success-modal"
import RhumbixReferralModalBody from "./modal-rhumbix-referral-body"
import Rmbx from "../../util"

class RhumbixReferralModal extends React.Component {
    state = {
        errorMessage: "Please correct errors and try again.",
        emailInputHasErrors: false,
        description: `Hello from Rhumbix,\n\n${this.props.currentUser.first_name}
        ${this.props.currentUser.last_name} has invited you to try Rhumbix's Time and Material Tracking for free!
        Time and Material Tracking helps ${this.props.currentUser.company} create, track and approve at risk and
        unplanned work.`,
        recipients: null,
    }

    closeReferralModal = () => {
        this.setState({
            emailInputHasErrors: false,
        })
        this.props.closeReferralModal()
    }

    updateEmailRecipients = e => {
        this.setState({
            recipients: e.target.value,
            emailInputHasErrors: false,
        })
    }

    updateEmailBody = e => {
        this.setState({
            description: e.target.value,
            emailInputHasErrors: false,
        })
    }

    sendReferrals = () => {
        let emails = null
        let inputHasErrors = false
        if (this.state.recipients == null) {
            inputHasErrors = true
        } else {
            emails = this.state.recipients.split(",")
            for (let i = 0, j = emails.length; i < j; i++) {
                if (Rmbx.util.isEmailInvalid(emails[i])) {
                    inputHasErrors = true
                    break
                }
            }
        }

        if (inputHasErrors) {
            this.setState({
                emailInputHasErrors: true,
            })
            return
        }

        this.props.sendReferrals(emails, this.state.description)
    }

    render() {
        return (
            <React.Fragment>
                <StandardModal
                    open={this.props.inviteModalIsOpen}
                    noButton={true}
                    autoClose={false}
                    backgroundCloseEnabled={false}
                    closeMethod={this.closeReferralModal}
                    size="referral-modal"
                >
                    <RhumbixReferralModalBody
                        closeReferralModal={this.closeReferralModal}
                        currentUser={this.props.currentUser}
                        updateEmailRecipients={this.updateEmailRecipients}
                        updateEmailBody={this.updateEmailBody}
                        sendReferrals={this.sendReferrals}
                        description={this.state.description}
                        emailInputHasErrors={this.state.emailInputHasErrors}
                        errorMessage={this.state.errorMessage}
                        sendingInProgress={this.props.sendingInProgress}
                    />
                </StandardModal>

                <SuccessModal
                    open={this.props.referralsSentSuccessfully}
                    closeMethod={this.props.closeReferralsSuccessDialog}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    inviteModalIsOpen: state.rhumbixReferral.inviteModalIsOpen,
    sendingInProgress: state.rhumbixReferral.sendingInProgress,
    referralsSentSuccessfully: state.rhumbixReferral.referralsSentSuccessfully,
    currentUser: state.current_user,
})

const mapDispatchToProps = dispatch => ({
    sendReferrals(emails, body) {
        const data = {
            email_addresses: emails,
            referral_body: body,
        }
        dispatch(sendReferrals(data))
    },
    closeReferralModal() {
        dispatch(closeRhumbixReferralModal())
    },
    closeReferralsSuccessDialog() {
        dispatch(closeReferralsSuccessDialog())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(RhumbixReferralModal)
