import styled from "styled-components"
import {
    ColorPicker,
    colorAttentionBlue50,
    colorFunctionalGray70,
    colorFunctionalWhite,
} from "@rhumbix/rmbx_design_system_web"
import React, { useImperativeHandle, useState, forwardRef, useRef, useEffect } from "react"
import { GridApi, RowNode } from "ag-grid-community"

type tProps = {
    api: GridApi
    value: { selected_color: string; is_outline: boolean }
    data: any
    onValueChange: (value: any) => void
    node: RowNode
    rowIndex: number
    stopEditing: (cancel?: boolean) => void
}

const ColorCellEditor: React.FunctionComponent<tProps> = forwardRef((props: tProps, ref) => {
    const [color, setColor] = useState(props.value.selected_color)
    const [outline, setOutline] = useState(props.value.is_outline)
    const refContainer = useRef(null)

    // This is required to have the cell editor pop out of the cell when the contents are too large for the cell
    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return { selected_color: color, is_outline: outline }
            },
        }
    })

    useEffect(() => {
        const popupChildren = document.getElementsByClassName("ag-popup-child") as HTMLCollectionOf<HTMLElement>
        if (popupChildren.length) {
            popupChildren[0].style.width = "auto"
        }
    }, [])

    const onColorChange = (newColor: string, isOutline: boolean) => {
        setColor(newColor.replace("#", ""))
        setOutline(isOutline)
    }

    return (
        <ColorPickerContainer
            className="color-cell-editor"
            id={`color-cell-editor-${props.rowIndex}`}
            ref={refContainer}
        >
            <ColorPicker
                onColorChange={onColorChange}
                initialColor={color}
                initialOutline={outline}
                showPreview={true}
                showOutlineCheckbox={true}
            />
            <ButtonContiner>
                <CancelButton
                    type={"button"}
                    data-testid={"color-picker-cancel"}
                    onClick={() => props.api.stopEditing(true)}
                >
                    Cancel
                </CancelButton>
                <ContinueButton
                    type={"button"}
                    data-testid={"color-picker-continue"}
                    onClick={() => props.api.stopEditing()}
                >
                    Continue
                </ContinueButton>
            </ButtonContiner>
        </ColorPickerContainer>
    )
})

export default ColorCellEditor

const ColorPickerContainer = styled.div`
    width: 100%;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 15px;
    padding-bottom: 20px;
`

const ButtonContiner = styled.div`
    display: flex;
    justify-content: flex-end;
`

const ContinueButton = styled.button`
    background-color: ${colorAttentionBlue50};
    color: ${colorFunctionalWhite};
`

const CancelButton = styled.button`
    margin-right: 10px;
    color: ${colorFunctionalGray70};
`
