import { fahrenheitToCelsius, angleToCardinalDirection } from "../common/ts-utils"
import { isNumber } from "../common/validators"
import { getFlagEnabled } from "../getFlagValue"
import {
    FETCH_WEATHER_SUCCESSFUL,
    FETCH_WEATHER_FAILED,
    WeatherActionTypes,
    WEATHER_INFORMATION_CLEARED,
    ADD_EXISTING_WEATHER_INFORMATION,
    ADD_EXISTING_CUSTOM_WEATHER_INFORMATION,
} from "./types"

type tWeatherData = {
    city_name: string
    state_code: string
    data: Array<any>
}

const formatWeatherResponse = (weatherData: tWeatherData, startDate: string): Record<string, string> => {
    const times: Record<string, string> = {
        morning: `${startDate}T06:00:00`,
        afternoon: `${startDate}T12:00:00`,
        evening: `${startDate}T18:00:00`,
    } // 6am, 12pm, and 6pm
    const response: Record<string, string> = {
        location: weatherData.city_name + ", " + weatherData.state_code,
    }
    weatherData.data.map(item => {
        for (const key in times) {
            if (item.timestamp_local === times[key] && item.temp) {
                const tempF = item.temp
                response[key] = `${tempF}°F (${fahrenheitToCelsius(tempF, 1)}°C) ${item.weather.description}`
            }
        }
    })

    return response
}

const formatRawWeatherResponse = (weatherData: tWeatherData) => {
    const response: Record<string, any> = {
        location: weatherData.city_name + ", " + weatherData.state_code,
    }
    // convert each entry to use it's local timestamp as the key, and
    // then include all the weather data and a formatted string for local weather fields
    weatherData.data.forEach(item => {
        const tempF = item.temp
        if (isNumber(tempF))
            response[item.timestamp_local] = {
                ...item,
                formattedWeather: `${tempF}°F (${fahrenheitToCelsius(tempF, 1)}°C) ${item.weather.description}`,
                // historic responses don't have cardinal directions, so use the wind angle to get that value
                wind_cdir: item?.wind_cdir ?? angleToCardinalDirection(item.wind_dir),
            }
    })

    return response
}

export type tWeatherState = {
    success: boolean
    error: string
    weather: Record<string, string>
    rawWeather: Record<string, any>
    startDate: string
}

export const weatherInitialState: tWeatherState = {
    success: true,
    error: "",
    weather: {},
    rawWeather: {},
    startDate: "",
}
export const weather = (state = weatherInitialState, action: WeatherActionTypes): tWeatherState => {
    switch (action.type) {
        case FETCH_WEATHER_SUCCESSFUL:
            const weatherData = formatWeatherResponse(action.data, action.startDate)
            return {
                ...state,
                success: true,
                weather: { ...state.weather, ...weatherData },
                rawWeather: getFlagEnabled("WA-8150-custom-weather")
                    ? { ...state.rawWeather, ...formatRawWeatherResponse(action.data) }
                    : {},
                startDate: action.startDate,
                error: "",
            }
        case FETCH_WEATHER_FAILED:
            return { ...state, success: false, error: action.error }
        case WEATHER_INFORMATION_CLEARED:
            return weatherInitialState
        case ADD_EXISTING_WEATHER_INFORMATION:
            return { ...state, weather: { ...state.weather, ...action.data } }
        case ADD_EXISTING_CUSTOM_WEATHER_INFORMATION:
            return { ...state, rawWeather: { ...state.rawWeather, ...action.data } }
        default:
            return state
    }
}

export default weather
