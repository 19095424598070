import React, { useEffect, useState } from "react"
import { colorAttentionBlue50, colorFunctionalGray30, colorFunctionalGray50 } from "@rhumbix/rmbx_design_system_web"
import SlideToggle from "react-switch"
import styled from "styled-components"
import { ColDef, EditableCallbackParams, ICellRendererParams } from "ag-grid-community"
import { JsonPointer as jsonpointer } from "json-ptr"
import { getFlagEnabled } from "../../../getFlagValue"
import { isTokenRowActive } from "../../../common/ag-grid-utils"
import { slideToggleHandleChange } from "../../../common/ag-grid-ts-utils"

type SlideToggleCellRendererParams = ICellRendererParams & {
    colDef: ColDef
    hideUneditable: boolean
    isListValueType?: boolean
    offText?: string
    onText?: string
    onValue?: string
}
/*
    Slide Toggle for use in ag-grid tables with an ON/OFF label based on value
*/
const SlideToggleCellRenderer = (params: SlideToggleCellRendererParams) => {
    const { colDef, context, api: gridApi, node: row, offText = "Off", onText = "On", hideUneditable } = params
    const { data: rowData } = row
    const { field: fieldPath, editable } = colDef as ColDef

    const getDisabledState = (): boolean => {
        if (!getFlagEnabled("WA-7979-ff-notifications")) return !isTokenRowActive(params?.node?.data)
        // editable can be a boolean or a function - if it's a function, pass the params to get the editable setting
        return typeof editable === "boolean"
            ? !editable
            : typeof editable === "function"
            ? !editable(params as EditableCallbackParams)
            : true // if no editable setting is passed, default to true, we don't always declare editable
    }
    const [disabled] = useState(getDisabledState())

    const getIsChecked = (): boolean => {
        if (!getFlagEnabled("WA-7979-ff-notifications")) return !!params?.node?.data?.options?.show_new_field_names
        if (fieldPath && rowData && jsonpointer.get(rowData, fieldPath)) {
            if (params.isListValueType && params.onValue) {
                const value = jsonpointer.get(rowData, fieldPath) as string[]
                return value.includes(params.onValue)
            }
            return !!jsonpointer.get(rowData, fieldPath)
        } else {
            return false
        }
    }
    const [checked, setChecked] = useState(getIsChecked())

    useEffect(() => setChecked(getIsChecked()), [rowData])

    const handleChange = () => {
        if (disabled) return

        if (fieldPath)
            return slideToggleHandleChange(
                row,
                context,
                colDef,
                gridApi,
                jsonpointer.get(rowData, fieldPath) as boolean | string[],
                newValue => {
                    row.setDataValue(colDef.field as string, newValue)
                    gridApi.clearFocusedCell()
                },
                params.isListValueType,
                params.onValue
            )

        if (row && context && colDef && gridApi) {
            if (colDef?.field === "/options/show_new_field_names") {
                const title = checked ? "Revert to Classic Form Names" : "Update to Newest Field Names"
                const revertToClassic = `Turning this option off will change the data returned from
                        the shift_extra_entries, project_entries (api v2), and workflow_entries (api v3) endpoints,
                        and could break existing integrations. `
                const updateToNewest = `Turning this option on could break existing integrations that
                        rely on shift_extra_entries, project_entries (api v2), and workflow_entries (api v3).`
                const description = checked ? revertToClassic : updateToNewest
                context.createModalAction({
                    title: title,
                    description: description,
                    action: () => {
                        row.setDataValue("/options/show_new_field_names", !checked)
                        setChecked(!checked)
                        gridApi.clearFocusedCell()
                    },
                    buttonClass: checked ? "continueButton" : "",
                    close: () => {
                        context.createModalAction(null)
                        gridApi.clearFocusedCell()
                    },
                })
            } else if (params.isListValueType && params.onValue) {
                const value = jsonpointer.get(rowData, colDef.field as string) as string[]
                let newValue
                if (!value) newValue = [params.onValue]
                else {
                    const index = value.indexOf(params.onValue)
                    if (index > -1) {
                        newValue = value.filter(role => role !== params.onValue)
                    } else {
                        newValue = [...value, params.onValue]
                    }
                }
                row.setDataValue(colDef.field as string, newValue)
                setChecked(!checked)
                gridApi.clearFocusedCell()
            } else if (colDef?.field) {
                row.setDataValue(colDef.field, !checked)
                setChecked(!checked)
                gridApi.clearFocusedCell()
            }
        }
    }
    // if there is no rowData, it is likely a grouping row
    if (getFlagEnabled("WA-7979-ff-notifications") && ((disabled && hideUneditable) || rowData == undefined))
        return <></>

    const className = params.isListValueType ? `slide-toggle-${params.onValue}` : "slide-toggle"
    return (
        <SlideToggleContainer>
            {/*// @ts-ignore*/}
            <SlideToggle
                className={className}
                checked={checked}
                onChange={handleChange}
                disabled={disabled}
                onColor={colorAttentionBlue50}
                offColor={colorFunctionalGray30}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={32}
            />
            <ToggleText disabled={disabled} onClick={handleChange}>
                {checked ? onText : offText}
            </ToggleText>
        </SlideToggleContainer>
    )
}

export default SlideToggleCellRenderer

/* pointer-events: auto overrides default css that allows this toggle to register the click/change events,
as there is css defined in aggrid.less that sets pointer-events: none */
const SlideToggleContainer = styled.span`
    display: flex;
    align-items: center;

    .slide-toggle {
        margin-right: 8px;
        pointer-events: auto;
    }
`

const ToggleText = styled.span<{ disabled: boolean }>`
    color: ${({ disabled }) => (!disabled ? "inherit" : colorFunctionalGray50)};
`
