import * as hash from "object-hash"

import { bulkUpdateSourceDataField } from "../../actions/bulk-actions"
import { BaseTkEntry } from "../../common/types"
import { tGroupBy, WeeklyViewSourceData } from "../../components/custom-dashboards/types"
import { getFlagEnabled } from "../../getFlagValue"
import {
    tButtonClickHandler,
    tButtonClickHandlerTuple,
    tButtonClickHandlerFactory,
    tExtraButtonParams,
} from "../types"
import { timekeepingDataIsEditable } from "../../common/ag-grid-utils"
import { filterData } from "../../common/ag-grid-grouping-utils"
import { TableIds } from "../../common/table-identifier-utils"
import { ProcessCellForExportParams, ProcessHeaderForExportParams } from "ag-grid-community"
import { iTimekeepingStatus } from "../../cached-data/types"

/**
 * In some tables, the grouping options are restricted based on the groupKeyInfo that is applied when the
 * table is opened. This function checks if those rules need to be applied on the current table and then
 * determines whether to allow this option
 * @param params    The full set of params passed from the calling tButtonClickHandlerFactory
 * @param label     The button label to validate
 * @returns         True if this button is allowed in the Grouping dropdown; otherwise, false
 */
const groupingAllowedInCurrentConfiguration = (params: any, label: string): boolean => {
    const { context } = params

    if (context.settings.id === TableIds.TimekeepingModal) {
        // In the Timekeeping tab of the Timekeeping modal, the toolbar options vary
        // based on which grouping is in effect back in the main list view. For example, if the user
        // grouped by Employees and the clicks on an Employee, the modal opens with a single
        // Employee in view, and options like "Group by Employee" would not make sense.
        // Remove any unnecessary options here
        if (context.groupKeyInfo?.find((key: any) => key.colDef.field === "/employee") && label === "Employee") {
            return false
        }
        if (context.groupKeyInfo?.find((key: any) => key.colDef.field === "/cost_code") && label === "Cost Code") {
            return false
        }
    }

    if (context.settings.id === TableIds.ShiftsAndBreaksModal) {
        if (context.groupKeyInfo?.find((key: any) => key.colDef.field === "/employee") && label === "Employee") {
            return false
        }
    }

    return true
}

export const getGroupingChangeHandlers: tButtonClickHandlerFactory = params => {
    const { context } = params
    const groupBy =
        context.settings.otherSettings && context.settings.otherSettings.groupBy
            ? context.settings.otherSettings.groupBy
            : []

    return groupBy
        .filter(
            (group: tGroupBy) =>
                group.label !== params.currentGrouping && groupingAllowedInCurrentConfiguration(params, group.label)
        )
        .map((group: tGroupBy) => {
            const actionHandler: tButtonClickHandler = (
                e,
                { columnApi, gridApi, setGroupByLabel, toggleRowExpansion }
            ) => {
                // With sticky grouping, we do all of this at the custom table component level instead
                // since we need to set grouping on component load
                if (getFlagEnabled("WA-7128-maintain-grouping-weekly-tk")) setGroupByLabel(group.label)
                else {
                    // Save and restore sort before switching group columns
                    //
                    // The actual sorting is controlled by getValueFromNode by way of
                    // compareGroupColumns on the autoGroupColumnDef
                    if (getFlagEnabled("WA-7605-ag-grid-settings-fix")) {
                        // We use the `setTimeout()` here per AG Grid's recommendation for when using their
                        // API and you're not seeing the results you'd expect. Go figure.
                        setTimeout(() => {
                            columnApi.setRowGroupColumns(group.cols)
                            group.cols.forEach(column => columnApi.setColumnVisible(column, false))

                            // Update labels to reflect the new grouping
                            const autoColumn = columnApi.getColumn("ag-Grid-AutoColumn")
                            if (autoColumn) {
                                if (getFlagEnabled("WA-7605-ag-grid-settings-fix"))
                                    gridApi.setAutoGroupColumnDef({
                                        ...autoColumn.getColDef(),
                                        headerName: group.label,
                                    })
                                else autoColumn.getColDef().headerName = group.label
                            }
                            setGroupByLabel(group.label)

                            // Collapse all rows
                            gridApi.collapseAll()
                            toggleRowExpansion(false)

                            // Refresh & resize the grid
                            gridApi.refreshHeader()
                            gridApi.sizeColumnsToFit()
                        })
                    } else {
                        columnApi.setRowGroupColumns(group.cols)
                        group.cols.forEach(column => columnApi.setColumnVisible(column, false))

                        // Update labels to reflect the new grouping
                        const autoColumn = columnApi.getColumn("ag-Grid-AutoColumn")
                        if (autoColumn) {
                            if (getFlagEnabled("WA-7605-ag-grid-settings-fix"))
                                gridApi.setAutoGroupColumnDef({
                                    ...autoColumn.getColDef(),
                                    headerName: group.label,
                                })
                            else autoColumn.getColDef().headerName = group.label
                        }
                        setGroupByLabel(group.label)

                        // Collapse all rows
                        gridApi.collapseAll()
                        toggleRowExpansion(false)

                        // Refresh & resize the grid
                        gridApi.refreshHeader()
                        gridApi.sizeColumnsToFit()
                    }
                }
            }

            return [group.label, actionHandler, "group"]
        })
}

export const getTimekeepingStatusChangeHandlers = (
    params: tExtraButtonParams,
    cellOnly: boolean
): tButtonClickHandlerTuple[] => {
    if (!params.context.currentUser.features?.timekeeping) {
        return []
    }
    const userRole = params.context.currentUser.user_role

    const userRoleStatus = Object.values(params.context.referenceableData?.timekeepingStatuses || []).sort((a, b) =>
        a.sort_order < b.sort_order ? -1 : 1
    )

    return userRoleStatus
        .filter(
            (status: iTimekeepingStatus) =>
                status.can_change_to_status && status.can_change_to_status.includes(userRole) && !status.is_hidden
        )
        .map((status: iTimekeepingStatus) => {
            const actionHandler: tButtonClickHandler = (e, { context, sourceData }) => {
                const data = cellOnly ? context.selectedCellData : (sourceData as WeeklyViewSourceData)
                const targetData: Record<string, BaseTkEntry[]> = filterData(data, context)
                if (!Object.keys(targetData).length) {
                    return
                }

                const cannotUpdateComponents: Record<string, number> = {}
                const canUpdateComponents: Record<string, BaseTkEntry[]> = {}
                Object.entries(targetData).forEach(([componentType, tkComponentList]) => {
                    tkComponentList.forEach(component => {
                        const status =
                            typeof component.status === "object" ? component.status.name : component.status
                        if (!timekeepingDataIsEditable({ data: component, context }).canEdit) {
                            if (!(status in cannotUpdateComponents)) cannotUpdateComponents[status] = 0
                            cannotUpdateComponents[status] += 1
                        } else {
                            if (!(componentType in canUpdateComponents)) canUpdateComponents[componentType] = []
                            canUpdateComponents[componentType].push(component)
                        }
                    })
                })

                if (Object.keys(cannotUpdateComponents).length) {
                    const numProblems = Object.values(cannotUpdateComponents).reduce((agg, entry) => agg + entry, 0)
                    let descriptionDetail = ""
                    Object.keys(cannotUpdateComponents).forEach(status => {
                        const statusInfo = userRoleStatus.find(s => s.name === status)
                        const items = cannotUpdateComponents[status] > 1 ? "items" : "item"
                        descriptionDetail += `<b>${statusInfo?.label} -
                                ${cannotUpdateComponents[status]} ${items}</b><br>`
                    })
                    context.createModalAction({
                        title: "Issue While Updating Statuses",
                        description: `You do not have permission to update ${numProblems} of the selected
                items:<br>${descriptionDetail}`,
                        action: () => {
                            params.context.dispatch(
                                bulkUpdateSourceDataField(
                                    canUpdateComponents,
                                    {},
                                    {
                                        field: "status",
                                        value: status.name,
                                    }
                                )
                            )
                        },
                        buttonClass: "blueContinueButton",
                        confirmButtonText: "Update Remaining Items",
                        cancelButtonText: "Cancel All Updates",
                        backgroundCloseEnabled: true,
                        close: () => context.createModalAction(null),
                    })
                } else {
                    context.createModalAction({
                        title: `Change status of ${cellOnly ? "selected cell" : "all cells"} to "${status.label}"?`,
                        description: "This action cannot be undone",
                        action: () => {
                            context.dispatch(
                                bulkUpdateSourceDataField(
                                    canUpdateComponents,
                                    {
                                        current_project_id: context.filters.projectId,
                                    },
                                    {
                                        field: "status",
                                        showErrorModal: true,
                                        value: status.name,
                                    }
                                )
                            )
                        },
                        buttonClass: "blueContinueButton",
                        close: () => context.createModalAction(null),
                    })
                }
            }

            return [status.label, actionHandler, "status"]
        })
}

/** Callback function invoked for each cell before exporting. */
export const processCellCallback = (params: ProcessCellForExportParams) => {
    /**
     * ag-grid doesn't call valueFormatters or use any of the other cell
     * renderer logic when exporting CSVs. We have to invoke the valueGetter
     * and formatter (if defined) programmatically in this callback
     */
    const { value, node, column, columnApi, api, context } = params
    const colDef = column.getColDef()
    const valueGetter = colDef.valueGetter as (params: any) => any
    const valueFormatter = colDef.valueFormatter as (params: any) => any
    /** Custom method to format the cell value before exporting. Useful to format any non-string value. */
    const exportFormatter = (colDef as Record<string, any>).exportFormatter
    if (exportFormatter) return exportFormatter(value)

    const rawValue =
        valueGetter && typeof valueGetter === "function" && node
            ? valueGetter({ api, colDef, column, columnApi, context, data: node.data, node })
            : value

    return valueFormatter && typeof valueFormatter === "function" && node
        ? valueFormatter({ api, colDef, column, columnApi, context, data: node.data, node, value: rawValue })
        : rawValue
}

/** Callback function invoked for each column header before exporting. */
export const processHeaderCallback = (params: ProcessHeaderForExportParams) => {
    const { headerName, headerExportFormatter, headerValueGetter } = params.column.getColDef() as any
    const header = headerValueGetter ? headerValueGetter(params) : headerName
    return headerExportFormatter ? headerExportFormatter(header) : header
}

type FormSummary = {
    modifyDisabled: boolean
    viewDisabled: boolean
    editTooltip?: string
    viewTooltip?: string
    selectedStatuses: Set<string>
    selectedHashes: Set<string>
    checkedSchemas: Set<number>
    selectedProjectIds: Set<number>
    selectedStoreIds: Array<number>
}

/**
 * Given an array of rows, value of the project filter, and the referenceableData, return a summary of the rows
 * and information about if the View/Edit buttons should be disabled, and the proper tooltips.
 * @param selectedRows Rows selected in ag-grid
 * @param current_project_id Value from project filter
 * @param referenceableData Data available to toolbar
 * @returns summary of selected forms
 */
export const getFormSummaryInfo = (
    selectedRows: any[],
    current_project_id: number | number[],
    referenceableData: Record<string, any>
): FormSummary => {
    const isBulkEdit = selectedRows.length > 1
    const selectedStatuses: Set<string> = new Set()
    const selectedHashes: Set<string> = new Set()
    const permissionHashes: Set<string> = new Set()
    const checkedSchemas: Set<number> = new Set()
    const selectedProjectIds: Set<number> = new Set()
    const selectedStoreIds: number[] = []
    let currentPermissions: Record<string, any> = {}

    let anySelectedMissingPermissions = false

    const hasSingleProjectSelectedInFilter = Array.isArray(current_project_id) && current_project_id?.length === 1

    // go through each row and collect the status, schema hash, and an md5 hash of the permissions for the status
    selectedRows.forEach(row => {
        // if any selected store doesn't have permissions in the context, skip checking others, no buttons for you!
        if (anySelectedMissingPermissions) return
        const { id, status, schema, schema_hash, project: projectId } = row
        selectedStoreIds.push(id)
        selectedStatuses.add(status)
        selectedProjectIds.add(projectId)

        if (!checkedSchemas.has(schema) && referenceableData?.companyFormSchemas?.[schema]) {
            // make a hash of the permissions object so we can compare to make sure all share the same perms
            // MD5 is the same hashing we use for schemas on the server-side
            const perms = referenceableData.companyFormSchemas?.[schema]?.permissions?.[status]
            if (!perms) {
                anySelectedMissingPermissions = true
                return
            }
            currentPermissions = perms
            selectedHashes.add(schema_hash)
            const { field, object } = perms
            // only need to check field/object permissions are the same
            permissionHashes.add(hash.MD5({ field, object }))
            // add the schema id to the checkedSchemas set so we don't check the same schema multiple times
            checkedSchemas.add(schema)
        }
    })

    let viewTooltip = ""
    let editTooltip = ""

    const currentObjPerm = currentPermissions?.object ?? {}
    const currentFieldPerm = currentPermissions?.field ?? {}
    // if all the selected rows share the same permission set, schema hash and project - we can bulk view
    const viewDisabled = !currentObjPerm?.read || !currentFieldPerm?.viewable || anySelectedMissingPermissions

    if (anySelectedMissingPermissions || !currentObjPerm?.read || !currentFieldPerm?.viewable) {
        viewTooltip = "You do not have the proper permissions for this action."
    }

    // we only allow bulk editing if all the selected forms use the same project, or a single project is filtered
    // this allows GCs to sign across multiple subs with different projects, but the same schema/permissions
    // GCs would need a project selected so that all forms showing have a project share with the same GC project
    const cannotBulkEditBasedOnProject =
        isBulkEdit && !hasSingleProjectSelectedInFilter && selectedProjectIds.size !== 1

    // if permissions don't allow editing or both buttons disabled or statuses/permissions don't match - disable
    const modifyDisabled =
        !currentFieldPerm?.editable ||
        !currentObjPerm?.update ||
        selectedStatuses.size !== 1 ||
        permissionHashes.size !== 1 ||
        selectedHashes.size !== 1 ||
        cannotBulkEditBasedOnProject ||
        viewDisabled

    if (!currentFieldPerm?.editable || !currentObjPerm?.update) {
        editTooltip = "You do not have the proper permissions for this action."
    } else if (selectedStatuses.size !== 1) {
        editTooltip = "The selected forms have different statuses and cannot be edited together."
    } else if (permissionHashes.size !== 1) {
        editTooltip = "The selected forms have different permissions and cannot be edited together."
    } else if (selectedHashes.size !== 1) {
        editTooltip = "The selected forms have different schemas and cannot be edited together."
    } else if (cannotBulkEditBasedOnProject) {
        if (!hasSingleProjectSelectedInFilter) {
            editTooltip = "Please select a single project from the filter."
        } else {
            editTooltip = "The selected forms are related to different projects and cannot be edited together."
        }
    } else if (viewDisabled) {
        editTooltip = viewTooltip
    }
    return {
        modifyDisabled,
        viewDisabled,
        editTooltip,
        viewTooltip,
        selectedStatuses,
        selectedHashes,
        checkedSchemas,
        selectedProjectIds,
        selectedStoreIds,
    }
}
