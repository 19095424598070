import { createSelector } from "reselect"
import { ReduxState } from "../common/types"
import { iTkModifier } from "../cached-data/types"

export const selectTkModifierTypes = (state: ReduxState) => state.current_user.company_tk_modifiers ?? []

export const selectTkModifierTypesById = createSelector(selectTkModifierTypes, tkModifierTypes =>
    tkModifierTypes.reduce<Record<number, iTkModifier>>((acc, tkModifierType) => {
        acc[tkModifierType.id] = tkModifierType
        return acc
    }, {})
)
