/**
 * This is a fork of https://github.com/vtaits/react-select-async-paginate/
 * It's been updated to handle cache management correctly for our use-case
 * (resetting the cached options any time a filter has changed).
 */
import React, { Component } from "react"
import Select from "react-select-v1"

import { tSearchOptions, tSearchResults, tSearchCache, tSearchOption } from "./types"
import { tFilterKey } from "../filters/types"
import { isApplicableFilterValue } from "../filters/utils"
import rmbx from "../util"

const initialCache = {
    options: [],
    hasMore: true,
    isLoading: false,
}

type tProps = {
    loadOptions: (
        inputValue: string,
        callback: (error: typeof Error | null, results: tSearchResults) => void,
        optionsLoaded: tSearchOptions
    ) => void
    options?: tSearchOptions
    primaryKey?: tFilterKey
    onChange?: (options: tSearchOptions) => void
    filterValue?: number | Array<number> | string | Array<string>
    multi?: boolean
}

type tState = {
    cache: tSearchCache
    search: string
    value: tSearchOptions | tSearchOption | null
}

class AsyncPaginate extends Component<tProps, tState> {
    innerRef: React.RefObject<any> = React.createRef()

    state: tState = {
        cache: {},
        search: "",
        value: null,
    }

    constructor(props: tProps) {
        super(props)

        // If initial options were passed in as a prop, hydrate the cache and
        // update the component state
        if (props.options && props.options.length) {
            this.state.cache = {
                "": {
                    options: props.options,
                    hasMore: true,
                    isLoading: false,
                },
            }
        } else if (props.primaryKey) {
            // If this component is the UI for a filter, use the filter
            // primaryKey we get from props to get the relevant selector
            // values we persisted to sessionStorage.
            //
            // This is necessary because the filter state in redux -- which
            // only stores primary keys for referenceable filters -- does not
            // control the value of the react-select v1 Selector component,
            // which needs a complete referenceable object. Without this, the
            // filter state and component's value will be out of sync when we
            // restore the filter state from sessionStorage after a hard refresh.
            const optionsCache = Object.values(
                JSON.parse(window.sessionStorage.getItem(props.primaryKey) || "{}")
            ) as tSearchOptions
            this.state.value = this.getSelectorValuesFromFilterValues(optionsCache, props.filterValue)
        }
    }

    /**
     * Given a list of selector values and the value for the current filter
     * Return only the selector values that are relevant. If there are no filterValues, return null
     */
    getSelectorValuesFromFilterValues = (
        selectorValues: tSearchOptions,
        filterValue: number | Array<number> | string | Array<string> | undefined
    ): Array<tSearchOption> | null => {
        if (!filterValue || !isApplicableFilterValue(filterValue)) {
            return null
        }

        let options = []

        if (Array.isArray(filterValue)) {
            options = selectorValues.filter((option: tSearchOption) => {
                if (option.id && rmbx.util.isNumber(filterValue[0])) {
                    return (filterValue as Array<number>).includes(option.id)
                } else if (option.name && typeof filterValue[0] === "string") {
                    return (filterValue as Array<string>).includes(option.name)
                }
            })
        } else {
            options = selectorValues.filter((option: tSearchOption) => option && option.id == filterValue)
        }

        return options.length ? options : null
    }

    onChange = (value: tSearchOptions) => {
        this.setState({ value })
        if (this.props.onChange) {
            this.props.onChange(value)
        }
    }

    clearCache = () => {
        this.setState({
            cache: {},
        })
    }

    onClose = () => {
        this.setState({
            search: "",
        })
    }

    onOpen = async () => {
        await this.loadOptions()
    }

    focus = () => {
        if (this.innerRef && this.innerRef.current) {
            this.innerRef.current.focus()
        }
    }

    onInputChange = async (search: string) => {
        await this.setState({
            search,
        })

        if (!this.state.cache[search]) {
            await this.loadOptions()
        }
    }

    onMenuScrollToBottom = async () => {
        const { search } = this.state

        const currentOptions = this.state.cache[search]

        if (currentOptions) {
            await this.loadOptions()
        }
    }

    onOptionsLoaded = (error: typeof Error | null, results: tSearchResults) => {
        const { search } = this.state
        const currentOptions = this.state.cache[search] || initialCache

        if (error) {
            this.setState({
                cache: {
                    ...this.state.cache,
                    [search]: {
                        ...currentOptions,
                        isLoading: false,
                    },
                },
            })
        } else {
            const { options, hasMore } = results

            this.setState({
                cache: {
                    ...this.state.cache,
                    [search]: {
                        ...currentOptions,
                        options: currentOptions.options.concat(options),
                        hasMore: !!hasMore,
                        isLoading: false,
                    },
                },
            })
        }
    }

    async loadOptions() {
        const { search } = this.state
        const currentOptions = this.state.cache[search] || initialCache

        if (currentOptions.isLoading || !currentOptions.hasMore) {
            return
        }

        await this.setState({
            cache: {
                ...this.state.cache,
                [search]: {
                    ...currentOptions,
                    isLoading: true,
                },
            },
            search,
        })

        this.props.loadOptions(search, this.onOptionsLoaded, currentOptions.options)
    }

    render() {
        const { search, value, cache } = this.state
        const { primaryKey, filterValue, multi } = this.props
        const currentOptions = cache[search] || initialCache
        let selectorValue = value

        // If this component is being used as the UI for a filter, set its
        // value to the set of options that correspond to filter values from
        // redux.
        if (primaryKey) {
            if (Array.isArray(value) && value.length > 0) {
                selectorValue = this.getSelectorValuesFromFilterValues(value, filterValue)
                if (!multi) {
                    selectorValue = selectorValue ? selectorValue[0] || null : null
                }
            }
        }

        return (
            <Select
                {...this.props}
                ref={this.innerRef}
                onClose={this.onClose}
                onOpen={this.onOpen}
                onInputChange={this.onInputChange}
                onMenuScrollToBottom={this.onMenuScrollToBottom}
                isLoading={currentOptions.isLoading}
                options={currentOptions.options}
                onChange={this.onChange}
                value={selectorValue}
            />
        )
    }
}

export default AsyncPaginate
