"use strict"

import React from "react"
import { connect } from "react-redux"
import * as API from "../../api"
import RhumbixReferralModal from "../modals/modal-rhumbix-referral-wrapper"
import expandArrowImg from "../../../images/sidebar_icons/expand-arrow.svg"
import closeWindowImg from "../../../images/sidebar_icons/close-window.svg"
import { openRhumbixReferralModal } from "../../actions/rhumbix-referral"

require("rhumbix-referral.less")

const ShareButton = ({ openReferralModal }) => {
    return (
        <div className="button-share-container">
            <button onClick={openReferralModal} className="button-share">
                <img src={expandArrowImg} className="expand-arrow-image" alt="Expand" />
                <div className="share-rhumbix-label">Share Rhumbix</div>
            </button>
        </div>
    )
}

class InviteWindow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checkboxEmpty: true,
        }
        this.toggleCheckbox = this.toggleCheckbox.bind(this)
    }

    toggleCheckbox() {
        if (this.state.checkboxEmpty) {
            API.getTmInviteCampaignCookie()
        } else {
            API.deleteTmInviteCampaignCookie()
        }
        this.setState({ checkboxEmpty: !this.state.checkboxEmpty })
    }

    getCheckboxImageName() {
        return this.state.checkboxEmpty ? "checkbox-empty" : "checkbox-full"
    }

    render() {
        return (
            <div className="invite-window">
                <p className="want-to-make-label">
                    Help your friends track and measure at-risk work! Share Rhumbix and score some gear!
                </p>
                <button onClick={this.props.openReferralModal} className="learn-more-button">
                    <div className="learn-more-label">Invite Now</div>
                </button>
                <button className="close-window" onClick={this.props.toggleReferralHidden}>
                    <img src={closeWindowImg} className="close-image" alt="Close" />
                </button>
                <button className="checkbox" onClick={this.toggleCheckbox}>
                    <img
                        src={require(`../../../images/sidebar_icons/${this.getCheckboxImageName()}.svg`)}
                        alt="Checkbox"
                    />
                </button>
                <div className="dont-show-again-label">Don&apos;t show again</div>
            </div>
        )
    }
}

const MiniShareButton = ({ openReferralModal }) => {
    return (
        <div>
            <button onClick={openReferralModal} className="mini-button-share">
                <img src={expandArrowImg} className="mini-expand-arrow-image" alt="Expand" />
            </button>
        </div>
    )
}

class RhumbixReferral extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReferralHidden: false,
        }
        this.toggleReferralHidden = this.toggleReferralHidden.bind(this)
    }

    componentDidMount() {
        if (document.cookie.includes("tm_invite_campaign=")) {
            this.setState({ isReferralHidden: true })
        }
    }

    toggleReferralHidden() {
        this.setState({ isReferralHidden: !this.state.isReferralHidden })
    }

    openReferralModal = () => {
        this.props.openReferralModal()
    }

    renderReferralSidebarButton() {
        if (this.props.isHidden || this.state.isReferralHidden) return null
        if (this.props.miniReferral) return <MiniShareButton openReferralModal={this.openReferralModal} />
        if (this.props.isInviteWindowHidden) {
            return (
                <div className="collapsible-menu-group sidebar-left-padding sidebar-menu-item-container">
                    <ShareButton openReferralModal={this.openReferralModal} />
                </div>
            )
        }
        return (
            <InviteWindow
                openReferralModal={this.openReferralModal}
                toggleReferralHidden={this.toggleReferralHidden}
            />
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.renderReferralSidebarButton()}
                <RhumbixReferralModal />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openReferralModal() {
        dispatch(openRhumbixReferralModal())
    },
})

export default connect(null, mapDispatchToProps)(RhumbixReferral)
