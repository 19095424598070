import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { createLogger } from "redux-logger"
import * as Sentry from "@sentry/react"
//import immutableStateInvariantMiddleware from "redux-immutable-state-invariant"

import apiMiddleware from "./middleware/api"
import socketMiddleware from "./middleware/sockets"
import rootReducer from "./reducers/index"

// Logger must be last middleware in chain, otherwise it will log thunk and promise,
// not actual actions (https://github.com/theaqua/redux-logger/issues/20).
const middleware = [thunk, apiMiddleware, socketMiddleware]
let enhancer = null
const sentryReduxEnhancer = Sentry.createReduxEnhancer()

if (process.env.NODE_ENV === "production") {
    enhancer = compose(applyMiddleware(...middleware), sentryReduxEnhancer)
} else {
    // Enabling browser redux extension (https://github.com/zalmoxisus/redux-devtools-extension)
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

    // Enable immutable state variant checker. This is extremely slow, so never
    // use it in production. It's useful enough that we want it available in dev.
    /**
    middleware.push(
        immutableStateInvariantMiddleware({
            ignore: [
                // We need a reference to the original Promise objects so we can
                // cancel them.
                "networkStatus.promises"
            ]
        })
    )
    */

    if (process.env.NODE_ENV !== "test") {
        middleware.push(createLogger())
    }

    enhancer = composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer)
}

export default function configureStore() {
    return createStore(rootReducer, enhancer)
}
