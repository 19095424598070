import { Action } from "redux"
import { getListOfTransformsOptionsData } from "../../api"
import { getMessageFromAPIErrorResponse } from "../../common/ts-utils"
import { Thunk, tResourceName, tTransformOption } from "../../common/types"
import { sendGuestCopyOfSignedForm } from "../../api"
import { tFilterState } from "../../filters/types"
import { CopyToModalParamsType, ImportToFieldFormModalParamsType } from "../../forms/FieldFormsForm/types"
import { tContext } from "../custom-dashboards/types"
import { ColDef, RowNode } from "ag-grid-community"

/**
 * Field Form Modal
 */

export const LEAVE_FIELD_FORM_MODAL_OPEN = "LEAVE_FIELD_FORM_MODAL_OPEN"
export const LEAVE_FIELD_FORM_MODAL_CLOSED = "LEAVE_FIELD_FORM_MODAL_CLOSED"
export const FORM_SHARE_MODAL_OPENED = "FORM_SHARE_MODAL_OPENED"
export const FORM_SHARE_MODAL_CLOSED = "FORM_SHARE_MODAL_CLOSED"

export interface iOpenLeaveFieldFormModal extends Action {
    type: typeof LEAVE_FIELD_FORM_MODAL_OPEN
}

export interface iCloseLeaveFieldFormModal extends Action {
    type: typeof LEAVE_FIELD_FORM_MODAL_CLOSED
}

export interface iOpenFormShareModal extends Action {
    type: typeof FORM_SHARE_MODAL_OPENED
    previousCollaborators: Array<Record<string, any>> | void
    projectsList: Array<any>
    selectedForms: Array<any>
    currentUserFullName: string
    companyName: string
    formType: string
    fetchError: string | null
    formSharePermissions: Record<string, any>
    formShareVariants: Record<string, string[]>
}

export interface iCloseFormShareModal extends Action {
    type: typeof FORM_SHARE_MODAL_CLOSED
}

export type tLeaveFieldFormModalActions = iOpenLeaveFieldFormModal | iCloseLeaveFieldFormModal

export type tFormShareModalActions = iOpenFormShareModal | iCloseFormShareModal

export const openLeaveFieldFormModal = (): tLeaveFieldFormModalActions => {
    return {
        type: LEAVE_FIELD_FORM_MODAL_OPEN,
    }
}

export const closeLeaveFieldFormModal = (): tLeaveFieldFormModalActions => {
    return {
        type: LEAVE_FIELD_FORM_MODAL_CLOSED,
    }
}

export const openFormShareModal = (
    previousCollaborators: Array<Record<string, any>> | void,
    projectsList: Array<any>,
    selectedForms: Array<any>,
    currentUserFullName: string,
    companyName: string,
    formType: string,
    fetchError: string | null,
    formSharePermissions: Record<string, any>,
    formShareVariants: Record<string, string[]>
): tFormShareModalActions => {
    return {
        type: FORM_SHARE_MODAL_OPENED,
        previousCollaborators,
        projectsList,
        selectedForms,
        currentUserFullName,
        companyName,
        formType,
        fetchError,
        formSharePermissions,
        formShareVariants,
    }
}

export const closeFormShareModal = (): tFormShareModalActions => {
    return {
        type: FORM_SHARE_MODAL_CLOSED,
    }
}

/**
 * Guest Info Modal
 */

export const GUEST_INFO_MODAL_OPENED = "GUEST_INFO_MODAL_OPENED"
export const GUEST_INFO_MODAL_CLOSED = "GUEST_INFO_MODAL_CLOSED"
export const GUEST_INFO_ERROR_MODAL_OPENED = "GUEST_INFO_ERROR_MODAL_OPENED"
export const GUEST_INFO_ERROR_MODAL_CLOSED = "GUEST_INFO_ERROR_MODAL_CLOSED"

export interface iOpenGuestInfoSaveModal extends Action {
    type: typeof GUEST_INFO_MODAL_OPENED
    guestEmail: string
    signedKey: string
}

export interface iCloseGuestInfoSaveModal extends Action {
    type: typeof GUEST_INFO_MODAL_CLOSED
}

export interface iOpenGuestInfoErrorModal extends Action {
    type: typeof GUEST_INFO_ERROR_MODAL_OPENED
    error: string
}

export interface iCloseGuestInfoErrorModal extends Action {
    type: typeof GUEST_INFO_ERROR_MODAL_CLOSED
}

export type tGuestInfoActions =
    | iOpenGuestInfoSaveModal
    | iCloseGuestInfoSaveModal
    | iOpenGuestInfoErrorModal
    | iCloseGuestInfoErrorModal

export const openGuestInfoModal = (guestEmail: string, signedKey: string): tGuestInfoActions => {
    return {
        type: GUEST_INFO_MODAL_OPENED,
        guestEmail,
        signedKey,
    }
}

export const closeGuestInfoModal = (): tGuestInfoActions => {
    return {
        type: GUEST_INFO_MODAL_CLOSED,
    }
}

export const openGuestInfoErrorModal = (error: string): tGuestInfoActions => {
    return {
        type: GUEST_INFO_ERROR_MODAL_OPENED,
        error,
    }
}

export const closeGuestInfoErrorModal = (): tGuestInfoActions => {
    return {
        type: GUEST_INFO_ERROR_MODAL_CLOSED,
    }
}

/**
 * Send a copy of the PDF that a guest is viewing to their email address
 *
 * Can go with the removal "WA-5689-update-external-form-view" and "WA-8154-ff-variants"
 * @param signedKey The signed key that grants the user access to this form
 * @param originalPDFS3VersionId The S3 PDF version number of the form that the user just edited.
 */
export const sendPDFToGuest = (signedKey: string, originalPDFS3VersionId: string): Thunk => {
    return () => sendGuestCopyOfSignedForm(signedKey, originalPDFS3VersionId).catch(error => error.response)
}

/**
 * Custom Form Modals
 */

export const CUSTOM_FORM_GUEST_SAVE_MODAL_OPENED = "CUSTOM_FORM_GUEST_SAVE_MODAL_OPENED"
export const CUSTOM_FORM_GUEST_SAVE_MODAL_CLOSED = "CUSTOM_FORM_GUEST_SAVE_MODAL_CLOSED"
export const CUSTOM_FORM_SAVE_MODAL_OPENED = "CUSTOM_FORM_SAVE_MODAL_OPENED"
export const CUSTOM_FORM_SAVE_MODAL_CLOSED = "CUSTOM_FORM_SAVE_MODAL_CLOSED"
export const CUSTOM_FORM_ERROR_MODAL_OPENED = "CUSTOM_FORM_ERROR_MODAL_OPENED"
export const CUSTOM_FORM_ERROR_MODAL_CLOSED = "CUSTOM_FORM_ERROR_MODAL_CLOSED"
export const CUSTOM_FORM_UI_CHANGE_MODAL_OPENED = "CUSTOM_FORM_UI_CHANGE_MODAL_OPENED"
export const CUSTOM_FORM_UI_CHANGE_MODAL_CLOSED = "CUSTOM_FORM_UI_CHANGE_MODAL_CLOSED"

export interface iOpenGuestCustomFormSaveModal extends Action {
    type: typeof CUSTOM_FORM_GUEST_SAVE_MODAL_OPENED
    id: number
    validatedData: any
    currentStatus: string
    statuses: string[]
    guestEmail: string
    signedKey: string
    guestLastName: string
    guestExpiration: number
    originalPDFS3VersionId: string // this can go with WA-8154-ff-variants
}

export interface iCloseGuestCustomFormSaveModal extends Action {
    type: typeof CUSTOM_FORM_GUEST_SAVE_MODAL_CLOSED
}

export interface iOpenCustomFormSaveModal extends Action {
    type: typeof CUSTOM_FORM_SAVE_MODAL_OPENED
    ids: Array<any>
}

export interface iCloseCustomFormSaveModal extends Action {
    type: typeof CUSTOM_FORM_SAVE_MODAL_CLOSED
}

export interface iOpenCustomFormErrorModal extends Action {
    type: typeof CUSTOM_FORM_ERROR_MODAL_OPENED
    errors: Array<any>
    saveErrorType: string
}

export interface iCloseCustomFormErrorModal extends Action {
    type: typeof CUSTOM_FORM_ERROR_MODAL_CLOSED
}

export interface iOpenCustomFormUIChangeModal extends Action {
    type: typeof CUSTOM_FORM_UI_CHANGE_MODAL_OPENED
}

export interface iCloseCustomFormUIChangeModal extends Action {
    type: typeof CUSTOM_FORM_UI_CHANGE_MODAL_CLOSED
}

export type tCustomFormModalActions =
    | iOpenGuestCustomFormSaveModal
    | iCloseGuestCustomFormSaveModal
    | iOpenCustomFormSaveModal
    | iCloseCustomFormSaveModal
    | iOpenCustomFormErrorModal
    | iCloseCustomFormErrorModal
    | iOpenCustomFormUIChangeModal
    | iCloseCustomFormUIChangeModal

export const openGuestCustomFormSaveModal = (
    id: number,
    validatedData: Record<string, any>,
    currentStatus: string,
    statuses: string[],
    guestEmail: string,
    signedKey: string,
    guestLastName: string,
    guestExpiration: number,
    originalPDFS3VersionId: string // this can go with WA-8154-ff-variants
): tCustomFormModalActions => {
    return {
        type: CUSTOM_FORM_GUEST_SAVE_MODAL_OPENED,
        id,
        validatedData,
        currentStatus,
        statuses,
        guestEmail,
        signedKey,
        guestLastName,
        guestExpiration,
        originalPDFS3VersionId, // this can go with WA-8154-ff-variants
    }
}

export const closeGuestCustomFormSaveModal = (): tCustomFormModalActions => {
    return {
        type: CUSTOM_FORM_GUEST_SAVE_MODAL_CLOSED,
    }
}

export const openCustomFormSaveModal = (ids: Array<any>): tCustomFormModalActions => {
    return {
        type: CUSTOM_FORM_SAVE_MODAL_OPENED,
        ids,
    }
}

export const closeCustomFormSaveModal = (): tCustomFormModalActions => {
    return {
        type: CUSTOM_FORM_SAVE_MODAL_CLOSED,
    }
}

export const openCustomFormErrorModal = (errors: Array<any>, saveErrorType: string): tCustomFormModalActions => {
    return {
        type: CUSTOM_FORM_ERROR_MODAL_OPENED,
        errors,
        saveErrorType,
    }
}

export const closeCustomFormErrorModal = (): tCustomFormModalActions => {
    return {
        type: CUSTOM_FORM_ERROR_MODAL_CLOSED,
    }
}

export const openChangeUIModal = (): tCustomFormModalActions => {
    return {
        type: CUSTOM_FORM_UI_CHANGE_MODAL_OPENED,
    }
}

export const closeChangeUIModal = (): tCustomFormModalActions => {
    return {
        type: CUSTOM_FORM_UI_CHANGE_MODAL_CLOSED,
    }
}

/**
 * Copy To Modal
 */

export const COPY_TO_MODAL_OPEN = "COPY_TO_MODAL_OPEN"
export const COPY_TO_MODAL_CLOSE = "COPY_TO_MODAL_CLOSE"
export const SET_TRANSFORMS_OPTIONS = "SET_TRANSFORMS_OPTIONS"
export const SET_SELECTED_TRANSFORMS_VALUE = "SET_SELECTED_TRANSFORMS_VALUE"

export interface iOpenCopyToModal extends Action {
    type: typeof COPY_TO_MODAL_OPEN
    copyToModalParams: CopyToModalParamsType
}

export interface iCloseCopyToModal extends Action {
    type: typeof COPY_TO_MODAL_CLOSE
}

export interface iSetSelectedTransformValue extends Action {
    type: typeof SET_SELECTED_TRANSFORMS_VALUE
    selectedTransformValue: number
}

export interface iSetTransformsOptions extends Action {
    type: typeof SET_TRANSFORMS_OPTIONS
    transformsOptions: Array<tTransformOption>
}

export type tCopyToActions =
    | iOpenCopyToModal
    | iCloseCopyToModal
    | iSetSelectedTransformValue
    | iSetTransformsOptions

export const openCopyToModal = (params: CopyToModalParamsType): tCopyToActions => {
    return { type: COPY_TO_MODAL_OPEN, copyToModalParams: params ?? {} }
}

export const closeCopyToModal = (): tCopyToActions => {
    return { type: COPY_TO_MODAL_CLOSE }
}

export const getListOfTransformsOptions = (fromSchemaId: number): Thunk => {
    return dispatch => {
        return getListOfTransformsOptionsData(fromSchemaId).then(data => {
            dispatch(setTransformsOptions(data))
        })
    }
}

export const setSelectedTransformValue = (selectedTransformValue: number): tCopyToActions => {
    return {
        type: SET_SELECTED_TRANSFORMS_VALUE,
        selectedTransformValue,
    }
}

export const setTransformsOptions = (transformsOptions: Array<tTransformOption>): tCopyToActions => {
    return {
        type: SET_TRANSFORMS_OPTIONS,
        transformsOptions,
    }
}

/**
 * Import To Field Form Modal
 */

export const IMPORT_TO_FIELD_FORM_MODAL_OPEN = "IMPORT_TO_FIELD_FORM_MODAL_OPEN"
export const IMPORT_TO_FIELD_FORM_MODAL_CLOSE = "IMPORT_TO_FIELD_FORM_MODAL_CLOSE"

export interface iOpenImportToFieldFormModal extends Action {
    type: typeof IMPORT_TO_FIELD_FORM_MODAL_OPEN
    importToFieldFormModalParams: ImportToFieldFormModalParamsType
}

export interface iCloseImportToFieldFormModal extends Action {
    type: typeof IMPORT_TO_FIELD_FORM_MODAL_CLOSE
}

export type tImportToFieldFormModalActions = iOpenImportToFieldFormModal | iCloseImportToFieldFormModal

export const openImportToFieldFormModal = (
    params: ImportToFieldFormModalParamsType
): tImportToFieldFormModalActions => {
    return { type: IMPORT_TO_FIELD_FORM_MODAL_OPEN, importToFieldFormModalParams: params ?? {} }
}

export const closeImportToFieldFormModal = (): tImportToFieldFormModalActions => {
    return { type: IMPORT_TO_FIELD_FORM_MODAL_CLOSE }
}

export const BUNDLE_EDIT_WARNING_MODAL_OPEN = "BUNDLE_EDIT_WARNING_MODAL_OPEN"
export const BUNDLE_EDIT_WARNING_MODAL_CLOSE = "BUNDLE_EDIT_WARNING_MODAL_CLOSE"

export interface iOpenBundleEditWarningModal extends Action {
    type: typeof BUNDLE_EDIT_WARNING_MODAL_OPEN
}

export interface iCloseBundleEditWarningModal extends Action {
    type: typeof BUNDLE_EDIT_WARNING_MODAL_CLOSE
}

export type tBundleEditWarningActions = iOpenBundleEditWarningModal | iCloseBundleEditWarningModal

export const openBundleEditWarningModal = (): tBundleEditWarningActions => {
    return { type: BUNDLE_EDIT_WARNING_MODAL_OPEN }
}

export const closeBundleEditWarningModal = (): tBundleEditWarningActions => {
    return { type: BUNDLE_EDIT_WARNING_MODAL_CLOSE }
}

/**
 * Change Password Modal
 */

export const PASSWORD_CHANGE_MODAL_OPENED = "PASSWORD_CHANGE_MODAL_OPENED"
export const PASSWORD_CHANGE_MODAL_CLOSED = "PASSWORD_CHANGE_MODAL_CLOSED"

export interface iOpenChangePasswordModal extends Action {
    type: typeof PASSWORD_CHANGE_MODAL_OPENED
}

export interface iCloseChangePasswordModal extends Action {
    type: typeof PASSWORD_CHANGE_MODAL_CLOSED
}

export type tChangePasswordModalActions = iOpenChangePasswordModal | iCloseChangePasswordModal

export const openChangePasswordModal = (): tChangePasswordModalActions => {
    return {
        type: PASSWORD_CHANGE_MODAL_OPENED,
    }
}

export const closeChangePasswordModal = (): tChangePasswordModalActions => {
    return {
        type: PASSWORD_CHANGE_MODAL_CLOSED,
    }
}

/**
 * Add Quantity Modal
 */

export const ADD_QUANTITY_MODAL_OPENED = "ADD_QUANTITY_MODAL_OPENED"
export const ADD_QUANTITY_MODAL_CLOSED = "ADD_QUANTITY_MODAL_CLOSED"

export interface iOpenAddQuantityModal extends Action {
    type: typeof ADD_QUANTITY_MODAL_OPENED
}

export interface iCloseAddQuantityModal extends Action {
    type: typeof ADD_QUANTITY_MODAL_CLOSED
}

export type tAddQuantityModalActions = iOpenAddQuantityModal | iCloseAddQuantityModal

export const addQtyModal = (): tAddQuantityModalActions => {
    return {
        type: ADD_QUANTITY_MODAL_OPENED,
    }
}

export const addQuantityModalClosed = (): tAddQuantityModalActions => {
    return {
        type: ADD_QUANTITY_MODAL_CLOSED,
    }
}

/**
 * Edit Work Component Modal
 */

export const EDIT_WORK_COMPONENT_MODAL_OPENED = "EDIT_WORK_COMPONENT_MODAL_OPENED"
export const EDIT_WORK_COMPONENT_MODAL_CLOSED = "EDIT_WORK_COMPONENT_MODAL_CLOSED"
export const OPEN_WORK_COMPONENT_SAVE_ERROR_MODAL = "OPEN_WORK_COMPONENT_SAVE_ERROR_MODAL"
export const CLOSE_WORK_COMPONENT_SAVE_ERROR_MODAL = "CLOSE_WORK_COMPONENT_SAVE_ERROR_MODAL"
export const WORK_COMPONENT_EDITED = "WORK_COMPONENT_EDITED"

export interface iOpenEditWorkComponentModal extends Action {
    type: typeof EDIT_WORK_COMPONENT_MODAL_OPENED
    worker: any // FIXME: replace type
    workComponent: any // FIXME: replace type
    costCode: any // FIXME: replace type
    employeeWorkShiftId: any // FIXME: replace type
}

export interface iCloseEditWorkComponentModal extends Action {
    type: typeof EDIT_WORK_COMPONENT_MODAL_CLOSED
}

export interface iOpenWorkComponentUpdateFailedModal extends Action {
    type: typeof OPEN_WORK_COMPONENT_SAVE_ERROR_MODAL
    errorMsg: any // FIXME: replace type
}

export interface iCloseWorkComponentUpdateFailedModal extends Action {
    type: typeof CLOSE_WORK_COMPONENT_SAVE_ERROR_MODAL
}

export interface iWorkComponentEdited extends Action {
    type: typeof WORK_COMPONENT_EDITED
    value: any // FIXME: replace type
    valid: any // FIXME: replace type
    total: any // FIXME: replace type
    wcType: string
}

export type tEditWorkComponentModalActions =
    | iOpenEditWorkComponentModal
    | iCloseEditWorkComponentModal
    | iOpenWorkComponentUpdateFailedModal
    | iCloseWorkComponentUpdateFailedModal
    | iWorkComponentEdited

export const editWorkComponent = (
    worker: Record<string, any>,
    workComponent: Record<string, any>,
    costCode: Record<string, any>,
    employeeWorkShiftId: Record<string, any>
): tEditWorkComponentModalActions => {
    return {
        type: EDIT_WORK_COMPONENT_MODAL_OPENED,
        worker,
        workComponent,
        costCode,
        employeeWorkShiftId: employeeWorkShiftId,
    }
}

export const closeEditWorkComponentModal = (): tEditWorkComponentModalActions => {
    return {
        type: EDIT_WORK_COMPONENT_MODAL_CLOSED,
    }
}

export const openWorkComponentUpdateFailedModal = (
    errorResponse: Record<string, any>
): tEditWorkComponentModalActions => {
    const errorMsg = getMessageFromAPIErrorResponse(errorResponse, "Failed to save changes.")
    return {
        type: OPEN_WORK_COMPONENT_SAVE_ERROR_MODAL,
        errorMsg,
    }
}

export const closeWorkComponentUpdateFailedModal = (): tEditWorkComponentModalActions => {
    return {
        type: CLOSE_WORK_COMPONENT_SAVE_ERROR_MODAL,
    }
}

export const workComponentEdited = (
    value: Record<string, any>,
    valid: Record<string, any>,
    total: Record<string, any>,
    wcType: string
): tEditWorkComponentModalActions => {
    return {
        type: WORK_COMPONENT_EDITED,
        value,
        valid,
        total,
        wcType,
    }
}

/**
 * Absence Modal
 */

export const ABSENCE_MODAL_OPENED = "ABSENCE_MODAL_OPENED"
export const ABSENCE_MODAL_CLOSED = "ABSENCE_MODAL_CLOSED"

export interface iOpenAbsenceModal extends Action {
    type: typeof ABSENCE_MODAL_OPENED
    employeeWorkshift: any // FIXME: replace type
}

export interface iCloseAbsenceModal extends Action {
    type: typeof ABSENCE_MODAL_CLOSED
}

export type tAbsenceModalActions = iOpenAbsenceModal | iCloseAbsenceModal

export const openAbsenceModal = (employeeWorkshift: Record<string, any>): tAbsenceModalActions => {
    return {
        type: ABSENCE_MODAL_OPENED,
        employeeWorkshift,
    }
}

export const closeAbsenceModal = (): tAbsenceModalActions => {
    return {
        type: ABSENCE_MODAL_CLOSED,
    }
}

/**
 * Project Config Modal
 */

export const PROJECT_CONFIG_MODAL_OPENED = "PROJECT_CONFIG_MODAL_OPENED"
export const PROJECT_CONFIG_MODAL_CLOSED = "PROJECT_CONFIG_MODAL_CLOSED"

export interface iOpenProjectConfigModal extends Action {
    type: typeof PROJECT_CONFIG_MODAL_OPENED
    newAssignments: Record<number, Record<string, any>>
    newRemovals: Record<number, Record<string, any>>
    newGroupAssignments: Record<number, Record<string, any>>
    newGroupRemovals: Record<number, Record<string, any>>
    saveCallback: () => void
}

export interface iCloseProjectConfigModal extends Action {
    type: typeof PROJECT_CONFIG_MODAL_CLOSED
}

export type tProjectConfigModalActions = iOpenProjectConfigModal | iCloseProjectConfigModal

export const openProjectConfigModal = (
    newAssignments: Record<number, Record<string, any>>,
    newRemovals: Record<number, Record<string, any>>,
    newGroupAssignments: Record<number, Record<string, any>>,
    newGroupRemovals: Record<number, Record<string, any>>,
    saveCallback: () => void
): tProjectConfigModalActions => {
    return {
        type: PROJECT_CONFIG_MODAL_OPENED,
        newAssignments,
        newRemovals,
        newGroupAssignments,
        newGroupRemovals,
        saveCallback,
    }
}

export const closeProjectConfigModal = (): tProjectConfigModalActions => {
    return {
        type: PROJECT_CONFIG_MODAL_CLOSED,
    }
}

/**
 * Add Worker Modal
 */

export const ADD_WORKER_MODAL_OPENED = "ADD_WORKER_MODAL_OPENED"
export const ADD_WORKER_MODAL_CLOSED = "ADD_WORKER_MODAL_CLOSED"

export interface iOpenAddWorkerModal extends Action {
    type: typeof ADD_WORKER_MODAL_OPENED
    workShift: any // FIXME: replace type
}

export interface iCloseAddWorkerModal extends Action {
    type: typeof ADD_WORKER_MODAL_CLOSED
}

export type tAddWorkerModalActions = iOpenAddWorkerModal | iCloseAddWorkerModal

export const addWorker = (workShift: Record<string, any>): tAddWorkerModalActions => {
    return {
        type: ADD_WORKER_MODAL_OPENED,
        workShift,
    }
}

export const closeAddWorkerModal = (): tAddWorkerModalActions => {
    return {
        type: ADD_WORKER_MODAL_CLOSED,
    }
}

/**
 * Update Worker Modal
 */

export const ADD_WORKER_MODAL_CHANGED_SELECTED_WORKER_ID = "ADD_WORKER_MODAL_CHANGED_SELECTED_WORKER_ID"

export interface iOpenUpdateWorkerModal extends Action {
    type: typeof ADD_WORKER_MODAL_CHANGED_SELECTED_WORKER_ID
    selectedWorker: any // FIXME: replace type
}

export type tUpdateWorkerModalActions = iOpenUpdateWorkerModal

export const updateSelectedWorker = (selectedWorker: Record<string, any>): tUpdateWorkerModalActions => {
    return {
        type: ADD_WORKER_MODAL_CHANGED_SELECTED_WORKER_ID,
        selectedWorker: selectedWorker,
    }
}

/**
 * Signature Required Modal
 */

export const SIGNATURE_REQUIRED_MODAL_CLOSED = "SIGNATURE_REQUIRED_MODAL_CLOSED"
export const SIGNATURE_REQUIRED_MODAL_OPENED = "SIGNATURE_REQUIRED_MODAL_OPENED"

export interface iOpenSignatureRequiredModal extends Action {
    type: typeof SIGNATURE_REQUIRED_MODAL_CLOSED
}

export interface iCloseSignatureRequiredModal extends Action {
    type: typeof SIGNATURE_REQUIRED_MODAL_OPENED
}

export type tSignatureRequiredModalActions = iOpenSignatureRequiredModal | iCloseSignatureRequiredModal

export const closeSignatureRequiredModal = (): tSignatureRequiredModalActions => {
    return {
        type: SIGNATURE_REQUIRED_MODAL_CLOSED,
    }
}

export const openSignatureRequiredModal = (): tSignatureRequiredModalActions => {
    return {
        type: SIGNATURE_REQUIRED_MODAL_OPENED,
    }
}

/**
 * Delete Workshift Modal
 */

export const DELETE_WORKSHIFT_MODAL_OPENED = "DELETE_WORKSHIFT_MODAL_OPENED"
export const DELETE_WORKSHIFT_MODAL_CLOSED = "DELETE_WORKSHIFT_MODAL_CLOSED"

export interface iOpenDeleteWorkshiftModal extends Action {
    type: typeof DELETE_WORKSHIFT_MODAL_OPENED
    workshiftId: any // FIXME: replace type
    ewsCount: any // FIXME: replace type
}

export interface iCloseDeleteWorkshiftModal extends Action {
    type: typeof DELETE_WORKSHIFT_MODAL_CLOSED
}

export type tDeleteWorkshiftModalActions = iOpenDeleteWorkshiftModal | iCloseDeleteWorkshiftModal

export const openDeleteWorkshiftModal = (workshiftId: number, ewsCount: number): tDeleteWorkshiftModalActions => {
    return {
        type: DELETE_WORKSHIFT_MODAL_OPENED,
        workshiftId,
        ewsCount,
    }
}

export const closeDeleteWorkshiftModal = (): tDeleteWorkshiftModalActions => {
    return {
        type: DELETE_WORKSHIFT_MODAL_CLOSED,
    }
}

/**
 * Leave Confirmation Modal
 */

export const LEAVE_CONFIRMATION_MODAL_OPENED = "LEAVE_CONFIRMATION_MODAL_OPENED"
export const LEAVE_CONFIRMATION_MODAL_CLOSED = "LEAVE_CONFIRMATION_MODAL_CLOSED"

export interface iOpenLeaveConfirmationModal extends Action {
    type: typeof LEAVE_CONFIRMATION_MODAL_OPENED
}

export interface iCloseLeaveConfirmationModal extends Action {
    type: typeof LEAVE_CONFIRMATION_MODAL_CLOSED
}

export type tLeaveConfirmationModalActions = iOpenLeaveConfirmationModal | iCloseLeaveConfirmationModal

export const openLeaveConfirmationModal = (): tLeaveConfirmationModalActions => {
    return {
        type: LEAVE_CONFIRMATION_MODAL_OPENED,
    }
}

export const closeLeaveConfirmationModal = (): tLeaveConfirmationModalActions => {
    return {
        type: LEAVE_CONFIRMATION_MODAL_CLOSED,
    }
}

/**
 * Walkthrough Modal
 */

export const WALK_THROUGH_MODAL_OPENED = "WALK_THROUGH_MODAL_OPENED"
export const WALK_THROUGH_MODAL_CLOSED = "WALK_THROUGH_MODAL_CLOSED"

export interface iOpenWalkThroughModal extends Action {
    type: typeof WALK_THROUGH_MODAL_OPENED
}

export interface iCloseWalkThroughModal extends Action {
    type: typeof WALK_THROUGH_MODAL_CLOSED
}

export type tWalkThroughModalActions = iOpenWalkThroughModal | iCloseWalkThroughModal

export const openWalkThroughModal = (): tWalkThroughModalActions => {
    return {
        type: WALK_THROUGH_MODAL_OPENED,
    }
}

export const closeWalkThroughModal = (): tWalkThroughModalActions => {
    return {
        type: WALK_THROUGH_MODAL_CLOSED,
    }
}

/**
 * Add Workshift Modal
 */

export const ADD_WORKSHIFT_MODAL_OPENED = "ADD_WORKSHIFT_MODAL_OPENED"
export const ADD_WORKSHIFT_MODAL_CLOSED = "ADD_WORKSHIFT_MODAL_CLOSED"

export interface iOpenAddWorkshiftModal extends Action {
    type: typeof ADD_WORKSHIFT_MODAL_OPENED
}

export interface iCloseAddWorkshiftModal extends Action {
    type: typeof ADD_WORKSHIFT_MODAL_CLOSED
}

export type tAddWorkshiftModalActions = iOpenAddWorkshiftModal | iCloseAddWorkshiftModal

export const addWorkshiftModalOpened = (): tAddWorkshiftModalActions => {
    return {
        type: ADD_WORKSHIFT_MODAL_OPENED,
    }
}

export const addWorkshiftModalClosed = (): tAddWorkshiftModalActions => {
    return {
        type: ADD_WORKSHIFT_MODAL_CLOSED,
    }
}

/**
 * Duplicate Workshift Modal
 */

export const DUPLICATE_WORKSHIFT_MODAL_OPENED = "DUPLICATE_WORKSHIFT_MODAL_OPENED"
export const DUPLICATE_WORKSHIFT_MODAL_CLOSED = "DUPLICATE_WORKSHIFT_MODAL_CLOSED"

export interface iOpenDuplicateWorkshiftModal extends Action {
    type: typeof DUPLICATE_WORKSHIFT_MODAL_OPENED
    workshiftId: any // FIXME: replace type
}

export interface iCloseDuplicateWorkshiftModal extends Action {
    type: typeof DUPLICATE_WORKSHIFT_MODAL_CLOSED
}

export type tDuplicateWorkshiftModalActions = iOpenDuplicateWorkshiftModal | iCloseDuplicateWorkshiftModal

export const duplicateWorkshiftModalOpened = (workshiftId: number): tDuplicateWorkshiftModalActions => {
    return {
        type: DUPLICATE_WORKSHIFT_MODAL_OPENED,
        workshiftId: workshiftId,
    }
}

export const duplicateWorkshiftModalClosed = (): tDuplicateWorkshiftModalActions => {
    return {
        type: DUPLICATE_WORKSHIFT_MODAL_CLOSED,
    }
}

/**
 * Duplicate Inactive Warning Modal
 */

export const DUPLICATE_INACTIVE_WARNING_MODAL_OPENED = "DUPLICATE_INACTIVE_WARNING_MODAL_OPENED"
export const DUPLICATE_INACTIVE_WARNING_MODAL_CLOSED = "DUPLICATE_INACTIVE_WARNING_MODAL_CLOSED"

export interface iOpenDuplicateInactiveWarningModal extends Action {
    type: typeof DUPLICATE_INACTIVE_WARNING_MODAL_OPENED
    workshiftId: any // FIXME: replace type
}

export interface iCloseDuplicateInactiveWarningModal extends Action {
    type: typeof DUPLICATE_INACTIVE_WARNING_MODAL_CLOSED
}

export type tDuplicateInactiveWarningModalActions =
    | iOpenDuplicateInactiveWarningModal
    | iCloseDuplicateInactiveWarningModal

export const duplicateInactiveWarningModalOpened = (workshiftId: number): tDuplicateInactiveWarningModalActions => {
    return {
        type: DUPLICATE_INACTIVE_WARNING_MODAL_OPENED,
        workshiftId: workshiftId,
    }
}

export const duplicateInactiveWarningModalClosed = (): tDuplicateInactiveWarningModalActions => {
    return {
        type: DUPLICATE_INACTIVE_WARNING_MODAL_CLOSED,
    }
}

/**
 * CSV Error Modal
 */

export const CSV_ERROR_MODAL_OPENED = "CSV_ERROR_MODAL_OPENED"
export const CSV_ERROR_MODAL_CLOSED = "CSV_ERROR_MODAL_CLOSED"

export interface iOpenCsvErrorModal extends Action {
    type: typeof CSV_ERROR_MODAL_OPENED
    errors: any // FIXME: replace type
}

export interface iCloseCsvErrorModal extends Action {
    type: typeof CSV_ERROR_MODAL_CLOSED
}

export type tCsvErrorModalActions = iOpenCsvErrorModal | iCloseCsvErrorModal

export const csvErrorModalOpened = (errors: Record<string, any>): tCsvErrorModalActions => {
    return {
        type: CSV_ERROR_MODAL_OPENED,
        errors,
    }
}

export const csvErrorModalClosed = (): tCsvErrorModalActions => {
    return {
        type: CSV_ERROR_MODAL_CLOSED,
    }
}

export const DELETE_CONFIRMATION_MODAL_OPENED = "DELETE_CONFIRMATION_MODAL_OPENED"
export const DELETE_CONFIRMATION_MODAL_CLOSED = "DELETE_CONFIRMATION_MODAL_CLOSED"

export interface iOpenDeleteConfirmationModal extends Action {
    type: typeof DELETE_CONFIRMATION_MODAL_OPENED
}

export interface iCloseDeleteConfirmationModal extends Action {
    type: typeof DELETE_CONFIRMATION_MODAL_CLOSED
}

export type tDeleteConfirmationModalActions = iOpenDeleteConfirmationModal | iCloseDeleteConfirmationModal

export const openDeleteConfirmationModal = (): tDeleteConfirmationModalActions => {
    return {
        type: DELETE_CONFIRMATION_MODAL_OPENED,
    }
}

export const closeDeleteConfirmationModal = (): tDeleteConfirmationModalActions => {
    return {
        type: DELETE_CONFIRMATION_MODAL_CLOSED,
    }
}

export const ERROR_MODAL_OPENED = "ERROR_MODAL_OPENED"
export const ERROR_MODAL_CLOSED = "ERROR_MODAL_CLOSED"

export interface iOpenErrorModal extends Action {
    type: typeof ERROR_MODAL_OPENED
    errors: Array<any>
    title: string
    subtitle: string
}

export interface iCloseErrorModal extends Action {
    type: typeof ERROR_MODAL_CLOSED
}

export type tErrorModalActions = iOpenErrorModal | iCloseErrorModal

export const openErrorModal = (errors: Array<any>, title: string, subtitle: string): tErrorModalActions => {
    return {
        type: ERROR_MODAL_OPENED,
        errors,
        title,
        subtitle,
    }
}

export const closeErrorModal = (): tErrorModalActions => {
    return {
        type: ERROR_MODAL_CLOSED,
    }
}

/**
 * Create Saved Filter Modal
 */

export const CREATE_SAVED_FILTER_MODAL_OPENED = "CREATE_SAVED_FILTER_MODAL_OPENED"
export const CREATE_SAVED_FILTER_MODAL_CLOSED = "CREATE_SAVED_FILTER_MODAL_CLOSED"
export const CREATE_SAVED_FILTER_SET_FAILED = "CREATE_SAVED_FILTER_SET_FAILED"

export interface iOpenCreateSavedFilterModal extends Action {
    type: typeof CREATE_SAVED_FILTER_MODAL_OPENED
    filterState: tFilterState
    page: string
}

export interface iCloseCreateSavedFilterModal extends Action {
    type: typeof CREATE_SAVED_FILTER_MODAL_CLOSED
    message: string | undefined
}

export interface iCreateSavedFilterSetFailed extends Action {
    type: typeof CREATE_SAVED_FILTER_SET_FAILED
    filterState?: tFilterState
    page?: string
    message: string
}

export type tCreateSavedFilterModalActions =
    | iOpenCreateSavedFilterModal
    | iCloseCreateSavedFilterModal
    | iCreateSavedFilterSetFailed

export const openCreateSavedFilterModal = (
    filterState: tFilterState,
    page: string
): tCreateSavedFilterModalActions => {
    return {
        type: CREATE_SAVED_FILTER_MODAL_OPENED,
        filterState,
        page,
    }
}

export const closeCreateSavedFilterModal = (): tCreateSavedFilterModalActions => {
    return {
        type: CREATE_SAVED_FILTER_MODAL_CLOSED,
        message: undefined, // Clear any error messages
    }
}

export const createSavedFilterSetFailed = (message: string): iCreateSavedFilterSetFailed => {
    return {
        type: CREATE_SAVED_FILTER_SET_FAILED,
        message,
    }
}

export const DELETE_SAVED_FILTER_MODAL_OPENED = "DELETE_SAVED_FILTER_MODAL_OPENED"
export const DELETE_SAVED_FILTER_MODAL_CLOSED = "DELETE_SAVED_FILTER_MODAL_CLOSED"
export const DELETE_SAVED_FILTER_SET_FAILED = "DELETE_SAVED_FILTER_SET_FAILED"

export interface iOpenDeleteSavedFilterModal extends Action {
    type: typeof DELETE_SAVED_FILTER_MODAL_OPENED
    id: number
    page: string
}

export interface iCloseDeleteSavedFilterModal extends Action {
    type: typeof DELETE_SAVED_FILTER_MODAL_CLOSED
    message: string | undefined
}

export interface iDeleteSavedFilterSetFailed extends Action {
    type: typeof DELETE_SAVED_FILTER_SET_FAILED
    message: string
}

export type tDeleteSavedFilterModalActions =
    | iOpenDeleteSavedFilterModal
    | iCloseDeleteSavedFilterModal
    | iDeleteSavedFilterSetFailed

export const openDeleteSavedFilterModal = (id: number, page: string): tDeleteSavedFilterModalActions => {
    return {
        type: DELETE_SAVED_FILTER_MODAL_OPENED,
        id,
        page,
    }
}

export const closeDeleteSavedFilterModal = (): tDeleteSavedFilterModalActions => {
    return {
        type: DELETE_SAVED_FILTER_MODAL_CLOSED,
        message: undefined, // Clear any error messages
    }
}

export const deleteSavedFilterSetFailed = (message: string): iDeleteSavedFilterSetFailed => {
    return {
        type: DELETE_SAVED_FILTER_SET_FAILED,
        message,
    }
}

export const ADD_ROWS_TO_WEEKLY_TK_MODAL_OPENED = "ADD_ROWS_TO_WEEKLY_TK_MODAL_OPENED"
export const ADD_ROWS_TO_WEEKLY_TK_MODAL_CLOSED = "ADD_ROWS_TO_WEEKLY_TK_MODAL_CLOSED"

export interface iOpenAddRowsToWeeklyTKModal extends Action {
    type: typeof ADD_ROWS_TO_WEEKLY_TK_MODAL_OPENED
    resource: tResourceName
    resourceDisplayName: string
    context: tContext
    colDefs: ColDef[]
}

export interface iCloseAddRowsToWeeklyTKModal extends Action {
    type: typeof ADD_ROWS_TO_WEEKLY_TK_MODAL_CLOSED
}

export type tAddRowsToWeeklyTKModalActions = iOpenAddRowsToWeeklyTKModal | iCloseAddRowsToWeeklyTKModal

export const openAddRowsToWeeklyTKModal = (
    resource: tResourceName,
    resourceDisplayName: string,
    context: tContext,
    colDefs: ColDef[]
): iOpenAddRowsToWeeklyTKModal => {
    return {
        type: ADD_ROWS_TO_WEEKLY_TK_MODAL_OPENED,
        resource,
        resourceDisplayName,
        context,
        colDefs,
    }
}

export const closeAddRowsToWeeklyTKModal = (): iCloseAddRowsToWeeklyTKModal => {
    return {
        type: ADD_ROWS_TO_WEEKLY_TK_MODAL_CLOSED,
    }
}

export const ADD_COHORT_EMPLOYEES_OPENED = "ADD_COHORT_EMPLOYEES_OPENED"
export const ADD_COHORT_EMPLOYEES_CLOSED = "ADD_COHORT_EMPLOYEES_CLOSED"

export interface iOpenAddCohortEmployeesModal extends Action {
    type: typeof ADD_COHORT_EMPLOYEES_OPENED
    cohortId: number
    context: tContext
    colDefs: ColDef[]
}

export interface iCloseAddCohortEmployeesModal extends Action {
    type: typeof ADD_COHORT_EMPLOYEES_CLOSED
}

export type tAddCohortEmployeesModalActions = iOpenAddCohortEmployeesModal | iCloseAddCohortEmployeesModal

export const openAddCohortEmployeesModal = (
    cohortId: number,
    context: tContext,
    colDefs: ColDef[]
): iOpenAddCohortEmployeesModal => {
    return {
        type: ADD_COHORT_EMPLOYEES_OPENED,
        cohortId,
        context,
        colDefs,
    }
}

export const closeAddCohortEmployeesModal = (): iCloseAddCohortEmployeesModal => {
    return {
        type: ADD_COHORT_EMPLOYEES_CLOSED,
    }
}

export const TK_SIGN_TIMECARD_MODAL_OPENED = "TK_SIGN_TIMECARD_MODAL_OPENED"
export const TK_SIGN_TIMECARD_MODAL_CLOSED = "TK_SIGN_TIMECARD_MODAL_CLOSED"

export interface iOpenTKSignTimecardModal extends Action {
    type: typeof TK_SIGN_TIMECARD_MODAL_OPENED
}

export interface iCloseTKSignTimecardModal extends Action {
    type: typeof TK_SIGN_TIMECARD_MODAL_CLOSED
}

export type tTKSignTimecardModalActions = iOpenTKSignTimecardModal | iCloseTKSignTimecardModal

export const openTKSignTimecardModal = (): tTKSignTimecardModalActions => {
    return {
        type: TK_SIGN_TIMECARD_MODAL_OPENED,
    }
}

export const closeTKSignTimecardModal = (): tTKSignTimecardModalActions => {
    return {
        type: TK_SIGN_TIMECARD_MODAL_CLOSED,
    }
}

export const SSO_CONFIGURATION_CONFIRMATION_MODAL_OPENED = "SSO_CONFIGURATION_CONFIRMATION_MODAL_OPENED"
export const SSO_CONFIGURATION_CONFIRMATION_MODAL_CLOSED = "SSO_CONFIGURATION_CONFIRMATION_MODAL_CLOSED"

export interface iOpenSSOConfigurationConfirmationModal extends Action {
    type: typeof SSO_CONFIGURATION_CONFIRMATION_MODAL_OPENED
    errorMessage?: string
}

export interface iCloseSSOConfigurationConfirmationModal extends Action {
    type: typeof SSO_CONFIGURATION_CONFIRMATION_MODAL_CLOSED
}

export type tSSOConfigurationConfirmationModalActions =
    | iOpenSSOConfigurationConfirmationModal
    | iCloseSSOConfigurationConfirmationModal

export const openSSOConfigurationConfirmationModal = (
    errorMessage?: string
): tSSOConfigurationConfirmationModalActions => {
    return {
        type: SSO_CONFIGURATION_CONFIRMATION_MODAL_OPENED,
        errorMessage,
    }
}

export const closeSSOConfigurationConfirmationModal = (): tSSOConfigurationConfirmationModalActions => {
    return {
        type: SSO_CONFIGURATION_CONFIRMATION_MODAL_CLOSED,
    }
}

export const WEEKLY_ADD_WORK_SHIFT_MODAL_OPENED = "WEEKLY_ADD_WORK_SHIFT_MODAL_OPENED"
export const WEEKLY_ADD_WORK_SHIFT_MODAL_CLOSED = "WEEKLY_ADD_WORK_SHIFT_MODAL_CLOSED"

export interface iOpenWeeklyAddWorkShiftModal extends Action {
    type: typeof WEEKLY_ADD_WORK_SHIFT_MODAL_OPENED
    context: tContext
}

export interface iCloseWeeklyAddWorkShiftModal extends Action {
    type: typeof WEEKLY_ADD_WORK_SHIFT_MODAL_CLOSED
}

export type tWeeklyAddWorkShiftModalActions = iOpenWeeklyAddWorkShiftModal | iCloseWeeklyAddWorkShiftModal

export const openWeeklyAddWorkShiftModal = (context: tContext): iOpenWeeklyAddWorkShiftModal => {
    return {
        type: WEEKLY_ADD_WORK_SHIFT_MODAL_OPENED,
        context,
    }
}

export const closeWeeklyAddWorkShiftModal = (): iCloseWeeklyAddWorkShiftModal => {
    return {
        type: WEEKLY_ADD_WORK_SHIFT_MODAL_CLOSED,
    }
}

export const ADD_EDIT_WORK_SHIFT_MODAL_OPENED = "ADD_EDIT_WORK_SHIFT_MODAL_OPENED"
export const ADD_EDIT_WORK_SHIFT_MODAL_CLOSED = "ADD_EDIT_WORK_SHIFT_MODAL_CLOSED"

export interface iOpenAddEditWorkShiftModal extends Action {
    type: typeof ADD_EDIT_WORK_SHIFT_MODAL_OPENED
    workShift: Record<string, any> | number | undefined
    context: tContext
    row?: RowNode
}

export interface iCloseAddEditWorkShiftModal extends Action {
    type: typeof ADD_EDIT_WORK_SHIFT_MODAL_CLOSED
}

export type tAddEditWorkShiftModalActions = iOpenAddEditWorkShiftModal | iCloseAddEditWorkShiftModal

export const openAddEditWorkShiftModal = (
    workShift: Record<string, any> | number | undefined,
    context: tContext,
    row?: RowNode
): iOpenAddEditWorkShiftModal => {
    return {
        type: ADD_EDIT_WORK_SHIFT_MODAL_OPENED,
        workShift,
        context,
        row,
    }
}

export const closeAddEditWorkShiftModal = (): iCloseAddEditWorkShiftModal => {
    return {
        type: ADD_EDIT_WORK_SHIFT_MODAL_CLOSED,
    }
}

export const ADD_PICKLIST_ITEM_MODAL_OPENED = "ADD_PICKLIST_ITEM_MODAL_OPENED"
export const ADD_PICKLIST_ITEM_MODAL_CLOSED = "ADD_PICKLIST_ITEM_MODAL_CLOSED"

export interface iOpenAddPicklistItemModal extends Action {
    type: typeof ADD_PICKLIST_ITEM_MODAL_OPENED
    context: tContext
    itemName: string
    picklistId: string
    picklistName: string
    costCodeId: number
    row?: RowNode
}

export interface iCloseAddPicklistItemModal extends Action {
    type: typeof ADD_PICKLIST_ITEM_MODAL_CLOSED
}

export type tAddPicklistItemModalActions = iOpenAddPicklistItemModal | iCloseAddPicklistItemModal

export const openAddPicklistItemModal = (
    context: tContext,
    itemName: string,
    picklistId: string,
    picklistName: string,
    costCodeId: number,
    row: RowNode
): iOpenAddPicklistItemModal => {
    return {
        type: ADD_PICKLIST_ITEM_MODAL_OPENED,
        context,
        itemName,
        picklistId,
        picklistName,
        costCodeId,
        row,
    }
}

export const closeAddPicklistItemModal = (): iCloseAddPicklistItemModal => {
    return {
        type: ADD_PICKLIST_ITEM_MODAL_CLOSED,
    }
}
