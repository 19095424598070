import React from "react"
import styled from "styled-components"
import {
    IconView,
    colorAttentionYellow50,
    colorAttentionBlue60,
    colorFunctionalGray60,
} from "@rhumbix/rmbx_design_system_web"
import { iCellRendererParams } from "./types"
import { BUNDLE_TABLE_NAME } from "../../../common/constants"
import { getObjectId } from "../../../common/ag-grid-ts-utils"

/**
 * This cell renderer returns the clickable column text value and a view icon to indicate
 * that the cell is clickable
 *
 * @param params aggrid parameters for displaying cell values
 * @returns html element - clickable text with icon
 */
const ClickableTextCellRenderer = (params: iCellRendererParams): JSX.Element | void => {
    if (params.node.group) return <></>

    const onClick = (event: React.MouseEvent) => {
        if (!params.node.isSelected()) {
            // We don't want to select the row if we're in the history modal.
            if (!params.colDef.history) {
                params?.api?.deselectAll()
                params.node.setSelected(true)
            }
        }
        // TODO: We ultimately want to open history in the right rail,
        // but for initial launch will keep it opening in a new tab
        if (params.colDef.history) {
            const url = `/rhumbix/custom-form/${params.data.id}/?history_id=${params.data.history_id}`
            window.open(url)
            return
        } else if (event.metaKey || event.ctrlKey) {
            const url = `/rhumbix/custom-form/${params.data.id}`
            window.open(url)
            return
        }

        const { schema, status } = params.data
        const perms = params.context.referenceableData.companyFormSchemas?.[schema]?.permissions?.[status]
        const modifyDisabled = !perms?.field?.editable
        // add the row data to the params as they get cleared out
        // on subsequent renders and the siderail needs the row data
        // for the status update dropdown
        params.context.selectedRows = [params.node.data]

        params.context.sideRailContext.enableSideRail({
            flow: "FIELD_FORMS",
            isBundle: params.context.settings.tableName === BUNDLE_TABLE_NAME,
            isNew: false,
            formId: params.data.id,
            projectId: getObjectId(params.context?.selectedRows?.[0].project),
            mode: "View",
            schemaId: params.data.schema,
            rowIndex: params.rowIndex,
            gridApi: params.api,
            extraBtnParams: params,
            modifyButtonDisabled: modifyDisabled,
            listViewFields: params.context.settings.otherSettings.requestSpecificFields,
            listViewContext: params.context.settings,
            gridId: params.context.selectedRows[0]?.gridId,
            setSelectedTab: params.context.setSelectedTab,
            enableSideRail: params.context.sideRailContext.enableSideRail,
        })
    }

    return (
        <ClickableContainer hasValue={!!params?.value} onClick={onClick} className="action-button">
            <div>{params?.value ?? "000000"}</div>
            <IconView className="view-icon" />
        </ClickableContainer>
    )
}

export default ClickableTextCellRenderer

const ClickableContainer = styled.div<{ hasValue: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: ${({ hasValue }) => (hasValue ? "underline" : "underline")};
    color: ${({ hasValue }) => (hasValue ? colorAttentionBlue60 : colorFunctionalGray60)};
    font-style: ${({ hasValue }) => (hasValue ? "none" : "italic")};
    .view-icon {
        display: none;
        width: 16px;
        align-self: end;
        color: ${colorAttentionYellow50};
    }

    &:hover {
        cursor: pointer;

        .view-icon {
            display: block;
        }
    }
`
