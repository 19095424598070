const initialState = {}

export default function form(state = initialState, action) {
    switch (action.type) {
        case "CREATE_SUCCESSFUL":
            return Object.assign({})
        case "CREATE_FAILED":
            return Object.assign({}, state, {
                errors: action.errors,
            })
        case "UPDATE_FIELD":
            return Object.assign({}, state, { [action.name]: action.value })
        case "SET_FIELDS":
            return Object.assign({}, state, action.data)
        case "CLEAR_FIELDS":
            return Object.assign({}, initialState)
        case "VALIDATION_FAILED":
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.key]: action.errors,
                },
            }
        case "VALIDATION_PASSED":
            delete state.errors[action.key]
            return {
                ...state,
                errors: state.errors,
            }
        default:
            return state
    }
}
