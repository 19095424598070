import { Action } from "redux"
import { iActionData } from "../common/types"
import {
    CLEAR_NOTIFICATION_DETAILS,
    iWebsocket,
    SET_NOTIFICATION_MESSAGE,
    tNotificationTypes,
    tWebsocketActions,
    WS_CONNECT,
    WS_CONNECTED,
    WS_CONNECTING,
    WS_DISCONNECT,
    WS_DISCONNECTED,
    WS_MESSAGE_RECEIVED,
} from "./types"

// actions for websocket connections
export const wsConnect = (host: string, isGuest = false): tWebsocketActions => ({ type: WS_CONNECT, host, isGuest })
export const wsConnecting = (host: string): tWebsocketActions => ({ type: WS_CONNECTING, host })
export const wsConnected = (host: string, socket: iWebsocket): tWebsocketActions => ({
    type: WS_CONNECTED,
    host,
    socket,
})
export const wsDisconnect = (): Action => ({ type: WS_DISCONNECT })
export const wsDisconnected = (): Action => ({ type: WS_DISCONNECTED })

/**
 * Given an object, try to convert it to JSON. If it fails will return null.
 * @param jsonMsg - Object to convert to JSON
 * @returns stringified version of jsonMsg
 */
export const convertToJson = (jsonMsg: Record<string, any>): string | null => {
    let msgString = ""
    try {
        msgString = JSON.stringify(jsonMsg)
    } catch (error) {
        return null
    }
    return msgString
}

/**
 * Sends a JSON message to the server websocket
 * @param socket - websocket instance
 * @param jsonMsg - Object that will be converted and sent as JSON
 */
export const sendJsonSocketMessage = (socket: iWebsocket | null, jsonMsg: Record<string, any>): void => {
    if (!socket) return
    const msg = convertToJson(jsonMsg)
    if (!msg) return
    socket.send(msg)
}

/**
 * When given a file Blob and a filename, trigger the browser to download the file
 * @param file - Blob file to download
 * @param filename - name of file
 */
export const downloadFile = (file: Blob, filename: string): void => {
    const fileURL = URL.createObjectURL(file)
    const link = document.createElement("a")
    link.href = fileURL
    link.download = filename
    link.click()
}

export const socketMessageReceived = (event: MessageEvent): tWebsocketActions => ({
    type: WS_MESSAGE_RECEIVED,
    event,
})

export const clearNotificationDetails = (): Action => {
    return {
        type: CLEAR_NOTIFICATION_DETAILS,
    }
}

export const setNotificationMessage = (
    message: string,
    notificationType: tNotificationTypes,
    extraArgs?: Record<string, any>
): iActionData => {
    return {
        type: SET_NOTIFICATION_MESSAGE,
        data: {
            message,
            notificationType,
            ...extraArgs,
        },
    }
}
