import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Action, bindActionCreators, Dispatch } from "redux"
import { isEqual } from "lodash"
import {
    activeSavedFilterSelector,
    savedFilterSetInvalidSelectionsSelector,
    savedFilterSetsSelector,
    savedFilterSetErrorSelector,
    startOfTheWeekSelector,
    savedFilterSetInvalidFiltersSelector,
} from "../selectors"
import {
    applySavedFilterSet,
    fetchSavedFilterSets,
    clearInvalidSelectionsForSavedFilterSet,
    updateSavedFilterSet,
    setSavedFilterSet,
    setMultipleFilterValues,
} from "../actions"
import {
    openCreateSavedFilterModal,
    openDeleteSavedFilterModal,
    tDeleteSavedFilterModalActions,
} from "../../components/modals/actions"
import CreateSavedFilterModal from "../../components/modals/modal-create-saved-filter"
import DeleteSavedFilterModal from "../../components/modals/modal-delete-saved-filter"
import { PageIdentifiers, getPageIdentifierForPath } from "../../common/page-identifier-utils"
import { tCreateSavedFilterModalActions } from "../../components/modals/actions"
import {
    iClearInvalidSelectionsForSavedFilterSet,
    iMutableFilterState,
    iSetMultipleFilterValuesAction,
    iSetSavedFilterSetAction,
    SavedFilterSetSelections,
    tErrorFilterDef,
    tFilterDef,
    tFilterKey,
    tFilterState,
    tInvalidFilterSelections,
    tSavedFilter,
} from "../types"
import { getDefaultLabelByFilterKey, getResourceByFilterKey } from "../constants"
import {
    ActionBarSelector,
    colorFunctionalGray70,
    colorMiscGray90,
    IconBookmark,
    IconBookmarkFilled,
    IconRefresh,
    IconRemove,
    Notification,
    KeyValueSelectorOption,
    IconTrash,
    colorAttentionRed50,
    spacingS,
    ActionBarButton,
    iconSizeM,
} from "@rhumbix/rmbx_design_system_web"
import styled from "styled-components"
import { tFilterContext } from "../types"
import { referenceablesToValueFormatters } from "../../common/referenceable-value-formatters"
import { tCachedResourceName } from "../../cached-data/types"
import { referenceableDataSelector } from "../../selectors"
import { ReduxState, Thunk } from "../../common/types"
import { eToolbarMode } from "../../toolbar/types"
import SaveIndicator from "../../toolbar/Toolbar/SaveIndicator"
import { tNetworkStatusState } from "../../dashboard-data/types"
import { zIndex } from "../../common/styles"
import {
    CLEAR_FILTERS,
    logUserAmplitudeEvent,
    SAVED_FILTER_SET_APPLIED,
    SAVED_FILTER_SET_INVALID_SELECTIONS_REPORTED,
    SAVED_FILTER_SET_UPDATED,
} from "../../common/amplitude-event-logging"
import { ToolbarButtonSeparator } from "../../toolbar/Toolbar/styles"

type tProps = {
    // Passed directly to component
    filterState: { [key: string]: any }
    filterDefs: Array<tFilterDef | tErrorFilterDef>
    path?: string | string[]
    // Functions called through mapDispatchToProps
    fetchSavedFilterSets: (page: string) => Thunk
    openCreateSavedFilterModal: { (filterState: tFilterState, page: string): tCreateSavedFilterModalActions }
    openDeleteSavedFilterModal: { (id: number, page: string): tDeleteSavedFilterModalActions }
    applySavedFilterSet: (
        savedFilterSet: any,
        currentFilterState: any,
        filterDefs: Array<tFilterDef | tErrorFilterDef>
    ) => Thunk
    setMultipleFilterValues: (
        filterUpdateMap: iMutableFilterState
    ) => Thunk<iSetMultipleFilterValuesAction | undefined>
    setSavedFilterSet: (savedFilterSet: tSavedFilter | undefined) => iSetSavedFilterSetAction
    clearInvalidSelectionsForSavedFilterSet: () => iClearInvalidSelectionsForSavedFilterSet
    updateSavedFilterSet: (savedFilterSet: tSavedFilter) => Thunk
    // Properties retrieved through mapStateToProps
    savedFilterSets: Array<any>
    activeSavedFilter: tSavedFilter
    invalidFilters?: tFilterKey[]
    invalidSelections: { [key: string]: tInvalidFilterSelections }
    entities: any
    context?: tFilterContext
    error?: string
    networkStatus: tNetworkStatusState
}

type SavedFilterSetOption = {
    value: tSavedFilter["id"]
    label: tSavedFilter["name"]
    data: tSavedFilter["filter_selections"]
}

// A component that handles all user interface elements for creating, managing, and applying saved filter sets.
// It's intended to sit at the top of the right rail over the filter controls
const SavedFilterSetController = (props: tProps) => {
    const path = Array.isArray(props.path) ? (props.path.length > 0 ? props.path[0] : "") : props.path
    const pageIdentifier = getPageIdentifierForPath(path) ?? ""
    const [selectedSavedFilterSet, setSelectedSavedFilterSet] = useState<SavedFilterSetOption | null>(
        props.activeSavedFilter
            ? {
                  value: props.activeSavedFilter.id,
                  label: props.activeSavedFilter.name,
                  data: props.activeSavedFilter.filter_selections,
              }
            : null
    )

    useEffect(() => {
        // Fetch the saved filters for this page
        props.fetchSavedFilterSets(pageIdentifier)
    }, [pageIdentifier])

    useEffect(() => {
        if (props.invalidSelections && Object.entries(props.invalidSelections).length > 0) {
            logUserAmplitudeEvent(SAVED_FILTER_SET_INVALID_SELECTIONS_REPORTED, {
                ...getAmplitudeInfo(),
                invalidSelections: props.invalidSelections,
            })
        }
    }, [props.invalidSelections])

    useEffect(() => {
        // Update the dropdown to ensure it reflects the saved filter set that is currently
        // in effect. This is necessary for example with the Clear Filters functionality.
        // To clear the selection from the dropdown, it's necessary to pass null - undefined does not work
        setSelectedSavedFilterSet(
            props.activeSavedFilter
                ? {
                      value: props.activeSavedFilter.id,
                      label: props.activeSavedFilter.name,
                      data: props.activeSavedFilter.filter_selections,
                  }
                : null
        )
    }, [props.activeSavedFilter])

    const getAmplitudeInfo = () => {
        return {
            pageIdentifier,
            activeSavedFilterSet: props.activeSavedFilter?.id ?? undefined,
        }
    }

    // Helper function that finds a filter name by filter key
    const getLabelForFilter = (filterKey: string): string => {
        const filterDef = props.filterDefs.find(def => def["key"] === filterKey)
        return (filterDef as tFilterDef)?.label ?? getDefaultLabelByFilterKey(filterKey) ?? ""
    }

    // Helper function that takes a given value and returns a display version
    // produced by the appropriate valueFormatter
    const getLabelForFilterSelection = (resource: string | undefined, selection: any) => {
        if (resource) {
            const formatter = referenceablesToValueFormatters[resource as tCachedResourceName]
            const valueFormatter = formatter ? formatter.valueFormatter : undefined
            return valueFormatter
                ? valueFormatter({
                      value: selection,
                      context: props.context,
                  })
                : selection.name
        }
        return ""
    }

    // Take the props.invalidSelections and create an HTML-formatted message for the notification
    const getMessageListingInvalidSelections = () => {
        // Build a bullet list of the invalid selections, grouped by resource. Stale elements
        // can be hard to identify, since the user can't just retrieve them from the backend. The defensive code
        // keeps blank names out of the message; if none of the elements could be identified well enough
        // to get a name, a generic error message is shown

        let invalidSelectionsList =
            Object.entries(props.invalidSelections).reduce(
                (accum: string, [key, selectionsForResource]: [string, any]) => {
                    const resource = getResourceByFilterKey(key)
                    const filterName = getLabelForFilter(key)

                    const selections =
                        selectionsForResource.invalidFilterSelections
                            ?.map((selection: any) => getLabelForFilterSelection(resource, selection))
                            .filter((elem: string) => elem.length > 0) ?? []

                    let bulletItem
                    if (selections?.length > 0) {
                        bulletItem = `<li>${filterName}: ${selections.join(", ")}</li>`
                    }

                    return bulletItem ? accum + bulletItem : accum
                },
                ""
            ) ?? ""

        if (props.invalidFilters && props.invalidFilters.length > 0) {
            props.invalidFilters.forEach((key: tFilterKey) => {
                const filterName = getLabelForFilter(key)
                invalidSelectionsList = invalidSelectionsList.concat(
                    `<li>The ${filterName} filter is no longer available</li>`
                )
            })
        }

        return invalidSelectionsList.length > 0
            ? "You do not have access to some items. These filters will not be applied:" +
                  `<ul>${invalidSelectionsList}</ul>`
            : "Some of the items in your filters are no longer available, and have been removed."
    }

    // As the saved filter sets are added to more pages, add their names here
    const areSavedFilterSetsAvailable = pageIdentifier === PageIdentifiers.WeeklyView

    // Helper function to prune and convert the current filter state to a set of selections that can be
    // stored in a saved filter set
    const getFilterSelectionsFromFilterState = (): any => {
        const filterSelections: { [key: string]: any } = {}

        // Strip out any filters that can't be saved. Right now, startDate and endDate
        // are skipped so that the Weekly View saved filter set can be reused from week to week.
        // The list might expand when we add saved filter sets to other pages
        const filterState = (Object.entries(props.filterState) as [string, any][]).filter(
            selection =>
                selection.length &&
                selection.length > 0 &&
                selection[0] !== "startDate" &&
                selection[0] !== "endDate" &&
                selection[1] != null
        )

        filterState.map(([filterKey, selectionValues]) => {
            const resource = getResourceByFilterKey(filterKey)

            // If the filter uses a resource (e.g. Project or Group), find the corresponding entities
            // so that they can be used to populate the filters when the saved filter set is applied
            if (resource) {
                const entities = props.entities[resource]

                // The key for each entity will be its filterKey (typically the id or name/label
                // that is its value in the selector). For example, if you save a group,
                // the data structure will look like:
                //
                // {"groupId":{
                //      "82241":
                //          {"id":82241,"name":"Main","parent_id":null, ... }}}
                //
                // If you save something simpler, like an Employee Trade, it will look like this:
                //
                // employeeClassification":{"Craft":{"name":"Craft"}}}
                //
                // Saving the entire entity may seem like overkill when we're just using this to
                // get the name, but different types store the name in different ways (e.g. the name
                // field is sufficient for a Project, but an Employee needs first and last name),
                // so it's safer to keep the whole thing and make it available to the valueFormatters
                const entitiesForResource: { [key: string]: any } = {}

                // The selectionValues may be in an array (if the filter is multi-select), or it may
                // just be a string
                if (Array.isArray(selectionValues) && selectionValues.length) {
                    selectionValues.forEach((v: any) => {
                        if (entities.objects[v]) entitiesForResource[v] = entities.objects[v]
                        // If there isn't a referenceable entity (for example, with
                        // Employee Trades or Employee Classifications), then just take the string
                        else {
                            const nonreferenceableEntity = { name: v }
                            entitiesForResource[v] = nonreferenceableEntity
                        }
                    })
                } else {
                    // In spite of the plural, selectionValues doesn't have to be an array -
                    // here, it only has one value
                    if (entities.objects[selectionValues])
                        entitiesForResource[selectionValues] = entities.objects[selectionValues]
                }

                filterSelections[filterKey] = entitiesForResource
            }
            // If the filter doesn't use a resource - for example, if it's an enumFilter with a set of
            // static options - then just save the value by itself
            else {
                const staticValues: { [key: string]: any } = {}
                if (Array.isArray(selectionValues) && selectionValues.length) {
                    selectionValues.forEach((v: any) => {
                        const staticValue: { [key: string]: any } = {}
                        staticValue["name"] = v
                        staticValues[v] = staticValue
                    })
                } else {
                    const staticValue: { [key: string]: any } = {}
                    staticValue["name"] = selectionValues
                    staticValues[selectionValues] = staticValue
                }

                filterSelections[filterKey] = staticValues
            }
        })

        return filterSelections
    }

    /////////////////////////////////////////////////////
    // Action and event handlers

    const createFilter = async () => {
        let filterSelections: { [key: string]: any } = {}

        if (props.filterState) {
            filterSelections = getFilterSelectionsFromFilterState()
        }

        props.openCreateSavedFilterModal(filterSelections, pageIdentifier)
    }

    const updateFilter = () => {
        const savedFilterSet = props.activeSavedFilter

        if (props.filterState) {
            savedFilterSet.filter_selections = getFilterSelectionsFromFilterState()
        }

        props.updateSavedFilterSet(savedFilterSet)
        props.setSavedFilterSet(savedFilterSet)

        logUserAmplitudeEvent(SAVED_FILTER_SET_UPDATED, getAmplitudeInfo())
    }

    // Helper function to check if the user has changed any of the filter selections on the active
    // saved filter set. The Update Filter button shouldn't be enabled if there are no changes to save
    const hasUpdatesToSave = (): boolean => {
        return !isEqual(getFilterSelectionsFromFilterState(), props.activeSavedFilter.filter_selections)
    }

    const deleteFilter = (id: number | undefined) => {
        if (id) {
            props.openDeleteSavedFilterModal(id, pageIdentifier)
        }
    }

    // Clear filter state and clear any saved filter set selection
    const clearFilters = () => {
        // TODO: Once again, maybe the filters to avoid should be recorded somewhere so we consistently
        // don't mess with them
        const filterKeysToClear = Object.keys(props.filterState).filter(
            selection => selection !== "startDate" && selection !== "endDate"
        )

        const filterState: { [key: string]: any } = {}

        // Filters that held arrays get an empty array; ones that hold strings (the single-select filters) get
        // undefined
        filterKeysToClear.forEach(
            filterKey => (filterState[filterKey] = Array.isArray(props.filterState[filterKey]) ? [] : undefined)
        )

        props.setMultipleFilterValues(filterState)
        props.setSavedFilterSet(undefined)

        logUserAmplitudeEvent(CLEAR_FILTERS, getAmplitudeInfo())
    }

    const handleSavedFilterChange = (option: any) => {
        setSelectedSavedFilterSet(option)
        const selectedId = option.value

        if (selectedId) {
            const sf = props.savedFilterSets.find((s: any) => s.id === selectedId)
            props.applySavedFilterSet(sf, props.filterState, props.filterDefs)
            logUserAmplitudeEvent(SAVED_FILTER_SET_APPLIED, { ...getAmplitudeInfo(), activeSavedFilterSet: sf.id })
        }
    }

    const renderSavedFilterSetOption = ({ value, label, data }: SavedFilterSetOption) => {
        const filterSelections = data as SavedFilterSetSelections
        const [optionHovered, setOptionHovered] = useState(false)

        const filterFieldDisplayOrder = props.filterDefs
            .map(def => def["key"])
            .reduce((acc, filterKey, index) => {
                acc[filterKey] = index
                return acc
            }, {} as { [key: string]: number })

        const kvPairs = (Object.keys(filterSelections) as (keyof SavedFilterSetSelections)[])
            // Order the data based on the display order (mirrors how the fields show up in the UI).
            .sort(
                (filterKeyA, filterKeyB) =>
                    filterFieldDisplayOrder[filterKeyA] - filterFieldDisplayOrder[filterKeyB]
            )
            // Map the filter selections into a format that ActionBarSelector expects.
            .reduce((pairs, filterKey) => {
                const displayName = getLabelForFilter(filterKey)
                const filters = filterSelections[filterKey]
                const stringifiedSelections = Object.entries(filters)
                    // A couple filter keys end up with an undefined key sometimes (projectStatus, timeCardOwnerId)
                    .filter(([k, _]) => k !== "undefined")
                    .map(([k, v]) => {
                        const resource = getResourceByFilterKey(filterKey)
                        return resource ? getLabelForFilterSelection(resource, v) : k
                    })
                    .join("; ")

                if (!stringifiedSelections) return pairs
                pairs.push([displayName, stringifiedSelections])

                return pairs
            }, [] as [string, string][])

        return (
            <div
                onMouseOver={() => setOptionHovered(true)}
                onFocus={() => setOptionHovered(true)}
                onMouseOut={() => setOptionHovered(false)}
                onBlur={() => setOptionHovered(false)}
                className="saved-filter-set-option"
            >
                <KeyValueSelectorOption
                    title={label}
                    kvPairs={kvPairs}
                    rightContent={
                        <ActionBarButton
                            renderIcon={(color: string) => (
                                <div className="remove-saved-filter-set">
                                    <IconTrash color={color} height={iconSizeM} />
                                </div>
                            )}
                            onClick={e => {
                                // Prevent the option from actually being selected.
                                e.stopPropagation()
                                deleteFilter(value)
                            }}
                            theme="destructive"
                            className="global-button-style-fix"
                            style={{
                                visibility: optionHovered ? "visible" : "hidden",
                            }}
                        />
                    }
                />
            </div>
        )
    }

    const placeholder =
        props.savedFilterSets.length && props.savedFilterSets.length > 0
            ? props.savedFilterSets.length === 1
                ? `${props.savedFilterSets.length} Filter`
                : `${props.savedFilterSets.length} Filters`
            : "Showing All..."

    ///////////////////////////////////////////////
    // Render the component

    // The Saved Filter component only renders if it's available for this page
    return areSavedFilterSetsAvailable ? (
        <SavedFilterSetContainer>
            <SaveIndicator networkStatus={props.networkStatus} toolbarMode={eToolbarMode.TABLE} />
            <div className="saved-filter-set-controls">
                {props.savedFilterSets?.length > 0 ? (
                    <>
                        <div id="saved-filter-set-selector">
                            <ActionBarSelector
                                options={props.savedFilterSets.map<SavedFilterSetOption>((sf: tSavedFilter) => {
                                    return { value: sf["id"], label: sf["name"], data: sf["filter_selections"] }
                                })}
                                onChange={handleSavedFilterChange}
                                placeholder={placeholder}
                                renderOption={renderSavedFilterSetOption}
                                renderIcon={(color: string) => <IconBookmarkFilled color={color} height={16} />}
                                value={selectedSavedFilterSet}
                            />
                        </div>
                        <ToolbarButtonSeparator toolbarMode={eToolbarMode.TABLE} />
                    </>
                ) : null}
                {props.activeSavedFilter ? (
                    <ActionBarButton
                        onClick={updateFilter}
                        disabled={!hasUpdatesToSave()}
                        id="update-filter"
                        className="global-button-style-fix"
                        renderIcon={color => <IconRefresh height={iconSizeM} color={color} />}
                        text="Update Filter"
                    />
                ) : null}
                <ActionBarButton
                    onClick={createFilter}
                    id="save-filter"
                    className="global-button-style-fix"
                    renderIcon={color => <IconBookmark height={iconSizeM} color={color} />}
                    text="Save New Filter"
                />
                {props.filterState ? (
                    <ActionBarButton
                        onClick={clearFilters}
                        id="clear-filters"
                        className="global-button-style-fix"
                        renderIcon={color => <IconRemove height={iconSizeM} color={color} />}
                        text="Clear Filters"
                        theme="destructive"
                    />
                ) : null}
            </div>
            {props.error ? <div className="error-message">{props.error}</div> : null}
            {(props.invalidSelections && Object.entries(props.invalidSelections).length > 0) ||
            (props.invalidFilters && props.invalidFilters?.length > 0) ? (
                <InvalidSelectionsWarning
                    message={""}
                    onClick={props.clearInvalidSelectionsForSavedFilterSet}
                    type="warning"
                >
                    <div
                        className={"notification-body"}
                        dangerouslySetInnerHTML={{ __html: getMessageListingInvalidSelections() }}
                    />
                </InvalidSelectionsWarning>
            ) : null}
            <CreateSavedFilterModal />
            <DeleteSavedFilterModal />
        </SavedFilterSetContainer>
    ) : null
}

const mapStateToProps = (state: ReduxState) => ({
    activeSavedFilter: activeSavedFilterSelector(state),
    savedFilterSets: savedFilterSetsSelector(state),
    entities: state.entities,
    invalidFilters: savedFilterSetInvalidFiltersSelector(state),
    invalidSelections: savedFilterSetInvalidSelectionsSelector(state),
    error: savedFilterSetErrorSelector(state),
    networkStatus: state.networkStatus,
    filterContext: {
        referenceableData: referenceableDataSelector(state),
        startOfTheWeekIndex: startOfTheWeekSelector(state.current_user),
    },
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
            applySavedFilterSet,
            clearInvalidSelectionsForSavedFilterSet,
            fetchSavedFilterSets,
            openCreateSavedFilterModal,
            openDeleteSavedFilterModal,
            setMultipleFilterValues,
            setSavedFilterSet,
            updateSavedFilterSet,
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(SavedFilterSetController)

const SavedFilterSetContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 60px;

    .saved-filter-set-controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        position: fixed;
        z-index: ${zIndex.SideRail};
        background-color: white;
        border-bottom: 2px solid ${colorFunctionalGray70};
        padding: ${spacingS};
    }

    .error-message {
        flex-basis: 100%;
        color: ${colorAttentionRed50};
        justify-content: left;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 16px;
    }

    .notification-body {
        color: ${colorMiscGray90};
        font-weight: 500;
        font-family: Roboto;
    }

    .remove-saved-filter-set {
        // Add a little bit of horizontal padding to give the button a square shape.
        padding: 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const InvalidSelectionsWarning = styled(Notification)`
    bottom: 60px;
    z-index: ${zIndex.SideRail + 1};
    transform: translateX(5%);
    margin-top: ${spacingS};
`
