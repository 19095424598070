import React from "react"
import { useSelector } from "react-redux"
import { LinkButton } from "@rhumbix/rmbx_design_system_web"
import Rmbx from "../../../../util"
import { picklistDashboardRoutePath } from "../../../../router/constants"
import { ResourceObject } from "../../../../dashboard-data/types"
import { GPTreeDashboardRowData } from "../../../../cached-data/types"
import { selectTkModifierTypesById } from "../../../../tk-modifiers/selectors"

type Props = {
    data: ResourceObject<GPTreeDashboardRowData>
}

export const LinkToPicklistButton: React.FC<Props> = ({ data }) => {
    const tkModifierTypesById = useSelector(selectTkModifierTypesById)

    const navigateToPicklist = () => {
        const slug = tkModifierTypesById[data.id].slug
        Rmbx.util.history.push(`${picklistDashboardRoutePath}/?picklist=${slug}`)
    }

    return (
        <LinkButton onClick={navigateToPicklist} type="button" className="action-button">
            {data.name}
        </LinkButton>
    )
}

export default LinkToPicklistButton
