import { iCohortEmployee } from "../cached-data/types"
import { CohortEmployeeActionTypes } from "./types"

export type tCohortEmployeeState = {
    cohortEmployees: iCohortEmployee[]
}

const initialState: tCohortEmployeeState = {
    cohortEmployees: [],
}

export default function cohorts(
    state: tCohortEmployeeState = initialState,
    action: CohortEmployeeActionTypes
): tCohortEmployeeState {
    switch (action.type) {
        case "FETCH_COHORT_EMPLOYEE_FAILURE":
            return state
        case "FETCH_COHORT_EMPLOYEE_SUCCESS":
            return {
                ...state,
                cohortEmployees: action.payload,
            }
        default:
            return state
    }
}
