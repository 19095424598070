/* eslint-disable max-len */
import {
    isPivotRowTotalColumn,
    noColumnAbsences,
    noColumnShiftExtras,
    groupedByEmployeeOrTrade,
    timekeepingDataIsEditable,
} from "./ag-grid-utils"
import { defaultWorkHours } from "./constants"
import { dateIsSameOrAfter, getObjectId, isPivotColumn, isPlaceholder } from "./ag-grid-ts-utils"
import { getFlagEnabled } from "../getFlagValue"

export const changeTooltip = params => {
    const payload = {
        tooltipShouldRender: false,
        data: {
            messages: null,
        },
    }

    if (params.value == "N/A") {
        payload.tooltipShouldRender = true
        payload.data.messages = ['Requires valid "Week Actual" and "Last Week" unit rate columns.']
    }
    return payload
}

export const jtdTooltip = params => {
    const payload = {
        tooltipShouldRender: false,
        data: {
            messages: null,
        },
    }

    if (typeof params.value === "string") {
        payload.tooltipShouldRender = true
        payload.data.messages = ["Requires non-zero reported hours and quantities for job to date."]
    }

    return payload
}

export const toGoTooltip = params => {
    const value = params.value
    const payload = {
        tooltipShouldRender: false,
        data: {
            messages: null,
        },
    }

    if (typeof value !== "string") {
        return payload
    }

    const firstChar = value.charAt(0)
    const tooltipMessages = []

    if (firstChar == "0") {
        tooltipMessages.push("No quantities remaining.")
    } else if (firstChar == "-") {
        tooltipMessages.push("Total installed quantities exceed the budget.")
    }

    // these regex's test if the trailing value is zero or negative
    if (/^(.+ \/ 0)$/.test(value) == true) {
        tooltipMessages.push("No man-hours remaining.")
    } else if (/^(.+ \/ -.+)$/.test(value) == true) {
        tooltipMessages.push("Total reported man-hours exceed the budget.")
    }

    if (tooltipMessages.length > 0) {
        payload.tooltipShouldRender = true
        payload.data.messages = tooltipMessages
    }

    return payload
}

export const performanceErrorTooltipMixin = params => {
    const value = params.value
    const payload = {
        tooltipShouldRender: false,
        data: {
            messages: null,
        },
    }

    if (typeof value !== "string") {
        return payload
    }

    const tooltipMessages = []
    const firstChar = value.charAt(0)
    const lastChar = value.charAt(value.length - 1)

    if (firstChar == "0") {
        tooltipMessages.push(`Please add quantities to ${params.colDef.customTooltipParameters}.`)
    } else if (firstChar == "-") {
        tooltipMessages.push(`Negative quantities were supplied to ${params.colDef.customTooltipParameters}.`)
    }

    if (lastChar == "0") {
        tooltipMessages.push(`Please add hours to ${params.colDef.customTooltipParameters}.`)
    } else if (lastChar == "-") {
        tooltipMessages.push(`Negative hours were supplied to ${params.colDef.customTooltipParameters}.`)
    }

    if (tooltipMessages.length > 0) {
        payload.tooltipShouldRender = true
        payload.data.messages = tooltipMessages
    }

    return payload
}

export const publicApiControlsTooltip = params => {
    const payload = {
        tooltipShouldRender: false,
        data: {
            messages: null,
        },
    }

    if (
        params.colDef.editable == false ||
        (typeof params.colDef.editable === "function" && params.colDef.editable(params) == false)
    ) {
        payload.tooltipShouldRender = true
        if (
            getFlagEnabled("wa-5033-uneditable-tooltip-message") &&
            !params.context.currentUser.features.read_only_employees &&
            params.colDef.uneditableMessage
        ) {
            payload.data.messages = [params.colDef.uneditableMessage]
        } else {
            payload.data.messages = ["This cell is controlled by the public API"]
        }
    }
    return payload
}

export const customSignatureTooltip = params => {
    const payload = {
        tooltipShouldRender: false,
        data: {
            messages: null,
        },
    }

    if (params?.data?.signature !== undefined && params?.data?.signature !== "") {
        payload.tooltipShouldRender = true
        payload.data.messages = params.data.signature
    }
    return payload
}

/**
 * Tooltip for a timekeeping row in the Weekly View Detail modal. If the row in the modal is disabled,
 * we present a helpful tooltip explaining why
 * @param params: Parameters we get for free from a colDef
 * @param noStatusException: Special case where a status with the value "__NONE__" does not generate a
 * tooltip saying you don't have permission in that status
 * @returns {{tooltipShouldRender: boolean}|{data: {messages: string[]}, tooltipShouldRender: boolean}}
 */
export const timekeepingDisabledTooltip = (params, noStatusException = false) => {
    const { canEdit, messages } = timekeepingDataIsEditable(params, noStatusException)
    return canEdit
        ? { tooltipShouldRender: false }
        : {
              tooltipShouldRender: true,
              data: {
                  messages,
              },
          }
}

/**
 * Tooltip for a timekeeping comment in the Weekly View Detail modal.
 * @param params: Parameters we get for free from a colDef
 * @returns {{tooltipShouldRender: boolean}|{data: {messages: string[]}, tooltipShouldRender: boolean}}
 */
export const timekeepingCommentTooltip = params => {
    return params.value
        ? {
              tooltipShouldRender: true,
              data: {
                  messages: [params.value],
              },
          }
        : { tooltipShouldRender: false }
}

export const pivotValueColumnCustomTooltipMessageBuilder = params => {
    const payload = {
        tooltipShouldRender: false,
        data: null,
    }
    const tooltipMessages = []
    const isValueColumn = isPivotColumn(params.colDef)
    const rowGroupColumns = params.columnApi.getRowGroupColumns()

    if (isValueColumn) {
        const lockEndDate = params.context.currentUser.company_options.lock_end_date
        const cellDate = params.colDef.pivotKeys[0]
        if (lockEndDate && dateIsSameOrAfter(cellDate, lockEndDate)) {
            tooltipMessages.push("Time period locked")
        }
    }

    // the row is an employee row
    // the table is grouped by "employee" or "trade" then "employee" (still not really clear how to do for now)
    if (isValueColumn && params.node.field === "/employee" && groupedByEmployeeOrTrade(rowGroupColumns)) {
        // This employee has fewer than 8 ST hours
        if (
            params.value.ST > 0 &&
            params.value.ST < defaultWorkHours.DAY_WORK_HOURS &&
            noColumnAbsences(params) &&
            noColumnShiftExtras(params)
        ) {
            tooltipMessages.push("This employee has fewer than 8 hours of ST without an absence or a shift extra.")
        }

        // This employee has more than 1 time card from the same foreman.
        if (typeof params.value.workshiftId !== "undefined") {
            // Make sure we filter out any phony work shift IDs which come from CI/CO
            const workShiftSet = new Set(
                params.value.workshiftId.filter(ws => getObjectId(ws) !== -1).map(ws => getObjectId(ws))
            )
            if (workShiftSet.size > 1)
                if (getFlagEnabled("WA-7577-unlink-aggregate-timecard-tooltip")) {
                    tooltipMessages.push(
                        `This time is an aggregate of ${params.value.workshiftId.size} time cards.`
                    )
                } else {
                    payload.data = {
                        date: params.column.colDef.pivotKeys[0],
                        link: `This time is an aggregate of ${params.value.workshiftId.size} time cards.`,
                    }
                }
        }
    }

    if (tooltipMessages.length > 0) {
        payload.data = { messages: tooltipMessages }
    }
    payload.tooltipShouldRender = payload.data != null

    return payload
}

export const pivotTotalColumnCustomTooltipMessageBuilder = params => {
    const payload = {
        tooltipShouldRender: false,
        data: {
            messages: null,
        },
    }
    const tooltipMessages = []
    const isPivotTotalColumn = isPivotRowTotalColumn(params.colDef)
    const rowGroupColumns = params.columnApi.getRowGroupColumns()

    //the pivot column of the cell is a total column for the week &&
    //the row is an employee row &&
    //the table is grouped by "employee" or "trade" then "employee" (not really clear how to do for now)
    if (isPivotTotalColumn && params.node.field === "/employee" && groupedByEmployeeOrTrade(rowGroupColumns)) {
        //This employee has more than 40 hours of ST.
        if (params.value.ST > defaultWorkHours.WEEK_WORK_HOURS) {
            tooltipMessages.push("This employee has more then 40 hours of ST.")
            //This employee has fewer than 40 hours of ST without an absence or a shift extra.
        } else if (
            params.value.ST < defaultWorkHours.WEEK_WORK_HOURS &&
            noColumnAbsences(params) &&
            noColumnShiftExtras(params)
        ) {
            tooltipMessages.push("This employee has fewer than 40 hours of ST without an absence or a shift extra.")
        }
    }

    if (tooltipMessages.length > 0) {
        payload.tooltipShouldRender = true
        payload.data.messages = tooltipMessages
    }

    return payload
}

// for the API Integration Dashboard, show the tooltip under the "New Field Names"
// header if the option to show new field names is turned off
// takes in ColDefParams and returns the tooltip data
export const apiIntegrationNewFieldNamesTooltip = params => {
    const payload = {
        tooltipShouldRender: !params.data.options.show_new_field_names,
        data: {
            messages: ["Enable this option to return newest field names, which may impact macros"],
        },
    }
    return payload
}

// General handler for custom tooltip messages affecting a row of the timekeeping table
export const timekeepingEntryGeneralErrorsTooltip = (params, noStatusException = false) => {
    // This tooltip must also include the functionality of the disabled tooltip
    let payload = timekeepingDisabledTooltip(params, noStatusException)
    if (payload.tooltipShouldRender) return payload

    // If we're a placeholder, don't display any misleading tooltips
    if (isPlaceholder(params.data)) {
        return { tooltipShouldRender: false, data: {} }
    }

    // If the disabled tooltip doesn't render, check for any row entry errors
    // (for example, if this timekeeping entry has the same modifiers and other key properties
    // as another entry, rendering it invalid)
    if (params?.data?.errors && Object.values(params.data.errors).filter(e => e.length).length) {
        const errors = Object.values(params.data.errors)
        let errorSet
        // Using a Set prevents duplicate error messages
        if (getFlagEnabled("WA-7083-duplicate-modifier-error-messages")) {
            errorSet = new Set(errors.flatMap(error => error))
        }

        payload = {
            tooltipShouldRender: errors.length > 0,
            data: {
                messages: getFlagEnabled("WA-7083-duplicate-modifier-error-messages") ? errorSet : errors,
            },
        }
    }

    return payload
}

// Tooltip handler for the Actions column, inside the Timekeeping modal inside Weekly View
export const timekeepingEntrySignatureActionsTooltip = params => {
    const payload = {
        tooltipShouldRender: false,
        data: {
            messages: null,
        },
    }

    /**
     * Note: the later ifs will take precedence.
     * If we require signatures and the project is not using daily signatures
     *     Show the weekly tooltip
     * If the employee has not agreed to receive texts
     *     Show that they need to sign up for texting
     * If the employee has no phone
     *     Show that they must add a phone number for the employee
     * If the employee has opted out
     *     Show the opted out message and how to get the employee to opt back in.
     */
    if (params.data?.require_signature) {
        if (params.data.signature_period !== "DAILY") {
            payload.tooltipShouldRender = true
            payload.data.messages = [
                "Requires weekly signatures. To sign for weekly timecards, close this modal, select the employee cell, and select Weekly Signature from the toolbar.",
            ]
        }
        const employee = params.context.referenceableData.employees?.[params.data.employee]
        if (!employee?.text_alerts_ok) {
            payload.tooltipShouldRender = true
            payload.data.messages = [
                "The employee has not signed up for texts from Rhumbix. They must text 'START' to +1(626)243-7629 for US numbers, +1(888)530-7629 for Canadian numbers to sign up.",
            ]
        }
        if (!employee?.phone) {
            payload.tooltipShouldRender = true
            payload.data.messages = [
                "The employee does not have a phone number registered with Rhumbix. Go to 'Company Team Members' to add their phone number.",
            ]
        }
        if (typeof employee?.text_opted_out_on === "string" && !!employee?.text_opted_out_on) {
            payload.tooltipShouldRender = true
            payload.data.messages = [
                "This employee has opted out of text messages from Rhumbix, to re-subscribe they must text 'START' to +1(626)243-7629 for US numbers, +1(888)530-7629 for Canadian numbers.",
            ]
        }
    }
    return payload
}

export const timekeepingModifierRoleListTooltip = params => {
    if (params.data.standard_tk_modifier_type != undefined) {
        return {
            tooltipShouldRender: true,
            data: {
                messages: ["This modifier does not support field-added items."],
            },
        }
    }

    return { tooltipShouldRender: false, data: { messages: null } }
}
