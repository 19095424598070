import form from "./form"

const initialState = {
    success: null,
    sending: false,
    message: "",
    errors: {},
    employeeIds: [],
}

export default function (state = initialState, action) {
    if (action.form == "massTextMessage") {
        return form(state, action)
    }
    switch (action.type) {
        case "SENDING_MASS_TEXT":
            return Object.assign({}, state, { sending: true })
        case "MASS_TEXT_SUCCESSFUL":
            return Object.assign({}, initialState, { success: true })
        case "MASS_TEXT_FAILED":
            return Object.assign({}, state, { sending: false })
        case "ADD_EMPLOYEE_TO_LIST":
            return Object.assign({}, state, {
                employeeIds: state.employeeIds.concat(action.employeeId),
            })
        case "REMOVE_EMPLOYEE_FROM_LIST":
            const newArr = state.employeeIds.slice()
            newArr.splice(state.employeeIds.indexOf(action.employeeId), 1)
            return Object.assign({}, state, { employeeIds: newArr })
        default:
            return state
    }
}
