import React from "react"
import { connect } from "react-redux"
import * as Action from "../../actions/index"
import { closeChangePasswordModal } from "./actions"
import StandardModal from "./modal-standard"
import { Password } from "../../form"

class ChangePasswordModal extends React.Component {
    initState = {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
        oldPwdErrors: [],
        newPwdErrors: [],
    }

    constructor(props) {
        super(props)
        this.state = this.initState
    }

    static defaultProps = {
        title: "Change password",
        cancelBtnText: "Cancel",
        saveBtnText: "Save",
    }

    closeModal = () => {
        this.setState(this.initState)
        this.props.dispatch(closeChangePasswordModal())
    }

    onInputChange = (field, newVal) => {
        this.setState({ [field]: newVal })
    }

    changePassword = () => {
        const data = {
            newPassword: this.state.newPassword,
            newPasswordConfirm: this.state.newPasswordConfirm,
            oldPassword: this.state.oldPassword,
        }
        this.props.dispatch(Action.changePassword(data)).then(this.closeModal, ({ response }) => {
            this.setState({
                oldPwdErrors: response.data.oldPassword || [],
                newPwdErrors: response.data.newPassword || [],
            })
        })
    }

    isFormValid = () =>
        this.state.oldPassword && this.state.newPassword && this.state.newPassword === this.state.newPasswordConfirm

    render() {
        const valueLinkOldPwd = {
            value: this.state.oldPassword,
            requestChange: this.onInputChange.bind(this, "oldPassword"),
        }
        const valueLinkNewPwd = {
            value: this.state.newPassword,
            requestChange: this.onInputChange.bind(this, "newPassword"),
        }
        const valueLinkNewPwdConfirm = {
            value: this.state.newPasswordConfirm,
            requestChange: this.onInputChange.bind(this, "newPasswordConfirm"),
        }

        return (
            <StandardModal
                title={this.props.title}
                open={this.props.isOpen}
                closeMethod={this.closeModal}
                secondaryBtnText={this.props.cancelBtnText}
                primaryBtnText={this.props.saveBtnText}
                primaryBtnOnClick={this.changePassword}
                primaryBtnDisabled={!this.isFormValid()}
                size="small"
                autoClose={false}
            >
                <Password valueLink={valueLinkOldPwd} name="Current Password" errors={this.state.oldPwdErrors} />
                <Password valueLink={valueLinkNewPwd} name="New Password" errors={this.state.newPwdErrors} />
                <Password valueLink={valueLinkNewPwdConfirm} name="New Password Confirm" />
            </StandardModal>
        )
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.modals.modalChangePassword.open,
    }
}

export default connect(mapStateToProps)(ChangePasswordModal)
