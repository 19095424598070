import React from "react"
import { eToolbarMode } from "../types"
import { tNetworkStatusState } from "../../dashboard-data/types"
import { SaveIndicatorContainer, SaveIndicatorImage } from "./styles"
import LoadingIconSrc from "../../../images/loading.gif"
import BlackCheckmarkSrc from "../../../images/csv-success-checkmark-black.png"
import CheckmarkSrc from "../../../images/csv-success-checkmark.png"
import FailedSrc from "../../../images/actions-required.png"

type tProps = {
    networkStatus: tNetworkStatusState
    toolbarMode: eToolbarMode
}

export const SaveIndicator: React.FunctionComponent<tProps> = ({ networkStatus, toolbarMode }) => {
    let testId = "dashboard-save-indicator"
    let message = ""
    let iconSrc = ""

    if (networkStatus.saveStatus === "in-progress") {
        testId = `${testId} in-progress`
        message = "Saving..."
        iconSrc = LoadingIconSrc
    } else if (networkStatus.saveStatus === "saved") {
        testId = `${testId} saved`
        message = "Changes Saved"
        iconSrc = toolbarMode === eToolbarMode.CELL ? BlackCheckmarkSrc : CheckmarkSrc
    } else if (networkStatus.saveStatus === "failed") {
        testId = `${testId} failed`
        message = "Save Error"
        iconSrc = FailedSrc
    } else {
        return null
    }

    return (
        <SaveIndicatorContainer data-testid={testId}>
            {message} <SaveIndicatorImage alt="" height="20" width="20" src={iconSrc} />
        </SaveIndicatorContainer>
    )
}

export default SaveIndicator
