import { bulkUpdateSourceDataField } from "../../../actions/bulk-actions"
import { statusesByUserRole, NoApExStatusesByUserRole } from "../../../common/constants"

export const NoApExStatusesNameLabelMap = NoApExStatusesByUserRole.reduce((accumulator, obj) => {
    accumulator[obj["name"]] = obj["label"]
    return accumulator
}, {})

export const statusesNameLabelMap = statusesByUserRole.reduce((accumulator, obj) => {
    accumulator[obj["name"]] = obj["label"]
    return accumulator
}, {})

export const statusNameLabelMap = () => {
    return statusesNameLabelMap
}

export const getActionsForButton = (selected = false) => {
    const userRoleStatus = statusesByUserRole
    const actions = []
    userRoleStatus.forEach(value => {
        actions[value.label] = {
            action: bulkUpdateSourceDataField,
            confirmTitle: selected
                ? `Change statuses of selected rows to "${value.label}"?`
                : `Change all statuses to "${value.label}"?`,
            actionParams: {
                field: "status",
                value: value.name,
            },
            canChangeToStatus: value.canChangeToStatus,
        }
    })
    return actions
}
