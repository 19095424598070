import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { closeLeaveConfirmationModal } from "./actions"

import StandardModal from "./modal-standard"

class LeaveConfirmationModal extends Component {
    constructor(props) {
        super(props)
        this.closeModal = this.closeModal.bind(this)
        this.onClickLeave = this.onClickLeave.bind(this)
    }

    closeModal() {
        this.props.dispatch(closeLeaveConfirmationModal())
    }

    onClickLeave() {
        this.closeModal()
        this.props.onSuccess()
    }

    render() {
        return (
            <StandardModal
                title="Save Changes?"
                description="Changes you've made will be lost if you navigate away from this page."
                open={this.props.modalIsOpen}
                closeMethod={this.closeModal}
                secondaryBtnText="Leave This Page"
                primaryBtnText="Stay On This Page"
                secondaryBtnOnClick={this.onClickLeave}
                primaryBtnOnClick={this.closeModal}
            />
        )
    }
}

LeaveConfirmationModal.propTypes = {
    onSuccess: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    return {
        modalIsOpen: state.modals.modalConfirmation.leave_confirmation_open,
    }
}

export default connect(mapStateToProps)(LeaveConfirmationModal)
