import DefaultCellRenderer from "../cell-renderers/default-cell-renderer"
import SparkLineRenderer from "../cell-renderers/spark-line-renderer"
import UnitRateWeekValueRenderer from "../cell-renderers/unit-rate-week-value-renderer"
import {
    unitManHourValueGetter,
    budgetUnitRateValueGetter,
    avgUnitRateValueGetter,
    percentWeeklyUnitRateChangeValueGetter,
    weekUnitRateValueGetter,
    entireRowValueGetter,
    toGoUnitRateValueGetter,
} from "../../../common/ag-grid-value-getters"
import { significantFigureMaker } from "../../../common/ag-grid-utils"
import {
    plusMinusPercentFormatter,
    upperCaseFormatter,
    getValueFormatter,
    filterFormatter,
} from "../../../common/ag-grid-value-formatters"
import { compareCostCodeObjects, compareNumbersStrings, compareStrings } from "../../../common/ag-grid-comparators"
import {
    changeTooltip,
    jtdTooltip,
    toGoTooltip,
    performanceErrorTooltipMixin,
} from "../../../common/ag-grid-custom-tooltip-formatters"
import { foremanFocusedTooltipTypes } from "../../../common/constants"
import {
    companyGroupsFilterDef,
    dateRangeFilterDef,
    projectStatusFilterDef,
    multiProjectFilterDef,
} from "../../../filters/filter-defs"

const cellStringShouldBeNumberWarningRules = {
    // the cell should be a number
    "": function (params) {
        return typeof params.value === "number"
    },
    // if it's not, warn them
    orange: function (params) {
        return typeof params.value !== "number"
    },
}

const cellRightAlignRedGreenRules = {
    orange: function (params) {
        return typeof params.value !== "number"
    },
    green: function (params) {
        return typeof params.value === "number" && params.value >= 0
    },
    red: function (params) {
        return typeof params.value === "number" && params.value < 0
    },
}

export function getUnitRateTrackerSettings() {
    return {
        tableName: "Foreman-focused",
        navId: "unit-rate-tracker",
        resources: ["costCodes"],
        filters: [companyGroupsFilterDef, projectStatusFilterDef, multiProjectFilterDef, dateRangeFilterDef],
        additionalQueryParams: {
            performance_weeks: 4,
            minimal_project_detail: true,
        },
        colDefs: [
            {
                headerName: "Cost Code",
                minWidth: 220,
                valueGetter: entireRowValueGetter,
                cellRenderer: DefaultCellRenderer,
                cellRendererParams: {
                    titleFormatter: getValueFormatter(["/project/job_number", "/code"], " - "),
                    primarySubtitleFormatter: getValueFormatter(["/description"]),
                },
                comparator: compareCostCodeObjects,
                getQuickFilterText: filterFormatter,
            },
            {
                headerName: "Budget ",
                headerTooltip: "Budget unit rate is calculated from \nbudgeted hours and quantities",
                minWidth: 120,
                valueGetter: budgetUnitRateValueGetter,
                valueFormatter: params => significantFigureMaker(params.value, 3, true),
                comparator: compareNumbersStrings,
                customTooltipParameters: foremanFocusedTooltipTypes.BUDGET,
                customTooltip: params => {
                    return performanceErrorTooltipMixin(params)
                },
            },
            {
                headerName: "Units",
                minWidth: 100,
                valueGetter: unitManHourValueGetter,
                valueFormatter: upperCaseFormatter,
                comparator: compareStrings,
            },
            {
                headerName: "Week Actual",
                headerTooltip: "Actual unit rate for \nthe current week",
                minWidth: 150,
                valueGetter: params => weekUnitRateValueGetter(params, 0),
                cellRenderer: UnitRateWeekValueRenderer,
                comparator: compareNumbersStrings,
                customTooltipParameters: foremanFocusedTooltipTypes.THIS_WEEK,
                customTooltip: params => {
                    return performanceErrorTooltipMixin(params)
                },
            },
            {
                headerName: "Last Week",
                headerTooltip: "Actual unit rate for \nthe previous week",
                minWidth: 140,
                valueGetter: params => weekUnitRateValueGetter(params, 1),
                cellRenderer: UnitRateWeekValueRenderer,
                comparator: compareNumbersStrings,
                customTooltipParameters: foremanFocusedTooltipTypes.LAST_WEEK,
                customTooltip: params => {
                    return performanceErrorTooltipMixin(params)
                },
            },
            {
                headerName: "Change",
                headerTooltip: "Change in actual unit rate \nfrom last week to this week",
                minWidth: 107,
                valueGetter: percentWeeklyUnitRateChangeValueGetter,
                valueFormatter: plusMinusPercentFormatter,
                cellClassRules: cellRightAlignRedGreenRules,
                comparator: compareNumbersStrings,
                customTooltip: changeTooltip,
            },
            {
                headerName: "To Go Performance",
                headerTooltip:
                    "Given the to-date performance, \nthe unit rate required to hit \nthe budgeted unit rate",
                valueFormatter: params => significantFigureMaker(params.value, 3, true),
                minWidth: 195,
                valueGetter: toGoUnitRateValueGetter,
                cellClassRules: cellStringShouldBeNumberWarningRules,
                comparator: compareNumbersStrings,
                customTooltip: toGoTooltip,
            },
            {
                headerName: "JTD Average",
                headerTooltip: "Average quantities per man hour \nfor the job-to-date",
                minWidth: 160,
                valueGetter: avgUnitRateValueGetter,
                valueFormatter: params => significantFigureMaker(params.value, 3),
                cellClassRules: cellStringShouldBeNumberWarningRules,
                comparator: compareNumbersStrings,
                customTooltip: jtdTooltip,
            },
            {
                headerName: "Last 4 Weeks",
                minWidth: 220,
                valueGetter: params => weekUnitRateValueGetter(params, 0),
                comparator: compareNumbersStrings,
                cellRenderer: SparkLineRenderer,
            },
        ],
        gridSettings: {
            rowHeight: 70,
        },
        otherSettings: {
            enableSearchBar: true,
        },
    }
}
