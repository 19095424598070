import { iCurrentUser, tFeatures } from "../../../common/types"
import {
    stringColDefCreator,
    checkboxColDefCreator,
    employeeColDefCreator,
    integerColDefCreator,
    booleanColDefCreator,
} from "./standard-col-def-creators"
import { tProject } from "../../../cached-data/types"
import { isActiveValueFormatter } from "../../../common/ag-grid-value-formatters"

export const getCohortEmployeeSettings = (
    _: tFeatures,
    __: tProject,
    ___: iCurrentUser,
    rowData: Record<string, any> = {}
) => {
    return {
        tableName: "Cohort Employees",
        navId: "cohort-employees",
        resources: ["cohortEmployees"],
        filters: [],
        relatedNames: {
            cohortEmployees: {
                employees: ["/employee"],
            },
        },
        additionalQueryParams: { cohort_id: rowData.id },
        colDefs: [
            checkboxColDefCreator({}),
            integerColDefCreator({
                field: "/cohort_id",
                hide: true,
            }),
            {
                ...employeeColDefCreator({
                    editable: true,
                    field: "/employee",

                    idsToExclude:
                        rowData && rowData.sourceData && rowData.sourceData.cohortEmployees
                            ? rowData.sourceData.cohortEmployees.map((r: Record<string, number>) => r.employee_id)
                            : [],
                }),
            },
            stringColDefCreator({
                field: "/first_name",
                headerName: "First Name",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            stringColDefCreator({
                field: "/last_name",
                headerName: "Last Name",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            stringColDefCreator({
                field: "/email",
                headerName: "Email",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            {
                ...booleanColDefCreator({
                    headerName: "Status",
                    field: "/is_active",
                    referenceableMap: {
                        referenceableKey: "employees",
                        rowKey: "employee",
                    },
                    minWidth: 100,
                    editable: false,
                }),
                valueFormatter: isActiveValueFormatter,
            },
        ],
        gridSettings: {
            rowHeight: 45,
            rowSelection: "multiple",
            defaultColDef: {
                editable: false,
            },
        },
        otherSettings: {
            enableSearchBar: false,
            hiddenColumnDefaults: {
                cohort: {
                    value: rowData.id,
                },
            },
            buttons: {
                row: [
                    {
                        label: "Delete",
                        icon: "delete",
                        action: "deleteSelectedRows",
                        args: { no_autosave: true },
                    },
                ],
                table: [
                    {
                        label: "Add Employees",
                        action: "addCohortEmployeeRows",
                        icon: "add",
                        args: { value: rowData.id },
                    },
                ],
            },
        },
    }
}
