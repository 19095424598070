import React from "react"
import { NavLink } from "react-router-dom"
import CollapsibleMenuGroup from "./collapsible-menu-group"
import { actOnEnterOrSpace } from "../../common/js-utils"
import { IconUser } from "@rhumbix/rmbx_design_system_web"

export const UserMenu = props => {
    const { isSidebarCollapsed, user_role, company_role, changePassword, logout } = props
    const menuOptionClass = isSidebarCollapsed ? "mini-sidebar-custom-list-item" : "custom-list-item"

    return (
        <CollapsibleMenuGroup
            {...props}
            icon={<IconUser width={20} height={20} />}
            iconName="user-email"
            menuTitle="User Settings"
            navCategory="email-alerts"
        >
            <>
                {["ADMIN", "PAYROLL_ADMIN", "PM"].includes(user_role) && company_role !== "OWNER" && (
                    <>
                        <li className={menuOptionClass}>
                            <NavLink
                                activeClassName="selected-menu-item"
                                className="sidebar-menu-options"
                                to="/rhumbix/email-alerts/"
                            >
                                <span>Email Alerts</span>
                            </NavLink>
                        </li>
                        <li className={menuOptionClass}>
                            <NavLink
                                activeClassName="selected-menu-item"
                                className="sidebar-menu-options"
                                to="/rhumbix/daily-summary-email-settings/"
                            >
                                <span>Daily Summary Email</span>
                            </NavLink>
                        </li>
                    </>
                )}
                <li className={menuOptionClass}>
                    <div
                        onClick={changePassword}
                        onKeyDown={e => actOnEnterOrSpace(e, changePassword)}
                        role="button"
                        tabIndex={0}
                    >
                        <p className="sidebar-menu-options">
                            <span>Change Password</span>
                        </p>
                    </div>
                </li>

                <li className={menuOptionClass}>
                    <div onClick={logout} onKeyDown={e => actOnEnterOrSpace(e, logout)} role="button" tabIndex={0}>
                        <p className="sidebar-menu-options">
                            <span>Sign Out</span>
                        </p>
                    </div>
                </li>
            </>
        </CollapsibleMenuGroup>
    )
}

export default UserMenu
