import React from "react"
import { connect } from "react-redux"
import DatePicker, { registerLocale } from "react-datepicker"
import { isValid } from "date-fns"

import { makePrimaryFilterValueSelector, makeSecondaryFilterValueSelector } from "../../selectors"
import { setMultipleFilterValues, setFilterState } from "../actions"
import { calculateAndSetFilterDates } from "./calculateFilterDates"
import { datePickerHasArrows } from "../utils"
import DateArrows from "./DateArrows"

import {
    eDatePickerType,
    tFilterKey,
    iMutableFilterState,
    tFilterActionCallbacks,
    tStartOfWeekIndex,
} from "../types"
import { iCurrentUser } from "../../common/types"
import { getLocaleForDatePicker } from "../../common/ts-utils"

import styled from "styled-components"
import { colorAttentionBlue10, colorMiscBlue70, IconCalendarWeek } from "@rhumbix/rmbx_design_system_web"

import "react-datepicker/dist/react-datepicker.css"
import "../FilterController/FilterController.less"
import "./DateFilter.less"
import { startOfTheWeekSelector } from "../selectors"
import { getFlagEnabled } from "../../getFlagValue"

type tProps = {
    className: string
    currentUser: iCurrentUser
    datePickerType: eDatePickerType
    primaryKey: tFilterKey
    secondaryKey: tFilterKey
    setMultipleFilterValues: {
        (arg: iMutableFilterState): void
    }
    startDate: Date
    endDate: Date
    startOfTheWeekIndex: tStartOfWeekIndex
    setFilterState: tFilterActionCallbacks
}

// this can be removed with WA-8094-start-of-week
type tState = {
    startOfWeekIndex: tStartOfWeekIndex
}

const StartDatePicker = styled(DatePicker)`
    color: ${colorMiscBlue70};
    background-color: ${colorAttentionBlue10};
    border: 0;
`

const StartDateInputWrapper = styled.div`
    position: relative;
    width: 100%;
`

const StartDateInputIcon = styled.i`
    position: absolute;
    right: 8px;
    top: 8px;
`
/* Custom Input for the start date picker, shows the calendar icon */
const CustomInput = React.forwardRef(
    (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
        return (
            <StartDateInputWrapper>
                <input ref={ref} {...props} />
                <StartDateInputIcon onClick={props.onClick}>
                    <IconCalendarWeek color={colorMiscBlue70} width={20} height={20} />
                </StartDateInputIcon>
            </StartDateInputWrapper>
        )
    }
)

export class DateFilter extends React.PureComponent<tProps, tState> {
    private node = React.createRef<HTMLDivElement>()

    constructor(props: tProps) {
        super(props)
        // the state can be removed with WA-8094-start-of-week
        this.state = { startOfWeekIndex: this.getStartOfWeekIndex() }
        registerLocale(
            "weekStart",
            getLocaleForDatePicker(
                getFlagEnabled("WA-8094-start-of-week")
                    ? this.props.startOfTheWeekIndex
                    : this.state.startOfWeekIndex
            )
        )
    }

    componentDidUpdate(prevProps: tProps) {
        // If the date picker type or current user changed, recalculate the range
        if (
            prevProps.currentUser !== this.props.currentUser ||
            prevProps.datePickerType !== this.props.datePickerType
        ) {
            this.setState(
                {
                    startOfWeekIndex: this.getStartOfWeekIndex(),
                },
                () => {
                    this.handleDatesChange({
                        startDate: this.props.startDate,
                        endDate: this.props.endDate,
                    })
                }
            )
        }
    }

    getStartOfWeekIndex = (): tStartOfWeekIndex =>
        this.props.currentUser && this.props.currentUser.employee
            ? this.props.currentUser.employee.company.start_of_week_index
            : 0

    handleDatesChange = (dates: { startDate: Date | null; endDate: Date | null }): void => {
        calculateAndSetFilterDates({
            dates: dates,
            startOfWeekIndex: getFlagEnabled("WA-8094-start-of-week")
                ? this.props.startOfTheWeekIndex
                : this.state.startOfWeekIndex,
            datePickerType: this.props.datePickerType,
            setMultipleFilterValues: this.props.setMultipleFilterValues,
            setFilterState: this.props.setFilterState,
        })
    }

    render() {
        // daily and weekly views currently use arrows - custom ranges do not
        const hasArrows = datePickerHasArrows(this.props.datePickerType)
        const className = hasArrows
            ? `${this.props.className} date-filter date-filter-arrow-nav`
            : `${this.props.className} date-filter`
        const startDate = this.props.startDate || new Date()
        const endDate = this.props.endDate || new Date()
        const isDaily = this.props.datePickerType === eDatePickerType.DAILY
        return (
            <div ref={this.node} className={className} id={`filter-${this.props.primaryKey}`}>
                <div className={"date-pickers"}>
                    <StartDatePicker
                        id="start-date-input"
                        selected={this.props.startDate}
                        onChange={date =>
                            this.handleDatesChange({
                                startDate: date,
                                endDate: this.props.endDate,
                            })
                        }
                        selectsStart={!hasArrows}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        className={`date-filter-input${isDaily ? " date-filter-daily" : ""}`}
                        locale="weekStart"
                        customInput={<CustomInput />}
                    />
                    {!isDaily ? (
                        <div className="date-filter-to">
                            <p> — </p>
                        </div>
                    ) : null}
                    {!isDaily ? (
                        <DatePicker
                            id="end-date-input"
                            selected={this.props.endDate}
                            onChange={date =>
                                this.handleDatesChange({
                                    startDate: this.props.startDate,
                                    endDate: date,
                                })
                            }
                            selectsEnd
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                            minDate={this.props.startDate}
                            className={`date-filter-input${hasArrows ? " date-filter-locked" : ""}`}
                            disabled={hasArrows}
                            locale="weekStart"
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                    escapeWithReference: false,
                                    boundariesElement: "viewport",
                                },
                            }}
                        />
                    ) : null}
                </div>
                <DateArrows
                    datePickerType={this.props.datePickerType}
                    endDate={endDate}
                    handleDatesChange={this.handleDatesChange}
                    isEnabled={hasArrows}
                    startDate={startDate}
                    startOfWeekIndex={
                        getFlagEnabled("WA-8094-start-of-week")
                            ? this.props.startOfTheWeekIndex
                            : this.state.startOfWeekIndex
                    }
                />
            </div>
        )
    }
}

// See the comments in ../../selectors/filters for an explanation of this factory pattern
const mapStateToProps = () => {
    const primaryFilterValueSelector = makePrimaryFilterValueSelector()
    const secondaryFilterValueSelector = makeSecondaryFilterValueSelector()

    const componentSpecificMapStateToProps = (state: any, props: tProps) => {
        const startDate = primaryFilterValueSelector(state, props)
        const endDate = secondaryFilterValueSelector(state, props)
        return {
            currentUser: state.current_user,
            startDate: isValid(startDate) ? (startDate as Date) : new Date(),
            endDate: isValid(endDate) ? (endDate as Date) : new Date(),
            startOfTheWeekIndex: startOfTheWeekSelector(state.current_user),
        }
    }

    return componentSpecificMapStateToProps
}

const mapDispatchToProps = {
    setMultipleFilterValues,
    setFilterState,
}

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter)
