import CollapsibleMenuGroup from "../collapsible-menu-group"
import React from "react"
import MenuOptions from "../menu-options"
import { navCategories } from "../../../common/constants"
import { IconCompanySettings } from "@rhumbix/rmbx_design_system_web"

const CompanySettings = props => (
    <CollapsibleMenuGroup
        {...props}
        iconName="company-settings"
        icon={<IconCompanySettings width={20} height={20} />}
        menuTitle="Company Settings"
        navCategory="company-settings"
        isHidden={!["ADMIN", "PM", "PAYROLL_ADMIN"].includes(props.user_role)}
    >
        <MenuOptions {...props} options={props.routes[navCategories.companySettings]} />
    </CollapsibleMenuGroup>
)

export default CompanySettings
