import Resizer from "react-image-file-resizer"
import { getFlagEnabled } from "../getFlagValue"

export function putS3(
    putUrl: string,
    file: File | Blob,
    proxyUrl: string,
    onSuccessfulUpload: (proxyUrl: string, name: string, type: string) => void,
    onError: () => void
): void {
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                onSuccessfulUpload(proxyUrl, file.name, file.type)
            } else {
                onError()
            }
        }
    }
    xhr.open("PUT", putUrl)
    xhr.setRequestHeader("Content-Type", file.type)
    xhr.send(file)
}

export const resizeImage = (
    attachment: File | Blob,
    handleBlob: (uri: Blob, attachment: File | Blob, filename?: string) => void,
    maxHeight = 1200,
    maxWidth = 1200,
    minHeight = 200,
    minWidth = 200
): void => {
    const filename = getFlagEnabled("WA-8278-fix-pt-attachments") ? attachment?.name : undefined
    // Double-check that it's a resizable image (SVGs aren't since they're XML)
    if (attachment.type.indexOf("image/") >= 0 && attachment.type !== "image/svg+xml") {
        const compression = attachment.type === "image/png" ? "PNG" : "JPEG"
        Resizer.imageFileResizer(
            attachment,
            maxWidth,
            maxHeight,
            compression,
            80, // Quality
            0, // rotation
            (uri: string | Blob | ProgressEvent<FileReader>) => {
                handleBlob(uri as Blob, attachment, filename)
            },
            "blob", // return as file blob
            minWidth,
            minHeight
        )
    }
}
