import React from "react"
import { connect } from "react-redux"

import CollapsibleMenuGroup from "../collapsible-menu-group"
import MenuOptions from "../menu-options"
import { isFeatureEnabledSelector } from "../../../selectors"
import { navCategories } from "../../../common/constants"
import { IconLaunch } from "@rhumbix/rmbx_design_system_web"
const category = navCategories.externalLinks

const ExternalLinks = props => {
    let isHidden = false
    // hide if there are no links
    if (!props.routes[category].length) isHidden = true

    return (
        <CollapsibleMenuGroup
            {...props}
            iconName="open-out"
            icon={<IconLaunch width={20} height={20} />}
            menuTitle={"External Links"}
            navCategory="external-links"
            isCore={true}
            isHidden={isHidden}
        >
            <MenuOptions {...props} options={props.routes[category]} />
        </CollapsibleMenuGroup>
    )
}

const mapStateToProps = state => ({
    isTimekeepingFeatureEnabled: isFeatureEnabledSelector(state, "timekeeping"),
})

export default connect(mapStateToProps)(ExternalLinks)
