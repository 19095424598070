import React, { useImperativeHandle, useState, forwardRef, useEffect, useRef } from "react"
import SlideToggle from "react-switch"
import { colorAttentionBlue50, colorFunctionalGray30, colorFunctionalGray50 } from "@rhumbix/rmbx_design_system_web"
import styled from "styled-components"
import { ColDef, ColumnApi, GridApi, RowNode } from "ag-grid-community"
import { getFlagEnabled } from "../../../getFlagValue"
import { isTokenRowActive } from "../../../common/ag-grid-utils"
import { tContext } from "../types"
import { Column } from "ag-grid-community/dist/lib/entities/column"
import { slideToggleHandleChange } from "../../../common/ag-grid-ts-utils"

type tProps = {
    api: GridApi
    colDef: ColDef & Record<string, any>
    column: Column
    columnApi: ColumnApi
    context: tContext
    hideUneditable?: boolean
    isListValueType?: boolean
    node: RowNode
    offText: string
    onText: string
    onValue: string
    params: Record<string, any>
    value: string[]
    width: number
    maxLength: number
}

const SliderCellEditor: React.FunctionComponent<tProps> = forwardRef((props: tProps, ref) => {
    const {
        colDef,
        context,
        api: gridApi,
        column,
        columnApi,
        node: row,
        onValue,
        offText,
        onText,
        isListValueType,
    } = props
    const { data: rowData } = row
    const { editable } = colDef

    const [currentValue, setCurrentValue] = useState<boolean | string[]>(
        props.value === undefined && isListValueType ? [] : props.value
    )
    const refInput = useRef<HTMLInputElement>(null)

    const getDisabledState = (): boolean => {
        if (!getFlagEnabled("WA-7979-ff-notifications")) return !isTokenRowActive(row?.data)
        // editable can be a boolean or a function - if it's a function, pass the params to get the editable setting
        return typeof editable === "boolean"
            ? !editable
            : typeof editable === "function"
            ? !editable({ node: row, data: rowData, colDef, column, api: gridApi, columnApi, context })
            : true // if no editable setting is passed, default to true, we don't always declare editable
    }
    const [disabled] = useState(getDisabledState())
    const handleChange = () => {
        slideToggleHandleChange(
            row,
            context,
            colDef,
            gridApi,
            currentValue,
            setCurrentValue,
            isListValueType,
            onValue
        )
    }
    useEffect(() => {
        // focus on the input
        setTimeout(() => refInput.current?.focus())
    }, [])

    // This is required to have the cell editor pop out of the cell when the contents are too large for the cell
    useImperativeHandle(ref, () => {
        return {
            getValue: () => currentValue,
        }
    })

    if (getFlagEnabled("WA-7979-ff-notifications") && ((disabled && props.hideUneditable) || rowData == undefined))
        return <></>
    return (
        <SlideToggleContainer onClick={handleChange}>
            {/*// @ts-ignore*/}
            <SlideToggle
                className="slide-toggle"
                checked={
                    (Array.isArray(currentValue) && currentValue.includes(onValue)) ||
                    (!Array.isArray(currentValue) && currentValue)
                }
                onChange={handleChange}
                onColor={colorAttentionBlue50}
                offColor={colorFunctionalGray30}
                checkedIcon={false}
                disabled={disabled}
                uncheckedIcon={false}
                onClick={handleChange}
                height={20}
                width={32}
            />
            <ToggleText disabled={false} onClick={handleChange}>
                {(Array.isArray(currentValue) && currentValue.includes(onValue)) ||
                (!Array.isArray(currentValue) && currentValue)
                    ? onText
                    : offText}
            </ToggleText>
        </SlideToggleContainer>
    )
})

export default SliderCellEditor
const SlideToggleContainer = styled.span`
    display: flex;
    align-items: center;
    padding-left: 6px;

    .slide-toggle {
        margin-right: 8px;
        pointer-events: auto;
    }
`
const ToggleText = styled.span<{ disabled: boolean }>`
    color: ${({ disabled }) => (!disabled ? "inherit" : colorFunctionalGray50)};
`
