import React, { useContext } from "react"

/** Components */
import { SideRailContext } from "../../SideRail/SideRailContext"
import { ReduxState } from "../../../common/types"
import { connect } from "react-redux"
import { tSourceData } from "../../../dashboard-data/types"
import { Dispatch } from "redux"
import { tContext, tGroupKeyInfo } from "../types"
import styled from "styled-components"
import { colorAttentionBlue10, colorAttentionBlue50 } from "@rhumbix/rmbx_design_system_web"

/** Types */
type Props = {
    data: Record<string, any>
    config: Record<string, any>
    context: tContext
    dispatch: Dispatch<any>
    lockedColumns: tGroupKeyInfo[]
    sideRailSettings: Record<string, any>
    sourceData: tSourceData
}

const ViewCicoHistoryButtonCellRenderer: React.FC<Props> = props => {
    const { enableSideRail } = useContext(SideRailContext)
    const openRightRail = () => {
        if (props.data.entry_id) {
            enableSideRail({
                flow: "VIEW_CICO_HISTORY",
                entryId: props.data.entry_id,
                date: props.data.entered_time,
                employee: props.data.worker_id,
                lockedColumns: props.lockedColumns,
            })
        }
    }
    const historyCount = (props.data.history?.length || 1) - 1
    return (
        <ActionButton
            onClick={openRightRail}
            id={props.data.buttonId}
            type="button"
            className="action-button"
            disabled={!props.data.history}
        >
            <div>{historyCount ? `View History (${historyCount})` : "View History"}</div>
        </ActionButton>
    )
}

const mapStateToProps = (state: ReduxState) => {
    return {
        sourceData: state.sourceData.sourceData,
    }
}

export default connect(mapStateToProps)(ViewCicoHistoryButtonCellRenderer)

const ActionButton = styled.button<{ longerButton?: boolean }>`
    background-color: ${colorAttentionBlue10};
    color: ${colorAttentionBlue50};
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    pointer-events: auto;
    min-width: 30px;
    height: 32px;
    text-align: center;
    padding: 8px;
    border: 0px;
    width: auto;
    border-radius: 2px;
}
`
