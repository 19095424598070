import React from "react"
import { iTimekeepingStatus } from "../../../cached-data/types"

import { colorFunctionalGray50, IconHide, IconLock } from "@rhumbix/rmbx_design_system_web"

type Props = {
    data: iTimekeepingStatus
    editable: boolean
    value: string
}

export const LockedDisabledStringRenderer: React.FC<Props> = props => {
    return (
        <span style={props.data.is_hidden ? { color: colorFunctionalGray50 } : {}}>
            {props.data.built_in_status_id ? (
                props.data.is_hidden ? (
                    <>
                        <IconHide width={16} style={{ marginRight: 5, verticalAlign: "top" }} />
                        <IconLock width={16} style={{ marginRight: 5, verticalAlign: "top" }} />
                    </>
                ) : (
                    <IconLock width={16} style={{ marginRight: 5, verticalAlign: "top" }} />
                )
            ) : (
                <></>
            )}
            {props.value}
        </span>
    )
}

export default LockedDisabledStringRenderer
