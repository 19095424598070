import * as Sentry from "@sentry/react"
import featureFlagsClient, { featureFlagsClientIsReady } from "./featureFlagsClient"
import { LDContext } from "launchdarkly-js-client-sdk"

/**
 * Helper function so that we can fit logging and returning "flag_unavailable"
 * @param flagName he name of the feature flag
 * @param attributes of the calling user
 * @returns "flag_unavailable"
 */
const sentryLogAndReturn = (flagName: string, context: LDContext): boolean => {
    // We make email private when sending to LD, so we'll redact it from the logs, too.
    context["email"] = "redacted"
    Sentry.captureMessage(
        `LaunchDarkly was unavailable while evaluating the flag ${flagName}. Context: ${JSON.stringify(context)}`
    )
    return false
}

/**
 * NOTE: USE THESE FOR JS CLASSES, INSIDE SETTINGS FILES.
 * This is the function that wraps the current feature flag vendors methods of evaluating flags
 *
 * @param flagName the name of the feature flag
 * @param defaultValue an optional default value to be passed in by the caller
 * @returns the defaultValue if the client is not ready, otherwise returns the flagValue
 */
export const getFlagValue = (flagName: string, defaultValue?: any): any => {
    const flagValue = featureFlagsClient.variation(flagName, defaultValue || false)
    /**
     * If the client is not ready
     * return defaultValue if available, otherwise log the event and return false
     * Otherwise, return flagValue
     */
    return !featureFlagsClientIsReady()
        ? defaultValue ?? sentryLogAndReturn(flagName, featureFlagsClient.getContext())
        : flagValue
}

/**
 * NOTE: USE THESE FOR JS CLASSES, INSIDE SETTINGS FILES.
 * Helper function that we export to get a straight boolean response
 * @param flagName the name of the feature flag
 * @returns true if getFlagValue() is truthy, else false
 */
export const getFlagEnabled = (flagName: string): boolean => !!getFlagValue(flagName, false)

/**
 * Allow for checking flag values through the browser console.
 */
window["rmbx"] = {
    ...window["rmbx"],
    getFlagEnabled,
    getFlagValue,
}
