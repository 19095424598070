// All typescript-y stuff stolen from
// https://redux.js.org/recipes/usage-with-typescript#type-checking-actions--action-creators
// Constants
import { GuestFormShare, tSchemaPermission } from "../common/types"
import {
    SchemaVariant,
    iCohort,
    iCohortEmployee,
    iCompanyAbsenceTypes,
    iTimekeepingStatus,
} from "../cached-data/types"

export const FETCH_ANALYTICS_DASHBOARDS_SUCCESS = "FETCH_ANALYTICS_DASHBOARDS_SUCCESS"
export const LIST_VIEW_SCHEMA_ERROR = "LIST_VIEW_SCHEMA_ERROR"
export const LIST_VIEW_SCHEMA_FETCHED = "LIST_VIEW_SCHEMA_FETCHED"
export const LOOKER_URL_FAILED = "LOOKER_URL_FAILED"
export const LOOKER_URL_SUCCESSFUL = "LOOKER_URL_SUCCESSFUL"
export const LOOKER_URL_CLEARED = "LOOKER_URL_CLEARED"
export const RELATED_TRANSFORMS_UPDATED = "RELATED_TRANSFORMS_UPDATED"
export const RELATED_TRANSFORMS_ERROR = "RELATED_TRANSFORMS_ERROR"
export const TRANSFORMS_FOR_IMPORT_UPDATED = "TRANSFORMS_FOR_IMPORT_UPDATED"
export const TRANSFORMS_FOR_IMPORT_ERROR = "TRANSFORMS_FOR_IMPORT_ERROR"
export const TRANSFORMS_FOR_IMPORT_PROCESS_DATA_ACTION = "TRANSFORMS_FOR_IMPORT_PROCESS_DATA_ACTION"
export const TRANSFORMS_FOR_IMPORT_PROCESS_DATA_SUCCEEDED = "TRANSFORMS_FOR_IMPORT_PROCESS_DATA_SUCCEEDED"
export const TRANSFORMS_FOR_IMPORT_PROCESS_DATA_FAILED = "TRANSFORMS_FOR_IMPORT_PROCESS_DATA_FAILED"
export const TRANSFORMS_FOR_IMPORT_RESET_RESULTS = "TRANSFORMS_FOR_IMPORT_RESET_RESULTS"
export const RELATED_SCHEMA_FETCHED = "RELATED_SCHEMA_FETCHED"
export const RELATED_SCHEMA_ERROR = "RELATED_SCHEMA_ERROR"
export const RELATED_SCHEMA_CLEAR = "RELATED_SCHEMA_CLEAR"
export const FETCH_WEATHER_SUCCESSFUL = "FETCH_WEATHER_SUCCESSFUL"
export const FETCH_WEATHER_FAILED = "FETCH_WEATHER_FAILED"
export const WEATHER_INFORMATION_CLEARED = "WEATHER_INFORMATION_CLEARED"
export const ADD_EXISTING_WEATHER_INFORMATION = "ADD_EXISTING_WEATHER_INFORMATION"
export const ADD_EXISTING_CUSTOM_WEATHER_INFORMATION = "ADD_EXISTING_CUSTOM_WEATHER_INFORMATION"
export const FETCH_SCHEMA_PERMISSIONS_SUCCESSFUL = "FETCH_SCHEMA_PERMISSIONS_SUCCESSFUL"
export const FETCH_SCHEMA_PERMISSIONS_FAILED = "FETCH_SCHEMA_PERMISSIONS_FAILED"
export const FETCH_GUEST_FORM_SHARES_SUCCESS = "FETCH_GUEST_FORM_SHARES_SUCCESS"
export const AGGRID_HIGHLIGHTED_ROW = "AGGRID_HIGHLIGHTED_ROW"
export const AGGRID_HIGHLIGHTED_ROW_RESET = "AGGRID_HIGHLIGHTED_ROW_RESET"
export const AGGRID_ROW_FLASHED = "AGGRID_ROW_FLASHED"

// Action types
export interface LookerUrlSuccessAction {
    type: typeof LOOKER_URL_SUCCESSFUL
    data: {
        signed_url: string
        looker_element: string
    }
}
export interface LookerUrlClearedAction {
    type: typeof LOOKER_URL_CLEARED
    data: {
        signed_url: null
    }
}
export interface LookerUrlFailedAction {
    type: typeof LOOKER_URL_FAILED
}
export interface iRelatedTransformsUpdateAction {
    type: typeof RELATED_TRANSFORMS_UPDATED
    data: Array<any>
}
export interface iRelatedTransformsErrorAction {
    type: typeof RELATED_TRANSFORMS_ERROR
}
export interface iRelatedSchemaFetchAction {
    type: typeof RELATED_SCHEMA_FETCHED
    data: Array<any>
}
export interface iRelatedSchemaErrorAction {
    type: typeof RELATED_SCHEMA_ERROR
}
export interface iTransformsForImportUpdateAction {
    type: typeof TRANSFORMS_FOR_IMPORT_UPDATED
    data: Array<any>
}
export interface iTransformsForImportErrorAction {
    type: typeof TRANSFORMS_FOR_IMPORT_ERROR
}
export interface iTransformsForImportProcessDataAction {
    type: typeof TRANSFORMS_FOR_IMPORT_PROCESS_DATA_ACTION
}
export interface iTransformsForImportProcessDataSucceeded {
    type: typeof TRANSFORMS_FOR_IMPORT_PROCESS_DATA_SUCCEEDED
    importedStore: Record<string, any>
    importedStoreNames: string[]
}
export interface iTransformsForImportProcessDataFailed {
    type: typeof TRANSFORMS_FOR_IMPORT_PROCESS_DATA_FAILED
    message: string
}
export interface iTransformsForImportResetResults {
    type: typeof TRANSFORMS_FOR_IMPORT_RESET_RESULTS
}
export interface iListViewSchemaFetchAction {
    type: typeof LIST_VIEW_SCHEMA_FETCHED
    data: Array<any>
}
export interface iListViewSchemaErrorAction {
    type: typeof LIST_VIEW_SCHEMA_ERROR
}
export interface iRelatedSchemaClearAction {
    type: typeof RELATED_SCHEMA_CLEAR
}

export interface AnalyticsDashboardSuccessAction {
    type: typeof FETCH_ANALYTICS_DASHBOARDS_SUCCESS
    payload: {
        analyticsDashboards: Record<any, any>
    }
}

export interface iWeatherFetchUpdateAction {
    type: typeof FETCH_WEATHER_SUCCESSFUL
    data: any
    startDate: string
}

export interface iWeatherFetchErrorAction {
    type: typeof FETCH_WEATHER_FAILED
    error: any
}

export interface iWeatherInfoClearedAction {
    type: typeof WEATHER_INFORMATION_CLEARED
}

export interface iWeatherUpdatedWithExisting {
    type: typeof ADD_EXISTING_WEATHER_INFORMATION
    data: Record<string, any>
}

export interface iWeatherUpdatedWithExistingCustom {
    type: typeof ADD_EXISTING_CUSTOM_WEATHER_INFORMATION
    data: Record<string, any>
}

export interface iGuestFormShareSuccessAction {
    type: typeof FETCH_GUEST_FORM_SHARES_SUCCESS
    payload: {
        guestFormShares: GuestFormShare[]
    }
}

export type ListViewSchemaActionTypes = iListViewSchemaFetchAction | iListViewSchemaErrorAction
export type LookerActionTypes = LookerUrlSuccessAction | LookerUrlFailedAction | LookerUrlClearedAction
export type RelatedTransformActionTypes = iRelatedTransformsUpdateAction | iRelatedTransformsErrorAction
export type TransformForImportActionTypes =
    | iTransformsForImportUpdateAction
    | iTransformsForImportErrorAction
    | iTransformsForImportProcessDataAction
    | iTransformsForImportProcessDataSucceeded
    | iTransformsForImportProcessDataFailed
    | iTransformsForImportResetResults
export type RelatedSchemaActionTypes =
    | iRelatedSchemaFetchAction
    | iRelatedSchemaErrorAction
    | iRelatedSchemaClearAction
export type WeatherActionTypes =
    | iWeatherFetchUpdateAction
    | iWeatherFetchErrorAction
    | iWeatherInfoClearedAction
    | iWeatherUpdatedWithExisting
    | iWeatherUpdatedWithExistingCustom
export type GuestFormShareActionTypes = iGuestFormShareSuccessAction

// State types
export type tLookerUrlState = {
    looker_url: string | null
}
export type tAnalyticsDashboardsState = {
    analyticsDashboards: Record<any, any>
}
export type tRelatedTransformState = {
    relatedTransforms: Array<any> | null
}
export type tTransformForImportState = {
    transformsForImport: Array<Record<string, any>> | null
    importedStore: Record<string, any>
    importedStoreNames?: string[]
    message?: string
}
export type tListViewSchemaState = {
    schemas: Array<any> | null
}
export type tRelatedSchemaState = {
    relatedSchemas: Array<any> | null
}
export type tListView = {
    id: number
    related_list_view: tListView
    title: string
    use_nav_folder_paths: boolean
}

export type tSchemaPermissionsState = {
    schemaPermissions: Array<tSchemaPermission> | null
}

export type tGuestFormShares = {
    guestFormShares: GuestFormShare[]
}

export interface iSchemaPermissionsFetchErrorAction {
    type: typeof FETCH_SCHEMA_PERMISSIONS_FAILED
}

export interface iSchemaPermissionsFetchSuccessAction {
    type: typeof FETCH_SCHEMA_PERMISSIONS_SUCCESSFUL
    data: Array<tSchemaPermission>
}

export type SchemaPermissionsActionTypes = iSchemaPermissionsFetchErrorAction | iSchemaPermissionsFetchSuccessAction

export interface iCompanyAbsenceTypesErrorAction {
    type: "FETCH_COMPANY_ABSENCE_TYPES_FAILURE"
}

export interface iCompanyAbsenceTypesSuccessAction {
    type: "FETCH_COMPANY_ABSENCE_TYPES_SUCCESS"
    payload: Array<iCompanyAbsenceTypes>
}

export type CompanyAbsenceTypesActionTypes = iCompanyAbsenceTypesErrorAction | iCompanyAbsenceTypesSuccessAction

export interface iCohortErrorAction {
    type: "FETCH_COHORT_FAILURE"
}

export interface iCohortSuccessAction {
    type: "FETCH_COHORT_SUCCESS"
    payload: Array<iCohort>
}

export type CohortActionTypes = iCohortErrorAction | iCohortSuccessAction

export interface iCohortEmployeeErrorAction {
    type: "FETCH_COHORT_EMPLOYEE_FAILURE"
}

export interface iCohortEmployeeSuccessAction {
    type: "FETCH_COHORT_EMPLOYEE_SUCCESS"
    payload: Array<iCohortEmployee>
}

export type CohortEmployeeActionTypes = iCohortEmployeeErrorAction | iCohortEmployeeSuccessAction

export interface iAggridAction {
    highlightRow: number
    flashedRows: number[]
}

export type tTimekeepingStatusState = {
    timekeepingStatuses: iTimekeepingStatus[]
}

export interface iTimekeepingStatusErrorAction {
    type: "FETCH_TIMEKEEPING_STATUSES_FAILURE"
}

export interface iTimekeepingStatusSuccessAction {
    type: "FETCH_TIMEKEEPING_STATUSES_SUCCESS"
    payload: Array<iTimekeepingStatus>
}

export type TimekeepingStatusActionTypes = iTimekeepingStatusErrorAction | iTimekeepingStatusSuccessAction

export interface iSchemaVariantsFailure {
    type: "FETCH_SCHEMA_VARIANT_NAMES_FAILURE"
}

export interface iSchemaVariantsSuccess {
    type: "FETCH_SCHEMA_VARIANT_NAMES_SUCCESS"
    data: string[]
}

export interface iSetSchemaVariantName {
    type: "SET_SCHEMA_VARIANT_NAME"
    selectedVariant: string
}

export interface iVariantTemplatesFailure {
    type: "FETCH_VARIANT_TEMPLATES_FAILURE"
}

export interface iVariantTemplatesSuccess {
    type: "FETCH_VARIANT_TEMPLATES_SUCCESS"
    data: SchemaVariant[]
}

export interface iClearFormVariants {
    type: "CLEAR_SELECTED_AND_AVAILABLE_VARIANTS"
}

export type SchemaVariantActions =
    | iSchemaVariantsFailure
    | iSchemaVariantsSuccess
    | iSetSchemaVariantName
    | iVariantTemplatesFailure
    | iVariantTemplatesSuccess
    | iClearFormVariants
