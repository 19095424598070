import React from "react"
import { connect } from "react-redux"

import InnerRoute from "./inner-route"
import LoadingIndicator from "../components/loading-indicator"

export const OpenRoute = ({ loadingIndicator, ...props }) => (
    <div className="rmbx" data-testid={props.path + "/"}>
        <LoadingIndicator active={loadingIndicator.active} />
        <div className="main-content">
            <InnerRoute {...props} />
        </div>
    </div>
)

const mapStateToProps = state => ({
    loadingIndicator: state.loadingIndicator,
})

export default connect(mapStateToProps)(OpenRoute)
