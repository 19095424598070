import { isEmpty } from "lodash"
import * as API from "../api"
import { Thunk, TimekeepingEntry, tRequestTimekeepingSignatures } from "../common/types"
import { tContext } from "../components/custom-dashboards/types"
import { openTKSignTimecardModal } from "../components/modals/actions"
import { setNotificationMessage } from "../websockets/actions"
import { iEmployee } from "../cached-data/types"

export const REQUEST_TIMEKEEPING_SIGNATURES_SUCCESS = "REQUEST_TIMEKEEPING_SIGNATURES_SUCCESS"
export const REQUEST_TIMEKEEPING_SIGNATURES_FAILED = "REQUEST_TIMEKEEPING_SIGNATURES_FAILED"

export interface iRequestTimekeepingSignaturesSuccess {
    type: typeof REQUEST_TIMEKEEPING_SIGNATURES_SUCCESS
    payload: { url: string }
}

export interface iRequestTimekeepingSignaturesFailed {
    type: typeof REQUEST_TIMEKEEPING_SIGNATURES_FAILED
    payload: { error: Record<string, any> }
}

export type requestTimekeepingSignaturesActionTypes =
    | iRequestTimekeepingSignaturesSuccess
    | iRequestTimekeepingSignaturesFailed

export type requestTimekeepingSignaturesActionState = tRequestTimekeepingSignatures

export const requestTimekeepingSignaturesSuccess = (payload: {
    url: string
}): iRequestTimekeepingSignaturesSuccess => {
    return {
        type: REQUEST_TIMEKEEPING_SIGNATURES_SUCCESS,
        payload: payload,
    }
}

export const requestTimekeepingSignaturesFailed = (
    error: Record<string, any>
): iRequestTimekeepingSignaturesFailed => {
    return {
        type: REQUEST_TIMEKEEPING_SIGNATURES_FAILED,
        payload: { error: error },
    }
}

/**
 * Can be used to request multiple signatures via text. Will post notification based on successes/errors after all
 * text requests have been sent.
 * @param data data for the request like date range
 * @param eids list of employee ids from selection
 */
export const bulkRequestTimekeepingSignatures = (
    data: {
        start_date?: string
        end_date?: string
        has_signature: boolean
    },
    eids: number[],
    preFlightErrors: string[]
): Thunk => {
    return dispatch => {
        let errors: string[] = []
        const successes: string[] = []
        let totalResponses = 0
        dispatch(
            setNotificationMessage(
                // eslint-disable-next-line max-len
                "Requesting signatures. Please do not close this window.",
                "info"
            )
        )
        eids.forEach(empId => {
            API.requestSignature({ ...data, eid: empId, return_url: false, is_mobile: true })
                .then(response => {
                    dispatch(requestTimekeepingSignaturesSuccess(response))
                    if ("text_delivered" in response && response?.message) {
                        successes.push(response?.message)
                    }
                    totalResponses++
                })
                .catch(error => {
                    dispatch(requestTimekeepingSignaturesFailed(error))
                    const errorData = error?.response?.data ?? {}
                    if ("text_delivered" in errorData) {
                        errors.push(errorData?.message)
                    }
                    totalResponses++
                })
                .finally(() => {
                    // we want to wait until we have all the requests back - once we do - send a notification
                    if (totalResponses === eids.length) {
                        // combine the request errors with any preflight errors
                        errors = [...errors, ...preFlightErrors]
                        const divider = "\n• "
                        const allErrors = errors.join(divider)
                        if (!errors.length && successes.length) {
                            dispatch(
                                setNotificationMessage("All requests sent successfully", "success", {
                                    autoCloseSeconds: 4,
                                })
                            )
                        } else if (!successes.length && errors.length) {
                            dispatch(
                                setNotificationMessage(
                                    "All signature requests failed:" + divider + allErrors,
                                    "error"
                                )
                            )
                        } else {
                            dispatch(
                                setNotificationMessage(
                                    // eslint-disable-next-line max-len
                                    `${successes.length} requests succeeded. The following signature requests failed:` +
                                        divider +
                                        allErrors,
                                    "warning"
                                )
                            )
                        }
                    }
                })
        })
    }
}

export const requestTimekeepingSignatures = (data: {
    start_date: string
    end_date: string
    eid: number
    return_url: boolean
    is_mobile: boolean
    has_signature: boolean
}): Thunk => {
    return dispatch => {
        if (!data?.start_date || !data?.end_date) {
            setNotificationMessage("You must include a start/end date to request a signature", "error", {
                autoCloseSeconds: 4,
            })
            return
        }
        API.requestSignature(data)
            .then(response => {
                dispatch(requestTimekeepingSignaturesSuccess(response))
                if (data.return_url) dispatch(openTKSignTimecardModal())
                if ("text_delivered" in response && response?.message) {
                    dispatch(
                        setNotificationMessage(response.message, response.text_delivered ? "success" : "error", {
                            autoCloseSeconds: 4,
                        })
                    )
                }
            })
            .catch(error => {
                dispatch(requestTimekeepingSignaturesFailed(error))
                const errorData = error?.response?.data ?? {}
                if ("text_delivered" in errorData) {
                    dispatch(
                        // We expect text_delivered to be false, but go by the details in the errorData
                        setNotificationMessage(
                            errorData?.message ?? "Error with your request.",
                            errorData?.text_delivered ? "success" : "error",
                            {
                                autoCloseSeconds: 4,
                            }
                        )
                    )
                }
            })
    }
}

// Some helpers for the list view buttons

/**
 * Gets tooltip for cell actions for in-app signatures
 * @param context ag-grid context with information about selected
 * @returns Relevant tooltip based on settings. May be undefined if no conditions for tooltip met
 */
export const getSignatureTooltip = (context: tContext): string | undefined => {
    const isValidSelection = singleEmployeeIsSelected(context)
    if (!isValidSelection) return "Select an employee cell with timekeeping data to sign for a timecard"
}

/**
 * Gets all employee ids for employees who have text alerts on, and have not opted out of texting
 * @param timekeepingEntries list of timekeeping entries from selected cell
 * @param employees Mapping of employee ids and employee objects
 * @param existingErrors Optional. Can return preflight error messages for employees that can't be texted
 * @returns List if employee ids that have text-to-sign activated
 */
export const getTextToSignEmployeeIds = (
    timekeepingEntries: TimekeepingEntry[],
    employees: Record<number, iEmployee>,
    existingErrors?: string[]
): number[] => {
    const errors = existingErrors ?? []
    const uniqueEmployeeIds = getUniqueEmployeeIds(timekeepingEntries)
    return uniqueEmployeeIds.filter(empId => {
        const empRef = employees?.[empId]
        if (!empRef) return false
        let canSign = true
        if (!empRef.phone) {
            canSign = false
            errors.push(
                `${empRef.first_name} ${empRef.last_name} does not have a phone number registered with Rhumbix.`
            )
        } else if (empRef.text_opted_out_on) {
            canSign = false
            errors.push(`${empRef.first_name} ${empRef.last_name} has opted out of texts from Rhumbix.`)
        } else if (!empRef.text_alerts_ok) {
            canSign = false
            errors.push(`${empRef.first_name} ${empRef.last_name} has not signed up for texts from Rhumbix.`)
        }
        return canSign
    })
}

/**
 * Gets tooltip for cell actions for requesting text-to-sign signatures
 * @param context ag-grid context with information about selected
 * @returns Relevant tooltip based on settings. May be undefined if no conditions for tooltip met
 */
export const getSignatureRequestTooltip = (context: tContext): string | undefined => {
    const { selectedCellData, referenceableData } = context
    const { employees } = referenceableData ?? {}
    const { timekeepingEntries } = selectedCellData ?? {}
    if (isEmpty(employees) || !timekeepingEntries)
        return "Select an employee cell with timekeeping data to sign for a timecard"
    const eligibleEmps = getTextToSignEmployeeIds(timekeepingEntries, employees)
    if (!eligibleEmps?.length) {
        return "The selected employee(s) do not have text-to-sign configured"
    }
}

/**
 * Returns list of all employee ids in list of timkeeping entries
 * @param timekeepingEntries list of timekeeping entries from selected cell
 * @returns list of employee ids
 */
export const getUniqueEmployeeIds = (timekeepingEntries: TimekeepingEntry[]): number[] => {
    const selectedEmployeeIds = timekeepingEntries.map((entry: TimekeepingEntry) => entry.employee)
    return [...new Set(selectedEmployeeIds)]
}

/**
 * Looked at timekeeping info selected and returns if it is a single employee selection
 * @param context ag-grid context with information about selected
 * @returns boolean - true is only one employee in the selection
 */
export const singleEmployeeIsSelected = (context: tContext): boolean => {
    const { selectedCellData } = context
    const { timekeepingEntries } = selectedCellData ?? {}
    if (!timekeepingEntries) return false

    const selectedEmployeeIds = getUniqueEmployeeIds(timekeepingEntries)
    // if we have more than one employee - we can't sign
    if (selectedEmployeeIds?.length === 1) return true
    return false
}
