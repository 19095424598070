import { COMPANY_SIGNATURES_UPDATED, LOCKED_PERIOD_UPDATED } from "../actions/company-options-actions"

const initialState = {}

export function current_user(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_CURRENT_USER":
            return { ...state, ...action.data }
        case "LOGOUT":
            return initialState
        case LOCKED_PERIOD_UPDATED:
        case COMPANY_SIGNATURES_UPDATED:
            return { ...state, company_options: action.data }
        case "FETCH_EMPLOYEE_START_OF_WEEK_SUCCESSFUL":
            return { ...state, employee_start_of_week: action.data?.start_of_week }
        case "FECTH_EMPLOYEE_START_OF_WEEK_FAILED":
            return { ...state, employee_start_of_week: null }
        default:
            return state
    }
}

export function current_user_failed(state = initialState, action) {
    switch (action.type) {
        case "GET_CURRENT_USER_FAILED":
            return action.data
        case "LOGIN_BEGAN":
            return initialState
        case "UPDATE_CURRENT_USER":
            return initialState
        default:
            return state
    }
}
