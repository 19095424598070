import { calculateStartDate, calculateEndDate } from "../utils"
import { eDatePickerType, iMutableFilterState, tFilterActionCallbacks, tStartOfWeekIndex } from "../types"
import { isBefore } from "date-fns"

type tFilterDateChangeProps = {
    dates: { startDate: Date | null; endDate: Date | null }
    datePickerType: eDatePickerType
    setMultipleFilterValues: {
        (arg: iMutableFilterState): void
    }
    setFilterState: tFilterActionCallbacks
    setState?: boolean
    startOfWeekIndex: tStartOfWeekIndex
}

export const calculateAndSetFilterDates = (props: tFilterDateChangeProps): void => {
    const { dates, datePickerType, setMultipleFilterValues, setFilterState, setState, startOfWeekIndex } = props
    const startDate = dates.startDate
        ? calculateStartDate(dates.startDate, datePickerType, startOfWeekIndex)
        : calculateStartDate(new Date(), datePickerType, startOfWeekIndex)
    let endDate = calculateEndDate(startDate, dates.endDate, datePickerType, startOfWeekIndex)
    // if the selected endDate is before the start date
    // update the end date to match the start date so the filter works properly
    endDate = isBefore(endDate, startDate) ? startDate : endDate
    const dateFilterState = {
        startDate: startDate,
        endDate: endDate,
    }
    setMultipleFilterValues(dateFilterState)

    if (setState) {
        setFilterState(dateFilterState)
    }
}
