import _extend from "lodash/extend"

function WorkComponent(data) {
    _extend(this, data)
}
WorkComponent.prototype.totalMinutes = function () {
    return this.adjusted_minutes_st + this.adjusted_minutes_dt + this.adjusted_minutes_ot
}

export default WorkComponent
