import form from "./form"
import { adjustDatetimeValues } from "../common/ts-utils"

const initialState = {
    errors: {},
    startTime: adjustDatetimeValues(new Date(), { hours: 7, minutes: 0 }),
    endTime: adjustDatetimeValues(new Date(), { hours: 15, minutes: 0 }),
}

const workshiftForm = (state = initialState, action) => {
    if (action.form == "workshiftForm") {
        return form(state, action, initialState)
    } else {
        return state
    }
}

export default workshiftForm
