import _each from "lodash/each"
import _reduce from "lodash/reduce"
import { format } from "date-fns"
import * as API from "../api"
import { query_work_shifts, stopLoader, startLoader } from "./index"
import { convertTimezoneToUtc } from "../common/ts-utils"
import {
    closeEditWorkComponentModal,
    openWorkComponentUpdateFailedModal,
    closeAddWorkerModal,
} from "../components/modals/actions"
import { STORED_DATE_ONLY_FORMAT } from "../common/constants"

export function dailyReportHeaderEnter(id) {
    return {
        type: "DAILY_REPORT_HEADER_ENTER",
        id,
    }
}

export function dailyReportHeaderLeave() {
    return {
        type: "DAILY_REPORT_HEADER_LEAVE",
    }
}

export function toggleCostCodeDropdownForEmployee(id) {
    return {
        type: "TOGGLE_COST_CODE_DROPDOWN_FOR_EMPLOYEE",
        id,
    }
}

export function toggleEditMode(workShiftId) {
    return {
        type: "TOGGLE_EDIT_MODE",
        workShiftId,
    }
}

export function signature_opened(id) {
    return {
        type: "SIGNATURE_OPENED",
        id,
    }
}

export function signature_closed() {
    return {
        type: "SIGNATURE_CLOSED",
    }
}

// Save to server (old way, bulk editing will remove)
export function create_absence(data) {
    return dispatch => {
        return API.createAbsence(data).then(() => {
            dispatch(query_work_shifts())
        })
    }
}

export function newAbsence(data) {
    return {
        type: "ADD_ABSENCE_TO_WORKSHIFT",
        data,
    }
}

export function deleteAbsence(absence) {
    return dispatch => {
        //TODO(KP): old ws edit, update absence via api immediately
        if (absence.id) {
            return API.deleteAbsence(absence.id).then(() => {
                dispatch(query_work_shifts())
            })
        }
        //Delete local absence
        else {
            dispatch({
                type: "DELETE_ABSENCE_FROM_WORKSHIFT",
                absence,
            })
        }
    }
}

export function addCostCodeToWorkshift(projectCostCodeId) {
    return dispatch => {
        dispatch({
            type: "ADD_COST_CODE_TO_WORKSHIFT",
            projectCostCodeId,
        })
    }
}

export function addEmployeeToWorkshift(employeeId) {
    return dispatch => {
        dispatch(closeAddWorkerModal())
        dispatch({
            type: "ADD_EMPLOYEE_TO_WORKSHIFT",
            employeeId,
        })
    }
}

export function addWorkComponentToWorkshift(wsId, employeeWorkShiftId, data) {
    return dispatch => {
        dispatch(closeAddWorkerModal())
        dispatch({
            type: "ADD_WORK_COMPONENT_TO_WORKSHIFT",
            wsId,
            employeeWorkShiftId,
            data,
        })
    }
}

export function saveNewWorkComponent(workComponent) {
    return dispatch => {
        dispatch(closeEditWorkComponentModal())
        dispatch(startLoader())
        return API.createWorkComponent(workComponent)
            .then(() => {
                dispatch(query_work_shifts())
            })
            .catch(({ response }) => {
                dispatch(openWorkComponentUpdateFailedModal(response.data))
            })
            .finally(() => dispatch(stopLoader()))
    }
}

export function saveWorkComponentEdit(workComponent) {
    return dispatch => {
        dispatch(closeEditWorkComponentModal())
        dispatch(startLoader())
        return API.updateWorkComponent(workComponent)
            .then(() => {
                dispatch(query_work_shifts())
            })
            .catch(({ response }) => {
                dispatch(openWorkComponentUpdateFailedModal(response.data))
            })
            .finally(() => {
                dispatch(stopLoader())
            })
    }
}

export function changeCostCode(id, data) {
    return dispatch => {
        dispatch(startLoader())
        API.changeCostCode(id, data)
            .then(() => {
                dispatch(query_work_shifts())
            })
            .finally(() => {
                dispatch(stopLoader())
            })
    }
}

export function changeCostCodeInWorkshift(fromCostCode, toCostCode) {
    return {
        type: "CHANGE_COST_CODE_IN_WORKSHIFT",
        fromCostCode,
        toCostCode,
    }
}

// region ADD WORKER ACTIONS

export function createWorkshift(startTime, endTime) {
    return {
        type: "CREATE_WORKSHIFT",
        startTime: startTime,
        endTime: endTime,
    }
}

export function duplicateWorkshift(workshiftId, newDate) {
    return dispatch => {
        dispatch(startLoader())
        return API.duplicateWorkshift(workshiftId, newDate)
            .then(() => {
                dispatch(query_work_shifts())
            })
            .catch(({ response }) => {
                if (response.status == 403) {
                    dispatch(openWorkComponentUpdateFailedModal(response.data))
                } else if (response.status == 404) {
                    // process 404 error - either workshift id or worker id were invalid
                } else if (response.status == 409) {
                    // process biz logic conflict error
                } else if (response.status == 500) {
                    // oh noes, something crashed on the server
                }
            })
            .finally(() => {
                dispatch(stopLoader())
            })
    }
}

export function saveNewWorkshift(workShift) {
    return (dispatch, getState) => {
        const workShiftPayload = {}
        workShiftPayload["employee_work_shifts"] = []
        workShiftPayload["start_time"] = convertTimezoneToUtc(workShift.startTime)
        workShiftPayload["end_time"] = convertTimezoneToUtc(workShift.endTime)
        workShiftPayload["notes"] = workShift.notes
        workShiftPayload["absences"] = workShift.absences
        workShiftPayload["date"] = format(workShift.date, STORED_DATE_ONLY_FORMAT)
        workShiftPayload["foreman"] = getState().current_user.employee_id

        _each(workShift.employee_work_shifts.ewsIds, ewsId => {
            const ews = workShift.employee_work_shifts[ewsId]

            const wcs = _reduce(
                ews.work_components.workComponentIds,
                (acc, wcId) => {
                    acc.push(ews.work_components[wcId])
                    return acc
                },
                []
            )

            workShiftPayload["employee_work_shifts"].push(Object.assign({}, ews, { work_components: wcs }))
        })

        dispatch({
            type: "SAVE_NEW_WORKSHIFT",
            workShiftPayload,
        })

        return API.createWorkshift(workShiftPayload).then(() => {
            dispatch(query_work_shifts())
        })
    }
}

export function saveWorkerToWorkShift(workerId, workShift) {
    return dispatch => {
        dispatch(startLoader())
        return API.addWorkerToShift(workShift, workerId)
            .then(() => {
                dispatch(query_work_shifts())
            })
            .catch(({ response }) => {
                // TODO(JG): We are our error modals!!
                if (response.status === 404) {
                    // process 404 error - either workshift id or worker id were invalid
                } else if (response.status === 409) {
                    // process biz logic conflict error
                } else if (response.status === 500) {
                    // oh noes, something crashed on the server
                }
            })
            .finally(() => {
                dispatch(stopLoader())
            })
    }
}

export function cancelWorkShiftEdit() {
    return {
        type: "CLEAR_WORKSHIFT",
    }
}
// endregion

// region CUSTOM USER LINKS

export function getCustomUserLinks() {
    return dispatch => {
        return API.getCustomUserLinks().then(function (data) {
            dispatch(customUserLinksUpdated(data))
        })
    }
}

export function customUserLinksUpdated(data) {
    return {
        type: "CUSTOM_USER_LINKS_UPDATED",
        data,
    }
}

// endregion

export function deleteWorkshift(id) {
    return dispatch => {
        dispatch(startLoader())
        return API.deleteWorkshift(id)
            .catch(({ response }) => {
                if (response.status == 403) {
                    dispatch(openWorkComponentUpdateFailedModal(response.data))
                }
            })
            .then(() => dispatch(toggleEditMode(null)))
            .then(() => dispatch(query_work_shifts()))
            .finally(() => dispatch(stopLoader()))
    }
}
