import React from "react"
import { Sparklines, SparklinesReferenceLine } from "react-sparklines"
import { SparklinesLine, SparklinesSpots } from "../../custom-sparklines"
import { budgetUnitRateValueGetter } from "../../../common/ag-grid-value-getters"

const SparkLineRenderer = props => {
    const sparklineData = []
    for (let i = props.data["week_quantities"].length; i--; ) {
        const quantity = props.data["week_quantities"][i]
        const hours = props.data["week_hours"][i]
        if (hours > 0) {
            sparklineData.push(quantity / hours)
        } else {
            sparklineData.push(0)
        }
    }
    const budget = budgetUnitRateValueGetter(props)

    // sparkline y-axis values are between 0 and 59 *when margin=0*, so figure out where the budget ref line fits in
    const margin = 3
    const maxPerf = Math.max(...sparklineData)
    const minPerf = Math.min(...sparklineData)
    let lineColor = "grey"
    let mainLineStyle = { fill: "none" }
    const refLineStyle = {
        stroke: "black",
        strokeOpacity: 1,
        strokeDasharray: "2, 2",
    }
    let refLineValue = 1000
    if (typeof budget === "number") {
        if (budget <= minPerf) {
            lineColor = "green"
            mainLineStyle = { fill: "green", fillOpacity: "0.15" }
        } else if (budget >= maxPerf) {
            lineColor = "red"
            mainLineStyle = {
                fill: "red",
                fillOpacity: "0.15",
                fillInvert: true,
            }
        } else {
            const scale = (maxPerf - minPerf) / (59 - margin)
            refLineValue = 59 - margin - (budget - minPerf) / scale
        }
    } else {
        lineColor = "orange"
    }

    return (
        <div>
            <Sparklines data={sparklineData} margin={margin}>
                <SparklinesLine color={lineColor} style={mainLineStyle} />
                <SparklinesReferenceLine type="custom" value={refLineValue} style={refLineStyle} />
                <SparklinesSpots budget={budget} size={3} />
            </Sparklines>
        </div>
    )
}

export default SparkLineRenderer
