import { loadAllEntities } from "../../../cached-data/actions"
import { tProject } from "../../../cached-data/types"
import { iCurrentUser, tFeatures, Thunk } from "../../../common/types"
import { stringColDefCreator, buttonColDefCreator } from "./standard-col-def-creators"
import { ManagePicklistAccessButton } from "../picklists/ManagePicklistAccessButton"
import { LinkToPicklistButton } from "../picklists/LinkToPicklistButton"
import { createModifierAnnotationColDefs } from "../../../tk-modifiers/utils"
import { getFlagEnabled } from "../../../getFlagValue"
import MultiChoiceCellEditor from "../cell-editors/multi-choice-cell-editor"
import LockableOptionListRenderer from "../cell-renderers/lockable-option-list-renderer"
import { timekeepingModifierRoleListTooltip } from "../../../common/ag-grid-custom-tooltip-formatters"

export const getTimekeepingModifiersDashboardSettings = (
    featureFlags: tFeatures,
    currentProject: tProject,
    currentUser: iCurrentUser
) => {
    const { user_role } = currentUser
    const canManage = user_role === "ADMIN"
    const settings = {
        tableName: "Timekeeping Modifiers",
        navId: "timekeepingModifiers",
        resources: ["timekeepingModifiersForDashboard"],
        filters: [],
        additionalQueryParams: {
            include_annotations: true,
        },
        initAction: (): Thunk => dispatch => {
            dispatch(loadAllEntities("companyGroups"))
            dispatch(loadAllEntities("projects", { forceBigPageSize: true }))
        },
        colDefs: [
            stringColDefCreator({
                field: "/name",
                headerName: "Name",
                sort: "asc",
            }),
            stringColDefCreator({
                editable: canManage,
                field: "/description",
                headerName: "Description",
                // TODO: Un-hide column when the backend supports the description field:
                // https://rhumbix.atlassian.net/browse/WA-7640
                hide: true,
                width: 300,
            }),
            ...createModifierAnnotationColDefs(),
            {
                headerName: "Allow New Values",
                field: "/can_field_add_items",
                hide: !getFlagEnabled("WA-8168-field-added-picklist-items"),
                editable: (params: any) => {
                    // Only custom modifiers allow editing of the role list
                    return params.data?.standard_tk_modifier_type === null
                },
                valueGetter: (params: any) => {
                    if (params.data?.can_field_add_items) {
                        return params.data?.can_field_add_items.sort()
                    }
                    return []
                },
                cellRenderer: LockableOptionListRenderer((data: any) => {
                    // Standard modifiers should be locked
                    return data?.standard_tk_modifier_type !== null
                }),
                cellEditor: MultiChoiceCellEditor,
                cellEditorPopup: true,
                cellEditorParams: {
                    choices: ["ADMIN", "FOREMAN", "OFFICE_STAFF", "PAYROLL_ADMIN", "PM"].map(role => ({
                        label: role.replace(/_/g, " "),
                        value: role,
                    })),
                },
                customTooltip: timekeepingModifierRoleListTooltip,
            },
            {
                ...buttonColDefCreator({ width: 150 }),
                cellRenderer: LinkToPicklistButton,
                headerName: "Picklist",
            },
            {
                ...buttonColDefCreator({ width: 150 }),
                cellRenderer: ManagePicklistAccessButton("picklist"),
                headerName: "Actions",
                hide: !canManage,
            },
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: false,
            },
        },
        otherSettings: {
            enableSearchBar: true,
        },
    }

    return settings
}

export const getGroupsTkModifierTabSettings = (
    featureFlags: tFeatures,
    currentProject: tProject,
    currentUser: iCurrentUser
) => {
    const base = getTimekeepingModifiersDashboardSettings(featureFlags, currentProject, currentUser)
    // Filter out any unnecessary columns
    const groupsDashboardExcludedColumns = ["Actions"]
    base.colDefs = base.colDefs.filter(
        colDef => colDef.headerName && !groupsDashboardExcludedColumns.includes(colDef.headerName)
    )

    // Add a column showing whether the selected group has auto-add enabled,
    // and insert it to the left of the "Groups with Auto-Add" column
    base.colDefs.splice(
        base.colDefs.findIndex(colDef => colDef.headerName === "Groups with Auto-Add"),
        0,
        stringColDefCreator({
            field: "/root_group_has_auto_add",
            headerName: "Auto-Add Enabled",
            valueGetter: ({ data }) => (data.root_group_has_auto_add ? "Yes" : "No"),
            width: 200,
        })
    )

    // Make everything read-only
    base.colDefs.forEach(colDef => (colDef.editable = false))

    return base
}
