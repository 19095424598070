import React from "react"
import { useLocation } from "react-router"
import LoadingIndicator from "./components/loading-indicator"
import qs from "qs"
import { setCookie } from "./common/ts-utils"

// The SSO authentication process kicks off with a request to a third-party provider
// who returns an authentication code and other parameters. That request comes back to
// this component, which sets up the next step in the process by populating the
// code and other parameters in a cookie that another component (such as LoginForm)
// has been waiting for
const OidcRedirectHandler = () => {
    const location = useLocation()

    if (location.pathname.includes("oidc-mobile-callback")) {
        const hostnameParts = window.location.hostname.toLowerCase().split(".")
        let appIdHost = "" // Prod/platform has no additional appId parts
        const userAgent = navigator.userAgent || navigator.vendor || window.opera
        // only do this check for Android
        if (/android/i.test(userAgent) && hostnameParts.length) {
            const firstPart = hostnameParts[0]
            if (!["platform", "prod", "localhost"].includes(firstPart)) {
                // Prod Test breaks the pattern since the server is testing.rhumbix.com, but the app id is .pt
                appIdHost = firstPart === "testing" ? ".pt" : `.${firstPart}`
            }
        }
        window.location.href = `com.rhumbix.rn${appIdHost}.auth://auth${location.search}`
    } else {
        // Get the return values from the auth redirect
        let qsParams

        if (location.search) {
            qsParams = qs.parse(location.search, { ignoreQueryPrefix: true })
        } else if (location.hash) {
            qsParams = qs.parse(location.hash.substring(1), { ignoreQueryPrefix: true })
        }

        setCookie("rmbx-auth", JSON.stringify(qsParams))

        window.close()
    }
    return (
        <div>
            <LoadingIndicator active={true} />
        </div>
    )
}

export default OidcRedirectHandler
