import { AsyncThunk } from "../common/types"
import { iHideNotificationAction, iShowNotificationAction } from "./types"

/**
 * Shows the flash message for a given amount of time (in miliseconds)
 *
 * Depends on the redux-thunk middleware
 */
export const showNotificationWithTimeout = (
    message: string,
    time: number
): AsyncThunk<iHideNotificationAction> => dispatch => {
    dispatch(showNotification(message))
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(dispatch(hideNotification()))
        }, time)
    })
}

/**
 * Displays the flash message
 */
export const showNotification = (message: string): iShowNotificationAction => ({
    type: "SHOW_NOTIFICATION",
    payload: {
        message,
    },
})

/**
 * Hides the flash message
 */
export const hideNotification = (): iHideNotificationAction => ({
    type: "HIDE_NOTIFICATION",
    payload: {
        message: "",
    },
})
