import React, { Component } from "react"
import { connect } from "react-redux"
import AsyncSelect from "react-select-v1/lib/Async"
import memoize from "memoize-one"

import SelectorFilter from "../SelectorFilter"
import { tRoute } from "../../router/types"
import { referenceableDataSelector } from "../../selectors"
import { referenceableValueKeyOverrides } from "../../common/constants"
import { referenceableToRelatedFilters } from "../constants"
import { referenceablesToValueFormatters } from "../../common/referenceable-value-formatters"
import { tFilterKey, tFilterKeyToQueryParam, tFilterResourceName, tFilterState } from "../types"
import { pendingFiltersSelector } from "../selectors"
import { isApplicableFilterValue } from "../utils"

type tProps = {
    className: string
    clearable?: boolean
    clearedBy?: tFilterKey[]
    context: {
        referenceableData: any
    }
    filters: tFilterState
    isDesignSystem?: boolean
    isSelectorV3?: boolean
    label: string
    multiselect?: boolean
    primaryKey: tFilterKey
    resourceName: tFilterResourceName
    valueKey?: string
    route: tRoute
    required?: boolean
    ignoreRelatedFilters?: Array<string>
    extraSearchFilters?: {
        [key: string]: string
    }
}

type States = {
    shouldClearCache: boolean
}

interface iReferenceableSelectorFilter<T> extends React.Component<T> {
    innerRef: React.RefObject<typeof AsyncSelect>
}

// Wraps the Selector (with a couple other wrappers in between), for use by filters
// that load and list referenceables (e.g. the Projects filter).
class ReferenceableSelectorFilter
    extends Component<tProps, States>
    implements iReferenceableSelectorFilter<tProps> {
    constructor(props: tProps) {
        super(props)
        this.state = {
            shouldClearCache: false,
        }
    }

    // TODO: Once  we don't have to support the v1
    // selector, we should be able to remove all the logic around setting the innerRef
    // and passing it to the Selector
    innerRef: React.RefObject<typeof AsyncSelect> = React.createRef()

    /** Clear React Select cached search results. */
    clearCache = () => {
        if (this.innerRef.current?.clearCache) {
            this.innerRef.current.clearCache()
        } else this.setState({ shouldClearCache: true })
    }

    /**
     * Update clearCache state.
     * @param {boolean} shouldClearCache True to set shouldClearCache state to true.
     */
    updateClearCache = (shouldClearCache: boolean) => this.setState({ shouldClearCache })

    componentDidUpdate(prevProps: tProps) {
        // if any of the related filters have changed, we will need to reload
        const refToRelated = referenceableToRelatedFilters.get(this.props.resourceName)

        const relatedFilterList = refToRelated ? refToRelated.relatedFilters : []

        if (
            relatedFilterList.some(
                relatedFilter =>
                    this.props.filters[relatedFilter.filterKey] !== prevProps.filters[relatedFilter.filterKey]
            )
        ) {
            this.clearCache()
        }
    }

    getRelatedFilters = memoize(
        (filters: tFilterState): tFilterKeyToQueryParam => {
            const refToRelated = referenceableToRelatedFilters.get(this.props.resourceName)

            // referenced from referenceable-selector-cell-editor.jsx
            // ignores related filters that are specified from the ignoreRelatedFilters prop
            const globalRelatedFilterList = refToRelated?.relatedFilters || []
            const relatedFieldExclusions = this.props.ignoreRelatedFilters || []
            const relatedFilterList = globalRelatedFilterList.filter(
                ele => !relatedFieldExclusions.includes(ele.filterKey)
            )

            return relatedFilterList.reduce((queryParams, relatedFilter) => {
                return {
                    ...queryParams,
                    [relatedFilter.queryParam]: filters[relatedFilter.filterKey],
                }
            }, {})
        }
    )

    getSearchFiltersForCell = () => ({
        ...this.getRelatedFilters(this.props.filters),
        ...this.props.extraSearchFilters,
    })

    // Memoized to prevent unnecessary rerenders. For performance reasons, but also
    // because we don't want AsyncSelect to rerender when the search results return. The
    // search results update referenceableData, and rerendering AsyncSelect clears the
    // user's search input.
    // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
    getSelectionValueFromFilterValue = memoize((filterValue, isMulti) => {
        if (!isApplicableFilterValue(filterValue)) {
            return undefined
        }

        const resourceMap = this.props.context.referenceableData[this.props.resourceName]

        if (isMulti && Array.isArray(filterValue)) {
            return filterValue.map(item => resourceMap[item])
        } else if (isMulti) {
            return [resourceMap[filterValue]]
        } else if (Array.isArray(filterValue)) {
            return resourceMap[filterValue[0]]
        }

        return resourceMap[filterValue]
    })

    getSelectionFromFilterValue = (filterValue: number | Array<number>) =>
        this.getSelectionValueFromFilterValue(filterValue, !!this.props.multiselect)

    render() {
        const valueFormatters = referenceablesToValueFormatters[this.props.resourceName] || {}
        // I can't find any reasonable way to access this dictionary if the string is a valid key
        // and otherwise default to "id" without TS throwing an error.
        const anyTypedValueKeyOverrides: any = referenceableValueKeyOverrides as any
        const valueKey = anyTypedValueKeyOverrides[this.props.resourceName]
            ? anyTypedValueKeyOverrides[this.props.resourceName]
            : "id"
        let placeholderText
        if (this.props.required) placeholderText = "Selection Required"
        return (
            <SelectorFilter
                className={this.props.className}
                clearable={this.props.clearable}
                clearedBy={this.props.clearedBy}
                clearText={"Show All"}
                getSelectionFromFilterValue={this.getSelectionFromFilterValue}
                innerRef={this.innerRef}
                isDesignSystem={this.props.isDesignSystem}
                isSelectorV3={this.props.isSelectorV3}
                label={this.props.label}
                multiselect={this.props.multiselect}
                placeholder={placeholderText}
                prefixFormatter={valueFormatters.prefixFormatter}
                primaryKey={this.props.primaryKey}
                primarySubtitleFormatter={valueFormatters.primarySubtitleFormatter}
                relatedFilters={this.getSearchFiltersForCell()}
                resourceName={this.props.resourceName}
                secondarySubtitleFormatter={valueFormatters.secondarySubtitleFormatter}
                shouldClearCache={this.state.shouldClearCache}
                titleFormatter={valueFormatters.titleFormatter}
                updateClearCache={this.updateClearCache}
                valueFormatter={valueFormatters.valueFormatter}
                valueKey={valueKey}
            />
        )
    }
}

const mapStateToProps = (state: any, ownProps: tProps) => ({
    filters: {
        schemaName: ownProps.route.listView ? ownProps.route.listView.schema_name : null,
        ...pendingFiltersSelector(state),
    },
    context: {
        referenceableData: referenceableDataSelector(state),
    },
})

export default connect(mapStateToProps)(ReferenceableSelectorFilter)
