import WorkComponent from "../reducers/work_component"

import _each from "lodash/each"
import _extend from "lodash/extend"
import _isNil from "lodash/isNil"
import _omit from "lodash/omit"
import _pull from "lodash/pull"
import _reject from "lodash/reject"
import _without from "lodash/without"
import { getAsDate, adjustDatetimeValues } from "../common/ts-utils"
import { getHours, getMinutes, getSeconds } from "date-fns"

function mapWorkComponentWithKeys(data) {
    const workComponents = {
        workComponentIds: [],
    }
    data.forEach(function (wc) {
        workComponents.workComponentIds.push(wc.id)
        workComponents[wc.id] = new WorkComponent(wc)
    })
    return Object.assign({}, workComponents)
}

function EmployeeWorkShift(data) {
    _extend(this, data)
    this.work_components = mapWorkComponentWithKeys(this.work_components)
}

EmployeeWorkShift.prototype.edited = function () {
    if (
        _isNil(this.worker_straight_minutes) &&
        _isNil(this.worker_over_minutes) &&
        _isNil(this.worker_double_minutes)
    ) {
        return false
    }
    const sm = this.worker_straight_minutes ? this.worker_straight_minutes : 0
    const om = this.worker_over_minutes ? this.worker_over_minutes : 0
    const dm = this.worker_double_minutes ? this.worker_double_minutes : 0

    let wcTotalSm = 0
    let wcTotalOm = 0
    let wcTotalDm = 0

    this.work_components.workComponentIds.forEach(id => {
        wcTotalSm += this.work_components[id].adjusted_minutes_st
        wcTotalOm += this.work_components[id].adjusted_minutes_ot
        wcTotalDm += this.work_components[id].adjusted_minutes_dt
    })

    return !!(sm != wcTotalSm || om != wcTotalOm || dm != wcTotalDm)
}

EmployeeWorkShift.prototype.workComponentOfCostCode = function (code) {
    const workComponents = this.work_components
    let workComponent = null
    workComponents.workComponentIds.forEach(function (id) {
        if (
            (workComponents[id].cost_code && workComponents[id].cost_code.id == code) ||
            workComponents[id].project_cost_code == code
        ) {
            workComponent = workComponents[id]
        }
    })
    return workComponent
}

EmployeeWorkShift.prototype.adjustedMinutesST = function () {
    const workComponents = this.work_components
    return workComponents.workComponentIds.reduce((sum, id) => {
        return sum + workComponents[id].adjusted_minutes_st
    }, 0)
}

EmployeeWorkShift.prototype.adjustedMinutesOT = function () {
    return this.work_components.workComponentIds.reduce((sum, id) => {
        return sum + this.work_components[id].adjusted_minutes_ot
    }, 0)
}

EmployeeWorkShift.prototype.adjustedMinutesDT = function () {
    return this.work_components.workComponentIds.reduce((sum, id) => {
        return sum + this.work_components[id].adjusted_minutes_dt
    }, 0)
}

EmployeeWorkShift.prototype.totalMinutes = function () {
    return this.adjustedMinutesST() + this.adjustedMinutesOT() + this.adjustedMinutesDT()
}

function mapEWSWithKeys(data) {
    const employeeWorkShifts = {
        ewsIds: [],
    }
    data.forEach(function (ews) {
        employeeWorkShifts.ewsIds.push(ews.id)
        employeeWorkShifts[ews.id] = new EmployeeWorkShift(ews)
    })
    return Object.assign({}, employeeWorkShifts)
}

function WorkShift(data) {
    _extend(this, data)
    this.employee_work_shifts = mapEWSWithKeys(this.employee_work_shifts)
}

WorkShift.prototype.employeeWorkShiftOfForeman = function () {
    const ids = this.employee_work_shifts.ewsIds.filter(id => {
        return (
            this.employee_work_shifts[id].foreman_profile.user_id ==
            this.employee_work_shifts[id].worker_profile.user_id
        )
    })
    return this.employee_work_shifts[ids[0]]
}

WorkShift.prototype.workComponentsOfCostCode = function (code) {
    return this.employee_work_shifts.ewsIds
        .map(id => this.employee_work_shifts[id].workComponentOfCostCode(code))
        .filter(wc => wc != undefined)
}

WorkShift.prototype.adjustedMinutesSTOfCostCode = function (code) {
    return this.workComponentsOfCostCode(code).reduce((sum, wc) => {
        return sum + wc.adjusted_minutes_st
    }, 0)
}

WorkShift.prototype.adjustedMinutesOTOfCostCode = function (code) {
    return this.workComponentsOfCostCode(code).reduce((sum, wc) => {
        return sum + wc.adjusted_minutes_ot
    }, 0)
}

WorkShift.prototype.adjustedMinutesDTOfCostCode = function (code) {
    return this.workComponentsOfCostCode(code).reduce((sum, wc) => {
        return sum + wc.adjusted_minutes_dt
    }, 0)
}

WorkShift.prototype.totalMinutesOfCostCode = function (code) {
    return (
        this.adjustedMinutesSTOfCostCode(code) +
        this.adjustedMinutesOTOfCostCode(code) +
        this.adjustedMinutesDTOfCostCode(code)
    )
}

function mapWorkShiftsWithKeys(data) {
    // eslint-disable-next-line no-shadow
    const workShifts = {
        workShiftIds: [],
    }
    data.forEach(function (ws) {
        workShifts.workShiftIds.push(ws.id)
        workShifts[ws.id] = new WorkShift(ws)
    })
    return Object.assign({}, workShifts)
}

const initialState = {
    workShiftIds: [],
}

function addNewEWS(employeeId, employee_work_shifts) {
    const newEWS = {}
    const newEWSId = "_" + employeeId

    newEWS[newEWSId] = new EmployeeWorkShift({
        state: "CLOSED",
        employee: employeeId,
        third_meal_minutes: 0,
        first_meal_minutes: 30,
        third_break_minutes: 0,
        first_break_minutes: 10,
        second_meal_minutes: 0,
        work_components: [],
    })
    return Object.assign(
        {},
        employee_work_shifts,
        { ewsIds: (employee_work_shifts.ewsIds || []).concat(newEWSId) },
        newEWS
    )
}

function addNewWC(newWCData, wcs) {
    const newWC = {}
    const newWCId = "_" + newWCData.project_cost_code
    newWC[newWCId] = new WorkComponent(newWCData)

    newWC["workComponentIds"] = wcs.workComponentIds || []
    if (!(newWCId in wcs)) {
        newWC["workComponentIds"] = newWC["workComponentIds"].concat(newWCId)
    }

    return Object.assign({}, wcs, newWC)
}

export default function workShifts(state = initialState, action) {
    let absences, newWS, newEWS, newCCs, ews, wcs
    switch (action.type) {
        case "CREATE_WORKSHIFT":
            return Object.assign({}, state, {
                new: new WorkShift({
                    date: action.startTime,
                    startTime: adjustDatetimeValues(getAsDate(action.startTime), {
                        hours: getHours(action.startTime),
                        minutes: getMinutes(action.startTime),
                        seconds: getSeconds(action.startTime),
                    }),
                    endTime: adjustDatetimeValues(getAsDate(action.endTime), {
                        hours: getHours(action.endTime),
                        minutes: getMinutes(action.endTime),
                        seconds: getSeconds(action.endTime),
                    }),
                    employee_work_shifts: [],
                    adjustedMinutesSTOfCostCode: () => 0,
                    adjustedMinutesOTOfCostCode: () => 0,
                    adjustedMinutesDTOfCostCode: () => 0,
                    totalMinutesOfCostCode: () => 0,
                    notes: [],
                    absences: [],
                    newCCs: [],
                }),
            })

        case "ADD_COST_CODE_TO_WORKSHIFT":
            newCCs = state["new"].newCCs.concat(action.projectCostCodeId)
            newWS = Object.assign({}, state["new"], { newCCs: newCCs })
            return Object.assign({}, state, { new: newWS })

        case "CHANGE_COST_CODE_IN_WORKSHIFT":
            const ws = state["new"]
            newCCs = _without(ws.newCCs, action.fromCostCode).concat(action.toCostCode)

            _each(ws.employee_work_shifts.ewsIds, id => {
                ews = ws.employee_work_shifts[id]
                wcs = ews.work_components

                //Make a new WC for the new CC
                const newWC = {}

                _each(wcs, wc => {
                    if (wc.project_cost_code == action.fromCostCode) {
                        //assign the time from the previous WC to the new one
                        newWC["adjusted_minutes_st"] = wc.adjusted_minutes_st
                        newWC["adjusted_minutes_ot"] = wc.adjusted_minutes_ot
                        newWC["adjusted_minutes_dt"] = wc.adjusted_minutes_dt
                        newWC["project_cost_code"] = action.toCostCode

                        //break loop once we find it
                        return false
                    }
                })

                //remove the previous WC from the EWS for the CC that was swapped out
                const wcIdToRemove = "_" + action.fromCostCode
                const editedWC = _omit(ews.work_components, [wcIdToRemove])
                editedWC.workComponentIds = _pull(editedWC.workComponentIds, wcIdToRemove)
                _extend(ews, { work_components: addNewWC(newWC, editedWC) })
            })

            newEWS = Object.assign({}, state["new"].employee_work_shifts)
            newWS = Object.assign({}, state["new"], { employee_work_shifts: newEWS }, { newCCs: newCCs })
            return Object.assign({}, state, { new: newWS })

        case "ADD_EMPLOYEE_TO_WORKSHIFT":
            newWS = Object.assign({}, state["new"], {
                employee_work_shifts: addNewEWS(action.employeeId, state["new"].employee_work_shifts),
            })
            return Object.assign({}, state, { new: newWS })

        case "ADD_WORK_COMPONENT_TO_WORKSHIFT":
            ews = state["new"].employee_work_shifts[action.employeeWorkShiftId]
            _extend(ews, {
                work_components: addNewWC(action.data, ews.work_components),
            })

            newEWS = Object.assign({}, state["new"].employee_work_shifts)
            newWS = Object.assign({}, state["new"], {
                employee_work_shifts: newEWS,
            })
            return Object.assign({}, state, { new: newWS })

        case "ADD_ABSENCE_TO_WORKSHIFT":
            absences = state["new"].absences.concat(action.data)
            newWS = Object.assign({}, state["new"], { absences: absences })
            return Object.assign({}, state, { new: newWS })

        case "DELETE_ABSENCE_FROM_WORKSHIFT":
            absences = _reject(state["new"].absences, ["employee", action.absence.employee])
            newWS = Object.assign({}, state["new"], { absences: absences })
            return Object.assign({}, state, { new: newWS })

        case "WORK_SHIFTS_CHANGED":
            return mapWorkShiftsWithKeys(action.data)

        case "CLEAR_WORKSHIFT":
            delete state.new
            return Object.assign({}, state)

        case "SAVE_NEW_WORKSHIFT":
            return Object.assign({}, state, {
                new: Object.assign({}, state["new"], { sending: true }),
            })

        default:
            return state
    }
}
