import React, { PureComponent } from "react"

import "../FilterController/FilterController.less"

type tProps = {
    className?: string
}

class FilterError extends PureComponent<tProps> {
    render() {
        const className = this.props.className ? `${this.props.className} filter-error` : "filter-error"

        return <div className={className}>FILTER ERROR</div>
    }
}

export default FilterError
