import React from "react"

type tProps = {
    arrow?: "left" | "right"
    buttonSize?: string
    color?: string
    continueButtonClass?: string
    icon?: string
    image?: string
    imageFormat?: string
    largeIcon?: string
    positionClass?: string
    text: string
    tooltip?: string
    type?: "button" | "submit" | "reset" | undefined
}

class Button extends React.Component<tProps & React.HTMLProps<HTMLButtonElement>> {
    leftIcon = (): JSX.Element | undefined => {
        if (this.props.arrow && this.props.arrow === "left") {
            return (
                <div>
                    <i className="arrow-l btn-icon-left" />
                </div>
            )
        }

        if (this.props.arrow && this.props.arrow === "right") {
            return (
                <div>
                    <span className="btn-icon-left blank-icon" />
                </div>
            )
        }

        if (this.props.icon) {
            let className = this.props.icon + " btn-icon-left btn-icon"
            if (this.props.largeIcon) {
                className += " large-icon"
            }
            return <i className={className} />
        }

        if (this.props.image) {
            const className = this.props.image + " btn-icon-left btn-icon"
            const imgFormat = this.props.imageFormat ? this.props.imageFormat : "png"
            const imgSource = `/static/images/${this.props.image}.${imgFormat}`

            return <img className={className} src={imgSource} alt="" />
        }
    }

    rightIcon = (): JSX.Element | undefined => {
        if (this.props.icon || !this.props.arrow) {
            return
        }

        if (this.props.arrow === "left") {
            return <span className="btn-icon-right blank-icon" />
        }

        if (this.props.arrow === "right") {
            return (
                <div>
                    <i className="arrow-r btn-icon-right" />
                </div>
            )
        }
    }

    text = (): JSX.Element => {
        let className = ""
        if (this.props.largeIcon) {
            className = "large-icon-spacing"
        }
        return <div className={className}>{this.props.text}</div>
    }

    render(): JSX.Element {
        const supportedButtonSizes = ["small", "medium"]
        const supportedColorSchemes = ["green", "blue", "dark-blue", "red", "dark-gray", "rhumbix-yellow"]
        const className = []

        if (this.props.color && supportedColorSchemes.includes(this.props.color)) {
            className.push(`${supportedColorSchemes.find(el => el === this.props.color)}-btn`)
        } else {
            // we don't care if this is null or undefined, it gets coerced to an empty string
            className.push(this.props.color)
        }

        if (this.props.buttonSize && supportedButtonSizes.includes(this.props.buttonSize)) {
            className.push(`btn-${supportedButtonSizes.find(el => el === this.props.buttonSize)}`)
        }

        if (this.props.positionClass) {
            className.push(this.props.positionClass)
        }

        if (this.props.continueButtonClass) {
            className.push(this.props.continueButtonClass)
        }
        return (
            <button
                className={className.join(" ")}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
                type={this.props.type}
                id={this.props.id}
                title={this.props.tooltip}
            >
                <div>
                    {this.leftIcon()}
                    {this.text()}
                    {this.rightIcon()}
                </div>
            </button>
        )
    }
}

export default Button
