import { connect } from "react-redux"
import React from "react"

const perms = {
    admin: {
        project: {
            add: true,
        },
    },
    pm: {
        project: {
            add: true,
        },
    },
    payroll_admin: {
        project: {
            add: true,
        },
    },
}

function _isAuthorized(role, model, action) {
    return perms[role] && perms[role][model] && perms[role][model][action] == true
}
function _temp() {
    return this.props.children
}

class Authorized extends React.Component {
    constructor(props) {
        super(props)
        this.isAuthorized = _isAuthorized.bind(this)
        this.temp = _temp.bind(this)
    }

    render() {
        if (
            this.props.current_user.user_role &&
            this.isAuthorized(this.props.current_user.user_role.toLowerCase(), this.props.model, this.props.action)
        ) {
            if (!this.props.children) {
                return null
            } else if (React.isValidElement(this.props.children)) {
                return this.props.children
            }
            return <span>{this.props.children}</span>
        }
        return null
    }
}

function mapStateToProps(state) {
    return { current_user: state.current_user }
}

export default connect(mapStateToProps)(Authorized)
