import { tmEmailPreferenceValueSetter } from "../../../common/ag-grid-value-setters"
import { tmEmailPreferenceValueFormatter } from "../../../common/ag-grid-value-formatters"
import { notificationPreferences } from "../../../common/constants"
import { checkboxColDefCreator, stringColDefCreator, slideToggleColDefCreator } from "./standard-col-def-creators"
import { getFlagEnabled } from "../../../getFlagValue"
import { multiGroupFilterDef, multiProjectFilterDef } from "../../../filters/filter-defs"
import SliderCellEditor from "../cell-editors/slider-cell-editor"

export const getAccountSettings = (featureFlags, currentProject, currentUser) => {
    const settings = {
        tableName: "AccountSettings",
        navId: "accountSettings",
        resources: ["accountSettings"],
        filters: getFlagEnabled("WA-7979-ff-notifications") ? [multiGroupFilterDef, multiProjectFilterDef] : [],
        additionalQueryParams: {
            employee_id: currentUser.employee.id,
        },
        colDefs: [
            checkboxColDefCreator(),
            {
                headerName: "Project",
                field: "/project_name",
                minWidth: 200,
                rowGroup: !getFlagEnabled("WA-7979-ff-notifications") || currentUser.company_role !== "SUB",
                hide: !getFlagEnabled("WA-7979-ff-notifications") || currentUser.company_role !== "SUB",
            },
            stringColDefCreator({
                headerName: getFlagEnabled("WA-7979-ff-notifications") ? "Related Company" : "Company",
                field: "/company_name",
                sort: "asc",
                width: 250,
                hide: !getFlagEnabled("WA-7979-ff-notifications") || currentUser.company_role === "SUB",
            }),
            emailOptInColumnCreator({
                headerName: "T&M Submit",
                field: "/field_form_email",
                editable: true,
            }),
            emailOptInColumnCreator({
                headerName: "T&M Status",
                field: "/field_form_status_change_email",
                editable: true,
            }),
            emailOptInColumnCreator({
                headerName: "Other Form Submit",
                field: "/other_field_form_email",
                // we disable other form emails for external companies - we also set uneditable cells to be hidden
                editable: params => !params?.data?.external_company,
                hide: !getFlagEnabled("WA-7979-ff-notifications"),
            }),
            emailOptInColumnCreator({
                headerName: "Other Form Status",
                field: "/other_field_form_status_change_email",
                editable: params => !params?.data?.external_company,
                hide: !getFlagEnabled("WA-7979-ff-notifications"),
            }),
        ],
        gridSettings: {
            rowHeight: 40,
            groupSelectsChildren: getFlagEnabled("WA-7979-ff-notifications"),
            autoGroupColumnDef: {
                headerName: "Project",
                field: null,
                cellRendererParams: {
                    suppressCount: true,
                },
                minWidth: 200,
            },
            rowSelection: "multiple",
            suppressRowClickSelection: true,
        },
        otherSettings: {
            rowLevelErrorDisplay: true,
            enableSearchBar: true,
            buttons: {
                cell: [],
                table: [],
                row: getFlagEnabled("WA-7979-ff-notifications")
                    ? [
                          {
                              label: "T&M Submit ▾",
                              icon: "share",
                              action: "getOptInOutTMSubmit",
                              ungroupOptions: false,
                          },
                          {
                              label: "T&M Status ▾",
                              icon: "share",
                              action: "getOptInOutTMStatus",
                              ungroupOptions: false,
                          },
                          {
                              label: "Other Form Submit ▾",
                              icon: "share",
                              action: "getOptInOutOtherSubmit",
                              ungroupOptions: false,
                          },
                          {
                              label: "Other Form Status ▾",
                              icon: "share",
                              action: "getOptInOutOtherStatus",
                              ungroupOptions: false,
                          },
                      ]
                    : [
                          {
                              label: "Opt-In: Submit",
                              action: "bulkUpdateField",
                              args: {
                                  field: "field_form_email",
                                  value: true,
                              },
                          },
                          {
                              label: "Opt-Out: Submit",
                              action: "bulkUpdateField",
                              args: {
                                  field: "field_form_email",
                                  value: false,
                              },
                          },
                          {
                              label: "Opt-In: Status",
                              action: "bulkUpdateField",
                              args: {
                                  field: "field_form_status_change_email",
                                  value: true,
                              },
                          },
                          {
                              label: "Opt-Out: Status",
                              action: "bulkUpdateField",
                              args: {
                                  field: "field_form_status_change_email",
                                  value: false,
                              },
                          },
                      ],
            },
        },
    }

    return settings
}

const emailOptInColumnCreator = (params = {}) => {
    if (!getFlagEnabled("WA-7979-ff-notifications")) {
        return {
            ...stringColDefCreator({
                ...params,
                enum: [notificationPreferences.OPT_IN, notificationPreferences.OPT_OUT],
            }),
            sortable: false,
            valueFormatter: tmEmailPreferenceValueFormatter,
            valueSetter: tmEmailPreferenceValueSetter,
        }
    }
    return {
        ...slideToggleColDefCreator({
            ...params,
            shouldPreventActionFocus: true,
            hideUneditable: true,
            onText: "Opted In",
            offText: "Opted Out",
            cellEditorParams: {
                onText: "Opted In",
                offText: "Opted Out",
            },
            cellEditor: SliderCellEditor,
        }),
        headerTooltip: "Enable this to send email notifications",
    }
}
