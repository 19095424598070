import React from "react"
import { add, sub } from "date-fns"

import { eDatePickerType, tFilterActionCallbacks, iMutableFilterState, tStartOfWeekIndex } from "../types"

import arrowLeftIconSrc from "./arrowLeft.svg"
import arrowRightIconSrc from "./arrowRight.svg"
import { calculateAndSetFilterDates } from "./calculateFilterDates"
import { connect } from "react-redux"
import { setFilterState, setMultipleFilterValues } from "../actions"
import styled from "styled-components"
import { colorAttentionBlue10, colorAttentionBlue50 } from "@rhumbix/rmbx_design_system_web"

type tProps = {
    datePickerType: eDatePickerType
    endDate: Date
    handleDatesChange?: {
        (arg: { startDate: Date | null; endDate: Date | null }): void
    }
    isEnabled: boolean
    startDate: Date
    setFilterState: tFilterActionCallbacks
    setMultipleFilterValues: {
        (arg: iMutableFilterState): void
    }
    startOfWeekIndex: tStartOfWeekIndex
}

const ArrowButton = styled.button`
    background-color: ${colorAttentionBlue10};
    margin-left: 5px;
    color: ${colorAttentionBlue50};
`

export class DateArrows extends React.PureComponent<tProps> {
    handleNavClick = (e: React.SyntheticEvent, next: boolean) => {
        e.preventDefault()
        let startDate = this.props.startDate
        const range = this.props.datePickerType === eDatePickerType.DAILY ? { days: 1 } : { weeks: 1 }
        if (next) {
            startDate = add(startDate, range)
        } else {
            startDate = sub(startDate, range)
        }

        /**
         * If the handleDatesChange callback had not been passed to DateArrow,
         * That means the component is being created by the DateQuickNav widget and
         * not DateFilter. So we will call calculateAndSetFilterDates instead to
         * set the filter state as well..
         */
        if (this.props.handleDatesChange) {
            this.props.handleDatesChange({
                startDate,
                endDate: this.props.endDate, // this will be recalculated
            })
        } else {
            calculateAndSetFilterDates({
                dates: {
                    startDate,
                    endDate: this.props.endDate,
                },
                startOfWeekIndex: this.props.startOfWeekIndex,
                datePickerType: this.props.datePickerType,
                setFilterState: this.props.setFilterState,
                setMultipleFilterValues: this.props.setMultipleFilterValues,
                setState: true,
            })
        }
    }

    handleNextStartDateClick = (e: React.SyntheticEvent) => this.handleNavClick(e, true)

    handlePreviousStartDateClick = (e: React.SyntheticEvent) => this.handleNavClick(e, false)

    render() {
        return this.props.isEnabled ? (
            <div className="date-filter-arrow-nav">
                <ArrowButton
                    className="date-filter-arrow date-filter-arrow-prev"
                    onClick={this.handlePreviousStartDateClick}
                >
                    <img alt="Go to earlier date" src={arrowLeftIconSrc} title="Previous" />
                </ArrowButton>
                <ArrowButton
                    className="date-filter-arrow date-filter-arrow-next"
                    onClick={this.handleNextStartDateClick}
                >
                    <img alt="Go to more recent date" src={arrowRightIconSrc} title="Next" />
                </ArrowButton>
            </div>
        ) : null
    }
}

const mapDispatchToProps = {
    setFilterState,
    setMultipleFilterValues,
}

export default connect(null, mapDispatchToProps)(DateArrows)
