import React, { Component } from "react"
import { connect } from "react-redux"
import Button from "../../components/button"
import { startLoader, stopLoader } from "../../actions/index"
import { openLeaveConfirmationModal, csvErrorModalOpened } from "../../components/modals/actions"
import LeaveConfirmationModal from "../../components/modals/modal-save-changes"
import CsvErrorModal from "../../components/modals/modal-csv-errors"
import StandardModal from "../../components/modals/modal-standard"
import CollapsibleSection from "../../components/collapsible"
import { currentProjectIdSelector } from "../../selectors"
import { CSVUploadPreview } from "./CSVUploadPreview"
import { entryTypeMap, priceFields } from "../../common/constants"
import Rmbx from "../../util"
import { findUnexpectedColumns, parseCsv } from "./utils"
import { getFlagEnabled } from "../../getFlagValue"
import styled from "styled-components"

import _every from "lodash/every"
import _isEmpty from "lodash/isEmpty"
import _reject from "lodash/reject"
import _trim from "lodash/trim"
import { CSVLink } from "react-csv"
import { colorAttentionBlue50, IconInfoFilled } from "@rhumbix/rmbx_design_system_web"

import Dropzone from "react-dropzone"
import Papa from "papaparse"
import "csv-upload-form.less"

export class CsvUploadWrapper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            errors: [],
            file: null,
            duplicates: 0,
            isAlertVisible: false,
            confirmSaveModalIsOpen: false,
            newEntries: [],
            updatedEntries: [],
            newCollapsed: true,
            updateCollapsed: true,
            ignoredColumns: [],
        }
    }

    showConfirmSaveModal = () => this.setState({ confirmSaveModalIsOpen: true })

    confirmSaveModalDescription = () => {
        const numNew = this.state.newEntries.length
        const numUpd = this.state.updatedEntries.length
        const entryType = this.props.csvTemplateName

        // change the 'Click Arrow to Reveal' text to be specific based on upload type
        let revealEndText = ""
        switch (entryType) {
            case "Workers.csv":
                revealEndText = "Employee IDs"
                break
            case "CostCodes.csv":
                revealEndText = "Cost Codes"
                break
            case "Equipment.csv":
                revealEndText = "Equipment IDs"
                break
            case "CostCodesBudgetItems.csv":
                revealEndText = "Cost Codes"
                break
            case "Materials.csv":
                revealEndText = "Materials"
                break
            case "Projects.csv":
            case "ProjectsGroups.csv":
                revealEndText = "Projects"
                break
            default:
                revealEndText = "Additional Information"
                break
        }

        let ignoredColumnsWarning = ""
        if (this.state.ignoredColumns?.length > 0) {
            const intro =
                this.state.ignoredColumns.length > 1
                    ? "Note: Your .csv file includes columns that cannot be uploaded, " +
                      " and will be ignored. The columns are: "
                    : "Note: Your .csv file includes a column that cannot be uploaded, " +
                      "and will be ignored. The column is: "
            ignoredColumnsWarning = (
                <p className="footnote footnote-error">{intro + this.state.ignoredColumns.join(", ")}</p>
            )
        }

        if (numNew || numUpd) {
            const newEnding = numNew === 1 ? "entry." : "entries."
            const newText = "You are about to add " + numNew + " new " + newEnding
            const newIDs = numNew ? (
                <div className="csv-upload-info">
                    <CollapsibleSection
                        collapsed={this.state.newCollapsed}
                        onClick={() => {
                            this.setState({
                                newCollapsed: !this.state.newCollapsed,
                            })
                        }}
                        header={newText}
                    >
                        <div className="csv-id-list">{this.state.newEntries.join(", ")} </div>
                    </CollapsibleSection>
                </div>
            ) : null
            const updEnding = numNew === 1 ? "entry." : "entries."
            const updText = "You are about to update " + numUpd + " " + updEnding
            const updIDs = numUpd ? (
                <div className="csv-upload-info">
                    <CollapsibleSection
                        collapsed={this.state.updateCollapsed}
                        onClick={() => {
                            this.setState({
                                updateCollapsed: !this.state.updateCollapsed,
                            })
                        }}
                        header={updText}
                    >
                        <div className="csv-id-list">{this.state.updatedEntries.join(", ")} </div>
                    </CollapsibleSection>
                </div>
            ) : null
            return (
                <div className="confirm-save">
                    <p className="footnote">
                        Click &apos;Next&apos; to Continue. Click Arrow
                        {newIDs && updIDs ? "s" : ""} to Reveal {revealEndText}.
                    </p>
                    {newIDs}
                    {updIDs}
                    {ignoredColumnsWarning}
                </div>
            )
        } else {
            return <div>No changes detected. Click &apos;Next&apos; to continue.</div>
        }
    }

    closeConfirmSaveModal = () => this.setState({ confirmSaveModalIsOpen: false })

    removeEmptyRows = csv_data => {
        return _reject(csv_data, row =>
            _every(row, val => {
                return _isEmpty(_trim(val))
            })
        )
    }

    // Clean up any values that need to be transformed in order to be validated - for example,
    // if necessary, remove the "$" from prices and turn them into numbers
    transformDataForValidation = parsedResults => {
        return parsedResults.map(value => {
            priceFields.forEach(key => {
                if (value[key]) {
                    // If we're uploading a file that was exported from our own system,
                    // the prices will be formatted as $0.00 - remove the dollar sign
                    // TODO: Possibly remove other currency types?
                    value[key] = value[key].replace(/\$/g, "")

                    // pretty much any invalid value except Infinity will return NaN
                    const parsedPrice = Number.parseFloat(value[key])

                    if (isNaN(parsedPrice) || !isFinite(parsedPrice)) {
                        // invalid value - each form will handle this if the price is required
                        value[key] = null
                    } else {
                        // the value is manageable - coerce to two decimals before multiplying
                        value[key] = parseInt(parseFloat(value[key]).toFixed(2) * 100)
                    }
                }
            })

            return value
        })
    }

    // Data that is exported from Rhumbix may show the status as a string instead of
    // an internal value or a boolean - this must be translated before it's uploaded
    transformDataForUpload = data => {
        return data.map(row => {
            // if the value of "is_active" is a string,
            // change "Active"/"Inactive" to the boolean True/False before
            // it hits the backend
            if ("is_active" in row && row["is_active"] != null && typeof row["is_active"] === "string") {
                const status = row["is_active"].trim().toUpperCase()
                if (status === "ACTIVE") {
                    row["is_active"] = true
                } else if (status === "INACTIVE") {
                    row["is_active"] = false
                } else if (getFlagEnabled("WA-7373-csv-default-values")) {
                    // If we don't have a valid value, let's get rid of it so it doesn't mess with the
                    // back end's default value handling
                    delete row["is_active"]
                }
            }

            // Transform these enum settings from the Budget page
            if ("default_entry_type" in row && row["default_entry_type"] != null) {
                const defaultEntryTypeLabel = row["default_entry_type"]?.trim().toUpperCase()
                if (defaultEntryTypeLabel) {
                    const mapping = Object.entries(entryTypeMap).find(
                        ([, v]) => v.toUpperCase() === defaultEntryTypeLabel
                    )

                    // This passed validation and is expected to work here - if for some reason
                    // there's no mapping, just plug in an empty string
                    row["default_entry_type"] = mapping?.length > 0 ? mapping[0] : ""
                } else if (getFlagEnabled("WA-7373-csv-default-values")) {
                    // If we don't have a valid value, let's get rid of it so it doesn't mess with the
                    // back end's default value handling
                    delete row["default_entry_type"]
                }
            }

            // Check for visible_in_reporting and if it isn't already a boolean, turn it into one
            if (
                "visible_in_reporting" in row &&
                row["visible_in_reporting"] != null &&
                typeof row["visible_in_reporting"] === "string"
            ) {
                const defaultVisibleInReportingLabel = row["visible_in_reporting"]?.trim().toUpperCase()
                if (defaultVisibleInReportingLabel) {
                    if (defaultVisibleInReportingLabel === "VISIBLE") row["visible_in_reporting"] = true
                    else if (defaultVisibleInReportingLabel === "HIDDEN") row["visible_in_reporting"] = false
                } else if (getFlagEnabled("WA-7373-csv-default-values")) {
                    // If we don't have a valid value, let's get rid of it so it doesn't mess with the
                    // back end's default value handling
                    delete row["visible_in_reporting"]
                }
            }

            return row
        })
    }

    saveToServer = () => {
        this.props.dispatch(startLoader())
        let errors = this.state.errors
        this.props
            .csvSave(this.state.data)
            .catch(err => {
                // Some endpoints that return 204 responses are being caught here
                if (err.response && err.response.status >= 200 && err.response.status < 300) {
                    errors = []
                } else if (err.response && err.response.status && err.response.status == 502) {
                    errors = {
                        non_field_errors:
                            "The request timed out while processing " +
                            "your upload, possibly because it contained too much data. " +
                            "Please break your upload into smaller files and try again.",
                    }
                } else {
                    //** TODO: WA-4292 needs to be done to clean this up **//
                    // some custom bulk processors (like materials or equipment) don't return an
                    // "errors" key from the back-end, they just return the field and error
                    const generic_err = {
                        non_field_errors: "An unexpected error occurred.",
                    }

                    // If the caught error is a client-side exception, we should still handle
                    // it; we can't assume that we're getting an HTTP error response here.
                    errors =
                        err.response && err.response.data
                            ? err.response.data.errors || err.response.data
                            : generic_err

                    // Sometimes err.response.data is empty
                    if (
                        (errors instanceof Object && Object.keys(errors).length < 1) ||
                        (errors instanceof Array && errors.length < 1)
                    ) {
                        errors = generic_err
                    }
                }
            })
            .finally(() => {
                this.props.dispatch(stopLoader())
                // check it's an array with at least one error like [{},{},{"field": ["message"]}]
                // or an object with at least one error key like {"field": ["message"]}
                let showError = false
                if (errors instanceof Array) {
                    showError = errors.length > 0 && !errors.every(_isEmpty)
                    /** Shift errors index by 1 to match the row number in the csv file.
                     * This is for the backend validation error.
                     * First data starts from the 2nd row in the csv file. */
                    if (showError) errors.unshift({})
                } else if (errors instanceof Object && Object.keys(errors).length > 0) {
                    showError = true
                }
                // Resist the urge to combine the above and below logic! If you do, the cases
                // determining the next action get muddled. The showError flag keeps it clear.
                if (showError) {
                    this.props.dispatch(csvErrorModalOpened(errors))
                } else {
                    this.props.handleNext(this.state.data)
                }
            })
    }

    onDrop = files => {
        Papa.parse(files[0], {
            header: true,
            skipEmptyLines: true,
            complete: results => {
                const dataWithoutEmptyRows = this.removeEmptyRows(results.data)
                const parsedResults = parseCsv(dataWithoutEmptyRows, this.props.csvHeadersMap)

                // If the CSV file contains columns that are not in csvHeadersMap, they are ignored.
                // Keep track of which ones were left out so the user can be warned
                const ignoredColumns = findUnexpectedColumns(dataWithoutEmptyRows, this.props.csvHeadersMap)
                const data = this.transformDataForValidation(parsedResults)

                this.setState({ data })

                this.setState({ ignoredColumns })

                this.setState({
                    duplicates: dataWithoutEmptyRows.length - this.state.data.length,
                })

                // validate for initial errors and update and new counts
                // We resolve a promise here in case we're dealing with a SSRM table
                // where we need to fetch matching employee info. Other non-SSRM tables
                // will resolve right away.
                Promise.resolve(this.props.validateCsv(this.state.data, results)).then(validationResults => {
                    const errors = validationResults["errors"]

                    // add in any errors from the parse results
                    if (validationResults.errors.length > 0) {
                        // unpack the error messages
                        const parseErrors = results.errors.map(err => {
                            return err.message
                        })
                        if (parseErrors.length > 0) {
                            errors.push({ "General CSV Error": parseErrors })
                        }
                    }

                    this.setState({ errors: errors })
                    this.setState({
                        newEntries: validationResults["new"] ? validationResults["new"] : [],
                    })
                    this.setState({
                        updatedEntries: validationResults["updates"] ? validationResults["updates"] : [],
                    })

                    // if the uploaded CSV is not formatted properly - don't try to save to server
                    // information could be mapped incorrectly and we don't want to save that
                    if (errors.every(_isEmpty)) {
                        // if there are no errors, perform any final transforms on the data to get it
                        // ready for upload
                        const modifiedData = this.transformDataForUpload(data)
                        this.setState({ data: modifiedData })
                        this.showConfirmSaveModal()
                    } else {
                        this.props.dispatch(csvErrorModalOpened(errors))
                    }
                })
            },
        })
    }

    onClickBack = () => this.props.dispatch(openLeaveConfirmationModal())

    goBack = () => Rmbx.util.history.goBack()

    /**
     * Determine what the markup is and return the upload area (bottom panel)
     * @returns {XML}
     */
    getUploadArea = () => {
        return (
            <div>
                <Dropzone className="step-two" onDrop={this.onDrop} accept="text/csv,.csv" multiple={false}>
                    <p> Drag Your Formatted .CSV Here </p>
                    <p> or </p>
                    <Button text="Select A File" color="blue" />
                </Dropzone>
            </div>
        )
    }

    /**
     * Render this react component
     * @returns {XML}
     */
    render = () => {
        const UploadArea = this.getUploadArea()

        return (
            <UploadWrapperContainer className="csv-upload-form center-block text-center">
                <h1 className="standard-header-title">{this.props.pageTitle}</h1>
                <div className="csv-upload-panel text-center">
                    <h2> Step 1: </h2>
                    <h3> {this.props.stepOneDescription} </h3>
                    {getFlagEnabled("WA-7010-replace-csv-upload-preview-image-with-table-component") ? (
                        <CSVUploadPreview fieldNames={this.props.csvHeadersMap} />
                    ) : (
                        <img
                            {...this.props.csvImageProps}
                            width="600"
                            alt="An example of the column headers avalable in the CSV template"
                        />
                    )}
                    <br />
                    <p className="required-text">
                        *Required
                        <br />
                    </p>
                    {this.props.optionalInfoDescription ? (
                        <div className="csv-optional-information-flex">
                            <IconInfoFilled
                                height="16"
                                width="16"
                                color={colorAttentionBlue50}
                                style={{ marginRight: "5px" }}
                            />
                            {this.props.optionalInfoDescription}
                        </div>
                    ) : null}
                    <br />
                    <CSVLink data={[Object.keys(this.props.csvHeadersMap)]} filename={this.props.csvTemplateName}>
                        <Button text="Download .CSV Template" color="blue" buttonSize="medium" />
                    </CSVLink>
                </div>

                <div className="csv-upload-panel">
                    <h2> Step 2: </h2>
                    <h3> {this.props.stepTwoDescription} </h3>
                    {UploadArea}
                    {
                        /* type in entries manually */
                        this.props.manualInputText ? (
                            <button id="csv-manual-input" onClick={() => this.props.handleNext()}>
                                {this.props.manualInputText}
                            </button>
                        ) : (
                            ""
                        )
                    }
                </div>

                <Button
                    text="Back"
                    arrow="left"
                    buttonSize="small"
                    positionClass="pull-left"
                    onClick={this.onClickBack}
                />
                <LeaveConfirmationModal onSuccess={this.goBack} />
                <CsvErrorModal errors={this.props.errors} />
                <StandardModal
                    open={this.state.confirmSaveModalIsOpen}
                    closeMethod={this.closeConfirmSaveModal}
                    title="Verify Pending Changes"
                    description={this.confirmSaveModalDescription()}
                    secondaryBtnText="Cancel"
                    primaryBtnText="Next"
                    primaryBtnOnClick={this.saveToServer}
                />
            </UploadWrapperContainer>
        )
    }
}

const UploadWrapperContainer = styled.div`
    ${() =>
        getFlagEnabled("WA-7010-replace-csv-upload-preview-image-with-table-component") &&
        `
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 900px;

        .required-text {
           padding: 0;
        }

        .csv-upload-panel {
            width: 100%;
        }

        // overrides the back button's positioning to be left-aligned with flex
        .pull-left {
            align-self: flex-start;
        }
    `}
`

const mapStateToProps = state => ({
    currentProjectId: currentProjectIdSelector(state),
})

export default connect(mapStateToProps)(CsvUploadWrapper)
