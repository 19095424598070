import { checkboxColDefCreator, dateColDefCreator, stringColDefCreator } from "./standard-col-def-creators"
import { isActiveEnumValueFormatter } from "../../../common/ag-grid-value-formatters"
import { alertCellClassRule } from "../../../common/ag-grid-utils"
import { getFlagEnabled } from "../../../getFlagValue"

export const getCompanyAbsenceTypesSettings = absenceCountTable => {
    return {
        tableName: "Absence Types",
        navId: "companyAbsenceTypes",
        resources: ["companyAbsenceTypes"],
        filters: [],
        additionalQueryParams: {
            include_inactive: true,
        },
        usePlaceholders: !getFlagEnabled("WA-8121-adjust-tooltip-position"),
        colDefs: [
            checkboxColDefCreator(),
            stringColDefCreator({
                headerName: "Absence Type",
                field: "/name",
                required: true,
                cellClassRules: { "alert-cell": alertCellClassRule },
                parentContainerId: "side-rail-wrapper",
            }),
            stringColDefCreator({
                headerName: "Code",
                field: "/code",
            }),
            stringColDefCreator({
                headerName: "Added by",
                field: "/created_by",
                editable: false,
            }),
            dateColDefCreator({
                headerName: "Modified",
                field: "/last_updated",
                minWidth: 150,
                editable: false,
            }),
            {
                ...stringColDefCreator({
                    headerName: "Status",
                    field: "/is_active",
                    enum: ["Active", "Inactive"],
                    minWidth: 100,
                    width: 100,
                    fixedWidth: true,
                }),
                valueFormatter: isActiveEnumValueFormatter,
            },
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: true,
            },
            rowSelection: "multiple",
            // allow our click events to happen
            suppressRowClickSelection: true,
        },
        otherSettings: {
            pageTitle: "Absence Types",
            rowLevelErrorDisplay: true,
            enableSearchBar: true,
            buttons: {
                table: [
                    {
                        label: "Add Row",
                        icon: "add",
                        action: "addNewRow",
                    },
                ],
                row: [
                    {
                        label: "Activate",
                        icon: "view",
                        action: "bulkUpdateField",
                        args: {
                            field: "is_active",
                            value: true,
                            no_autosave: true,
                        },
                    },
                    {
                        label: "Deactivate",
                        icon: "hide",
                        action: "bulkUpdateField",
                        args: {
                            field: "is_active",
                            value: false,
                            no_autosave: true,
                        },
                    },
                    {
                        separator: true,
                    },
                    {
                        label: "Delete",
                        icon: "delete",
                        action: "deleteSelectedRows",
                        args: { no_autosave: true },
                        disabled: context => context.selectedRows.some(row => absenceCountTable[row.id]),
                        tooltip: context =>
                            context.selectedRows.some(row => absenceCountTable[row.id])
                                ? "Cannot delete absence types with absences associated with them. Consider " +
                                  "deactivating instead"
                                : context.selectedRows.length > 1
                                ? "Deletes these absence types"
                                : "Deletes this absence type",
                    },
                ],
            },
        },
    }
}
