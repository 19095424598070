import React, { useContext } from "react"
import { SchemaDashboardRowData } from "../../../../cached-data/types"
import { ResourceObject } from "../../../../dashboard-data/types"
import { SideRailContext } from "../../../SideRail/SideRailContext"
import { SchemaAccessManagerMode } from "../types"

type Props = {
    data: ResourceObject<SchemaDashboardRowData>
}

type ComponentType = (mode: SchemaAccessManagerMode) => React.FC<Props>
export const ManageSchemaAccessButton: ComponentType = mode => ({ data }) => {
    const { enableSideRail } = useContext(SideRailContext)

    const openSideRail = () => {
        // Start the workflow with just the schema ID, instead of all the schema info.
        // Why? Because Ag Grid won't ever give this component updated row data.
        // The ID, however, will remain relevant, and can be used to select up-to-date data from the Store.
        enableSideRail({
            flow: "MANAGE_SCHEMA_ACCESS",
            mode,
            schemaId: data.id,
            itemTitle: "Manage Access",
        })
    }

    return (
        <button onClick={openSideRail} type="button" className="action-button">
            <div>Manage Access</div>
        </button>
    )
}

export default ManageSchemaAccessButton
