import PivotHeader from "../custom-headers/pivot-header"
import { filterFormatter } from "../../../common/ag-grid-value-formatters"
import { compareGroupColumns } from "../../../common/ag-grid-comparators"
import { aggregateWithStringHandling } from "../../../common/ag-grid-utils"
import {
    employeeColDefCreator,
    foremanColDefCreator,
    dateColDefCreator,
    costCodeColDefCreator,
    equipmentColDefCreator,
} from "./standard-col-def-creators"
import {
    companyGroupsFilterDef,
    weekFilterDef,
    projectStatusFilterDef,
    multiProjectFilterDef,
} from "../../../filters/filter-defs"
import { dateAndDefaultTimeValueSetter } from "../../../common/ag-grid-value-setters"
import { getTimekeepingEntryTable } from "./timekeeping-entries"
import { getFlagEnabled } from "../../../getFlagValue"
import { jsonPointerValueGetter, jsonPointerValueGetterEnhanced } from "../../../common/ag-grid-value-getters"

export const getEquipmentTrackingSettings = (featureFlags, currentProject, user, currentProjectIds, sourceData) => {
    const enableTk = featureFlags.cost_code_level_modifiers
    const equipmentColDef = enableTk
        ? {
              ...equipmentColDefCreator({
                  field: "/store/equipment",
                  altField: "/modifier_active/equipment",
                  moreDetail: true,
                  referenceableMap: {
                      referenceableKey: "equipment",
                      rowKey: "/modifier_active/equipment",
                  },
              }),
              rowGroup: true,
              returnEntireRef: true,
              fallbackToField: true,
              valueGetter: jsonPointerValueGetterEnhanced,
          }
        : {
              ...equipmentColDefCreator({
                  field: "/store/equipment",
                  moreDetail: true,
              }),
              rowGroup: true,
          }

    const costCodeColDef = enableTk
        ? {
              ...costCodeColDefCreator({
                  field: "/store/code",
                  altField: "/cost_code",
                  moreDetail: true,
                  referenceableMap: {
                      referenceableKey: "costCodes",
                      rowKey: "cost_code",
                  },
              }),
              rowGroup: true,
              returnEntireRef: true,
              fallbackToField: true,
              valueGetter: jsonPointerValueGetterEnhanced,
          }
        : {
              ...costCodeColDefCreator({
                  field: "/store/code",
                  moreDetail: true,
              }),
              rowGroup: true,
          }

    const hoursColDef = {
        headerName: "Hours",
        field: "/store/hours",
        width: 107,
        aggFunc: aggregateWithStringHandling,
        cellStyle: { justifyContent: "center" },
    }
    if (enableTk) {
        hoursColDef.valueGetter = params => {
            return params.data.dataType === "timekeepingEntries"
                ? (params.data["adjusted_minutes_st"] +
                      params.data["adjusted_minutes_ot"] +
                      params.data["adjusted_minutes_dt"]) /
                      60
                : jsonPointerValueGetter(params)
        }
    }
    const resources = ["employeeEntries"]
    if (enableTk) resources.push("timekeepingEntries")
    return {
        tableName: "Equipment Tracking",
        navId: "equipment-tracking",
        resources: resources,
        filters: [companyGroupsFilterDef, projectStatusFilterDef, multiProjectFilterDef, weekFilterDef],
        relatedNames: {
            employeeEntries: {
                employees: ["/employee", "/foreman"],
                costCodes: ["/store/code/id"],
                employeeSchemas: ["/schema"],
                equipment: ["/store/equipment/id"],
            },
            ...(enableTk
                ? {
                      timekeepingEntries: {
                          companyClassifications: ["/modifier_active/classification"],
                          companyTrades: ["/modifier_active/trade"],
                          costCodes: ["/cost_code"],
                          employees: ["/employee", "/foreman"],
                          equipment: ["/modifier_active/equipment"],
                          ...(getFlagEnabled("WA-7200-Custom-Modifiers")
                              ? {
                                    picklistItems: (user.company_tk_modifiers ?? [])
                                        .filter(m => m.standard_tk_modifier_type == null)
                                        .map(m => `/modifier_active/${m.slug}`),
                                }
                              : {}),
                          projects: ["/project"],
                          workShifts: ["/work_shift_id"],
                      },
                  }
                : {}),
        },
        additionalQueryParams: {
            queryParamsByResource: {
                employeeEntries: { schema_name: "Equipment-Tracking" },
                timekeepingEntries: { has_equipment_modifier: true },
            },
        },
        colDefs: [
            {
                ...dateColDefCreator(),
                valueSetter: dateAndDefaultTimeValueSetter,
                pivot: true,
            },
            equipmentColDef,
            {
                ...employeeColDefCreator({ moreDetail: true }),
                rowGroup: true,
            },
            costCodeColDef,
            foremanColDefCreator({ moreDetail: true }),
            hoursColDef,
        ],
        gridSettings: {
            pivotHeaderHeight: 60,
            rowHeight: 60,
            pivotMode: true,
            pivotRowTotals: "after",
            autoGroupColumnDef: {
                headerName: "Equipment", // might might need an OtherSetting to
                // make this based off of vars in the grid
                suppressMenu: true, // this too
                sort: "asc",
                unSortIcon: true,
                cellRendererParams: {
                    suppressCount: true,
                },
                comparator: compareGroupColumns,
                minWidth: 250,
            },
            defaultColDef: {
                getQuickFilterText: filterFormatter,
            },
            rowSelection: "multiple",
            // hide the group header, we don't like it
            pivotGroupHeaderHeight: 0,
        },
        otherSettings: {
            forceWeeklyPivotColumns: true,
            pivotColDef: {
                headerComponent: PivotHeader,
                headerComponentParams: {
                    subtitles: ["RT"],
                    centerSubtitle: true,
                },
                minWidth: 90,
                dayOfWeekHeaderName: true,
            },
            pivotRowTotalColDef: {
                headerComponent: PivotHeader,
                headerComponentParams: {
                    subtitles: ["RT"],
                },
                headerName: "Total",
            },
            enableClipboard: true,
            groupBy: [
                {
                    cols: ["/store/equipment", "/employee", "/store/code"],
                    label: "Equipment",
                },
                {
                    cols: ["/employee", "/store/equipment", "/store/code"],
                    label: "Employee",
                },
                {
                    cols: ["/store/code", "/employee", "/store/equipment"],
                    label: "Cost Code",
                },
            ],
            editablePivotSourceModal: {
                tableSchemaList: ["Equipment-Tracking"],
                tableList: enableTk
                    ? [
                          getTimekeepingEntryTable(
                              featureFlags,
                              currentProject,
                              user,
                              currentProjectIds,
                              sourceData,
                              true
                          ),
                      ]
                    : [],
                schemasFirst: true,
            },
            enableSearchBar: true,
            enableAuditHistory: true,
            buttons: {
                cell: [],
                row: [],
                table: [
                    {
                        label: "Expand/Collapse All",
                        action: "toggleRowExpansion",
                        icon: "expand",
                    },
                    {
                        label: "Group By",
                        action: "groupBy",
                        icon: "group",
                    },
                ],
            },
        },
    }
}
