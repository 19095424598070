import { createSelector } from "reselect"

import Rmbx from "../util"
import {
    currentProjectIdSelector,
    currentProjectIdsListSelector,
    projectStatusSelector,
    selectedProjectIdsListSelector,
} from "./projects"
import { DEFAULT_PAGE_SIZE } from "../cached-data/actions"

/**
 * Return all cached cost codes as an object keyed by ID
 */
export const costCodesSelector = state => (state.entities.costCodes ? state.entities.costCodes.objects : {})

/**
 * Return all cached cost codes as an array of objects
 */
export const costCodesListSelector = createSelector(
    costCodesSelector,
    costCodesObj => Object.values(costCodesObj) || []
)

/**
 * Return all cached cost codes for the current project as an object keyed by
 * cost code ID
 */
export const costCodesForCurrentProjectSelector = createSelector(
    currentProjectIdsListSelector,
    costCodesSelector,
    (currentProjectIds, costCodes) =>
        Object.keys(costCodes)
            .filter(key => currentProjectIds.includes(costCodes[key].project.id))
            .reduce((obj, key) => {
                obj[key] = costCodes[key]
                return obj
            }, {})
)

/**
 * Return all cached cost codes for the current project as an array of objects
 */
export const costCodesForCurrentProjectListSelector = createSelector(
    costCodesForCurrentProjectSelector,
    costCodesObj => Object.values(costCodesObj)
)

/**
 * Return all active cost codes for selected projects
 */
export const activeCostCodesForSelectedProjectsSelector = createSelector(
    costCodesListSelector,
    selectedProjectIdsListSelector,
    projectStatusSelector,
    (costCodes, selectedProjects, projectStatus) => {
        let selectedCostCodes = []

        // We aren't filtering by ID or status, so all projects are selected
        if (selectedProjects.length === 0 && !projectStatus) {
            selectedCostCodes = costCodes
        } else {
            const selectedProjectIds = selectedProjects.map(id => parseInt(id, 10))
            selectedCostCodes = costCodes.filter(
                costCode => costCode && costCode.project && selectedProjectIds.includes(costCode.project.id)
            )
        }

        return selectedCostCodes.filter(costCode => costCode.is_active === true)
    }
)

/**
 * Return the pagination object for cost codes
 */
export const costCodesPaginationSelector = state => state.pagination.costCodes

/**
 * Return true if the cost codes for the current project have finished loading
 */
export const costCodesForCurrentProjectLoadedSelector = createSelector(
    currentProjectIdSelector,
    costCodesPaginationSelector,
    (currentProjectId, pagination) => {
        const key = currentProjectId
            ? Rmbx.util.serializeQueryParams({
                  minimal_project_detail: true,
                  page_size: DEFAULT_PAGE_SIZE,
                  project_id: currentProjectId,
              })
            : "all"

        return !!(pagination[key] && pagination[key].pageCount > 0 && !pagination[key].nextPageUrl)
    }
)
