import React, { useState, useEffect, useRef, MouseEvent as tMouseEvent } from "react"
import arrowDropDown from "../../../images/sidebar_icons/arrow-drop-down.svg"
import arrowCollapsible from "../../../images/sidebar_icons/arrow-collapsible-white.svg"

import "collapsible-menu-group.less"
import { tCollapsibleMenuGroupProps, tMiniMenuGroupProps, tMenuGroupProps } from "./types"
import { actOnEnterOrSpace } from "../../common/js-utils"
import { getFlagEnabled } from "../../getFlagValue"

const MiniMenuGroup: React.FunctionComponent<tMiniMenuGroupProps> = (props: tMiniMenuGroupProps) => {
    const { wrapperRef, wrapperClassName, handleClick, iconName, menuTitle, children } = props
    return (
        <div
            ref={wrapperRef}
            className={wrapperClassName}
            onClick={handleClick}
            onKeyDown={e => actOnEnterOrSpace(e, handleClick)}
            role="button"
            tabIndex={0}
        >
            <div className="menu-icon-block">
                {getFlagEnabled("WA-7796-enhance-custom-links") && props.icon ? (
                    props.icon
                ) : (
                    <img
                        alt=""
                        className="menu-title-icon"
                        data-icon-name={iconName}
                        src={require(`sidebar_icons/${iconName}-white.svg`)}
                    />
                )}
                <img alt="" className="arrow-collapsible" src={arrowDropDown} />
            </div>
            <ul className="dropdown-menu">
                <li className="custom-list-title">
                    <p className="mini-sidebar-custom-list-title no-hover-menu-item">{menuTitle}</p>
                </li>
                <div className="mini-list-options-container">{children}</div>
            </ul>
        </div>
    )
}

const MenuGroup: React.FunctionComponent<tMenuGroupProps> = (props: tMenuGroupProps) => {
    const { wrapperClassName, isExpanded, handleClick, iconName, menuTitle, isCore, children } = props
    return (
        <div className={wrapperClassName}>
            <div
                className={`collapsible-menu-title ${isExpanded ? "expanded" : ""}`}
                onClick={handleClick}
                onKeyDown={e => actOnEnterOrSpace(e, handleClick)}
                tabIndex={0}
                id={`sidebar-menu-${menuTitle.replace(" ", "-")}`}
                role="button"
            >
                <img alt="" className="arrow-collapsible" src={arrowCollapsible} />
                <div className={`menu-title-container ${isExpanded ? "expanded" : ""}`}>
                    {getFlagEnabled("WA-7796-enhance-custom-links") && props.icon ? (
                        props.icon
                    ) : (
                        <img
                            alt=""
                            className="menu-title-icon"
                            data-icon-name={iconName}
                            src={require(`sidebar_icons/${iconName}-white.svg`)}
                        />
                    )}
                    <div
                        className={`sidebar-foreground-text menu-title ${isCore ? "core-label" : ""} ${
                            getFlagEnabled("WA-7796-enhance-custom-links") && props.icon ? "icon-spacer" : ""
                        }`}
                    >
                        {menuTitle}
                    </div>
                </div>
            </div>
            <ul className="sidebar-left-padding">{isExpanded && children}</ul>
        </div>
    )
}

/**
 * This component has a couple of different responsibilities
 *  - Primarily it handles laying out 2 different menu variations, a
 *  collapsed sidebar menu (a.k.a mini), and a expanded version
 *
 *  - The Mini version of the menu leverages a flyout menus that ought
 *  to respond application click events and update its state accordingly,
 *  i.e. is the menu visible or not.
 *  Solution from: https://stackoverflow.com/a/42234988
 */
export const CollapsibleMenuGroup: React.FunctionComponent<tCollapsibleMenuGroupProps> = (
    props: tCollapsibleMenuGroupProps
) => {
    const [isSelected, updateIsSelected] = useState(false)

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    })

    const wrapperRef = useRef<HTMLDivElement>(null)
    const {
        iconName,
        icon,
        menuTitle,
        isCore,
        children,
        isExpanded,
        currentMenuGroup,
        navCategory,
        isSidebarCollapsed,
        isHidden,
    } = props

    const handleClickOutside = (event: any) => {
        // if a click evt happens outside of this mini menu option, we need to hide the flyout menu
        if (wrapperRef.current && !wrapperRef.current.contains(event.target) && isSelected) {
            updateIsSelected(false)
        }
    }

    const handleClick = (e: tMouseEvent<HTMLDivElement>) => {
        if (e) e.preventDefault()

        if (isSidebarCollapsed) {
            updateIsSelected(!isSelected)
        }
        props.handleClick(navCategory)
    }

    if (isHidden) {
        return null
    }

    let wrapperClassName = ""

    if (isSidebarCollapsed) {
        wrapperClassName = `${isSelected ? "flyout-menu-wrapper" : ""} ${
            currentMenuGroup === navCategory ? "mini-sidebar-current-menu-group" : ""
        } mini-sidebar-item-dropdown-container`
        // by dropping the children when not selected resets the dropdown menus after it is closed
        return (
            <MiniMenuGroup
                wrapperRef={wrapperRef}
                wrapperClassName={wrapperClassName}
                iconName={iconName}
                icon={icon}
                menuTitle={menuTitle}
                handleClick={handleClick}
            >
                {isSelected || !getFlagEnabled("WA-7882-nested-left-nav") ? children : []}
            </MiniMenuGroup>
        )
    }

    wrapperClassName = "collapsible-menu-group  sidebar-menu-item-container"
    return (
        <MenuGroup
            wrapperClassName={wrapperClassName}
            iconName={iconName}
            icon={icon}
            menuTitle={menuTitle}
            handleClick={handleClick}
            isExpanded={isExpanded}
            isCore={isCore}
        >
            {children}
        </MenuGroup>
    )
}

export default CollapsibleMenuGroup
