import React from "react"
import { tFilterState, tFilterDef, tFilterContext } from "../types"
import "./FilterBanner.less"
import { FilterBannerLabelGetter } from "./FilterBannerLabelGetter"
import { actOnEnterOrSpace } from "../../common/js-utils"

type tProps = {
    openPanel: () => void
    filterState: tFilterState
    filterDefs: Array<tFilterDef>
    context: tFilterContext
    requiredFilterNotSet: boolean
}

export const FilterBanner = (props: tProps) => {
    let filterStateLength = 0
    let activeFilterCountLabel = ""
    const { openPanel, requiredFilterNotSet, ...otherProps } = props
    let bannerLabel: Array<JSX.Element> | string = "Apply filters to this view"

    if (!requiredFilterNotSet) {
        // Required filters are either set, or there aren't any required filters.
        const bannerLabelElements = FilterBannerLabelGetter(otherProps)
        if (bannerLabelElements.length > 0) {
            /**
             * There are required filters, and they've been set. Get the number of set filters.
             * This is necessary because FilterBannerLabelGetter returns its results in pairs (label,
             * and value), therefore the resulting array length will always be even.
             */
            filterStateLength = bannerLabelElements.length / 2
            activeFilterCountLabel = filterStateLength + " Filters"
            bannerLabel = bannerLabelElements
        }
    }

    return (
        <div
            className="filter-bar-container"
            onClick={() => openPanel()}
            onKeyDown={e => actOnEnterOrSpace(e, () => openPanel())}
            role="button"
            tabIndex={0}
        >
            <i className="icon-filter" id="filter-icon" />
            <div className="num-filters-label">{activeFilterCountLabel}</div>
            <div className="filter-string-label">{bannerLabel}</div>
        </div>
    )
}
