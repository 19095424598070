/**
 * Created by jongon on 10.11.17
 */

"use strict"

import __isEmpty from "lodash/isEmpty"
import __filter from "lodash/filter"

const initialState = {
    quantityList: [],
    quantityEditDirtyTracker: [],
    apiFailures: [],
    quantityTotals: [],
}

export default function quantity(state = initialState, action) {
    switch (action.type) {
        case "QUANTITY_LIST_CHANGED":
            return Object.assign({}, state, {
                quantityList: action.data,
                apiFailures: [],
            })

        case "CLEAR_QUANTITY_EDITS":
            return Object.assign({}, state, {
                quantityEditDirtyTracker: [],
                apiFailures: [],
            })

        case "QUANTITY_EDIT_DIRTY_TRACKER_CHANGED":
            // we expect a qty obj with id and value, i.e. {id: int, value: int}
            // determine if the item already exists in the dirty array, and if not, add it
            if (
                __isEmpty(
                    __filter(state.quantityEditDirtyTracker, function (e) {
                        return e.id === action.data.id
                    })
                )
            ) {
                // add the new item!
                return Object.assign({}, state, {
                    quantityEditDirtyTracker: [
                        ...state.quantityEditDirtyTracker,
                        {
                            id: action.data.id,
                            value: action.data.value,
                            additionalQuantityIds: action.data.additionalQtyIds,
                        },
                    ],
                })
            }

            // item already exists, let's mutate it instead
            return Object.assign({}, state, {
                quantityEditDirtyTracker: state.quantityEditDirtyTracker.map(qtyItem => {
                    if (qtyItem.id === action.data.id) {
                        return Object.assign({}, qtyItem, {
                            value: action.data.value,
                            additionalQuantityIds: action.data.additionalQtyIds,
                        })
                    }
                    return qtyItem
                }),
            })

        case "FAILED_TO_UPDATE_QUANTITIES":
            return Object.assign({}, state, {
                apiFailures: [
                    "There was an issue editing these quantities, " +
                        "please ensure they are valid numbers and try again.",
                ],
            })

        case "FAILED_TO_CREATE_QUANTITY":
            return Object.assign({}, state, {
                apiFailures: [
                    "There was an issue creating your quantity, " +
                        "please ensure you provided valid values and try again.",
                ],
            })

        case "QUANTITY_TO_DATE_TOTAL_CHANGED":
            return Object.assign({}, state, { quantityTotals: action.data })

        default:
            return state
    }
}
