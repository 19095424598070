import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
/** Actions */
import { setFilterValue as updateReduxFilterValue } from "../actions"
/** Selectors */
import { activeSavedFilterSelector, pendingFiltersSelector } from "../selectors"
/** Components */
import SelectorWrapper from "../../SelectorWrapper"
import { getValueFormatter } from "../../common/ag-grid-value-formatters"

/** Types */
import { AppDispatch, ReduxState } from "../../common/types"
import { tFilterKey, tFilterResourceName, tFilterKeyToQueryParam, tValueFormatter } from "../types"
import AsyncSelect from "react-select-v1/lib/Async"
/** Styles */
import "../FilterController/FilterController.less"
import "./SelectorFilter.less"
/** Utils */
import rmbx from "../../util"
import { getFlagEnabled } from "../../getFlagValue"
import { getCookie } from "../../common/ts-utils"
import { referenceableDataSelector } from "../../selectors"
import { tCachedResourceName } from "../../cached-data/types"
import { tResourceObject } from "../../dashboard-data/types"
import { isEqual } from "lodash"

type tProps = {
    className?: string
    clearable?: boolean
    clearedBy?: tFilterKey[]
    clearText: string
    getSelectionFromFilterValue?: { (arg: any): any }
    innerRef: React.RefObject<typeof AsyncSelect>
    isDesignSystem?: boolean
    isSelectorV3?: boolean
    label: string
    multiselect?: boolean
    options?: Array<any>
    placeholder: string | undefined
    prefixFormatter?: tValueFormatter
    primaryKey: tFilterKey
    primarySubtitleFormatter?: tValueFormatter
    relatedFilters: tFilterKeyToQueryParam
    resourceName: tFilterResourceName
    secondarySubtitleFormatter?: tValueFormatter
    shouldClearCache?: boolean
    titleFormatter?: tValueFormatter
    updateClearCache: (shouldClearCache: boolean) => void
    valueFormatter?: tValueFormatter
    valueKey?: string
}

// Wraps the Selector (with one other wrapper in between) for use by filters.
// Options are either passed in by its parent class if it's used inside ReferenceableSelectorFilter,
// or passd in by the filter def creator as static options if it's used in an enum filter.
const SelectorFilter = (props: tProps) => {
    const {
        className,
        clearable,
        clearedBy,
        clearText,
        innerRef,
        multiselect,
        options,
        prefixFormatter,
        primaryKey,
        primarySubtitleFormatter,
        relatedFilters,
        resourceName,
        secondarySubtitleFormatter,
        shouldClearCache,
        titleFormatter,
        updateClearCache,
        valueFormatter,
        valueKey,
    } = props
    const dispatch: AppDispatch = useDispatch()
    const isInitialMount = useRef(true)
    const currentUser = useSelector((state: ReduxState) => state.current_user)
    const activeSavedFilter = useSelector(activeSavedFilterSelector)
    const filters = useSelector(pendingFiltersSelector)
    const referenceableData = useSelector(referenceableDataSelector)
    const [filterValue, setFilterValue] = useState(filters[primaryKey])
    const [isLoading, setIsLoading] = useState(false)
    // track props
    const [pendingFilters, setPendingFilters] = useState(filters)
    const [isUpdatingFiltersFromSelector, setIsUpdatingFiltersFromSelector] = useState(false)

    useEffect(() => {
        // If the filter value is updated outside the component (e.g. by a
        // chained filter), we need to update the component state.
        const filterVal = filters[props.primaryKey]
        const prevFilterVal = pendingFilters[primaryKey]

        if (filterVal !== prevFilterVal) {
            setIsUpdatingFiltersFromSelector(true)
            setFilterValue(filterVal)
        }
        if (
            clearedBy &&
            clearedBy.some(filterKey => {
                // @ts-ignore
                if (!filters[filterKey] || (Array.isArray(filters[filterKey]) && !filters[filterKey].length))
                    return false
                return !isEqual(pendingFilters[filterKey], filters[filterKey])
            })
        ) {
            setIsUpdatingFiltersFromSelector(true)
            setFilterValue(null)
        }

        setPendingFilters(filters)
    }, [filters])

    useEffect(() => {
        // We want to make sure we don't get into an infinite loop situation so don't dispatch if the selector
        // is what's causing the update
        if (!isUpdatingFiltersFromSelector && !isInitialMount.current) {
            dispatchFilterChange()
        } else if (isInitialMount.current) {
            isInitialMount.current = false
        }
        setIsUpdatingFiltersFromSelector(false)
    }, [filterValue])

    const getValueKey = () => valueKey ?? "name"

    const getFilterValueFromSelectionValue = (selectionValue: any) => {
        let selectedFilterValue = selectionValue ? selectionValue[getValueKey()] : undefined
        if (multiselect) {
            selectedFilterValue = selectionValue.map((item: any) => {
                // We store values internally in the Select component as {name: "Value"}. This
                // ignores the valueKey parameter and what we really want here is the whole thing so
                // we can get the proper filter value...
                const option: any = options?.find(op => op.data?.name === item.name)
                if (option) return option.data[getValueKey()]
                return item[getValueKey()] ?? item["name"]
            })
        }
        return selectedFilterValue
    }

    const persistSelectedOptions = (selectionValue: any) => {
        // If the filter value was set to something (as opposed to cleared),
        // add it to the cache of option values we keep in sessionStorage.
        //
        // This gets consumed by the AsyncPaginate component to set its value
        // after a hard refresh. AsyncPaginate will only render the options
        // that correspond to the current filter selection.
        const selectionValueArray = Array.isArray(selectionValue) ? selectionValue : [selectionValue]
        if (selectionValue && selectionValueArray.length) {
            const optionCache = window.sessionStorage.getItem(props.primaryKey) || "{}"
            const newOptions = selectionValueArray.reduce((acc, item: { id: number; name: string }) => {
                if (item.id === undefined && item.name && typeof item.name === "string") {
                    acc[item.name] = item
                } else {
                    acc[item.id] = item
                }
                return acc
            }, {})
            const newOptionCache = { ...JSON.parse(optionCache), ...newOptions }
            window.sessionStorage.setItem(primaryKey, JSON.stringify(newOptionCache))
        }
    }

    const onChange = (selectionValue: any) => {
        const changedFilterValue = getFilterValueFromSelectionValue(selectionValue)
        setFilterValue(changedFilterValue)
        if (!multiselect || !changedFilterValue.length) {
            dispatch(updateReduxFilterValue(primaryKey, changedFilterValue))
        }
        persistSelectedOptions(selectionValue)
    }

    const dispatchFilterChange = () => {
        dispatch(updateReduxFilterValue(primaryKey, filterValue))
    }

    /** Get the initial selected options for React Select V3. */
    const getSelectedOptions = () => {
        // filterValue contains the filter's current selection
        if (!filterValue) return

        // If options were passed to the selector - for example, if this is an enumFilter -
        // match the filterValue to those
        if (options && options.length > 0) {
            const selectedOptions: any = []
            if (Array.isArray(filterValue)) {
                const filterValueArray: (string | number)[] = filterValue
                options.forEach(option => {
                    // TODO: The option may be formatted correctly (with a data property) at this point -
                    // but it may not. SelectorWrapper formats the options correctly, but this function
                    // is called before that happens, and this has to match the original format to the
                    // filterValue. For example, if an enum filter receives a filter value from session storage,
                    // it will get matched up here before the options have a chance to be formatted.
                    // This has to be straightened out one way or the other (maybe the enum filters should
                    // use the data property off the bat), but for now, this additional check
                    // solves the issue
                    // NOTE: The check for option?.label === fv will catch enum filters that are populated
                    // from session storage - for example, Project Status. The option?.value === fv check
                    // will catch enum filters that use a defaultGetter, which sets the value instead of the
                    // label - the only one of those is productionCostCodesFilterDef.
                    if (
                        filterValueArray.find(
                            fv => option?.data?.name === fv || option?.label === fv || option?.value === fv
                        )
                    ) {
                        selectedOptions.push(option)
                    }
                })
            } else {
                selectedOptions.push(
                    options.find(
                        // TODO: See comment above - this shouldn't have to support both formats of option
                        // here - it would be better if it was cleaned up before we got here
                        option =>
                            option?.data?.name === filterValue ||
                            option?.label === filterValue ||
                            option?.value === filterValue
                    )
                )
            }
            // Format the options to have the name property that the selector expects
            return selectedOptions.map((option: { [key: string]: any }) => {
                return { name: option?.label ?? "" }
            })
        }

        const getSelectedOptions = () => {
            const cachedResourceName = resourceName as tCachedResourceName
            // If we have a referenceable filter, we use any loaded referenceables as backups
            // in case we don't have session storage (like if we created a new tab).
            if (getFlagEnabled("WA-7926-rr-filter-save")) {
                const cookieFilters = getCookie(`filters_${currentUser.id}`)
                const cookieFilterDict = cookieFilters && JSON.parse(cookieFilters)
                const selectorFilter = cookieFilterDict?.[primaryKey]
                let backupValue: tResourceObject[] = []
                let valueCount = 0
                if (selectorFilter && referenceableData[cachedResourceName]) {
                    if (Array.isArray(selectorFilter)) {
                        backupValue = selectorFilter
                            .filter((el: number) => referenceableData[cachedResourceName][el])
                            .map((el: number) => referenceableData[cachedResourceName][el])
                        valueCount = backupValue.length
                    } else {
                        backupValue = [referenceableData[cachedResourceName][selectorFilter]]
                        valueCount = 1
                    }
                }

                // The FilterController component handles loading the needed referenceable data,
                // but we might have to wait a minute for that so we try and maintain a loading state
                if (cachedResourceName) {
                    if (selectorFilter && backupValue.length !== valueCount && !isLoading) {
                        setIsLoading(true)
                    } else if (selectorFilter && backupValue.length === valueCount && isLoading) {
                        setIsLoading(false)
                    }
                }
                const primaryValue = window.sessionStorage.getItem(primaryKey)
                return primaryValue ? Object.values(JSON.parse(primaryValue)) : backupValue
            } else {
                return Object.values(JSON.parse(window.sessionStorage.getItem(primaryKey) || "{}"))
            }
        }

        // Otherwise, fetch the selected options from the local storage ...
        const selectedOptions = getSelectedOptions()

        // ... and if applicable, add the contents of the saved filter set
        const selectedOptionsFromSavedFilter = Object.values(
            activeSavedFilter?.filter_selections?.[primaryKey] ?? {}
        )

        // Combine the option lists, weeding out duplicates
        selectedOptionsFromSavedFilter.forEach((savedOption: any) => {
            if (
                !selectedOptions.find((sessionOption: any) => {
                    return (
                        (sessionOption["id"] && sessionOption["id"] === savedOption["id"]) ||
                        (sessionOption["name"] && sessionOption["name"] === savedOption["name"])
                    )
                })
            ) {
                selectedOptions.push(savedOption)
            }
        })

        if (Array.isArray(filterValue)) {
            const filterSet = new Set<string | number>(filterValue)
            const firstItem = filterValue[0]
            return selectedOptions.filter((option: any) => {
                const { id, name } = option
                // Check whether the filter in the local storage stores "id" or "name."
                if (id && rmbx.util.isNumber(firstItem)) return filterSet.has(id)
                if (name && typeof firstItem === "string") return filterSet.has(name)
            })
        }

        return selectedOptions.filter((option: any) => {
            const result = option?.id === filterValue || option?.name === filterValue
            return result
        })
    }

    const isInactive = !filterValue || (Array.isArray(filterValue) && !filterValue.length)
    const baseClassName = className ? `${className} selector-filter` : "selector-filter"
    const isMulti = multiselect
    const isClearable = isMulti || clearable

    // TODO: Should this be in a common location?
    // Selector values that are not tied to a resource may not have a titleFormatter or valueFormatter
    // specified for them. In that case, use a simple default formatter (otherwise, nothing will render)
    // Note that this is protected by a flag because it does not work in the v1 selector
    const defaultValueFormatter = getValueFormatter(["/name"])
    const selectorProps = {
        isClearable,
        clearText: clearText,
        filters: relatedFilters,
        onChange: onChange,
        prefixFormatter: prefixFormatter,
        primarySubtitleFormatter: primarySubtitleFormatter,
        resourceName: resourceName,
        secondarySubtitleFormatter: secondarySubtitleFormatter,
        titleFormatter: titleFormatter ?? defaultValueFormatter,
        valueFormatter: valueFormatter ?? defaultValueFormatter,
    }

    return (
        <div
            className={isInactive ? `${baseClassName} selector-filter-inactive` : baseClassName}
            id={`filter-${primaryKey}`}
        >
            <SelectorWrapper
                {...selectorProps}
                innerRef={innerRef}
                isLoading={isLoading}
                isMulti={isMulti}
                onMenuCloseCallBack={dispatchFilterChange}
                shouldClearCache={shouldClearCache}
                updateClearCache={updateClearCache}
                optionList={options}
                value={getSelectedOptions()}
            />
        </div>
    )
}

export default SelectorFilter
