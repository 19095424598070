import React from "react"
import styled from "styled-components"
import DefaultCellRenderer from "./default-cell-renderer"
import { isGroupRow } from "../../../common/ag-grid-utils"
import { ICellRendererParams } from "ag-grid-community"
import CicoEmployeeCellRenderer from "./cico-employee-cell-renderer"

const GroupRowFormattingDefaultCellRenderer = (
    props: ICellRendererParams & { includeCico: boolean }
): JSX.Element => {
    const CellRenderer = props.includeCico ? CicoEmployeeCellRenderer : DefaultCellRenderer
    return isGroupRow(props) ? (
        <GroupSummaryCell>
            <CellRenderer {...props} />
        </GroupSummaryCell>
    ) : (
        <CellRenderer {...props} />
    )
}

const GroupSummaryCell = styled.div`
    font-weight: 700;
`

export default GroupRowFormattingDefaultCellRenderer
