const initialState = {
    active: false,
}

export default function loadingIndicator(state = initialState, action) {
    switch (action.type) {
        case "LOADING_INDICATOR_STARTED":
            return Object.assign({}, state, { active: true })
        case "LOADING_INDICATOR_STOPPED":
            return Object.assign({}, state, { active: false })
        default:
            return state
    }
}
