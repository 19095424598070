import { tNotificationState, iShowNotificationAction, iHideNotificationAction } from "./types"

const initialState: tNotificationState = {
    message: "",
}

export const notification = (
    state: tNotificationState = initialState,
    action: iShowNotificationAction | iHideNotificationAction
): tNotificationState => {
    switch (action.type) {
        case "SHOW_NOTIFICATION": {
            return {
                ...state,
                message: action.payload.message,
            }
        }
        case "HIDE_NOTIFICATION": {
            return {
                ...state,
                message: action.payload.message,
            }
        }
        default: {
            return state
        }
    }
}
