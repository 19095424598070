import React from "react"

import { actOnEnterOrSpace } from "../../../common/js-utils"

require("pivot-headers.less")

export class HeaderSort extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ascSort: "inactive",
            descSort: "inactive",
            noSort: "active",
        }
    }

    componentDidMount() {
        this.props.column.addEventListener("sortChanged", this.onSortChanged)
    }

    componentWillUnmount() {
        this.props.column.removeEventListener("sortChanged", this.onSortChanged)
    }

    onSortChanged = () => {
        this.setState({
            ascSort: this.props.column.isSortAscending() ? "active" : "inactive",
            descSort: this.props.column.isSortDescending() ? "active" : "inactive",
            noSort:
                !this.props.column.isSortAscending() && !this.props.column.isSortDescending()
                    ? "active"
                    : "inactive",
        })
    }

    onSortRequested(order, event) {
        this.props.setSort(order, event.shiftKey)
    }

    render() {
        return (
            <span className="total-column-pivot-sort">
                <span
                    onClick={this.onSortRequested.bind(this, "desc")}
                    onKeyDown={e => actOnEnterOrSpace(e, () => this.onSortRequested.bind(this, "desc"))}
                    role="button"
                    tabIndex="0"
                    className={`ag-header-icon ag-sort-ascending-icon ${this.state.ascSort}`}
                >
                    <span className="ag-icon ag-icon-asc" />
                </span>
                <span
                    onClick={this.onSortRequested.bind(this, "nosort")}
                    onKeyDown={e => actOnEnterOrSpace(e, () => this.onSortRequested.bind(this, "nosort"))}
                    role="button"
                    tabIndex="0"
                    className={`ag-header-icon ag-sort-descending-icon ${this.state.descSort}`}
                >
                    <span className="ag-icon ag-icon-desc" />
                </span>
                <span
                    onClick={this.onSortRequested.bind(this, "asc")}
                    onKeyDown={e => actOnEnterOrSpace(e, () => this.onSortRequested.bind(this, "asc"))}
                    role="button"
                    tabIndex="0"
                    className={`ag-header-icon ag-sort-none-icon ${this.state.noSort}`}
                >
                    <span className="ag-icon ag-icon-none" />
                </span>
            </span>
        )
    }
}
