// TODO: We could obviously cover more of the application as it's needed -
// these identifiers just get us started

/**
 * Define the id value for tables. This can be useful for identifying a table in code, as an
 * alternative to using tableName
 */
export const TableIds = {
    TimekeepingModal: 11,
    ShiftsAndBreaksModal: 13,
}
