"use strict"
import React, { Component } from "react"
import PropTypes from "prop-types"

export class SparklinesSpots extends Component {
    static propTypes = {
        budget: PropTypes.number,
        size: PropTypes.number,
        style: PropTypes.object,
        spotColors: PropTypes.object,
    }

    static defaultProps = {
        size: 2,
        spotColors: {
            "-1": "red",
            0: "black",
            1: "green",
        },
    }

    render() {
        const { points, size, data, budget } = this.props
        const spots = []
        for (let i = 0; i < data.length; i++) {
            const amount = data[i]
            let color = "orange"
            if (amount >= budget) {
                color = "green"
            } else if (amount < budget) {
                color = "red"
            }
            spots.push(<circle cx={points[i].x} cy={points[i].y} r={size} style={{ fill: color }} />)
        }
        return <g>{spots}</g>
    }
}

export class SparklinesLine extends Component {
    static propTypes = {
        color: PropTypes.string,
        style: PropTypes.object,
    }

    static defaultProps = {
        style: {},
        invert: false,
        onMouseMove: () => {},
    }

    render() {
        const { data, points, height, margin, color, style, onMouseMove } = this.props
        const fillInvert = style.fillInvert ? style.fillInvert : false
        const linePoints = points.map(p => [p.x, p.y]).reduce((a, b) => a.concat(b))

        const closePolyPoints = [
            points[points.length - 1].x,
            fillInvert ? margin : height - margin,
            margin,
            fillInvert ? margin : height - margin,
            margin,
            points[0].y,
        ]

        const fillPoints = linePoints.concat(closePolyPoints)

        const lineStyle = {
            stroke: color || style.stroke || "slategray",
            strokeWidth: style.strokeWidth || "1",
            strokeLinejoin: style.strokeLinejoin || "round",
            strokeLinecap: style.strokeLinecap || "round",
            fill: "none",
        }
        const fillStyle = {
            stroke: style.stroke || "none",
            strokeWidth: "0",
            fillOpacity: style.fillOpacity || ".1",
            fill: style.fill || color || "slategray",
            pointerEvents: "auto",
        }

        const tooltips = points.map((p, i) => {
            return (
                <circle
                    key={i}
                    cx={p.x}
                    cy={p.y}
                    r={2}
                    style={fillStyle}
                    onMouseEnter={() => onMouseMove("enter", data[i], p)}
                    onClick={() => onMouseMove("click", data[i], p)}
                />
            )
        })

        return (
            <g>
                {tooltips}
                <polyline points={fillPoints.join(" ")} style={fillStyle} />
                <polyline points={linePoints.join(" ")} style={lineStyle} />
            </g>
        )
    }
}
