import { ServerSideRowModelAction } from "../actions/server-side-row-model"
type SSRMState = {
    performDataRefresh: boolean
}

const initialState: SSRMState = {
    performDataRefresh: false,
}

const serverSideRowModel = (state = initialState, action: ServerSideRowModelAction): SSRMState => {
    switch (action.type) {
        case "PERFORM_DATA_REFRESH":
            return { ...state, performDataRefresh: action.shouldRefresh }
        default:
            return state
    }
}

export default serverSideRowModel
