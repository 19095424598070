export default function (state = {}, action) {
    switch (action.type) {
        case "UPDATE_BUDGET_ITEMS":
            return action.data.reduce((acc, bi) => {
                if (!acc[bi.cost_code]) acc[bi.cost_code] = { erp: {}, field: {} }
                acc[bi.cost_code][bi.source.toLowerCase()] = bi
                return acc
            }, state)
        default:
            return state
    }
}
