import styled from "styled-components"
import React from "react"
import { iTimekeepingStatus } from "../../../cached-data/types"

import { colorFunctionalBlack, colorFunctionalWhite } from "@rhumbix/rmbx_design_system_web"

type Props = {
    data: iTimekeepingStatus
}

export const ColorPickerCellRenderer: React.FC<Props> = ({ data }) => {
    return (
        <div data-testid={"color-picker"}>
            <ColorSwatch
                style={{
                    backgroundColor: data.is_outline ? colorFunctionalWhite : `#${data.selected_color}`,
                    borderWidth: data.is_outline ? "3px" : "1px",
                    borderColor: data.is_outline ? `#${data.selected_color}` : colorFunctionalBlack,
                }}
            />
        </div>
    )
}

export default ColorPickerCellRenderer

const ColorSwatch = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border-style: solid;
    margin: auto;
`
