import React from "react"
import StandardModal from "./modals/modal-standard"

const SuccessModal = props => (
    <StandardModal
        open={props.open}
        noButton={true}
        autoClose={false}
        closeMethod={props.closeMethod}
        size="success-modal"
    >
        <img className="success-dialog-icon" src="/static/images/success.svg" alt="Success" />
        <div className="success-dialog-text">Sent!</div>
    </StandardModal>
)

export default SuccessModal
