import React, { useState, useEffect } from "react"
import UserInfo from "./user-info"
import RhumbixReferral from "./rhumbix-referral"
import CompanySettings from "./menus/company-settings"
import ProjectSettings from "./menus/project-settings"
import Analytics from "./menus/analytics"
import FieldForms from "./menus/field-forms"
import Reports from "./menus/reports"
import TimeCards from "./menus/time-cards"
import DemoDashboards from "./menus/demo-dashboards"
import BetaDashboards from "./menus/beta-dashboards"
import ExternalLinks from "./menus/external-links"
import Logo from "./logo"
import Status from "./status"
import UserMenu from "./user-menu"
import { useLocation, matchPath } from "react-router-dom"
import { protectedRoutes } from "../../router/routes"
import { getFlagEnabled } from "../../getFlagValue"

import "sidebar.less"

const MENU_GROUPS = [
    "email-alerts",
    "company-settings",
    "project-settings",
    "analytics",
    "field-forms",
    "reports",
    "time-cards",
    "demo-dashboards",
    "beta-dashboards",
    "external-links",
]

const initialMenuState = {
    [MENU_GROUPS]: false,
}

const allRoutes = [...protectedRoutes, { path: "/rhumbix/field-forms/list-view/:slug", navCategory: "field-forms" }]

export const getDefaultMenuGroup = currentPath => {
    let defaultMenuGroup = "time-cards"
    if (!matchPath(currentPath, "/rhumbix/time-cards/:weekly-or-daily/")) {
        const currentRoutes = allRoutes.filter(route => matchPath(currentPath, route.path))

        if (currentRoutes.length) {
            const route = currentRoutes.find(possibleRoute => possibleRoute.navCategory)
            defaultMenuGroup = route && MENU_GROUPS.includes(route.navCategory) ? route.navCategory : "time-cards"
        }
    }
    return defaultMenuGroup
}

export const Sidebar = props => {
    const location = useLocation()
    const [isSidebarCollapsed, toggleSideMenu] = useState(false)
    const [menuGroupsExpandedState, toggleMenuGroupsExpand] = useState({
        ...initialMenuState,
        [getDefaultMenuGroup(location.pathname)]: true,
    })
    /**
     * If the location / current menu group changes, and the route we're on is the "first time project setup"
     * then collapse every menu group except for project settings. This is to handle the case where a new user
     * lands on the new project page.
     */
    useEffect(() => {
        if (location.pathname === "/rhumbix/projects/") {
            collapseAllButOne("company-settings")
        }
    }, [location, props.currentMenuGroup])

    const currentUserId = props.user_id
    /**
     * If current user is not set, then don't render the Sidebar.
     */
    if (!currentUserId) {
        return null
    }
    const collapsedStateClass = isSidebarCollapsed ? "collapsed-state" : ""

    const handleCollapseExpandClick = menuTitle => {
        toggleMenuGroupsExpand({
            ...menuGroupsExpandedState,
            [menuTitle]: !menuGroupsExpandedState[menuTitle],
        })
    }

    const collapseAllButOne = menuTitle => {
        toggleMenuGroupsExpand({
            ...initialMenuState,
            [menuTitle]: true,
        })
    }

    //Callback passed down as a prop to Logo
    const handleToggleSideMenu = isCollapsed => {
        //If the sidebar is being expanded, collapse all but the active menu group.
        if (!isCollapsed && props.currentMenuGroup) {
            collapseAllButOne(props.currentMenuGroup)
        }
        toggleSideMenu(isCollapsed)
    }

    const propsWithSidebarState = { ...props, isSidebarCollapsed }
    const propsWithStateAndHandler = { ...propsWithSidebarState, handleClick: handleCollapseExpandClick }

    return (
        <div className={`sidebar-nav-component ${collapsedStateClass}`}>
            <Logo {...propsWithSidebarState} toggleSideMenu={handleToggleSideMenu} />
            <UserInfo {...propsWithSidebarState} />
            <nav aria-labelledby="Primary Navigation">
                {(!getFlagEnabled("RN-1757-cico-worker-experience") ||
                    (getFlagEnabled("RN-1757-cico-worker-experience") && props.user_role !== "WORKER")) && (
                    <>
                        <DemoDashboards
                            {...propsWithStateAndHandler}
                            isExpanded={menuGroupsExpandedState["demo-dashboards"]}
                        />
                        <BetaDashboards
                            {...propsWithStateAndHandler}
                            isExpanded={menuGroupsExpandedState["beta-dashboards"]}
                        />
                        <TimeCards
                            {...propsWithStateAndHandler}
                            isExpanded={menuGroupsExpandedState["time-cards"]}
                        />
                        <Reports {...propsWithStateAndHandler} isExpanded={menuGroupsExpandedState["reports"]} />
                        <FieldForms
                            {...propsWithStateAndHandler}
                            isExpanded={menuGroupsExpandedState["field-forms"]}
                            menuGroupsExpandedState={menuGroupsExpandedState}
                        />
                        <Analytics
                            {...propsWithStateAndHandler}
                            isExpanded={menuGroupsExpandedState["analytics"]}
                        />
                        {getFlagEnabled("WA-7796-enhance-custom-links") && (
                            <ExternalLinks
                                {...propsWithStateAndHandler}
                                isExpanded={menuGroupsExpandedState["external-links"]}
                            />
                        )}
                        <ProjectSettings
                            {...propsWithStateAndHandler}
                            isExpanded={menuGroupsExpandedState["project-settings"]}
                        />
                        <CompanySettings
                            {...propsWithStateAndHandler}
                            isExpanded={menuGroupsExpandedState["company-settings"]}
                        />
                    </>
                )}
                <UserMenu {...propsWithStateAndHandler} isExpanded={menuGroupsExpandedState["email-alerts"]} />
                {(!getFlagEnabled("RN-1757-cico-worker-experience") ||
                    (getFlagEnabled("RN-1757-cico-worker-experience") && props.user_role !== "WORKER")) && (
                    <RhumbixReferral
                        isHidden={!props.features["custom_form"] || !props.features["tm_invite_campaign"]}
                        isInviteWindowHidden={true}
                        miniReferral={isSidebarCollapsed}
                        isSidebarCollapsed={isSidebarCollapsed}
                    />
                )}
            </nav>
            <Status {...propsWithSidebarState} />
        </div>
    )
}

export default Sidebar
