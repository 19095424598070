export const error: Record<string, string> = {
    company_name_missing: "Please enter your company name.",
    completionDate_before_startDate: "End Date is before Start Date",
    cost_codes_missing: "No cost codes found, please edit your csv and reupload.",
    email_missing: "Please enter your email.",
    email_regex: "Please enter a valid email address.",
    equipment_missing: "No equipment found, please edit your csv and reupload.",
    first_name_missing: "Please enter your first name.",
    foreman_phone_type_missing: "Please select at least 1 device type.",
    gt_three_months: "Please limit exports to three months.",
    improper_phone: "Not a proper phone number.",
    invalidDate: "Invalid date format. Please use YYYY-MM-DD",
    job_number_missing: "Please enter a Job #.",
    last_name_missing: "Please enter your last name.",
    login_auth_failed:
        "We could not find that email/password combination. Please try typing in your login information again.",
    materials_missing: "No materials found, please edit your csv and reupload.",
    maximumRowsExceeded: "Please limit number of rows in one import to",
    missingField: "Missing field",
    mobile_phone_missing: "Please enter your phone number.",
    password_content: "Must contain at least 1 number.",
    password_expired: "Your password has expired. Please check your email for a link to reset your password.",
    password_length: "Must be at least 8 characters.",
    policiesNotAccepted: "Click the check box to accept the Rhumbix policies.",
    project_address_missing:
        "Please type at least an approximate address (city) and select from the drop-down " +
        "to set the project timezone.",
    projects_missing: "No projects found, please edit your csv and reupload.",
    project_name_missing: "Please enter a project name.",
    sso_login_user_not_found: "We could not find this user in our database. Please contact an administrator.",
    startDate_after_completionDate: "Start Date is after Completion Date",
    workers_missing: "No workers found, please edit your csv and reupload.",
}
