import {
    CLEAR_NOTIFICATION_DETAILS,
    iWebsocket,
    PDF_CREATING,
    PDF_ERROR,
    PDF_READY,
    SET_NOTIFICATION_MESSAGE,
    tNotificationTypes,
    tWebsocketActions,
    WS_CONNECTED,
    WS_DISCONNECTED,
    WS_MESSAGE_RECEIVED,
} from "./types"

import { iActionData } from "../common/types"
import { downloadFile } from "./actions"
import { getFlagEnabled } from "../getFlagValue"

export type tSocketNotificationState = {
    connected: boolean
    filename?: string
    // if the user takes the action, they don't always need a notification
    ignoreIds?: Record<string, number[] | undefined>
    message?: string
    notificationType?: tNotificationTypes
    pdfMessage?: typeof PDF_READY | typeof PDF_CREATING | typeof PDF_ERROR // used on single edit pdf download
    socket: iWebsocket | null
    showNotification: boolean
    uniqueRequestId?: string
    updatedIds?: Record<string, number[] | undefined>
    autoCloseSeconds?: number // if given will auto-close after provided number of seconds
}

const websocketNotificationInitialState: tSocketNotificationState = {
    connected: false,
    filename: undefined,
    ignoreIds: {
        companyFormStores: undefined,
    },
    message: undefined,
    pdfMessage: undefined,
    notificationType: "info",
    showNotification: false,
    socket: null,
    uniqueRequestId: undefined,
    updatedIds: {
        companyFormStores: undefined,
    },
    autoCloseSeconds: undefined,
}

/**
 * Original setup of websockets only allows for one active socket to be stored in the state at a time
 * updatedIds and ignoreIds currently have nested id lists based on resource
 * (currently field forms is the only place using them). It is unclear what future use cases might be,
 * so that nesting may prove to be unnecessary. Open to the idea of removing that nesting if it's not needed
 * or adjusting this state to allow for multiple sockets for different resources.
 * Sockets are currently being used for Field Forms Bulk Modify and PDF Download.
 */
export const socketNotification = (
    state = { ...websocketNotificationInitialState },
    action: tWebsocketActions
): tSocketNotificationState => {
    switch (action.type) {
        case WS_CONNECTED:
            if ("socket" in action) {
                return { ...state, connected: true, socket: action.socket }
            } else {
                return { ...state, ...websocketNotificationInitialState }
            }
        case WS_MESSAGE_RECEIVED:
            if ("event" in action) {
                const e = action.event
                // if the data is a Blob it is a file from the server
                // check for uniqueRequestId before saving so we don't download in a browser that didn't request it
                if (e.data instanceof Blob) {
                    // if the filename and request id are not set, the request wasn't made by this browser
                    if (!state?.filename || !state?.uniqueRequestId) {
                        return { ...state }
                    }
                    // only close the notification if the current message is about a pending download
                    const closeNotification = state.message && state.message.toLowerCase().includes("download")
                    // file download should trigger as soon as they arrive
                    downloadFile(e.data, state.filename)
                    // once the file is download clear out the state's file details
                    return {
                        ...state,
                        filename: undefined,
                        uniqueRequestId: undefined,
                        // close the notification if it was open, don't reopen if it it is already false
                        showNotification: state.showNotification && !closeNotification,
                        // if we're getting a file it's for download - no text message included with binary messages
                    }
                } else {
                    const data = JSON.parse(e.data)
                    const { ids: updatedIds, message, notification_type = "info" } = data
                    switch (message) {
                        case "Connected":
                            // don't display the initial connected message, just update connected status
                            return { ...state, connected: true }
                        case "File download ready":
                            // this message will come in right before a file message, so set filename and request id
                            const { filename, uniqueRequestId } = data
                            if (uniqueRequestId !== state.uniqueRequestId) {
                                // if the request id doesn't match, don't download
                                // could happen if user has multiple browser windows open
                                return { ...state }
                            }
                            return { ...state, filename, uniqueRequestId }
                        case PDF_READY:
                            // this will come after a new PDF is generated - remove the id from ignoreIds
                            const updatedIgnore =
                                getFlagEnabled("WA-7925-ff-status-update-pdf") &&
                                Array.isArray(state.ignoreIds?.companyFormStores)
                                    ? state.ignoreIds?.companyFormStores.filter(id => id !== updatedIds[0])
                                    : []

                            return {
                                ...state,
                                pdfMessage: message,
                                ...(getFlagEnabled("WA-7925-ff-status-update-pdf")
                                    ? {
                                          ignoreIds: {
                                              companyFormStores: updatedIgnore,
                                          },
                                      }
                                    : {}),
                            }
                        case PDF_ERROR:
                            // this will come if a PDF fails to generate
                            return { ...state, pdfMessage: message }
                        default:
                            let idsToUpdate = updatedIds
                            if (state?.ignoreIds?.companyFormStores) {
                                idsToUpdate = updatedIds.filter(
                                    (id: number) => !state?.ignoreIds?.companyFormStores?.includes(id)
                                )
                            }
                            // we may get more specific about messages, but this works for the current use cases
                            return {
                                ...state,
                                message: message,
                                notificationType: notification_type,
                                showNotification: updatedIds?.length ? !!idsToUpdate?.length : true,
                                updatedIds: {
                                    companyFormStores: idsToUpdate,
                                },
                                // this allows us to unlock the download button in event of an error
                                uniqueRequestId: notification_type !== "error" ? state.uniqueRequestId : undefined,
                            }
                    }
                }
            } else {
                return { ...state }
            }
        case WS_DISCONNECTED:
            // clear everything if the socket is killed
            return { ...websocketNotificationInitialState }
        case CLEAR_NOTIFICATION_DETAILS:
            // reset the notification so it is fresh for the next one
            return {
                ...websocketNotificationInitialState,
                socket: state.socket,
                connected: state.connected,
                uniqueRequestId: state?.uniqueRequestId,
                autoCloseSeconds: undefined,
                showNotification: false,
            }
        case SET_NOTIFICATION_MESSAGE:
            // allows us to set notifications without a websocket message - can be used for progress messaging
            const { data } = action as iActionData
            const { message, notificationType = "info", uniqueRequestId, ignoreIds, autoCloseSeconds } = data
            if (message === PDF_CREATING) {
                // this will come in when new PDF is being generated
                // we update ignoreIds so the user doesn't get notified about their own changes
                return { ...state, pdfMessage: message, ignoreIds }
            }
            return {
                ...state,
                message,
                notificationType,
                showNotification: true,
                uniqueRequestId,
                autoCloseSeconds,
            }
        default:
            return state
    }
}
