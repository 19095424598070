import { tFilterToStringParams } from "./types"
import { referenceablesToValueFormatters } from "../common/referenceable-value-formatters"
import { tCachedResourceName, tEntitySelectionReturn } from "../cached-data/types"
import { dateValueFormatter } from "../common/ag-grid-value-formatters"

export const referenceableIdsToString = (params: tFilterToStringParams): string => {
    const formatter = referenceablesToValueFormatters[params.resourceName as tCachedResourceName]
    const valueFormatter = formatter ? formatter.valueFormatter : undefined
    let refIds: Array<number> | number = params.primaryValue as Array<number> | number
    if (!Array.isArray(refIds)) {
        refIds = [refIds]
    }

    if (params.context) {
        const refData = params.context.referenceableData[params.resourceName as tCachedResourceName] as {
            [key: number]: tEntitySelectionReturn
        }

        return refIds
            .map(id => {
                // If the refId is a numerical ID, it will be used to look up the related entity -
                // but in some cases, refId is a string which can be displayed as-is, so use that as a
                // fallback
                return valueFormatter && typeof id === "number"
                    ? valueFormatter({
                          value: refData[id as number],
                          context: params.context,
                      })
                    : id
            })
            .join(", ")
    } else {
        throw new Error("Parameter context is missing in referenceableIdsToString")
    }
}

export const dateToString = (params: tFilterToStringParams): string => {
    const startDate = dateValueFormatter({ value: params.primaryValue })
    const endDate = dateValueFormatter({ value: params.secondaryValue })
    return startDate && endDate && startDate !== endDate ? `${startDate} - ${endDate}` : startDate
}

export const enumLabelToString = (params: tFilterToStringParams): string => {
    return params.enumLabel ? params.enumLabel : ""
}

export const defaultFilterToString = (params: tFilterToStringParams): string => {
    return params.primaryValue ? params.primaryValue.toString() : ""
}

export const booleanToString = (params: tFilterToStringParams): string => {
    // leaving the false case as a "" means the filter won't display in the banner
    return params.primaryValue ? "Yes" : ""
}
