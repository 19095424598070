import _debounce from "lodash/debounce"
import Rmbx from "../util"
import { getDataFromSearchv4 } from "./get"
import { updateCacheSuccess } from "../cached-data/actions"

export const getDebouncedSearch = options =>
    _debounce(
        (inputValue, callback, currentResults, searchVariables) => {
            const pageSize = 25
            const page = parseInt(currentResults.length / pageSize, 10) + 1
            const { extraFilters } = searchVariables

            const queryParams = {
                ...extraFilters,
                page_size: pageSize,
                page,
                /*
            - If we are already using an exclude_project_id, we don't need to send a list of
            excluded ids, because the server will filter them out via the project id
            exclude_project_id is used in the project employees, equip, and materials.
            - extraFilters can be undefined.
            */
                exclude: extraFilters?.exclude_project_id ? null : searchVariables.idsToExclude,
            }

            getDataFromSearchv4(inputValue, searchVariables.resourceName, queryParams)
                .then(response => {
                    // Add these items to the redux cache. There is TECHNICALLY the possibility
                    // of a race condition here, in that it might be possible for the user to
                    // make a selection before the reducer has added these items to the cache.
                    //
                    // To completely avoid that, we'd have to do something ugly like add logic
                    // that waits for the redux store to contain all these items before returning
                    // them as options for the select dropdown.
                    const normalizedData = Rmbx.util.formatApiResponse(
                        response.results,
                        searchVariables.resourceName
                    )
                    searchVariables.dispatch(updateCacheSuccess(normalizedData, "AUTOCOMPLETE_SEARCH_SUCCESS"))
                    callback(null, {
                        options: response.results,
                        hasMore: !!response.next,
                    })
                })
                .catch(error => callback(error, null))
        },
        500,
        // This option toggles whether we avoid the debounce delay on the initial fetch (default: false).
        // Helpful to turn this off for our selectors where we usually double-click first.
        options
    )
