import React, { Suspense, lazy } from "react"
import LoadingIndicator from "../components/loading-indicator"

// a function to retry loading a chunk to avoid chunk load error for out-of-date code
const lazyRetry = function (dynamicImport) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(window.sessionStorage.getItem("retry-lazy-refreshed") || "false")
        // try to import the component
        dynamicImport()
            .then(component => {
                window.sessionStorage.setItem("retry-lazy-refreshed", "false") // success so reset the refresh
                resolve(component)
            })
            .catch(error => {
                if (!hasRefreshed) {
                    // not been refreshed yet
                    window.sessionStorage.setItem("retry-lazy-refreshed", "true") // we are now going to refresh
                    return window.location.reload() // refresh the page
                }
                reject(error)
            })
    })
}

/**
 * Returns a lazy-loaded component
 *
 * This makes testing much easier, since Enzyme does not yet support React.lazy
 * or React.Suspense: this wrapper allows you to shallow render the AppRouter
 * without enzyme trying (and failing) to render `<Suspense>`. It also gives us
 * the ability to specify per-route loading indicators in a DRY fashion.
 *
 * `dynamicImport` should be a function of the form:
 *
 *      () => import("./path/to/component")
 *
 * This indirection is necessary because `import` was designed to be statically
 * analyzable and so cannot take a variable as an argument (Babel will just
 * emit a warning... for now).
 *
 * `Fallback` is the component to render while the lazy-loaded component is
 * loading. If no `Fallback` is provided, the default `LoadingIndicator` will
 * be used.
 */
const asyncComponent = (dynamicImport, Fallback) => {
    const Component = lazy(() => lazyRetry(dynamicImport))
    const fallback = Fallback ? <Fallback /> : <LoadingIndicator active={true} />
    return props => (
        <Suspense fallback={fallback}>
            <Component {...props} />
        </Suspense>
    )
}

export default asyncComponent
