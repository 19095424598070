const initialState = {
    employees: {
        data: [],
    },
    cost_codes: {
        data: [],
    },
    equipment: {
        data: [],
    },
    materials: {
        data: [],
    },
}

export default function csv_data(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_EMPLOYEES_CSV_DATA":
            return Object.assign({}, state, {
                employees: { data: action.data },
            })
        case "UPDATE_COST_CODES_CSV_DATA":
            return Object.assign({}, state, {
                cost_codes: { data: action.data },
            })
        case "UPDATE_EQUIPMENT_CSV_DATA":
            return Object.assign({}, state, {
                equipment: { data: action.data },
            })
        case "UPDATE_MATERIAL_CSV_DATA":
            return Object.assign({}, state, {
                materials: { data: action.data },
            })
        case "CLEAR_EMPLOYEES_CSV_DATA":
            return Object.assign({}, state, { employees: { data: [] } })
        case "CLEAR_EQUIPMENT_CSV_DATA":
            return Object.assign({}, state, { equipment: { data: [] } })
        case "CLEAR_MATERIAL_CSV_DATA":
            return Object.assign({}, state, { materials: { data: [] } })
        default:
            return state
    }
}
